import { capitalize, debounce, uniqueId } from "lodash";
import { ReactComponent as TableIcon } from "../../assets/icons/table.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { Popover } from "reactstrap";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CrossIcon } from "@/assets/icons/cross.svg";
import { ReactComponent as UserIcon } from "@/assets/icons/user.svg";
import { ReactComponent as RobotIcon } from "@/assets/icons/robot.svg";
import { Tooltip } from "react-tooltip";

const LEVEL_COLOR = {
  warning: styles.level_orange,
  error: styles.level_red,
};

const COLOR_MAP = {
  blue: styles.level_blue,
  orange: styles.level_orange,
  red: styles.level_red,
  green: styles.level_green,
  dark_green: styles.level_dark_green,
  yellow: styles.level_yellow,
  purple: styles.level_purple,
};

const RECT_COLOR_MAP = {
  lightGreen: styles.level_light_green,
  purple: styles.level_purple,
  pink: styles.level_pink,
};

const ColorTag = ({ color, label }) => (
  <>
    {label.length > 25 && (
      <Tooltip id={label}>
        <div className="d-flex flex-column align-items-left gap-xs justify-content-center">
          {label}
        </div>
      </Tooltip>
    )}
    <div
      data-tooltip-id={label}
      className={classnames(styles.level_tag, COLOR_MAP[color] || color)}
    >
      {label.length > 25 ? `${label.slice(0, 25)}...` : label}
    </div>
  </>
);

const RectColorTag = ({ color, label }) => (
  <div
    className={classnames(
      styles.level_tag_rectangle,
      RECT_COLOR_MAP[color] || color
    )}
  >
    {label}
  </div>
);

const LevelTag = ({ level }) => (
  <div className={classnames(styles.level_tag, LEVEL_COLOR[level])}>
    {capitalize(level)}
  </div>
);

const ModelTag = ({ label }) => {
  return (
    <div className={classnames(styles.model_tag, "overflow-x-hidden")}>
      <TableIcon className="me-2" />
      <div className="text-overflow">{label}</div>
    </div>
  );
};

const UserTag = ({ label }) => {
  return (
    <div className={classnames(styles.model_tag, "overflow-x-hidden")}>
      <UserIcon className="me-2" />
      <div className="text-overflow">{label}</div>
    </div>
  );
};

const TableTag = ({ table }) => {
  const [isOpen, setIsOpen] = useState(false);
  const debounceFn = useCallback(debounce(setIsOpen, 500, { trailing: true }), [
    setIsOpen,
  ]);
  const navigate = useNavigate();
  const id = useMemo(
    () => uniqueId("table-tag-") + table.resource_id,
    [table.resource_id]
  );
  return (
    <>
      <div
        className={classnames(styles.tag, "overflow-x-hidden")}
        onMouseEnter={() => debounceFn(true)}
        onMouseLeave={() => debounceFn(false)}
      >
        <TableIcon className="me-2" />
        <div id={id} className="fw-semibold text-overflow">
          {table.tablename}
        </div>
      </div>
      <Popover target={id} isOpen={isOpen} hideArrow placement="bottom">
        <div
          onMouseEnter={() => debounceFn(true)}
          onMouseLeave={() => debounceFn(false)}
        >
          <div
            className={classnames("p-2 text-primary", styles.link)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(false);
              navigate("/datasets/incidents", {
                state: {
                  table: {
                    id: table.resource_id,
                    scope: ["", table.database, table.schema, table.tablename],
                  },
                },
              });
            }}
          >
            <LinkIcon />
            <p>{table.path}</p>
          </div>
        </div>
      </Popover>
    </>
  );
};

const TableTags = ({ tables }) => (
  <div className={styles.tags}>
    {tables.map((item) => (
      <TableTag key={item.resource_id} table={item} />
    ))}
  </div>
);

const TableTagsWithMore = ({ tables, moreClick }) => (
  <div className={styles.tags}>
    {tables.map((item) => (
      <TableTag key={item.resource_id} table={item} />
    ))}
    <span className={styles.more} onClick={moreClick}>
      more
    </span>
  </div>
);

const BetaTag = () => <div className={styles.beta_tag}>Beta</div>;

export const CustomTag = ({ name }) => (
  <div className={styles.beta_tag}>{name}</div>
);

const Tags = ({ children }) => <div className={styles.tags}>{children}</div>;

const BG_COLOR = {
  grey: "#f5f6f7",
  white: "#ffffff",
};

const DEFAULT_DISPLAY_COUNT = 5;

const FilterTagWrapper = ({ filters }) => {
  const [displayCount, setDisplayCount] = useState(DEFAULT_DISPLAY_COUNT);
  const [showMore, setShowMore] = useState(true);

  // Flatten the filters into a single array
  const flatFilters = Object.values(filters).flatMap((filter) =>
    Array.isArray(filter.filterStr)
      ? filter.filterStr.map((filterStr) => ({
          filterStr,
          label: filter.label,
          onclose: () => filter.onclose(filterStr),
        }))
      : filter.filterStr
      ? [
          {
            filterStr: filter.filterStr,
            label: filter.label,
            onclose: () => filter.onclose(filter.filterStr),
          },
        ]
      : []
  );

  const handleMore = () => {
    setDisplayCount(flatFilters.length); // Display all filters
    setShowMore(false);
  };

  const handleLess = () => {
    setDisplayCount(DEFAULT_DISPLAY_COUNT); // Display only 5 filters
    setShowMore(true);
  };

  return (
    <div className={styles.filter_tags}>
      {flatFilters.slice(0, displayCount).map((filter, index) => (
        <FilterTag
          key={index}
          label={filter.label}
          filterStr={filter.filterStr}
          onClose={filter.onclose}
          bgColor={filter.bgColor || "grey"}
        />
      ))}
      {flatFilters.length > DEFAULT_DISPLAY_COUNT &&
        (showMore ? (
          <div
            className="text-primary d-flex align-items-center cursor-pointer"
            onClick={handleMore}
          >
            More
          </div>
        ) : (
          <div
            className="text-primary d-flex align-items-center cursor-pointer"
            onClick={handleLess}
          >
            Less
          </div>
        ))}
    </div>
  );
};

const FilterTag = ({ label, filterStr, onClose, bgColor = "grey" }) =>
  label && (
    <div
      className={styles.filter_tag}
      style={{ background: BG_COLOR[bgColor] }}
    >
      <span>
        {label}: <span className="text-primary">{filterStr}</span>
      </span>
      <div
        className={styles.close_icon}
        onClick={(e) => {
          e.stopPropagation();
          onClose?.();
        }}
      >
        <CrossIcon />
      </div>
    </div>
  );

const ModifiedByTag = ({ users, lastModifiedBy }) => {
  let label = "";
  if (lastModifiedBy === -1 || !lastModifiedBy) label = "Datapilot";
  else
    label =
      users?.find((u) => u.id === lastModifiedBy)?.first_name || "Datapilot";
  return (
    <div className={styles.last_update}>
      {lastModifiedBy === -1 ? <RobotIcon /> : <UserIcon />}
      <div>{label}</div>
    </div>
  );
};

const ResourceName = ({ resource }) => {
  const [isOpen, setIsOpen] = useState(false);
  const debounceFn = useCallback(debounce(setIsOpen, 500, { trailing: true }), [
    setIsOpen,
  ]);
  const navigate = useNavigate();
  const id = useMemo(
    () => uniqueId("recent-resource-") + resource.resource_id,
    [resource.resource_id]
  );
  return (
    <>
      <div
        className={classnames(styles.misc_tag, "text-overflow")}
        onMouseEnter={() => debounceFn(true)}
        onMouseLeave={() => debounceFn(false)}
      >
        <div id={id} className="fw-semibold text-overflow">
          {resource.resource_name}
        </div>
      </div>
      <Popover target={id} isOpen={isOpen} hideArrow placement="bottom">
        <div
          onMouseEnter={() => debounceFn(true)}
          onMouseLeave={() => debounceFn(false)}
        >
          <div
            className={classnames("p-2 text-primary", styles.link)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(false);
              navigate(`/datasets/${resource.resource_id}`);
            }}
          >
            <LinkIcon />
            <p>{resource.resource_path}</p>
          </div>
        </div>
      </Popover>
    </>
  );
};

const colors = ["blue", "orange", "yellow", "purple", "dark_green"];
const colorsMap = {};
let i = 0;

const ColorInsightTags = ({ tags, onClick, labelFn = (i) => i }) => {
  const isClickable = typeof onClick === "function";
  return (
    <Tags>
      {tags.map((t) => {
        const tag_rk = labelFn(t);
        if (!colorsMap[tag_rk]) colorsMap[tag_rk] = colors[i++ % colors.length];
        return (
          <div
            key={tag_rk}
            className={isClickable ? "cursor-pointer" : ""}
            onClick={() => {
              if (isClickable) onClick(t);
            }}
          >
            <ColorTag color={colorsMap[tag_rk]} label={tag_rk} />
          </div>
        );
      })}
    </Tags>
  );
};

export {
  TableTags,
  TableTagsWithMore,
  LevelTag,
  ColorTag,
  BetaTag,
  FilterTag,
  FilterTagWrapper,
  Tags,
  RectColorTag,
  ModifiedByTag,
  ResourceName,
  ColorInsightTags,
  ModelTag,
  UserTag,
};
