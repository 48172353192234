import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { DatasetsIcon, QueryGroupIcon, WarehouseIcon, UserIcon, RoleIcon, ClockBlackIcon, } from "@/assets/icons";
const resourceImageMap = {
    datasets: _jsx(DatasetsIcon, {}),
    query_group: _jsx(QueryGroupIcon, {}),
    query_hash: _jsx(QueryGroupIcon, {}),
    query: _jsx(QueryGroupIcon, {}),
    warehouse: _jsx(WarehouseIcon, {}),
    user: _jsx(UserIcon, {}),
    role: _jsx(RoleIcon, {}),
};
const blueResourceImageMap = {
    datasets: _jsx(DatasetsIcon, {}),
    query_group: _jsx(QueryGroupIcon, {}),
    query_hash: _jsx(QueryGroupIcon, {}),
    query: _jsx(QueryGroupIcon, {}),
    warehouse: _jsx(WarehouseIcon, {}),
    user: _jsx(UserIcon, {}),
    role: _jsx(RoleIcon, {}),
};
const defaultImage = _jsx(ClockBlackIcon, {});
export const getResourceImageByType = (type) => {
    const imageSrc = resourceImageMap[type] || defaultImage;
    const altText = type === "query_group"
        ? "QRY"
        : type === "query_hash"
            ? "QRY"
            : type === "query"
                ? "QRY"
                : type === "datasets"
                    ? "TBL"
                    : type === "user"
                        ? "USR"
                        : type === "role"
                            ? "ROL"
                            : type === "warehouse"
                                ? "WRH"
                                : "";
    return _jsx("img", { src: imageSrc, alt: altText });
};
export const getNamedResourceImageByType = (type) => {
    const imageSrc = blueResourceImageMap[type] || defaultImage;
    const altText = type === "query_group"
        ? "QRY"
        : type === "query_hash"
            ? "QRY"
            : type === "query"
                ? "QRY"
                : type === "datasets"
                    ? "TBL"
                    : type === "user"
                        ? "USR"
                        : type === "role"
                            ? "ROL"
                            : type === "warehouse"
                                ? "WRH"
                                : "";
    return (_jsxs("div", { className: "d-flex flex-column", style: { color: "#247EFE" }, children: [imageSrc, _jsx("div", { className: styles.resourceImageText, children: altText })] }));
};
