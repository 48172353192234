import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { InfoIcon } from "@/assets/icons";
import { Stack } from "@uicore";
const changePercent = (item, data) => {
    if (item.showChangePercentage) {
        const change = data[`percentage_${item.value}`];
        if (change) {
            const number = parseFloat(`${change}`);
            return (_jsxs("span", { className: number >= 0 ? "negative" : "positive", children: ["(", number > 0 ? "+" : "", number.toFixed(2), "%)"] }));
        }
    }
    return null;
};
export const CustomTooltip = ({ active, payload, nameValue, timeRange, }) => {
    const getLabel = (item) => {
        if (item.name) {
            return `${item.name}: `;
        }
        return "";
    };
    const getValue = (item, data) => {
        return item.formatter ? item.formatter(data, item) : data[item.value];
    };
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        let hasChangePercentage = false;
        return (_jsxs("div", { className: "custom-tooltip bg-white px-2 py-1 border-4 rounded", children: [nameValue.map((item, idx) => {
                    const valueText = getValue(item, data);
                    if (!valueText) {
                        return null;
                    }
                    hasChangePercentage = hasChangePercentage || Boolean(item.showChangePercentage);
                    return (_jsxs(Stack, { className: "my-2 align-items-center", style: { color: item.color }, children: [item.showSticker ? (_jsx("span", { className: "px-2 rounded", style: { height: 6, background: item.color } })) : null, _jsx("span", { className: "text-capitalize", children: `${getLabel(item)}` }), `${valueText}`, changePercent(item, data)] }, idx));
                }), hasChangePercentage && timeRange ? (_jsxs("div", { style: { maxWidth: 215, background: "#F5FAFF", padding: 8 }, children: [_jsx(InfoIcon, {}), " Change is calculated from the value for the previous ", timeRange] })) : null] }));
    }
    return null;
};
