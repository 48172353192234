import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useMemo, useReducer } from "react";
import appSlice, { initialState } from "./appSlice";
export const AppContext = createContext({
    state: initialState,
    dispatch: () => null,
});
const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appSlice.reducer, appSlice.getInitialState());
    const values = useMemo(() => ({
        state,
        dispatch,
    }), [state, dispatch]);
    return _jsx(AppContext.Provider, { value: values, children: children });
};
export default AppProvider;
