import { Card, CardBody } from "reactstrap";
import { FlexListSummary } from "../../Components/ListSummary";
import { ListingTable } from "../../Components/Tables";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { RelativeComponentLoader } from "../../Components/Loader";
import { Paginate } from "../../Components/Paginate";
import SidebarModal from "../../Components/SidebarModal";
import { RoleDetailsModal, UserDetailsModal } from "./DetailsModal";
import styles from "./styles.module.scss";
import {
  getGovernanceFilters,
  getGovernanceRoles,
  getGovernanceSummary,
  getGovernanceUsers,
} from "../../helpers/backend_helper";
import { Tab } from "../../Components/Tab";
import { ColorInsightTags, FilterTagWrapper } from "../../Components/Tags";
import { AlertBox } from "../Alerts/AlertBox";
import { useCopilotActions } from "../Copilot/useCopilotActions";

const GET_USERS = "GET-USERS";
const GET_ROLES = "GET-ROLES";
const GET_SUMMARY = "GET-SUMMARY";
const GET_USER_FILTERS = "GET-USER-FILTERS";

const onSortChange = (key, setSort, setSortAttribute) => (newOrder) => {
  setSort((prevSortOrder) => ({
    ...prevSortOrder,
    [key]: newOrder,
  }));
  setSortAttribute(key);
};

const UsersList = ({ onItemClick, filters }) => {
  const [page, setPage] = useState(0);
  const [userFilter, setUserFilter] = useState([]);
  const [insightFilter, setInsightFilter] = useState([]);
  const [roleFilter, setRoleFilter] = useState([]);
  const [sortAttribute, setSortAttribute] = useState("total_cost");
  const [sort, setSort] = useState({
    total_cost: "",
    max_cost: "",
    avg_cost: "",
    num_queries: "",
    num_query_groups: "",
    adhoc_queries: "",
  });
  const { data: users, isLoading } = useQuery({
    queryKey: [
      GET_USERS,
      sortAttribute,
      sort[sortAttribute],
      page,
      ...userFilter,
      ...roleFilter,
      ...insightFilter,
    ],
    queryFn: () => {
      const params = {
        page: page + 1,
        size: 8,
        sort_key: sortAttribute,
        sort_order: sort[sortAttribute] || "desc",
      };
      if (userFilter.length > 0) params.users = userFilter;
      if (roleFilter.length > 0) params.roles = roleFilter;
      if (insightFilter.length > 0) params.insights = insightFilter;
      return getGovernanceUsers(params);
    },
  });
  if (isLoading) return <RelativeComponentLoader />;
  return (
    <Card>
      <CardBody>
        <div className="mb-4 d-flex gap-sm align-items-center">
          <div className="text-black">Users</div>
          <FilterTagWrapper
            filters={{
              userFilter: {
                filterStr: userFilter,
                label: "User",
                onclose: (removedFilter) =>
                  setUserFilter(
                    userFilter.filter((filter) => filter !== removedFilter)
                  ),
              },
              roleFilter: {
                filterStr: roleFilter,
                label: "Role",
                onclose: (removedFilter) =>
                  setRoleFilter(
                    roleFilter.filter((filter) => filter !== removedFilter)
                  ),
              },
              insightFilter: {
                filterStr: insightFilter,
                label: "Tag",
                onclose: (removedFilter) =>
                  setInsightFilter(
                    insightFilter.filter((filter) => filter !== removedFilter)
                  ),
              },
            }}
          />
          <div className="spacer" />
          <div className={styles.duration}>Last 1 Month</div>
        </div>
        <ListingTable
          header={[
            {
              id: 1,
              label: "Username",
              filter: {
                filterType: "dropdown",
                value: userFilter,
                options: filters.user_names
                  ? filters.user_names.map((user) => ({
                      label: user,
                      value: user,
                    }))
                  : [],
                onChange: setUserFilter,
              },
            },
            {
              id: 2,
              label: "Total Query Cost",
              sort: {
                onChange: onSortChange("total_cost", setSort, setSortAttribute),
                value: sort.total_cost,
              },
            },
            {
              id: 3,
              label: "Max Query Cost",
              sort: {
                onChange: onSortChange("max_cost", setSort, setSortAttribute),
                value: sort.max_cost,
              },
            },
            {
              id: 4,
              label: "Avg Query Cost",
              sort: {
                onChange: onSortChange("avg_cost", setSort, setSortAttribute),
                value: sort.avg_cost,
              },
            },
            {
              id: 5,
              label: "Queries",
              sort: {
                onChange: onSortChange(
                  "num_queries",
                  setSort,
                  setSortAttribute
                ),
                value: sort.num_queries,
              },
            },
            {
              id: 6,
              label: "Query Groups",
              sort: {
                onChange: onSortChange(
                  "num_query_groups",
                  setSort,
                  setSortAttribute
                ),
                value: sort.num_query_groups,
              },
            },
            {
              id: 7,
              label: "Adhoc Queries",
              sort: {
                onChange: onSortChange(
                  "adhoc_queries",
                  setSort,
                  setSortAttribute
                ),
                value: sort.adhoc_queries,
              },
            },
            {
              id: 8,
              label: "Insights",
              filter: {
                filterType: "dropdown",
                value: insightFilter,
                options: filters.insights
                  ? filters.insights.map((insight) => ({
                      label: insight,
                      value: insight,
                    }))
                  : [],
                onChange: setInsightFilter,
              },
            },
            {
              id: 9,
              label: "Default Role",
              filter: {
                filterType: "dropdown",
                value: roleFilter,
                options: filters.default_roles
                  ? filters.default_roles.map((role) => ({
                      label: role,
                      value: role,
                    }))
                  : [],
                onChange: setRoleFilter,
              },
            },
          ]}
          items={users?.items}
          idKey="user_name"
          onItemClick={onItemClick}
          resetPage={() => setPage(0)}
          templateColumns="2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr"
          rowRender={(item) => (
            <>
              <div className={styles.table_item_break_word}>
                {item.user_name}
              </div>
              <div>{item.total_cost}</div>
              <div>{item.max_cost}</div>
              <div>{item.avg_cost}</div>
              <div>{item.num_of_queries}</div>
              <div>{item.num_of_query_groups}</div>
              <div>{item.num_of_adhoc_queries}</div>
              <ColorInsightTags tags={item.insights || []} />
              <div className={styles.table_item_break_word}>
                {item.default_role}
              </div>
            </>
          )}
        />
        <Paginate
          itemCount={users.total}
          page={page}
          pageSize={users.size}
          numPages={users.pages}
          onPageClick={setPage}
        />
      </CardBody>
    </Card>
  );
};

const RolesList = ({ onItemClick, filters }) => {
  const [page, setPage] = useState(0);
  const [roleFilter, setRoleFilter] = useState([]);
  const [insightFilter, setInsightFilter] = useState([]);
  const [sortAttribute, setSortAttribute] = useState("total_cost");
  const [sort, setSort] = useState({
    total_cost: "",
    max_cost: "",
    avg_cost: "",
    num_queries: "",
    num_query_groups: "",
    adhoc_queries: "",
  });

  const { data: roles, isLoading } = useQuery({
    queryKey: [
      GET_ROLES,
      sortAttribute,
      sort[sortAttribute],
      page,
      ...roleFilter,
      ...insightFilter,
    ],
    queryFn: () => {
      const params = {
        page: page + 1,
        size: 8,
        sort_key: sortAttribute,
        sort_order: sort[sortAttribute] || "desc",
      };
      if (roleFilter.length > 0) params.roles = roleFilter;
      if (insightFilter.length > 0) params.insights = insightFilter;
      return getGovernanceRoles(params);
    },
  });
  if (isLoading) return <RelativeComponentLoader />;

  return (
    <Card>
      <CardBody>
        <div className="mb-4 d-flex gap-sm align-items-center">
          <div className="text-black fs-4">Roles</div>
          <FilterTagWrapper
            filters={{
              roleFilter: {
                filterStr: roleFilter,
                label: "Role",
                onclose: (removedFilter) =>
                  setRoleFilter(
                    roleFilter.filter((filter) => filter !== removedFilter)
                  ),
              },
              insightFilter: {
                filterStr: insightFilter,
                label: "Tag",
                onclose: (removedFilter) =>
                  setInsightFilter(
                    insightFilter.filter((filter) => filter !== removedFilter)
                  ),
              },
            }}
          />
          <div className="spacer" />
          <div className={styles.duration}>Last 1 Month</div>
        </div>
        <ListingTable
          header={[
            {
              id: 1,
              label: "Role Name",
              filter: {
                filterType: "dropdown",
                value: roleFilter,
                options: filters.role_names
                  ? filters.role_names.map((role) => ({
                      label: role,
                      value: role,
                    }))
                  : [],
                onChange: setRoleFilter,
              },
            },
            {
              id: 2,
              label: "Total Query Cost",
              sort: {
                onChange: onSortChange("total_cost", setSort, setSortAttribute),
                value: sort.total_cost,
              },
            },
            {
              id: 3,
              label: "Max Query Cost",
              sort: {
                onChange: onSortChange("max_cost", setSort, setSortAttribute),
                value: sort.max_cost,
              },
            },
            {
              id: 4,
              label: "Avg Query Cost",
              sort: {
                onChange: onSortChange("avg_cost", setSort, setSortAttribute),
                value: sort.avg_cost,
              },
            },
            {
              id: 5,
              label: "Total Number of queries",
              sort: {
                onChange: onSortChange(
                  "num_queries",
                  setSort,
                  setSortAttribute
                ),
                value: sort.num_queries,
              },
            },
            {
              id: 6,
              label: "Number of Adhoc queries",
              sort: {
                onChange: onSortChange(
                  "adhoc_queries",
                  setSort,
                  setSortAttribute
                ),
                value: sort.adhoc_queries,
              },
            },
            {
              id: 7,
              label: "Number of Query Groups",
              sort: {
                onChange: onSortChange(
                  "num_query_groups",
                  setSort,
                  setSortAttribute
                ),
                value: sort.num_query_groups,
              },
            },
            {
              id: 8,
              label: "Insights",
              filter: {
                filterType: "dropdown",
                value: insightFilter,
                options: filters.insights
                  ? filters.insights.map((insight) => ({
                      label: insight,
                      value: insight,
                    }))
                  : [],
                onChange: setInsightFilter,
              },
            },
          ]}
          items={roles.items}
          idKey="role_name"
          onItemClick={onItemClick}
          resetPage={() => setPage(0)}
          templateColumns="2fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr"
          rowRender={(item) => (
            <>
              <div className={styles.table_item_break_word}>
                {item.role_name}
              </div>
              <div>{item.total_cost}</div>
              <div>{item.max_cost}</div>
              <div>{item.avg_cost}</div>
              <div>{item.num_of_queries}</div>
              <div>{item.num_of_adhoc_queries}</div>
              <div>{item.num_of_query_groups}</div>
              <ColorInsightTags tags={item.insights || []} />
            </>
          )}
        />
        <Paginate
          itemCount={roles.total}
          page={page}
          pageSize={roles.size}
          numPages={roles.pages}
          onPageClick={setPage}
        />
      </CardBody>
    </Card>
  );
};

const Governance = ({ defaultTab = 0 }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);

  const { resetWorkflow } = useCopilotActions();

  useEffect(() => {
    resetWorkflow();
  }, []);

  const { data: summary, isLoading: isSummaryLoading } = useQuery({
    queryKey: [GET_SUMMARY],
    queryFn: getGovernanceSummary,
  });
  const { data: filters, isLoading: isUserFiltersloading } = useQuery({
    queryKey: [GET_USER_FILTERS],
    queryFn: getGovernanceFilters,
  });
  const withOpenSidebar =
    (fn) =>
    (...args) => {
      fn(...args);
      setShowSidebar(true);
    };
  if (isSummaryLoading || isUserFiltersloading)
    return <RelativeComponentLoader />;
  return (
    <>
      <div className="h-100 d-flex flex-column">
        <div className="mb-4 d-flex align-items-center justify-content-between">
          <h3 className="text-black">Users & Roles</h3>
          <AlertBox
            suggestionWorkflowType={"alert_creation_governance_sidebar"}
          />
        </div>
        <div className="bg-blue-light p-2 br-2 text-primary mb-3">
          This page shows insights around user costs and login activities
        </div>
        <FlexListSummary summary={summary || []} />
        <Tab
          tabs={[
            {
              label: "Users",
              path: "/users",
              component: (
                <UsersList
                  onItemClick={withOpenSidebar(setSelectedUser)}
                  filters={filters.users}
                />
              ),
            },
            {
              label: "Roles",
              path: "/roles",
              component: (
                <RolesList
                  onItemClick={withOpenSidebar(setSelectedRole)}
                  filters={filters.roles}
                />
              ),
            },
          ]}
          defaultTab={defaultTab}
        />
      </div>
      <SidebarModal
        isOpen={showSidebar}
        toggleModal={() => {
          setSelectedUser(null);
          setSelectedRole(null);
          setShowSidebar(false);
        }}
        width="692"
      >
        {selectedUser && (
          <UserDetailsModal user_name={selectedUser.user_name} />
        )}
        {selectedRole && (
          <RoleDetailsModal role_name={selectedRole.role_name} />
        )}
      </SidebarModal>
    </>
  );
};

export { Governance };
