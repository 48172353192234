import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { SqlLineage } from "../../../Components/Lineage";
const QuerySqlLineage = ({ sqlLineage, selectedInsight, setSelectedNode, setShowSideContent, showSideContent, setSelectedInsight, }) => {
    useEffect(() => {
        if (!sqlLineage)
            return;
        const { query_tags_meta } = sqlLineage;
        if (selectedInsight) {
            const insightNodes = [];
            const nodesSavingsPerformance = {};
            for (const key in query_tags_meta) {
                for (const t of query_tags_meta[key] || []) {
                    if (t.tag_rk !== selectedInsight)
                        continue;
                    insightNodes.push(key);
                    if (t.cost_savings_ui || t.time_savings_ui) {
                        nodesSavingsPerformance[key] = nodesSavingsPerformance[key] || {};
                    }
                    if (t.cost_savings_ui) {
                        nodesSavingsPerformance[key]["savings"] = t.cost_savings_ui;
                    }
                    if (t.time_savings_ui) {
                        nodesSavingsPerformance[key]["performance"] = t.time_savings_ui;
                    }
                }
            }
            console.log("nodesSavingsPerformance", nodesSavingsPerformance);
            document.dispatchEvent(new CustomEvent("onHighlightedNodes", { detail: insightNodes }));
            document.dispatchEvent(new CustomEvent("onNodesSavingsPerformance", {
                detail: nodesSavingsPerformance,
            }));
        }
        else {
            const nodesCost = {};
            for (const key in sqlLineage.details) {
                if (sqlLineage.details[key].node_cost)
                    nodesCost[key] = sqlLineage.details[key].node_cost;
            }
            document.dispatchEvent(new CustomEvent("onNodesCost", { detail: nodesCost }));
        }
    }, [selectedInsight, sqlLineage]);
    useEffect(() => {
        document.dispatchEvent(new CustomEvent("fitView"));
    }, [showSideContent]);
    useEffect(() => {
        const handleSelectedNode = (e) => {
            const node = e.detail;
            setSelectedNode(node);
            setSelectedInsight("");
            setShowSideContent(true);
            document.dispatchEvent(new CustomEvent("onSelectedNodes", { detail: node }));
        };
        // @ts-ignore
        document.addEventListener("selectedNode", handleSelectedNode);
        return () => {
            // @ts-ignore
            document.removeEventListener("selectedNode", handleSelectedNode);
        };
    }, [setSelectedInsight, setSelectedNode, setShowSideContent]);
    if (!sqlLineage)
        return null;
    return _jsx(SqlLineage, Object.assign({}, sqlLineage, { height: "50vh", externalSidePanel: true }));
};
export { QuerySqlLineage };
