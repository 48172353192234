import { useContext } from "react";
import { AppContext } from "./AppProvider";
const useAppContext = () => {
    return useContext(AppContext);
};
export const useAppDispatch = () => {
    const { dispatch } = useContext(AppContext);
    return dispatch;
};
export const useAppState = () => {
    const { state } = useContext(AppContext);
    return state;
};
export default useAppContext;
