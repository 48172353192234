import { uniqueId } from "lodash";
import { useMemo, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import { CopyIconButtonFab } from "../CopyButton";
import { createMonacoEditor } from "./editorUtils";

const ROCodeEditor = ({
  value = "",
  height = "60vh",
  lineNumbers = false,
  showCopy = true,
}) => {
  const editorId = useMemo(() => uniqueId("editor-"), []);
  const editorRef = useRef(null);
  useEffect(() => {
    createMonacoEditor(editorId, { readOnly: true, lineNumbers }).then(
      (editor) => {
        editorRef.current = editor;
        editorRef.current?.setValue(value);
      }
    );
  }, []);
  useEffect(() => {
    editorRef.current?.setValue(value);
  }, [value]);
  return (
    <div className={styles.editor_container}>
      {showCopy && (
        <div className={styles.copy_button}>
          <CopyIconButtonFab value={value} />
        </div>
      )}
      <div id={editorId} style={{ height }} />
    </div>
  );
};

export default ROCodeEditor;
