var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Nav, NavItem, NavLink } from "reactstrap";
const Tabs = (_a) => {
    var { items, selectedTab, onTabSelect, pills = true } = _a, rest = __rest(_a, ["items", "selectedTab", "onTabSelect", "pills"]);
    return (_jsx(Nav, Object.assign({ fill: true, pills: pills }, rest, { children: items.map((tab) => (_jsx(NavItem, { children: _jsx(NavLink, { active: selectedTab === tab.value, onClick: () => onTabSelect(tab.value), children: tab.label }) }, tab.value))) })));
};
export default Tabs;
