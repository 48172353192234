import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Input, Label, Form } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormAlert,
  FormInput,
  FormPassword,
  FormSubmit,
  FormSuccess,
} from "./components/form";
import { BaseContainer, Header } from "./components/base";
import { isFormFieldError } from "./helper";
import { useMutation } from "react-query";
import {
  postLogin,
  verifyFreeUser,
  postUserOnboard,
} from "../../helpers/backend_helper";
import { savePermissions, loginUser, setRememberMe } from "../../services/auth";
import { getDefaultRoute, getTenant, isFreeStack } from "../../helpers/tenant";
import { decodeToken } from "react-jwt";
import { ReactComponent as TickGradientIcon } from "../../assets/icons/tick_gradient.svg";
import classNames from "classnames";
import styles from "./components/styles.module.scss";

const Login = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [expired, setExpired] = useState(state?.expired);
  const { token } = useParams();
  const [remember, setRemember] = useState(false);
  const [message, setMessage] = useState("");
  const isFreeUser = isFreeStack();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const user = queryParams.get("user");

  const { mutate: onboardUser } = useMutation(postUserOnboard, {});

  const { mutate: login, error } = useMutation(postLogin, {
    onSuccess: (response) => {
      if (response.auth_token) {
        setRememberMe(remember);
        loginUser(response);
        if (state?.from) navigate(state?.from);
        else if (isFreeUser) {
          if (!response?.is_onboarded) {
            onboardUser();
            navigate("/settings/api-key");
          } else {
            navigate("/copilot");
          }
        } else navigate(getDefaultRoute(getTenant()));
      }
    },
  });

  useEffect(() => {
    if (!token) return;
    const decodedToken = decodeToken(token);
    if (!decodedToken) return navigate("/expired");
    validation.setFieldValue("email", decodedToken.email);
    (async () => {
      const data = await verifyFreeUser({ token });
      if (data.ok) setMessage("We've verified your identity. Please sign in");
    })();
  }, [navigate, token]);

  useEffect(() => {
    if (!user)
      navigate(".", { replace: true, state: { ...state, expired: false } });
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user ? user : "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => login(values),
  });

  const showFormFieldError = isFormFieldError(validation);
  const _error =
    error || showFormFieldError("email") || showFormFieldError("password");

  const isMobile = window.innerWidth <= 768;

  return (
    <BaseContainer
      footer={
        isFreeStack() && (
          <div>
            <span className="text-muted fw-400">Don't have an account? </span>
            <Link
              to="https://app.myaltimate.com/register"
              className="text-primary"
            >
              Register
            </Link>
          </div>
        )
      }
    >
      <div className={classNames({ [styles.mobile_login]: isMobile })}>
        {message && (
          <FormSuccess
            message={
              <div className="d-flex align-items-center gap-sm">
                <TickGradientIcon />
                <div className="text-muted">{message}</div>
              </div>
            }
          />
        )}

        <Header>Sign In</Header>
        {expired && (
          <div className="text-muted">Session expired. Please login again.</div>
        )}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
          className={`${isMobile ? "w-100 mt-3" : "mt-4"}`}
        >
          <div className="mb-3">
            <Label className="form-label" htmlFor="email">
              Email
            </Label>
            <FormInput
              id="email"
              name="email"
              type="email"
              placeholder="Enter email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email}
            />
          </div>

          <div className="mb-3">
            <Label className="form-label" htmlFor="password">
              Password
            </Label>
            <FormPassword
              id="password"
              name="password"
              placeholder="Enter password"
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              value={validation.values.password}
            />
          </div>

          <div className="form-check">
            <Input
              id="remember-check"
              className="form-check-input"
              type="checkbox"
              value={remember}
              onChange={(e) => setRemember(e.target.checked)}
            />
            <Label className="text-muted fw-400" htmlFor="remember-check">
              Remember me
            </Label>
            <div className="float-end">
              <Link to="/forgot" className="text-primary">
                Forgot password?
              </Link>
            </div>
          </div>

          <FormAlert error={_error} />

          <div className="mt-4">
            <FormSubmit>Sign In</FormSubmit>
          </div>
        </Form>
      </div>
    </BaseContainer>
  );
};

export { Login };
