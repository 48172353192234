import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import contract_icon from "../../assets/icons/contract_icon.svg";
import classnames from "classnames";
import { Button, Card, CardBody, Input, Label } from "reactstrap";
import { ReactComponent as ViolationIcon } from "../../assets/icons/violation_icon.svg";
import { ReactComponent as CodeIcon } from "../../assets/icons/code.svg";
import { ReactComponent as ValidateIcon } from "../../assets/icons/validation_default.svg";
import validationProcessing from "../../assets/images/validation_processing.png";
import { ReactComponent as ValidateSuccessIcon } from "../../assets/icons/validation_success.svg";
import { ReactComponent as ValidateFailIcon } from "../../assets/icons/validation_fail.svg";
import { LevelTag, TableTags } from "../../Components/Tags";
import { Policy } from "./Policy";
import { useQuery } from "react-query";
import { getContractDetail } from "../../helpers/backend_helper";
import { useState } from "react";
import { Code } from "./Code";
import { Timeline } from "./Timeline";
import { ComponentLoader } from "../../Components/Loader";
import { Tooltip } from "react-tooltip";
import { useMutation } from "react-query";
import {
  validateContract,
  toggleContractSchedule,
} from "../../helpers/backend_helper";
import cronstrue from "cronstrue";

const VALIDATION_BUTTON_TIMEOUT = 2000;
const GET_CONTRACT_DETAILS = "GET-CONTRACT-DETAILS";
const ContractDetails = () => {
  const [showCode, setShowCode] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [showValidationSuccess, setShowValidationSuccess] = useState(false);
  const [showValidationFail, setShowValidationFail] = useState(false);
  const { contractId } = useParams();
  const { data: contract, isLoading } = useQuery({
    queryKey: [GET_CONTRACT_DETAILS, contractId],
    queryFn: () => getContractDetail(contractId),
    onSuccess: (res) => {
      if (res?.name) {
        setIsChecked(res?.is_scheduled);
      }
    },
  });
  const {
    mutate: runValidate,
    isLoading: isValidationLoading,
    error,
  } = useMutation(validateContract, {
    onSuccess: (res) => {
      if (res?.contract_name) {
        setShowValidationSuccess(true);
        setTimeout(() => {
          setShowValidationSuccess(false);
        }, VALIDATION_BUTTON_TIMEOUT);
      } else {
        setShowValidationFail(true);
        setTimeout(() => {
          setShowValidationFail(false);
        }, VALIDATION_BUTTON_TIMEOUT);
      }
    },
    onError: () => {
      setShowValidationFail(true);
      setTimeout(() => {
        setShowValidationFail(false);
      }, VALIDATION_BUTTON_TIMEOUT);
    },
  });

  const { mutate: runToggleSchedule, isLoading: isRunningToggle } = useMutation(
    () => toggleContractSchedule(contractId),
    {
      onSuccess: (res) => {
        if (res?.ok) {
          setIsChecked(!isChecked);
        }
      },
    }
  );

  const handleToggleSwitch = (e) => {
    e.preventDefault();
    runToggleSchedule();
  };

  const week_data = contract?.week_data;
  const summary = [
    { label: "Error", value: week_data?.error, color: "level-red" },
    { label: "Warning", value: week_data?.warning, color: "level-orange" },
    { label: "Policies", value: week_data?.policies, color: "level-blue" },
    {
      label: "Validations",
      value: week_data?.validations,
      color: "level-yellow",
    },
  ];
  const details = [
    {
      label: "Last updated",
      value: contract?.last_updated,
    },
    { label: "Version", value: contract?.version },
    {
      label: "Created at",
      value: contract?.created_at,
    },
  ];
  if (contract?.slack_channel)
    details.push({ label: "Slack", value: contract?.slack_channel });
  if (contract?.schedule) {
    let parsedSchedule = "";
    try {
      parsedSchedule = ` (${cronstrue.toString(contract?.schedule)})`;
    } catch (err) {
      /* empty */
    }
    if (parsedSchedule) {
      details.push({
        customElement: (
          <>
            <div className="text-muted">Schedule:</div>
            <div>
              {contract?.schedule}{" "}
              <span className={styles.parsed_schedule}>{parsedSchedule}</span>
            </div>
          </>
        ),
      });
    } else {
      details.push({
        label: "Schedule",
        value: contract?.schedule,
      });
    }
  }
  details.push({
    customElement: (
      <>
        <div className="text-muted">Scheduled By:</div>
        <div class="form-check form-switch">
          <Input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            checked={isChecked}
            onClick={handleToggleSwitch}
          />
          <Label
            className={classnames({
              [styles.toggleSwitchText]: isChecked,
              [styles.toggleSwitchTextMuted]: !isChecked,
            })}
            for="flexSwitchCheckDefault"
          >
            Altimate
          </Label>
        </div>
      </>
    ),
  });
  details.push({ label: "Registry Id", value: contract?.registry_id });
  details.push({ label: "Registry Name", value: contract?.registry_name });

  if (isLoading) return <ComponentLoader left={60} />;

  const handleContractValidation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (contract?.registry_id) {
      runValidate({
        registryId: contract?.registry_id,
        contractId: contractId,
      });
    }
  };
  const showValidateIcon =
    contract?.can_validate && !showValidationFail && !showValidationSuccess;

  return (
    <>
      <div className={styles.contract_details}>
        <div className="mb-4 d-flex align-items-center">
          <div
            className={classnames("me-2", styles.icon_bg, {
              [styles.active]: contract.status === "active",
              [styles.inactive]: contract.status === "inactive",
            })}
          >
            <img src={contract_icon} alt="" />
          </div>
          <div
            className={classnames(
              "me-2 fs-3 fw-semibold no-break",
              styles.header
            )}
          >
            {contract.name}
          </div>
          <Button
            size="sm"
            className="br-3 no-break"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowCode(true);
            }}
          >
            View Code
          </Button>
          {isValidationLoading ? (
            <div className="d-flex justify-content-between align-items-center">
              <img
                src={validationProcessing}
                className={styles.validation_processing}
                alt="validationProcessing"
              />
              <div className="ms-1 text-muted fw-400">Processing</div>
            </div>
          ) : (
            <>
              {showValidateIcon && (
                <div>
                  <ValidateIcon
                    data-tooltip-id="validate_contract"
                    className={styles.validation}
                    onClick={handleContractValidation}
                  />
                  <Tooltip
                    id="validate_contract"
                    className={styles.validation_tooltip}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <div>Validate contract</div>
                      {contract?.last_violation_timestamp && (
                        <span className={styles.tooltip_date}>
                          Last&nbsp;:&nbsp;
                          {contract.last_violation_timestamp}
                        </span>
                      )}
                    </div>
                  </Tooltip>
                </div>
              )}
              {showValidationSuccess && (
                <div className="d-flex align-items-center justify-content-between">
                  <ValidateSuccessIcon className={styles.validation} />
                  <div className="ms-1 text-muted fw-400">
                    Validation Successful
                  </div>
                </div>
              )}
              {showValidationFail && (
                <div className="d-flex align-items-center justify-content-between">
                  <ValidateFailIcon className={styles.validation} />
                  <div className="ms-1 text-muted fw-400">
                    Validation Failed
                  </div>
                </div>
              )}
            </>
          )}
          <div className={styles.spacer} />
          <Card className="me-3 mb-0 no-break" style={{ minWidth: "12em" }}>
            <CardBody className="pt-2 pb-2 ps-4 pe-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="me-2 level-red icon-sm d-flex align-items-center">
                  <CodeIcon />
                </div>
                <div>{contract.no_of_violations} Violations</div>
              </div>
            </CardBody>
          </Card>
          {contract.last_violation_timestamp && (
            <Card className="me-3 mb-0 no-break" style={{ minWidth: "12em" }}>
              <CardBody className="pt-2 pb-2 ps-4 pe-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="me-2 level-orange icon-sm d-flex align-items-center">
                    <ViolationIcon />
                  </div>
                  <div>{contract.last_violation_timestamp}</div>
                </div>
              </CardBody>
            </Card>
          )}
          <Timeline contractId={contractId} />
        </div>
        <div className="d-flex">
          <Card className={classnames("me-4", styles.description)}>
            <CardBody className="d-flex flex-column">
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <div className="fs-5 fw-semibold">Description</div>
                <LevelTag level={contract.level} />
              </div>
              <TableTags tables={contract.tables} />
              <div className="mt-3 mb-2 text-muted">{contract.description}</div>
              <div className="spacer" />
              <div className="mt-3 d-flex justify-content-between">
                <div className="fs-5 fw-semibold">State</div>
                <div className="text-muted">Last 7 days facts</div>
              </div>
              <div className={classnames("mt-3 mb-3", styles.divider)} />
              <div className="d-flex justify-content-between">
                {summary.map((item) => (
                  <div
                    key={item.label}
                    className="d-flex flex-column align-items-start"
                  >
                    <div className={classnames(styles.marker, item.color)} />
                    <div className="fs-4 fw-semibold">{item.value}</div>
                    <div className="text-muted">{item.label}</div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
          <Card className={styles.details}>
            <CardBody>
              <div className="mb-4 fs-5 fw-semibold">Details</div>
              <div className="d-flex flex-column gap-sm">
                <div className={styles.details_row}>
                  <div className="text-muted">Owner:</div>
                  <div>
                    {contract.owners?.map((item) => (
                      <div key={item}>{item}</div>
                    ))}
                  </div>
                </div>
                {details.map((item) => (
                  <div key={item.label} className={styles.details_row}>
                    {/* customElement is to render a custom element */}
                    {!item?.customElement ? (
                      <>
                        <div className="text-muted">{item.label}:</div>
                        <div>{item.value}</div>
                      </>
                    ) : (
                      <>{item?.customElement}</>
                    )}
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </div>
        <Policy policies={contract.policies} />
      </div>
      <Code
        isOpen={showCode}
        toggle={() => setShowCode((b) => !b)}
        contract={contract}
      />
    </>
  );
};

export { ContractDetails };
