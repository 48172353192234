import styles from "./styles.module.scss";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import { useQuery } from "react-query";
import { ListSummary, FlexListSummary } from "../../Components/ListSummary";
import {
  getSummary,
  getTableInsightsById,
  getTableStorageOverTimeById,
} from "../../helpers/backend_helper";
import {
  ComponentLoader,
  RelativeComponentLoader,
} from "../../Components/Loader";
dayjs.extend(RelativeTime);
import bytes from "bytes";
import classnames from "classnames";

const GET_SUMMARY = "GET-SUMMARY";
const GET_TABLE_INSIGHTS_BY_ID = "GET-TABLE_INSIGHTS-BY-ID";
const GET_TABLE_STORAGE_OVER_TIME_BY_ID = "GET-STORAGE_OVER_TIME-BY-ID";
const Summary = ({ table }) => {
  const { data, isLoading } = useQuery({
    queryKey: GET_SUMMARY,
    queryFn: () => getSummary(table ? { table_id: table } : null),
  });
  const items = [
    {
      label: "Last Incident",
      value: data?.last_violation_timestamp
        ? `${dayjs(data?.last_violation_timestamp).fromNow(true)} ago`
        : "None",
    },
    { label: "Errors", value: data?.total_error_count },
    { label: "Warning", value: data?.total_warning_count },
    {
      label: "Policies",
      value: data?.total_policy_violation_count,
    },
    { label: "Validations", value: data?.total_validation_count },
  ];
  if (isLoading) {
    return <ComponentLoader top={25} left={60} />;
  }
  return <ListSummary summary={items} />;
};

const TableInsightsSummary = ({ tableId }) => {
  const convertGBtoBytes = (value) => {
    if (!value) return 0;
    return value * 1073741824;
  };
  const { data: tableInsights, isLoading } = useQuery({
    queryKey: [GET_TABLE_INSIGHTS_BY_ID, tableId],
    queryFn: () => {
      return getTableInsightsById(tableId);
    },
  });
  const formatDate = (date) => {
    if (typeof date === "string") {
      try {
        const formattedDate = dayjs(date).format("DD MMM, YYYY");
        if (date.includes("<")) {
          return "Before " + formattedDate;
        } else if (date.includes("N/A")) {
          return "N/A";
        }
        return formattedDate;
      } catch {
        return date;
      }
    } else {
      return "None";
    }
  };
  const items = [
    {
      label: "Annual Storage Spend",
      value: tableInsights?.insights?.estimated_annualized_storage_costs,
    },
    {
      label: "Annual Data Insertion Cost",
      value: tableInsights?.insights?.annualized_insert_cost || "None",
    },
    {
      label: "Last Write",
      value: formatDate(tableInsights?.insights?.last_modified),
    },
    {
      label: "Last Read",
      value: formatDate(tableInsights?.insights?.last_read),
    },
    {
      label: "Reads/Writes \n(in last month)",
      value: tableInsights?.insights?.read_write_ratio || "None",
    },
  ];
  if (isLoading) {
    return <RelativeComponentLoader componentHeight={20} />;
  }
  return <FlexListSummary summary={items || []} />;
};

const TableStorageSummary = ({ tableId }) => {
  const { data: tableStorageOverTime, isLoading } = useQuery({
    queryKey: [GET_TABLE_STORAGE_OVER_TIME_BY_ID, tableId],
    queryFn: () => {
      return getTableStorageOverTimeById(tableId);
    },
  });
  if (isLoading) {
    return null;
  }
  if (
    !("table_owner" in tableStorageOverTime) ||
    tableStorageOverTime["table_owner"] === "Owner not found"
  ) {
    return null;
  }
  return (
    <div className={styles.summary_card}>
      {Object.keys(tableStorageOverTime).map(
        (key) =>
          tableStorageOverTime[key] !== null && (
            <p key={key}>
              <span className={classnames("text-muted", styles.summary_text)}>
                {key} :
              </span>{" "}
              <span>{tableStorageOverTime[key]}</span>
              {"   "}
            </p>
          )
      )}
    </div>
  );
};

export { Summary, TableInsightsSummary, TableStorageSummary };
