const getEffortLabel = (effort) => {
    switch (effort) {
        case 1:
            return "low";
        case 2:
            return "medium";
        case 3:
            return "high";
        default:
            return "";
    }
};
const getResourceImageType = (resourceType) => {
    switch (resourceType) {
        case 1:
            return "query";
        case 2:
            return "datasets";
        case 3:
            return "warehouse";
        case 4:
            return "user";
        case 5:
            return "role";
        default:
            return "";
    }
};
const getResourceType = (resourceType) => {
    switch (resourceType) {
        case 1:
            return "Query Hash";
        case 2:
            return "Table";
        case 3:
            return "Warehouse";
        case 4:
            return "User";
        case 5:
            return "Role";
        default:
            return "";
    }
};
const getOpportunityStatus = (status) => {
    switch (status) {
        case 1:
            return "active";
        case 2:
            return "implemented";
        case 3:
            return "closed";
        case 4:
            return "dismissed";
        default:
            return "";
    }
};
const getResourceTypeURL = (resourceType) => {
    switch (resourceType) {
        case 1:
            return "/query";
        case 2:
            return "/datasets";
        case 3:
            return "/warehouse";
        case 4:
            return "/users";
        case 5:
            return "/roles";
        default:
            return "";
    }
};
export { getEffortLabel, getResourceType, getResourceImageType, getOpportunityStatus, getResourceTypeURL, };
