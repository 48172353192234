import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Table,
} from "reactstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ROCodeEditor,
  CodeEditor,
  CodeDiffEditor,
  CueCodeEditor,
} from "@/Components/CodeEditor";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import styles from "./styles.module.scss";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { useBoolean } from "../../helpers/hooks";
import { ReactComponent as ThinkingIcon } from "@/assets/icons/thinking.svg";
import { ReactComponent as ArrowRightFullIcon } from "@/assets/icons/arrow-right-full.svg";
import { ReactComponent as ArrowDownIcon } from "@/assets/icons/arrow_down.svg";
import { ReactComponent as LikeIcon } from "@/assets/icons/like.svg";
import { ReactComponent as DislikeIcon } from "@/assets/icons/dislike.svg";
import { ReactComponent as LikeSelectedIcon } from "@/assets/icons/like_selected.svg";
import { ReactComponent as DislikeSelectedIcon } from "@/assets/icons/dislike_selected.svg";
import { ReactComponent as BlockedDanger } from "@/assets/icons/blocked_danger.svg";
import { ReactComponent as MaskDanger } from "@/assets/icons/mask_danger.svg";
import { ReactComponent as UploadIcon } from "@/assets/icons/upload.svg";
import QueryInsightsRobot from "../../assets/images/query_insights_robot.png";
import { AsyncMultiSelectDropdown } from "@/Components/Form";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import classNames from "classnames";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ReactComponent as Bot } from "@/assets/icons/bot.svg";

// Old copilot Imports
import { Form } from "reactstrap";
import { ReactComponent as ArrowRightFull } from "../../assets/icons/arrow-right-full.svg";
import classnames from "classnames";
import { ColorTag } from "../../Components/Tags";
import { ColumnDatatype } from "../../Components/Column";
import { DatasetModal } from "./DatasetModal";
import { ColumnPopover, DocGenColumnPopover } from "./ColumnPopover";
import { RelativeComponentLoader } from "@/Components/Loader";

import { capitalize } from "lodash";
import { ReactComponent as InfoCircle } from "../../assets/icons/info_circle.svg";
import { FeedbackModal } from "./FeedbackModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as ThumbsupIcon } from "@/assets/icons/thumbs-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { Dropdown } from "../../Components/Form";
import { getIconByDatastoreType } from "../Datasets/DatastoreImage";
import { COLOR_PALETTE, SAVED_TIME } from "./constants";
import { CopyIconButton } from "../../Components/CopyButton";
import { Feedback } from "../../Components/Feedback";
import { useCopilot } from "./CopilotContext";
import { useMutation, useQuery } from "react-query";
import {
  getAllSlackChannels,
  getIntegrationEnvironments,
  getIntegrations,
} from "../../helpers/backend_helper";
import { generateStream } from "../../helpers/api_helper";
import { StaticLineage } from "../../Components/Lineage";
import { NoDbtIntegration } from "./NoDbtIntegration";
import { Link } from "react-router-dom";
// Old copilot Imports end

const CodeInput = ({
  onSubmit,
  isSubmitted,
  label = "Enter the query below",
  value: defaultValue = "",
  piiEnabled: defaultPiiEnabled = false,
}) => {
  const editorRef = useRef(null);
  const { value: piiEnabled, toggle: togglePiiEnabled } =
    useBoolean(defaultPiiEnabled);

  return (
    <div className="d-flex flex-column gap-sm">
      <div className="fw-5 fs-4 mb-2 d-flex">
        <div>{label}</div>
        <div className="spacer" />
        <FormGroup switch className="mb-0">
          <Input
            type="switch"
            checked={piiEnabled}
            onChange={togglePiiEnabled}
            disabled={isSubmitted}
          />
          <Label className="mb-0">Data Protection</Label>
        </FormGroup>
      </div>
      <CodeEditor
        height="16em"
        ref={editorRef}
        value={defaultValue}
        readOnly={isSubmitted}
      />
      <div className="d-flex justify-content-between">
        <div />
        <Button
          disabled={isSubmitted}
          onClick={(e) => {
            e.stopPropagation();
            onSubmit({ purpose: editorRef.current?.getValue(), piiEnabled });
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

const CodeInputWithText = ({
  onSubmit,
  isSubmitted,
  label = "Enter the query below",
  purpose: defaultValue = "",
  piiEnabled: defaultPiiEnabled = false,
}) => {
  const editorRef = useRef(null);
  const { value: piiEnabled, toggle: togglePiiEnabled } =
    useBoolean(defaultPiiEnabled);

  return (
    <div className={styles.code_input_with_text}>
      <div className="fw-5 fs-4 d-flex">
        <div>{label}</div>
        <div className="spacer" />
        <FormGroup switch className="mb-0">
          <Input
            type="switch"
            checked={piiEnabled}
            onChange={togglePiiEnabled}
            disabled={isSubmitted}
          />
          <Label className="mb-0">Data Protection</Label>
        </FormGroup>
      </div>
      <div className={styles.spacer}></div>
      <CodeEditor
        height="16em"
        ref={editorRef}
        value={defaultValue}
        readOnly={isSubmitted}
      />
      <Button
        disabled={isSubmitted}
        className={styles.button}
        onClick={(e) => {
          e.stopPropagation();
          onSubmit({
            purpose: editorRef.current?.getValue(),
            piiEnabled,
          });
        }}
      >
        Submit
      </Button>
    </div>
  );
};

const GET_INTEGRATIONS = "GET_INTEGRATIONS";
const GET_INTEGRATION_ENVIRONMENTS = "GET_INTEGRATION_ENVIRONMENTS";

const CodeInputWithDropdown = ({
  onSubmit,
  isSubmitted,
  label = "Enter your lineage question below:",
  dbtCoreIntegrationId: defaultDbtCoreIntegrationId = null,
  dbtCoreIntegrationEnvironmentId:
    defaultDbtCoreIntegrationEnvironmentId = null,
  purpose: defaultValue = "",
  piiEnabled: defaultPiiEnabled = false,
}) => {
  const [purpose, setPurpose] = useState(defaultValue);
  const [dbtCoreIntegrationId, setDbtCoreIntegrationId] = useState(
    defaultDbtCoreIntegrationId
  );
  const [dbtCoreIntegrationEnvironmentId, setDbtCoreIntegrationEnvironmentId] =
    useState(defaultDbtCoreIntegrationEnvironmentId);
  const [integrationEnvironments, setIntegrationEnvironments] = useState([]);
  const [showIntegrationEnvironments, setShowIntegrationEnvironments] =
    useState(
      false ||
        (defaultDbtCoreIntegrationId !== null &&
          defaultDbtCoreIntegrationEnvironmentId !== null)
    );
  useEffect(() => {
    setPurpose(defaultValue);
  }, [defaultValue]);

  const { value: piiEnabled, toggle: togglePiiEnabled } =
    useBoolean(defaultPiiEnabled);

  const { data: integrations, isLoading } = useQuery({
    queryKey: [GET_INTEGRATIONS],
    queryFn: getIntegrations,
  });

  const { mutate } = useMutation(getIntegrationEnvironments, {
    onSuccess: (data) => {
      setIntegrationEnvironments(data);
      setShowIntegrationEnvironments(true);
    },
  });

  useEffect(() => {
    if (dbtCoreIntegrationId) {
      mutate(dbtCoreIntegrationId);
    }
  }, [dbtCoreIntegrationId]);

  const handleDbtCoreIntegrationChange = (e) => {
    setDbtCoreIntegrationId(e);
    mutate(e);
  };

  const handleDbtCoreIntegrationEnvironmentChange = (e) => {
    setDbtCoreIntegrationEnvironmentId(e);
  };

  if (isLoading)
    return (
      <div style={{ height: "40vh" }}>
        <RelativeComponentLoader
          label="Loading Integrations..."
          componentHeight={40}
        />
      </div>
    );

  if (!integrations || integrations.length === 0) {
    return <NoDbtIntegration />;
  }

  return (
    <div className={styles.code_input_with_text}>
      <div className="fw-5 fs-4 mb-2">
        Which dbt integration should be included in this agent?
      </div>
      <div className="d-flex gap-lg align-items-center">
        <div className="mb-3 d-flex gap-lg">
          <Dropdown
            popoverProps={{ placement: "bottom", offset: [0, 0] }}
            id={"dbt-core-integration-id-dropdown"}
            onChange={(e) => {
              handleDbtCoreIntegrationChange(e);
            }}
            value={dbtCoreIntegrationId}
            options={(integrations || []).map((v) => ({
              label: (
                <div className={styles.dropdown_text}>{capitalize(v.name)}</div>
              ),
              value: v.id,
            }))}
            showDivider
          >
            <div className={styles.dbt_integration_dropdown}>
              {!dbtCoreIntegrationId ? (
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <div className={styles.dbt_dropdown_text}>
                    Select dbt core integration
                  </div>
                  <div className={styles.icon}>
                    <ArrowDown />
                  </div>
                </div>
              ) : (
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <div className={styles.dbt_dropdown_text}>
                    {capitalize(
                      (integrations || []).find(
                        (v) => v.id === dbtCoreIntegrationId
                      )?.name
                    )}
                  </div>
                  <div className={styles.icon}>
                    <ArrowDown />
                  </div>
                </div>
              )}
            </div>
          </Dropdown>
        </div>
        {showIntegrationEnvironments && (
          <div className="mb-3 d-flex gap-lg">
            <Dropdown
              popoverProps={{
                placement: "bottom",
                offset: [0, 0],
              }}
              id={"dbt-core-integration-environment-id-dropdown"}
              onChange={(e) => {
                handleDbtCoreIntegrationEnvironmentChange(e);
              }}
              value={dbtCoreIntegrationEnvironmentId}
              options={(integrationEnvironments || []).map((v) => ({
                label: (
                  <div className={styles.dropdown_text}>
                    {capitalize(v.environment_type)}
                  </div>
                ),
                value: v.id,
              }))}
              showDivider
            >
              <div className={styles.dbt_integration_dropdown}>
                {!dbtCoreIntegrationEnvironmentId ? (
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className={styles.dbt_dropdown_text}>
                      Select integration environment
                    </div>
                    <div className={styles.icon}>
                      <ArrowDown />
                    </div>
                  </div>
                ) : (
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className={styles.dbt_dropdown_text}>
                      {capitalize(
                        (integrationEnvironments || []).find(
                          (v) => v.id === dbtCoreIntegrationEnvironmentId
                        )?.environment_type
                      )}
                    </div>
                    <div className={styles.icon}>
                      <ArrowDown />
                    </div>
                  </div>
                )}
              </div>
            </Dropdown>
          </div>
        )}
      </div>
      <div className="fw-5 fs-4 d-flex">
        <div>{label}</div>
        <div className="spacer" />
        <FormGroup switch className="mb-0">
          <Input
            type="switch"
            checked={piiEnabled}
            onChange={togglePiiEnabled}
            disabled={isSubmitted}
          />
          <Label className="mb-0">Data Protection</Label>
        </FormGroup>
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.user_input}>
        <textarea
          rows={4}
          value={purpose}
          disabled={isSubmitted}
          onChange={(e) => setPurpose(e.target.value)}
        />
        <Button
          size="sm"
          disabled={
            isSubmitted ||
            !purpose ||
            !dbtCoreIntegrationEnvironmentId ||
            !dbtCoreIntegrationId
          }
          onClick={(e) => {
            e.stopPropagation();
            onSubmit({
              dbt_core_integration_id: dbtCoreIntegrationId,
              dbt_core_integration_environment_id:
                dbtCoreIntegrationEnvironmentId,
              purpose,
              piiEnabled,
            });
          }}
        >
          <ArrowRightFullIcon />
        </Button>
      </div>
    </div>
  );
};

const CodeInputWithTags = ({
  onSubmit,
  isSubmitted,
  label = "Enter the query below",
  value: defaultValue = "",
  piiEnabled: defaultPiiEnabled = false,
  tags = [""],
}) => {
  const editorRef = useRef(null);
  const { value: piiEnabled, toggle: togglePiiEnabled } =
    useBoolean(defaultPiiEnabled);
  const [tagData, setTagData] = useState(tags.join(", "));

  return (
    <div className="d-flex flex-column gap-sm">
      <div className="fw-5 fs-4 mb-2 d-flex">
        <div>{label}</div>
        <div className="spacer" />
        <FormGroup switch className="mb-0">
          <Input
            type="switch"
            checked={piiEnabled}
            onChange={togglePiiEnabled}
            disabled={isSubmitted}
          />
          <Label className="mb-0">Data Protection</Label>
        </FormGroup>
      </div>
      <CodeEditor
        height="16em"
        ref={editorRef}
        value={defaultValue}
        readOnly={isSubmitted}
      />
      <div className="fw-5 fs-4 mt-2 mb-0">Tags</div>
      <div className={styles.user_input}>
        <textarea
          rows={2}
          value={tagData}
          disabled={isSubmitted}
          onChange={(e) => setTagData(e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div />
        <Button
          disabled={isSubmitted}
          onClick={(e) => {
            e.stopPropagation();
            onSubmit({
              purpose: editorRef.current?.getValue(),
              piiEnabled,
              tags: tagData.split(",").map((tag) => tag.trim()),
            });
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

const CodeOutput = ({ value }) => {
  return <ROCodeEditor value={value} height="16em" />;
};

const pattern = /```[^`]*```|\n\n/gi;
const replaceNewlines = (value) =>
  value.replace(pattern, (match) =>
    match.startsWith("```") ? match : " \n \n"
  );

const ToolOutput = ({ value }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[gfm, remarkBreaks]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              {...props}
              style={vscDarkPlus}
              language={match[1]}
              wrapLines={true}
              wrapLongLines={true}
              PreTag="div"
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code {...props} className={className}>
              {children}
            </code>
          );
        },
      }}
    >
      {replaceNewlines(value)}
    </ReactMarkdown>
  );
};

const SensitiveToolOutput = ({ value, pii }) => {
  const { value: showSensitive, toggle: toggleSensitive } = useBoolean(false);
  if (!pii) {
    return (
      <ReactMarkdown
        remarkPlugins={[gfm, remarkBreaks]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                {...props}
                style={vscDarkPlus}
                language={match[1]}
                wrapLines={true}
                wrapLongLines={true}
                PreTag="div"
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>
            ) : (
              <code {...props} className={className}>
                {children}
              </code>
            );
          },
        }}
      >
        {replaceNewlines(value)}
      </ReactMarkdown>
    );
  }
  return (
    <div>
      <div className={styles.sensitive_tool}>
        <ReactMarkdown
          remarkPlugins={[gfm, remarkBreaks]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  {...props}
                  style={vscDarkPlus}
                  language={match[1]}
                  wrapLines={true}
                  wrapLongLines={true}
                  PreTag="div"
                >
                  {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
              ) : (
                <code {...props} className={className}>
                  {children}
                </code>
              );
            },
          }}
        >
          {replaceNewlines(value)}
        </ReactMarkdown>
      </div>
      <Button className={styles.sensitive_data} onClick={toggleSensitive}>
        <MaskDanger />
        Sensitive Data
      </Button>
      {showSensitive && (
        <div className={styles.sensitive_chat_action}>
          <div className={styles.spacer}></div>
          <div className={styles.details_title}>Data Protection</div>
          <div className={styles.subtitle}>{pii?.message}</div>
        </div>
      )}
    </div>
  );
};

const ActionResponse = ({ title, value }) => {
  return (
    <div className={styles.action_response}>
      <div className={styles.action_title}>{title}</div>
      <div className={styles.action_divider}></div>
      <div className={styles.action_data}>
        <div className={styles.action_value}>{value}</div>
        <div className={styles.copy}>
          <CopyIconButton value={value} />
        </div>
      </div>
    </div>
  );
};

const ALLOWED_TOOL_TITLES = [
  "Query Summary",
  "Query Explanation",
  "Query Breakdown",
];
const WORKFLOWS_WITH_TOOL_TITLES = ["query_explanation"];

const getTitleAndToolOutput = (workflow, value) => {
  if (!WORKFLOWS_WITH_TOOL_TITLES.includes(workflow)) {
    return { title: null, toolOutput: value };
  }

  const regex = /^##\s(.*?)\n\n([\s\S]*)$/m;
  const matches = value.match(regex);

  if (matches && ALLOWED_TOOL_TITLES.includes(matches[1])) {
    const title = matches[1];
    const toolOutput = matches[2];
    return { title, toolOutput };
  } else {
    return { title: null, toolOutput: value };
  }
};

const ChatAction = ({
  workflow,
  value,
  onSubmit,
  stepDetail,
  stepPii,
  plan,
  reasoning,
  criticism,
  speak,
  isFinish = false,
  feedback: defaultFeedback = "",
  isSubmitted = false,
  rejectText = "Reject with Suggestions",
}) => {
  const [feedback, setFeedback] = useState(defaultFeedback);
  const { value: showDetails, toggle: toggleDetails } = useBoolean(false);
  const { value: showSensitive, toggle: toggleSensitive } = useBoolean(false);
  const { value: showFeedbackInput, toggle: toggleFeedbackInput } =
    useBoolean(false);
  const detailItems = [
    { label: "Reasoning", value: reasoning },
    { label: "Plan", value: plan },
    { label: "Criticism", value: criticism },
    { label: "Speak", value: speak },
  ];

  const { title, toolOutput } = getTitleAndToolOutput(workflow, value);

  return (
    <div className={styles.chat_action}>
      {isFinish && (
        <div className={styles.finished}>
          <span className={styles.text}>Finished</span>
        </div>
      )}
      {!isFinish && (
        <>
          <div className={styles.title}>{title || "Execution Step"}</div>
          <div className={styles.spacer_title}></div>
        </>
      )}
      {/* <div className={styles.subtitle}>{stepDetail}</div> */}
      <ToolOutput value={toolOutput} />
      <div className="d-flex justify-content-between align-items-center mb-1">
        <div className="d-flex gap-sm">
          <Button
            color="primary"
            disabled={showFeedbackInput || isSubmitted}
            onClick={() => onSubmit({ approval: true, feedback })}
          >
            {isFinish
              ? "Finish"
              : workflow === "query_explanation"
              ? "Continue to next step"
              : "Approve"}
          </Button>
          <Button
            className={classNames(styles.reject_button, {
              [styles.reject_active]: showFeedbackInput,
            })}
            disabled={isSubmitted}
            onClick={toggleFeedbackInput}
          >
            {rejectText}
          </Button>
        </div>
        <div className="d-flex gap-sm">
          {stepPii && (
            <Button className={styles.sensitive_data} onClick={toggleSensitive}>
              <MaskDanger />
              Sensitive Data
            </Button>
          )}
          <Button
            className={classNames(styles.details_button, {
              [styles.details_active]: showDetails,
            })}
            onClick={toggleDetails}
          >
            Details
          </Button>
        </div>
      </div>
      {showFeedbackInput && (
        <div className="d-flex flex-column gap-sm mt-4">
          <div
            className={classNames(
              "w-100 bg-light br-2 d-flex flex-column gap-xs",
              styles.reject_feedback
            )}
          >
            <div className={styles.reject_feedback_title}>
              Additional Suggestions
            </div>
            <Input
              type="textarea"
              className={classNames(styles.reject_feedback_input, "mb-2")}
              value={feedback}
              disabled={isSubmitted}
              onChange={(e) => setFeedback(e.target.value)}
            />
            <div>
              <Button
                color="primary"
                disabled={!feedback || isSubmitted}
                onClick={() => onSubmit({ approval: false, feedback })}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
      {showDetails && (
        <div className={styles.chat_action}>
          <div className={styles.spacer}></div>
          <div className={styles.details_title}>Details</div>
          <div className={styles.subtitle}>{stepDetail}</div>
          {detailItems.map((item, i) => {
            return (
              <div key={i} className="d-flex gap-xs">
                <div className={styles.detail_title}>{item.label}:</div>
                <div className={styles.detail_action}>{item.value}</div>
              </div>
            );
          })}
        </div>
      )}
      {showSensitive && (
        <div className={styles.chat_action}>
          <div className={styles.spacer}></div>
          <div className={styles.details_title}>Data Protection</div>
          <div className={styles.subtitle}>{stepPii?.message}</div>
        </div>
      )}
    </div>
  );
};

const ChatFinished = () => (
  <div className="d-flex align-items-center gap-xs">
    <ThinkingIcon />
    <div>
      Our AI Agent just finished. Please help engineers make AI more useful for
      you with feedback
    </div>
  </div>
);

const ChatLoader = ({ label = "Thinking" }) => {
  const [text, setText] = useState(label);
  useEffect(() => {
    const timer = setInterval(() => {
      setText((_text) => (_text.includes("...") ? label : _text + "."));
    }, 500);
    return () => clearInterval(timer);
  }, [label]);
  return (
    <div className={styles.launcher}>
      <Bot className={styles.robot_image} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

const ChatError = ({ error }) => {
  return <div>{error}</div>;
};

const CodeDiff = ({ onSubmit, isSubmitted }) => {
  const editorRef = useRef(null);
  return (
    <div className="d-flex flex-column gap-sm">
      <div className="text-muted">Enter the queries below</div>
      <CodeDiffEditor height="16em" ref={editorRef} />
      <div className="d-flex justify-content-between">
        <div />
        <Button
          disabled={isSubmitted}
          onClick={(e) => {
            e.stopPropagation();
            onSubmit(editorRef.current?.getValue());
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

const ChatTable = ({ header, data }) => {
  return (
    <Table bordered responsive hover size="sm" className="m-0">
      <thead>
        <tr className="table-light">
          {header.map((h) => (
            <th key={h}>{h}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, j) => (
          <tr key={j}>
            {row.map((v, i) => (
              <td key={i}>{v}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const Chart = ({ chartType, header, data, xAxis, yAxis }) => {
  const tableData = useMemo(
    () =>
      data.map((row) => {
        const rowObj = {};
        header.forEach((label, i) => (rowObj[label] = row[i]));
        return rowObj;
      }),
    []
  );

  const [ChartContainer, ChartElem] = useMemo(() => {
    if (chartType === "bar") return [BarChart, Bar];
    if (chartType === "line") return [LineChart, Line];
    return [LineChart, Line];
  }, [chartType]);

  const [_xAxis, _yAxis] = useMemo(() => {
    let _xAxis = xAxis,
      _yAxis = yAxis;
    if (!_xAxis || !header.includes(_xAxis)) _xAxis = header[0];
    if (_yAxis && _yAxis.length > 0)
      _yAxis = _yAxis.filter((y) => header.includes(y));
    if (!_yAxis || _yAxis.length === 0)
      _yAxis = header.filter((h) => h !== _xAxis);
    return [_xAxis, _yAxis];
  }, []);

  return (
    <ChartContainer width={500} height={300} data={tableData}>
      <CartesianGrid strokeDasharray="5 5" />
      <XAxis dataKey={_xAxis} />
      <YAxis />
      <Tooltip />
      <Legend />
      {_yAxis.map((y, i) => (
        <ChartElem
          key={y}
          dataKey={y}
          fill={COLOR_PALETTE[i % COLOR_PALETTE.length]}
          stroke={COLOR_PALETTE[i % COLOR_PALETTE.length]}
          strokeWidth={2}
          type="monotone"
        />
      ))}
    </ChartContainer>
  );
};

const HumanInput = ({
  label,
  placeholder = "",
  defaultValue = "",
  onSubmit,
  isSubmitted = false,
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <div>
      <div className="fw-5 fs-4 mb-2">{label}</div>
      <div className={styles.user_input}>
        <textarea
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          size="sm"
          disabled={isSubmitted}
          onClick={(e) => {
            e.stopPropagation();
            onSubmit(value);
          }}
        >
          <ArrowRightFullIcon />
        </Button>
      </div>
    </div>
  );
};

const TableSelection = ({
  tables,
  onSubmit,
  isSubmitted = false,
  purpose: defaultPurpose = "",
  datastoreId: defaultDatastoreId = -1,
  piiEnabled: defaultPiiEnabled = false,
}) => {
  const [purpose, setPurpose] = useState(defaultPurpose);
  const [datastoreId, setDatastoreId] = useState(defaultDatastoreId);
  const [datastore, setDatastore] = useState(defaultDatastoreId);
  const { value: piiEnabled, toggle: togglePiiEnabled } =
    useBoolean(defaultPiiEnabled);

  useEffect(() => {
    setPurpose(defaultPurpose);
  }, [defaultPurpose]);

  return (
    <div>
      <div className="fw-5 fs-4 mb-2">
        Which datastores should be included in this agent?
      </div>
      <div className="mb-3 d-flex gap-lg">
        <AsyncMultiSelectDropdown
          options={tables.map((t) => ({
            label: t.data_store_name,
            value: t.data_store_id,
          }))}
          onChange={setDatastoreId}
          value={(k) => k === datastoreId}
          onApply={() => setDatastore(datastoreId)}
          disabled={isSubmitted}
        >
          <div className={styles.select_trigger}>
            <div>
              {datastore !== -1
                ? tables.find((t) => t.data_store_id === datastore)
                    .data_store_name
                : "Select Datastore"}
            </div>
            <ArrowDownIcon />
          </div>
        </AsyncMultiSelectDropdown>
      </div>
      <div className="fw-5 fs-4 mb-2 d-flex">
        <div>Specify the initial checks to run</div>
        <div className="spacer" />
        <FormGroup switch className="mb-0">
          <Input
            type="switch"
            checked={piiEnabled}
            onChange={togglePiiEnabled}
            disabled={isSubmitted}
          />
          <Label className="mb-0">Data Protection</Label>
        </FormGroup>
      </div>
      <div className={styles.user_input}>
        <textarea
          rows={4}
          value={purpose}
          disabled={isSubmitted}
          onChange={(e) => setPurpose(e.target.value)}
        />
        <Button
          size="sm"
          disabled={isSubmitted || !purpose || datastore === -1}
          onClick={(e) => {
            e.stopPropagation();
            onSubmit({ datastoreId: datastore, purpose, piiEnabled });
          }}
        >
          <ArrowRightFullIcon />
        </Button>
      </div>
    </div>
  );
};

const TaskList = ({ tasks, onSubmit, isSubmitted = false }) => {
  return (
    <div>
      <div className="fs-4 mb-3">Steps to be executed</div>
      <div className="d-flex flex-column gap-xs mb-3">
        {tasks.map((t) => (
          <div className={styles.task_item} key={t}>
            {t}
          </div>
        ))}
      </div>
      <Button className="w-100" disabled={isSubmitted} onClick={onSubmit}>
        Launch
      </Button>
    </div>
  );
};

const ChatBlock = ({ data }) => {
  return (
    <div className={styles.blocked}>
      <div className={styles.blocked_title}>
        <BlockedDanger />
        Agent Terminated
      </div>
      <div className={styles.spacer_title}></div>
      <div className={styles.text}>{data}</div>
    </div>
  );
};

const ChatAgentError = ({ data }) => {
  return (
    <div className={styles.blocked}>
      <div className={styles.blocked_title}>
        <BlockedDanger />
        Agent Error
      </div>
      <div className={styles.spacer_title}></div>
      <div className={styles.text}>
        {data.split(/(\[.*?\]\(.*?\))/).map((part, i) => {
          if (part.startsWith("[") && part.endsWith(")")) {
            const [text, link] = part
              .replace("[", "")
              .replace(")", "")
              .split("](");
            return (
              <Link key={i} to={link}>
                {text}
              </Link>
            );
          }
          return part;
        })}
      </div>
    </div>
  );
};

const ManifestInfo = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleUnhover = () => {
    setIsHovered(false);
  };

  return (
    <div className={styles.manifest_info_icon}>
      <div
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
        className={styles.manifest_info}
        data-tooltip-id="manifest_info"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group">
            <path
              id="Vector"
              d="M9 1.5C4.85787 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5Z"
              stroke={isHovered ? "#247EFE" : "#7A899E"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M9 12.332L9 8.9987"
              stroke={isHovered ? "#247EFE" : "#7A899E"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="Vector_3">
              <path
                d="M9 5.66602L8.99167 5.66602L9 5.66602Z"
                fill={isHovered ? "#247EFE" : "#7A899E"}
              />
              <path
                d="M9 5.66602L8.99167 5.66602"
                stroke={isHovered ? "#247EFE" : "#7A899E"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </svg>
      </div>
      <ReactTooltip
        id="manifest_info"
        className={styles.manifest_info_tooltip}
        classNameArrow={styles.manifest_info_arrow}
      >
        <div className="mb-2">
          {
            "If you use dbt core, manifest.json file is stored in /target folder in your dbt project directory."
          }
        </div>
        <div>
          {
            "If you use dbt cloud, manifest.json file is available in Artifacts tab in the job Run History (Navigation: Deploy->Run History -> <specific run> -> Artifacts)"
          }
        </div>
      </ReactTooltip>
    </div>
  );
};

const ManifestFileUpload = ({
  onSubmit,
  piiEnabled: defaultPiiEnabled = false,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [permanentDisabled, setPermanentDisabled] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const jsonContent = JSON.parse(event.target.result);
          onSubmit({
            manifest_file: jsonContent,
            piiEnabled: defaultPiiEnabled,
          });
          setPermanentDisabled(true);
        } catch (error) {
          onSubmit({
            manifest_file: "Error parsing JSON file",
            piiEnabled: defaultPiiEnabled,
          });
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  return (
    <div className={styles.manifest_upload}>
      <div className={styles.heading}>
        <div className={styles.title}>Upload the dbt manifest file</div>
        <ManifestInfo />
      </div>

      <div className={styles.spacer}></div>
      <div className={styles.upload}>
        <label className={styles.label}>
          <Input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          {selectedFile ? (
            <div className={styles.file_name}>{selectedFile.name}</div>
          ) : (
            <div className={styles.select_file}>
              <UploadIcon />
              <div className={styles.normal_text}>Select files for upload</div>
            </div>
          )}
        </label>
        <Button
          className={styles.upload_button}
          onClick={handleFileUpload}
          disabled={!selectedFile || permanentDisabled}
        >
          <div className={styles.upload_text}>Upload</div>
        </Button>
      </div>
    </div>
  );
};

const ParsingFile = () => {
  const label = "Parsing your file";
  const [text, setText] = useState(label);
  useEffect(() => {
    const timer = setInterval(() => {
      setText((_text) => (_text.includes("...") ? label : _text + "."));
    }, 500);
    return () => clearInterval(timer);
  }, [label]);

  return (
    <div className={styles.parse_file}>
      <ChatLoader label="Parsing your file" />
      {/* <div className={styles.robot}>
        <img
          className={styles.robot_image}
          src={QueryInsightsRobot}
          alt="query_insights_robot"
        />
        <div className={styles.text}>{text}</div>
      </div> */}
    </div>
  );
};

const ParsingComplete = () => {
  return (
    <div className={styles.parsing_complete}>
      <div className={styles.title}>Parsing your file</div>
      <div className={styles.spacer}></div>
      <div className={styles.text}>File parsing is complete.</div>
    </div>
  );
};

const DBTModelColumnSelection = ({ result, onSubmit }) => {
  const [modelName, setModelName] = useState("");
  const [activeColumns, setActiveColumns] = useState({});
  const [columnsSelected, setColumnsSelected] = useState(0);
  const [resource, setResource] = useState(null);
  const [permanentDisabled, setPermanentDisabled] = useState(false);

  const handleModelChange = (e) => {
    setModelName(e);
    setResource(result.find((d) => d.model_name == e));
  };

  const handleGenerateDocs = () => {
    setPermanentDisabled(true);
    const columns = Object.keys(activeColumns).filter((d) => activeColumns[d]);
    let purpose = `The user wants the model description for the model ${modelName}`;
    purpose += ` and the documentation for the following columns : ${columns}`;
    onSubmit(purpose, resource);
  };

  return (
    <div className={styles.dbt_model_column_selection}>
      <div className={styles.model_row}>
        <div className={styles.title}>Model name :</div>
        <Dropdown
          popoverProps={{ placement: "bottom", offset: [0, 0] }}
          id={"model-dropdown"}
          onChange={(e) => {
            handleModelChange(e);
          }}
          value={modelName}
          options={result.map((v) => ({
            label: (
              <div className={styles.dropdown_text}>
                {capitalize(v.model_name)}
              </div>
            ),
            value: v.model_name,
          }))}
          showDivider
        >
          <div className={styles.dbt_dropdown}>
            {!modelName ? (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className={styles.dbt_dropdown_text}>Select Model</div>
                <div className={styles.icon}>
                  <ArrowDown />
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className={styles.dbt_dropdown_text}>
                  {capitalize(modelName)}
                </div>
                <div className={styles.icon}>
                  <ArrowDown />
                </div>
              </div>
            )}
          </div>
        </Dropdown>
      </div>
      {modelName && (
        <div className={styles.column_row}>
          <div className={styles.title}>Column name :</div>
          <div id="doc-gen-columns-popover" className={styles.dbt_dropdown}>
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div className={styles.dbt_dropdown_text}>
                {columnsSelected === 0
                  ? "Select Columns"
                  : columnsSelected === 1
                  ? "1 Column selected"
                  : `${columnsSelected} Columns selected`}
              </div>
              <div className={styles.icon}>
                <ArrowDown />
              </div>
            </div>
          </div>
          <DocGenColumnPopover
            id="doc-gen-columns-popover"
            resource={resource}
            setColumns={setActiveColumns}
            setColumnsSelected={setColumnsSelected}
          />
        </div>
      )}
      <Button
        className={styles.button}
        disabled={permanentDisabled || !modelName || columnsSelected === 0}
        onClick={handleGenerateDocs}
      >
        <div className={styles.text}>Generate docs</div>
      </Button>
    </div>
  );
};

const DBTDescription = ({ value }) => {
  return (
    <div className={styles.dbt_description}>
      <div className={styles.title}>Description</div>
      <div className={styles.spacer}></div>
      <div className={styles.text}>Here is your description</div>
      <CodeOutput value={value} />
    </div>
  );
};

const DBTFinish = ({ reset, onSubmit }) => {
  const handleFinish = () => {
    reset();
  };
  const handleGenMoreDocs = () => {
    onSubmit();
  };
  return (
    <div className={styles.dbt_finish}>
      <div className={styles.title}>I have finished the workflow.</div>
      <div className={styles.buttons}>
        <Button className={styles.finish_button}>
          <div className={styles.finish_text} onClick={handleFinish}>
            Finish
          </div>
        </Button>
        <Button
          outline
          className={styles.gen_button}
          onClick={handleGenMoreDocs}
        >
          <div className={styles.gen_text}>Generate more docs</div>
        </Button>
      </div>
    </div>
  );
};

const AlertCodeInputWithText = ({
  tables = null,
  onSubmit,
  isSubmitted,
  label = "Enter the query below",
  purpose: defaultPurpose = "",
  datastoreId: defaultDatastoreId = -1,
  piiEnabled: defaultPiiEnabled = false,
}) => {
  const editorRef = useRef(null);
  const { value: piiEnabled, toggle: togglePiiEnabled } =
    useBoolean(defaultPiiEnabled);
  const [purpose, setPurpose] = useState(defaultPurpose);
  const [datastoreId, setDatastoreId] = useState(defaultDatastoreId);
  const [datastore, setDatastore] = useState(defaultDatastoreId);
  const [notifierType, setNotifierType] = useState("");
  const [slackChannel, setSlackChannel] = useState("");
  const [slackChannels, setSlackChannels] = useState("");
  const [email, setEmail] = useState("");

  const GET_ALL_SLACK_CHANNELS = "GET-ALL-SLACK-CHANNELS";

  const { data, isLoading } = useQuery({
    queryKey: [GET_ALL_SLACK_CHANNELS],
    queryFn: getAllSlackChannels,
    onSuccess: (res) => {
      setSlackChannels(res.map((r) => r.config.name));
    },
  });

  const { config } = useCopilot();

  useEffect(() => {
    setPurpose(defaultPurpose);
  }, [defaultPurpose]);

  return (
    <div className={styles.code_input_with_text}>
      {tables && (
        <>
          <div className="fw-5 fs-4 mb-2">
            Which datastores should be included in this agent?
          </div>
          <div className="mb-3 d-flex gap-lg">
            <AsyncMultiSelectDropdown
              options={tables.map((t) => ({
                label: t.data_store_name,
                value: t.data_store_id,
              }))}
              onChange={setDatastoreId}
              value={(k) => k === datastoreId}
              onApply={() => setDatastore(datastoreId)}
              disabled={isSubmitted}
            >
              <div className={styles.select_trigger}>
                <div>
                  {datastore !== -1
                    ? tables.find((t) => t.data_store_id === datastore)
                        .data_store_name
                    : "Select Datastore"}
                </div>
                <ArrowDownIcon />
              </div>
            </AsyncMultiSelectDropdown>
          </div>
        </>
      )}

      <div className="fw-5 fs-4 d-flex">
        <div>{label}</div>
        <div className="spacer" />
        <FormGroup switch className="mb-0">
          <Input
            type="switch"
            checked={piiEnabled}
            onChange={togglePiiEnabled}
            disabled={isSubmitted}
          />
          <Label className="mb-0">Data Protection</Label>
        </FormGroup>
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.alert_notifier}>
        <div className={styles.title}>Select notification destination</div>
        <Dropdown
          id="notifier-type-dropdown"
          popoverProps={{ placement: "bottom", offset: [0, 0] }}
          onChange={setNotifierType}
          value={notifierType}
          options={["slack", "email"].map((v) => ({
            label: <div>{capitalize(v)}</div>,
            value: v,
          }))}
          optionStyles={{ width: "200px" }}
          showDivider
        >
          <div className={styles.notifier_dropdown}>
            {notifierType === "" ? (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div>Select type</div>
                <div className={styles.icon}>
                  <ArrowDown />
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center gap-xs">
                {capitalize(notifierType)}
              </div>
            )}
          </div>
        </Dropdown>
        {notifierType === "slack" && (
          <Dropdown
            id="slack-dropdown"
            popoverProps={{ placement: "bottom", offset: [0, 0] }}
            onChange={setSlackChannel}
            value={slackChannel}
            options={slackChannels.map((v) => ({
              label: <div>{v}</div>,
              value: v,
            }))}
            optionStyles={{ width: "200px" }}
            showDivider
          >
            <div className={styles.notifier_dropdown}>
              {slackChannel === "" ? (
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <div>Select Slack channel</div>
                  <div className={styles.icon}>
                    <ArrowDown />
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center gap-xs">
                  {slackChannel}
                </div>
              )}
            </div>
          </Dropdown>
        )}
        {notifierType === "email" && (
          <Input
            style={{ width: "200px" }}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        )}
      </div>
      <CodeEditor
        height="16em"
        ref={editorRef}
        value={defaultPurpose}
        readOnly={isSubmitted}
      />
      <Button
        disabled={isSubmitted}
        className={styles.button}
        onClick={(e) => {
          e.stopPropagation();
          let notifierConfig = {};
          if (notifierType) {
            if (notifierType === "email") {
              notifierConfig = { email: email };
            } else if (notifierType === "slack") {
              notifierConfig = { slack_channel: slackChannel };
            }
          }
          onSubmit({
            datastoreId,
            purpose: editorRef.current?.getValue(),
            piiEnabled,
            config: {
              notifier_config: [{ type: notifierType, config: notifierConfig }],
              ...config,
            },
          });
        }}
      >
        Submit
      </Button>
    </div>
  );
};

const ChatDropdown = ({ options, onSubmit }) => {
  const [selection, setSelection] = useState({});

  if (!options) {
    return null;
  }

  const handleSubmit = () => {
    onSubmit(selection);
  };

  return (
    <div className={styles.dbt_model_column_selection}>
      {Object.keys(options).map((key) => (
        <div key={key} className={styles.model_row}>
          <div className={styles.title}>{capitalize(key)}</div>
          <Dropdown
            id={`${key}-dropdown`}
            popoverProps={{ placement: "bottom", offset: [0, 0] }}
            onChange={(e) => {
              setSelection({ ...selection, [key]: e });
            }}
            value={selection[key]}
            options={options[key].map((v) => ({
              label: <div>{v}</div>,
              value: v,
            }))}
            optionStyles={{ width: "200px" }}
            showDivider
          >
            <div className={styles.dbt_dropdown}>
              {selection[key] === "" ? (
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <div>Select {key}</div>
                  <div className={styles.icon}>
                    <ArrowDown />
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center gap-xs">
                  {selection[key]}
                </div>
              )}
            </div>
          </Dropdown>
        </div>
      ))}
      <Button
        className={styles.button}
        onClick={handleSubmit}
        disabled={Object.keys(options).some((key) => !selection[key])}
      >
        <div className={styles.text}>Submit</div>
      </Button>
    </div>
  );
};

const LineageDropdown = ({ options = [], onSubmit }) => {
  const [column, setColumn] = useState("");
  const [model, setModel] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (!options) return;
    if ("column_order" in options && options["column_order"].length > 0)
      setColumn(options["column_order"][0]);
  }, [options]);

  useEffect(() => {
    if (!options) return;
    if (column in options && options[column].length > 0)
      setModel(options[column][0]);
  }, [column, options]);

  useEffect(() => {
    if (
      "column_order" in options &&
      options["column_order"].length === 1 &&
      options["column_order"][0] in options &&
      options[options["column_order"][0]].length === 1
    ) {
      onSubmit({
        column: options["column_order"][0],
        model: options[options["column_order"][0]][0],
      });
      setDisableSubmit(true);
    }
  }, [onSubmit, options]);

  if (!options) {
    return null;
  }

  const handleSubmit = () => {
    onSubmit({
      column: column,
      model: model,
    });
    setDisableSubmit(true);
  };

  return (
    <div className={styles.dbt_model_column_selection}>
      {!options ||
      !options["column_order"] ||
      options["column_order"].length === 0 ? (
        <div className={styles.text}>No column and model data found</div>
      ) : (
        <>
          <div className={classNames(styles.column_row, "mb-2")}>
            <div className={styles.title}>Column:</div>
            <Dropdown
              id={"column-dropdown"}
              popoverProps={{ placement: "bottom", offset: [0, 0] }}
              onChange={setColumn}
              value={column}
              options={(options["column_order"] || []).map((v) => ({
                label: <div>{v}</div>,
                value: v,
              }))}
              showDivider
            >
              <div className={styles.dbt_dropdown}>
                {column === "" ? (
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <div>Select Column</div>
                    <div className={styles.icon}>
                      <ArrowDown />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-xs">
                    {column.substring(0, 50) +
                      (column.length > 50 ? "..." : "")}
                  </div>
                )}
              </div>
            </Dropdown>
          </div>
          <div className={styles.model_row}>
            <div className={styles.title}>Model: </div>
            <Dropdown
              id={"model-dropdown"}
              popoverProps={{ placement: "bottom", offset: [0, 0] }}
              onChange={setModel}
              value={model}
              options={(options[column] || []).map((v) => ({
                label: <div>{v}</div>,
                value: v,
              }))}
              showDivider
            >
              <div className={styles.dbt_dropdown}>
                {model === "" ? (
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <div>Select Model</div>
                    <div className={styles.icon}>
                      <ArrowDown />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-xs">
                    {model.substring(0, 50) + (model.length > 50 ? "..." : "")}
                  </div>
                )}
              </div>
            </Dropdown>
          </div>
          <Button
            className={styles.button}
            onClick={handleSubmit}
            disabled={!column || !model || disableSubmit}
          >
            <div className={styles.text}>Submit</div>
          </Button>
        </>
      )}
    </div>
  );
};

const LineageGraph = ({ data }) => {
  const [showGraph, setShowGraph] = useState(false);

  if (!data || !data.tables || data.tables.length === 0) {
    return <div className={styles.text}>No lineage data found</div>;
  }

  return (
    <>
      <StaticLineage {...data} />
      <Button onClick={() => setShowGraph(true)} className="m-2">
        View Graph in fullscreen
      </Button>
      <Modal
        isOpen={showGraph}
        toggle={() => setShowGraph((b) => !b)}
        fullscreen
      >
        <ModalBody>
          <StaticLineage height="95%" {...data} />
          <Button onClick={() => setShowGraph(false)} className="m-2">
            Go back
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

const StreamingToolOutput = ({ session_id, onSubmit }) => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const getStream = async () => {
    setIsLoading(true);
    const url = `/copilot/session/${session_id}`;

    const onProgress = (chunk) => {
      if (!value && chunk.length > 0) {
        setIsLoading(false);
      }
      setValue((prev) => prev + chunk);
    };

    await generateStream(url, onProgress, {})
      .then(() => {
        setIsLoading(false);
        onSubmit();
      })
      .catch((error) => {
        setValue(error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (session_id) {
      getStream();
    }
  }, [session_id]);

  if (isLoading)
    return (
      <RelativeComponentLoader
        componentHeight={40}
        label="Gathering intermediate lineage details..."
      />
    );

  return (
    <ReactMarkdown
      remarkPlugins={[gfm, remarkBreaks]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              {...props}
              style={vscDarkPlus}
              language={match[1]}
              wrapLines={true}
              wrapLongLines={true}
              PreTag="div"
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code {...props} className={styles.syntax_highlighter}>
              {children}
            </code>
          );
        },
      }}
    >
      {replaceNewlines(value)}
    </ReactMarkdown>
  );
};

// Old copilot components
const Tag = ({ active, onClick, children }) => (
  <button
    className={classnames(styles.tag, {
      [styles.active]: active,
    })}
    onClick={onClick}
  >
    {children}
  </button>
);

const TermForm = ({
  showTitle,
  rule,
  onRuleSubmit,
  onRuleChange,
  datasource_type,
}) => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { rule, data_store_type: datasource_type },
    validationSchema: Yup.object({ rule: Yup.string().required() }),
    onSubmit: onRuleSubmit,
  });

  return (
    <>
      {showTitle && <div className="fs-4 fw-500 mb-2">Generate Terms</div>}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
      >
        <div className={styles.generate_input}>
          <Dropdown
            id="datasource-type-dropdown"
            onChange={(v) => validation.setFieldValue("data_store_type", v)}
            value={validation.values.data_store_type}
            options={["s3", "postgres", "snowflake"].map((v) => ({
              label: (
                <div>
                  {getIconByDatastoreType(v, "me-2 icon-md")}
                  {capitalize(v)}
                </div>
              ),
              value: v,
            }))}
            showDivider
          >
            <div className={styles.datasource_dropdown}>
              {validation.values.data_store_type === "" ? (
                <>
                  Datasource
                  <div className={styles.icon}>
                    <ArrowDown />
                  </div>
                </>
              ) : (
                getIconByDatastoreType(
                  validation.values.data_store_type,
                  "icon-md"
                )
              )}
            </div>
          </Dropdown>
          <input
            id="rule"
            name="rule"
            placeholder="Type terms here"
            value={validation.values.rule}
            onChange={(e) => {
              validation.handleChange(e);
              onRuleChange();
            }}
          />
          <button
            type="submit"
            className={classnames(styles.input_next_icon, {
              [styles.active]: validation.values.rule,
            })}
          >
            <ArrowRightFull />
          </button>
        </div>
      </Form>
    </>
  );
};

const TermCodeEditor = ({ termLoading, editorText, termId, isError }) => (
  <>
    {termLoading ? (
      <div style={{ height: "40vh" }}>
        <RelativeComponentLoader
          label="Generating contract code..."
          componentHeight={40}
        />
      </div>
    ) : (
      <div>
        {isError && (
          <div className={styles.term_error}>
            <InfoCircle />
            <div>
              Sorry! Term code cannot be generated by a machine, we still need
              humans sometime.-{" "}
              <a
                href="https://docs.altimate.ai/docs/list-of-metrics"
                target="_blank"
                className={styles.doc_link}
                rel="noreferrer"
              >
                contract policies reference
              </a>
            </div>
          </div>
        )}
        <CueCodeEditor height="32vh" value={editorText} />
        <div className="mt-3 d-flex justify-content-between">
          <FeedbackModal termId={termId}>
            <Button
              outline
              color="warning"
              className="d-flex align-items-center gap-xs"
            >
              <ThumbsupIcon />
              Feedback
            </Button>
          </FeedbackModal>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                "https://docs.altimate.ai/docs/list-of-metrics",
                "_blank"
              );
            }}
          >
            Policy Docs
          </Button>
        </div>
      </div>
    )}
  </>
);

export const TermTableSelection = ({
  setShowDatasetModal,
  resource,
  isRecommend,
  rules,
  active,
  onTagClick,
  columns,
  recommendations,
  selectedRule,
  setRuleInput,
  setSelectedRule,
  setRuleFeedback,
  ruleInput,
  _postTerms,
  setShowEditor,
  ruleFeedback,
  _postFeedbackRule,
  showEditor,
  termLoading,
  isTermGenError,
  terms,
  editorText,
  showDatasetModal,
  setResource,
  setActive,
  setColumns,
  setRules,
  setEditorText,
  _postRules,
}) => {
  return (
    <>
      <div className="fs-4 fw-500 mb-2">Generate Contract Policy Terms</div>
      <div className="d-flex gap-lg">
        <div
          className={classnames(
            "d-flex justify-content-between align-items-center",
            styles.select_trigger
          )}
          onClick={(e) => {
            setShowDatasetModal(true);
          }}
        >
          <div>{resource?.name || "1. Select the Dataset"}</div>
          <div className={styles.trigger_next_icon}>
            <ArrowRightFull />
          </div>
        </div>
        <div id="columns-popover" className={styles.select_trigger}>
          2. Select columns
        </div>
      </div>
      {isRecommend && (
        <div className={styles.tags}>
          {rules?.cross_column_rules?.length > 0 && (
            <Tag
              active={active["crossColumn"]}
              onClick={onTagClick("crossColumn")}
            >
              Cross-Column
            </Tag>
          )}
          {rules?.table_rules?.length > 0 && (
            <Tag active={active["table"]} onClick={onTagClick("table")}>
              Table
            </Tag>
          )}
          {columns.map((c) => (
            <Tag
              key={c.name}
              active={active[c.name]}
              onClick={onTagClick(c.name)}
            >
              <ColumnDatatype
                datatype={c.datatype}
                color={active[c.name] && "white"}
                size="1.6em"
              />
              <div>{c.name}</div>
            </Tag>
          ))}
        </div>
      )}
      <DatasetModal
        isOpen={showDatasetModal}
        toggle={() => setShowDatasetModal((b) => !b)}
        onSelect={(r) => {
          setShowEditor(false);
          setResource(r);
          setActive({});
          setColumns([]);
          setRules(null);
        }}
      />
      <ColumnPopover
        id="columns-popover"
        resource={resource}
        setColumns={(c) => {
          setColumns(c);
          setRuleInput("");
          setEditorText("");
          _postRules({
            resource_id: resource?.id,
            column_ids: c.map((item) => item.table_schema_id),
          });
        }}
      />
    </>
  );
};

export const RuleSelection = ({
  rules,
  recommendations,
  selectedRule,
  setRuleInput,
  setSelectedRule,
  setRuleFeedback,
}) => {
  return (
    <>
      <div className={styles.scroll_container}>
        <div className="fs-4 fw-500 mb-2">Recommendations</div>
        <div className={styles.scroll_list}>
          {recommendations.map((item) => (
            <div className={styles.card} key={item.id}>
              <div className="mb-3 d-flex justify-content-between">
                <ColorTag label={item.column} color={item.color} />
                <Button
                  size="sm"
                  color={selectedRule === item.id ? "success" : "primary"}
                  onClick={(e) => {
                    setRuleInput(item.rule);
                    setSelectedRule(item.id);
                    setRuleFeedback({
                      updated: false,
                      ruleId: rules.rule_recommendation_id,
                    });
                  }}
                >
                  {selectedRule === item.id ? "Selected" : "Select"}
                </Button>
              </div>
              <div className="fw-500">{item.rule}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const RuleInput = ({
  resource,
  isRecommend,
  rules,
  setSelectedRule,
  setRuleFeedback,
  ruleInput,
  _postTerms,
  setShowEditor,
  ruleFeedback,
  _postFeedbackRule,
}) => {
  return (
    <>
      {(!resource || !!rules) && (
        <TermForm
          showTitle={!isRecommend}
          rule={ruleInput}
          datasource_type={resource?.datasource_type || ""}
          onRuleSubmit={(data) => {
            _postTerms(data);
            setShowEditor(true);
            if (ruleFeedback.ruleId && ruleFeedback.updated) {
              _postFeedbackRule({
                rule_recommendation_id: ruleFeedback.ruleId,
                updated_rule: data.rule,
              });
            }
          }}
          onRuleChange={() => {
            setRuleFeedback((x) => ({ ...x, updated: true }));
            setSelectedRule(null);
          }}
        />
      )}
    </>
  );
};

export const TermCode = ({
  termLoading,
  isTermGenError,
  terms,
  editorText,
}) => {
  return (
    <>
      <TermCodeEditor
        termLoading={termLoading}
        isError={isTermGenError}
        termId={terms?.term_generation_id}
        editorText={editorText}
      />
    </>
  );
};
// Old copilot components end

const chatItemMap = {
  "code-input": CodeInput,
  "code-output": CodeOutput,
  "code-diff": CodeDiff,
  "chat-loader": ChatLoader,
  "chat-error": ChatError,
  "tool-output": SensitiveToolOutput,
  "chat-action": ChatAction,
  "chat-table": ChatTable,
  "chat-chart": Chart,
  "table-selection": TableSelection,
  "chat-finished": ChatFinished,
  "human-input": HumanInput,
  "chat-feedback": Feedback,
  "code-input-text": CodeInputWithText,
  "code-input-tags": CodeInputWithTags,
  "code-input-dropdown": CodeInputWithDropdown,
  "task-list": TaskList,
  "chat-block": ChatBlock,
  "chat-agent-error": ChatAgentError,
  "manifest-file-upload": ManifestFileUpload,
  "parsing-file": ParsingFile,
  "parsing-complete": ParsingComplete,
  "dbt-model-column-selection": DBTModelColumnSelection,
  "dbt-description": DBTDescription,
  "dbt-finish": DBTFinish,
  "alert-code-input-text": AlertCodeInputWithText,
  "chat-dropdown": ChatDropdown,
  "lineage-dropdown": LineageDropdown,
  "lineage-graph": LineageGraph,
  "chat-streaming": StreamingToolOutput,
};

const ChatItemRenderer = ({ type, args }) => {
  const Comp = useMemo(() => chatItemMap[type], [type]);
  return <Comp {...args} />;
};

export { ChatItemRenderer };
