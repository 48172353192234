import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown, Stack } from "@uicore";
import classes from "../summary.module.scss";
import OpportunitiesList from "./OpportunitiesList";
import dayjs from "dayjs";
const Opportunities = () => {
    var _a;
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    return (_jsxs("section", { id: "opportunities", children: [_jsxs(Stack, { className: "justify-content-between", children: [_jsx("h3", { children: "Opportunities" }), _jsxs(Stack, { className: "align-items-center", children: [_jsxs(Stack, { children: [_jsx("div", { children: dayjs()
                                            .subtract(28, "day")
                                            .toDate()
                                            .toLocaleDateString("en-US", options) }), _jsx("div", { children: "-" }), _jsx("div", { children: (_a = dayjs().toDate()) === null || _a === void 0 ? void 0 : _a.toLocaleDateString("en-US", options) })] }), _jsx(Dropdown, { value: "Last 28 days", onOptionSelect: () => { }, options: [
                                    {
                                        label: "Last 28 days",
                                        value: "Last 28 days",
                                    },
                                ] })] })] }), _jsx("p", { className: classes.caption, children: "This section shows key areas in which major cost saving opportunities are present" }), _jsx(OpportunitiesList, {})] }));
};
export default Opportunities;
