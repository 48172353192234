import { Button, Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useQuery } from "react-query";
import {
  getExperimentationSavings,
  getSummaryGraph,
  getSummaryInsights,
  getSummarySidepanel,
  getSummaryStorageComparison,
  getWarehouseDecisionsCount,
} from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Dropdown } from "../../Components/Form";
import { ReactComponent as ArrowDownIcon } from "@/assets/icons/arrow_down.svg";
import { ReactComponent as AutoEqual } from "@/assets/icons/auto_equal.svg";
import { ReactComponent as AutoPlus } from "@/assets/icons/auto_plus.svg";
import { ReactComponent as SavingsAuto } from "@/assets/icons/savings_auto.svg";
import { ReactComponent as SavingsExp } from "@/assets/icons/savings_exp.svg";
import classNames from "classnames";
import { GhostTab } from "../../Components/Tab";
import { AutoManageGraph } from "../Warehouse/AutoManageGraph";
import { CustomTooltip } from "../../Components/Graph/misc";
import { formatNumber, roundToTwoDecimalPlaces } from "../../helpers/utils";
import { EXPERIMENTATION_SAVINGS } from "../../config";
import { DEFAULT_EXPERIMENT_SAVINGS } from ".";

const COST_GRAPH = "COST-GRAPH";
const COST_OVERALL_GRAPH = "COST-OVERALL-GRAPH";
const COST_HEADER = "COST-HEADER";
const COST_SIDE_PANEL = "COST-SIDE-PANEL";
const COST_INSIGHT = "COST-INSIGHT";
const STORAGE_COMPARISON = "STORAGE-COMPARISON";
const GET_EXPERIMENTATION_SAVINGS = "GET-EXPERIMENTATION-SAVINGS";
const GET_DECISIONS_COUNT = "GET-DECISIONS-COUNT";

const PAGE_MAPPING = {
  datasets: "tables",
  warehouses: "warehouse",
  experimentation: "experimentation",
  query: "query",
  query_group: "query",
};
const filterToParams = (filter) => {
  if (filter) {
    return Object.entries(filter)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${key}=${v}`).join("&");
        }
        return `${key}=${value}`;
      })
      .join("&");
  }
  return "";
};
const getNavParams = ({ page, filters }) => {
  const to = "/" + (PAGE_MAPPING[page] || page);
  if (!(filters || page === "query_group")) return [to, undefined];
  const options = { state: {} };
  if (filters) options.state.filters = filters;
  if (page === "query_group") {
    const filterParams = filterToParams(filters);
    return [`/query/groups?${filterParams}`, {}];
  }
  if (page === "query") {
    const filterParams = filterToParams(filters);
    return [`/query/all?${filterParams}`, {}];
  }
  return [to, options];
};

const HeaderTile = ({ title, detail, value, onClick }) => {
  return (
    <div className={styles.header_tile} onClick={onClick}>
      <div className={classNames("fs-5 mb-2", styles.title)}>{title}</div>
      <div className="d-flex gap-sm align-items-center">
        <div className={styles.value}>{value}</div>
        <div className={styles.detail}>{detail}</div>
      </div>
    </div>
  );
};

const ColorTile = (style) => {
  // eslint-disable-next-line react/display-name
  return ({ title, value, onClick }) => {
    const shouldClick = typeof onClick === "function";
    return (
      <div
        className={classNames(style, {
          "cursor-pointer": shouldClick,
        })}
        onClick={onClick}
      >
        <div className={styles.title}>{title}</div>
        <div className="d-flex align-items-end">
          <div className={styles.value}>{value}</div>
          <div className="spacer" />
          {shouldClick && (
            <div className={styles.expand_btn}>
              <ArrowDownIcon />
            </div>
          )}
        </div>
      </div>
    );
  };
};

const GreenTile = ColorTile(styles.green_tile);
const BlueTile = ColorTile(styles.blue_tile);
const OrangeTile = ColorTile(styles.orange_tile);
const PurpleTile = ColorTile(styles.purple_tile);
const DarkBlueTile = ColorTile(styles.dark_blue_tile);
const YellowOrangeTile = ColorTile(styles.yellow_orange_tile);

const LightColorTile = (style) => {
  // eslint-disable-next-line react/display-name
  return ({ title, value, onClick }) => {
    return (
      <div className={style} onClick={onClick}>
        <div className={styles.title}>{title}</div>
        <div className={styles.value}>{value}</div>
      </div>
    );
  };
};

const LightGreenTile = LightColorTile(styles.lightgreen_tile);
const LightBlueTile = LightColorTile(styles.lightblue_tile);
const LightPurpleTile = LightColorTile(styles.lightpurple_tile);
const LightDarkBlueTile = LightColorTile(styles.lightdarkblue_tile);
const LightYellowOrangeTile = LightColorTile(styles.lightyelloworange_tile);

const CustomYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text textAnchor="end" dy={5} fill="#66768D">
      ${payload.value}
    </text>
  </g>
);

const CustomXAxisTick = ({ x, y, payload, yearMode = false }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={yearMode ? -24 : -30}
        dy={4}
        transform="rotate(270)"
        textAnchor="middle"
        fill="#66768D"
      >
        {dayjs(payload.value).format(`${yearMode ? "MMM-YY" : "DD-MMM-YY"}`)}
      </text>
    </g>
  );
};

const PERIOD_OPTIONS = [
  { label: "Week", value: 7 },
  { label: "Last 30 Days", value: 30 },
  { label: "Year", value: 365 },
];

const AUTO_SAVINGS_PERIOD_OPTIONS = [
  { label: "Week", value: 7 },
  { label: "Last 30 Days", value: 30 },
];

const COST_COLORS_MAP = {
  compute: "#5B9FFF",
  storage: "#FFCE73",
  serverless: "#9382D9",
};

const STORAGE_COLORS_MAP = {
  active_storage: "#5B9FFF",
  stage: "#9382D9",
  failsafe: "#FFCE73",
  hybrid_table_storage: "#66768D",
};

const STYLE_MAP = {
  compute: styles.compute,
  storage: styles.storage,
  serverless: styles.serverless,
};

const SavingsBox = ({
  total_cost_savings_ui,
  overall_actual_cost_savings_ui,
  experimentation_savings_ui,
}) => {
  const navigate = useNavigate();
  const navigateToURL = {
    warehouse: "/warehouse",
    experiment: "/experimentation?statusFilter=Completed",
  };
  const handleTitleClick = (key) => () => {
    navigate(navigateToURL[key]);
  };
  return (
    <div className={styles.saving_box}>
      <div className={styles.box_1}>
        <div className={styles.label}>Total Actual Savings</div>
        <div className={styles.value}>{total_cost_savings_ui}</div>
      </div>
      <div className={styles.box_2} onClick={handleTitleClick("warehouse")}>
        <AutoEqual className={styles.auto_icon} />
        <div className={styles.box_icon}>
          <SavingsAuto />
          <div>
            <div className={styles.label}>Auto Tune Savings</div>
            <div className={styles.value}>{overall_actual_cost_savings_ui}</div>
          </div>
        </div>
      </div>
      <div className={styles.box_3} onClick={handleTitleClick("experiment")}>
        <AutoPlus className={styles.auto_icon} />
        <div className={styles.box_icon}>
          <SavingsExp />
          <div>
            <div className={styles.label}>Experimentation savings</div>
            <div className={styles.value}>{experimentation_savings_ui}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DecisionCunt = ({ period }) => {
  const { data, isLoading } = useQuery({
    queryKey: [GET_DECISIONS_COUNT, period],
    queryFn: () => getWarehouseDecisionsCount({ period: period }),
  });
  if (isLoading) return <RelativeComponentLoader componentHeight={10} />;
  return (
    <div className={styles.decision_count}>
      Auto-Tune Decisions by AI Agents: {data.decisions}
    </div>
  );
};

const CostChart = ({ graph_data, period }) => {
  const [costFilter, setCostFilter] = useState("all");

  const handleLegendClick = (data) => {
    const datakey = data?.value;
    if (datakey) {
      if (costFilter === datakey) {
        setCostFilter("all");
      } else {
        setCostFilter(datakey);
      }

      return;
    }
    setCostFilter("all");
  };

  return (
    <ResponsiveContainer minHeight={400} maxHeight={400} className="mb-4">
      <BarChart data={graph_data} margin={{ bottom: 30 }}>
        <CartesianGrid vertical={false} />
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{ bottom: -24 }}
          onClick={handleLegendClick}
          formatter={(value, entry, index) => (
            <span className={styles.legend}>{value}</span>
          )}
        />
        <XAxis
          dataKey="date"
          angle={-90}
          label={{
            value: "Date",
            position: "insideBottomRight",
            fill: "black",
          }}
          tick={
            <CustomXAxisTick
              yearMode={
                PERIOD_OPTIONS.find((x) => x.value === period)?.label === "Year"
              }
            />
          }
          interval={0}
          padding={{ right: 36 }}
        />
        <YAxis
          label={{
            value: "Cost",
            position: "insideTopLeft",
            fill: "black",
            offset: 0,
          }}
          padding={{ top: 20 }}
          tick={<CustomYAxisTick />}
        />
        <Tooltip />
        {Object.entries(COST_COLORS_MAP).map(([k, v]) => {
          return (
            <Bar
              key={k}
              barSize={8}
              name={k}
              dataKey={
                costFilter === "all" || costFilter === k ? k : k + `hidden`
              }
              fill={v}
              stackId="single"
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

const StorageChart = ({ graph_data, period }) => {
  const [storageFilter, setStorageFilter] = useState("all");

  const handleLegendClick = (data) => {
    const datakey = data?.value;
    switch (datakey) {
      case "active_storage":
      case "stage":
      case "failsafe":
      case "hybrid_table_storage":
        if (storageFilter === datakey) {
          setStorageFilter("all");
        } else {
          setStorageFilter(datakey);
        }
        break;
      case "Total storage":
        if (storageFilter === "total_storage") {
          setStorageFilter("all");
        } else {
          setStorageFilter("total_storage");
        }
        break;
      default:
        setStorageFilter("all");
    }
  };
  return (
    <ResponsiveContainer minHeight={400} maxHeight={400} className="mb-4">
      <AreaChart data={graph_data} margin={{ bottom: 30 }}>
        <CartesianGrid vertical={false} />
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{ bottom: -24 }}
          onClick={handleLegendClick}
          formatter={(value, entry, index) => (
            <span className={styles.legend}>{value}</span>
          )}
        />
        <XAxis
          dataKey="date"
          angle={-90}
          label={{
            value: "Date",
            position: "insideBottomRight",
            fill: "black",
          }}
          tick={
            <CustomXAxisTick
              yearMode={
                PERIOD_OPTIONS.find((x) => x.value === period)?.label === "Year"
              }
            />
          }
          interval={0}
          padding={{ right: 50 }}
        />
        <YAxis
          label={{
            value: "Cost",
            position: "insideTopLeft",
            fill: "black",
            offset: 0,
          }}
          padding={{ top: 20 }}
          tick={<CustomYAxisTick />}
        />
        <Tooltip />
        {Object.entries(STORAGE_COLORS_MAP).map(([k, v]) => {
          return (
            <Area
              type="monotone"
              key={k}
              name={k}
              dataKey={
                storageFilter === "all" || storageFilter === k
                  ? k
                  : k + `hidden`
              }
              fill={v}
              stroke={v}
              stackId="single"
            />
          );
        })}
        {storageFilter === "total_storage" ? (
          <Area type="monotone" dataKey="total_storage" name="Total storage" />
        ) : (
          <Area
            type="monotone"
            dataKey={
              storageFilter === "all" ? "total_storage" : "total_storage_hidden"
            }
            fill="transparent"
            name="Total storage"
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

const StorageComparisionTiles = ({ period }) => {
  const { data } = useQuery({
    queryKey: [STORAGE_COMPARISON, period],
    queryFn: () => getSummaryStorageComparison({ period }),
  });

  const formatComparison = (curr, prev) => {
    if (prev === 0) return "(0%)";
    const diff = curr >= prev ? curr - prev : prev - curr;
    const percent = Math.round((diff * 100) / prev);
    return `(${curr >= prev ? "+" : "-"}${percent}%)`;
  };

  return (
    <div className="d-flex gap-sm p-2">
      {(data?.data || []).map((item) => (
        <div key={item.label} className={styles.comparison_tile}>
          <div className={styles.label}>{item.label}</div>
          <div className={styles.value}>
            {item.curr_value}
            <div
              className={classNames(
                styles.diff,
                item.curr_value >= item.prev_value ? styles.pos : styles.neg
              )}
            >
              {formatComparison(item.curr_value, item.prev_value)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const ChartTile = ({
  graph_data,
  period,
  setPeriod,
  insightType,
  setInsightType,
  topInfo,
}) => {
  const { data } = useQuery({
    queryKey: [GET_EXPERIMENTATION_SAVINGS],
    queryFn: getExperimentationSavings,
  });
  const [startDate, setStartDate] = useState(dayjs().subtract(period, "days"));
  const [endDate, setEndDate] = useState(dayjs());

  // The useEffect below finds the minimum and maximum date from the graph_data
  // and sets the startDate and endDate accordingly
  useEffect(() => {
    if (graph_data && graph_data.length > 0) {
      const dates = graph_data.map((item) => item.date);
      const sortedDates = dates.sort((a, b) => dayjs(a) - dayjs(b));
      const minDate = dayjs(sortedDates[0]);
      const maxDate = dayjs(sortedDates[sortedDates.length - 1]);
      setStartDate(minDate);
      setEndDate(maxDate);
    }
  }, [graph_data, insightType, period]);

  const yearMode =
    PERIOD_OPTIONS.find((x) => x.value === period)?.label === "Year";

  const dateFormat = yearMode ? "MMM 'YY" : "DD MMM 'YY";

  return (
    <Card className="mb-0 h-100">
      <CardBody>
        <div className="text-black fs-5 mb-2">Costs and Savings</div>
        <div className="d-flex mb-2 gap-sm align-items-center">
          <GhostTab
            tabs={[
              { label: "Overall Cost", value: "cost" },
              { label: "Savings", value: "auto" },
              { label: "Storage Cost", value: "storage" },
            ]}
            selectedTab={insightType}
            setSelectedTab={setInsightType}
          />
          <div className="spacer" />
          <div className="text-muted">
            {startDate.format(dateFormat)}
            {" - "}
            {endDate.format(dateFormat)}
          </div>
          {insightType === "cost" && (
            <Dropdown
              id="cost-graph-period"
              options={PERIOD_OPTIONS}
              onChange={setPeriod}
              value={period}
              popoverProps={{ placement: "bottom-end", offset: [5, 5] }}
            >
              <div className={styles.chart_duration}>
                {PERIOD_OPTIONS.find((x) => x.value === period)?.label}
                <ArrowDownIcon />
              </div>
            </Dropdown>
          )}
          {insightType === "auto" && (
            <Dropdown
              id="auto-graph-period"
              options={AUTO_SAVINGS_PERIOD_OPTIONS}
              onChange={setPeriod}
              value={period}
              popoverProps={{ placement: "bottom-end", offset: [5, 5] }}
            >
              <div className={styles.chart_duration}>
                {PERIOD_OPTIONS.find((x) => x.value === period)?.label}
                <ArrowDownIcon />
              </div>
            </Dropdown>
          )}
        </div>
        {insightType === "cost" && (
          <div className={styles.summary_graph}>
            <div className={styles.spacer} />
            <CostChart graph_data={graph_data} period={period} />
          </div>
        )}
        {insightType === "auto" && (
          <div className="d-flex flex-column gap-sm">
            <AutoManageGraph
              data={graph_data}
              showPeriodDropdown={false}
              topInfo={topInfo}
            />
            <DecisionCunt period={period} />
            {data && <SavingsBox {...data} />}
          </div>
        )}
        {insightType === "storage" && (
          <div className={styles.summary_graph}>
            <div className={styles.spacer} />
            <StorageChart graph_data={graph_data} period={period} />
          </div>
        )}
        {/* {insightType === "storage" && (
          <StorageComparisionTiles period={period} />
        )} */}
      </CardBody>
    </Card>
  );
};

const BreakdownTile = ({ data, showVertical = false }) => {
  return (
    <Card className="mb-0" style={{ height: "100%" }}>
      <CardBody>
        <div className={styles.breakdown_tile}>
          <div className={styles.title}>Detailed Annual Breakdown</div>
          <div className="spacer" />
          <div className="d-flex flex-column align-items-center">
            <PieChart width={120} height={120}>
              <Pie
                data={data}
                nameKey="label"
                dataKey="real_value"
                innerRadius={0}
                outerRadius={50}
                label={false}
                paddingAngle={0}
                cornerRadius={0}
              >
                {data.map((item) => (
                  <Cell fill={COST_COLORS_MAP[item.label]} key={item.label} />
                ))}
              </Pie>
              <Tooltip
                className={styles.pie_chart_tooltip}
                formatter={(v) => `$${v.toLocaleString()}`}
              />
            </PieChart>
          </div>
          <div className="spacer" />
          <div className="d-flex flex-column gap-sm">
            {data.map(({ label, value }) => (
              <div
                key={label}
                className={classNames(
                  styles.breakdown_subtile,
                  STYLE_MAP[label]
                )}
              >
                <div className={styles.label}>
                  {label}:<span className={styles.value}>{value}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const INSIGHT_FILTERS = ["query", "infra", "datasets"];
const INSIGHT_FILTERS_LABELS = {
  query: "Query insights",
  infra: "Infra insights",
  datasets: "Datasets insights",
};
const PAGE_FILTER_MAPPING = {
  query: "query",
  query_group: "query",
  warehouses: "infra",
  datasets: "datasets",
};

const InsightCard = ({ insights }) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("query");
  const filteredInsights = insights.filter(
    (i) => PAGE_FILTER_MAPPING[i.attributes.page] === filter
  );
  return (
    <Card>
      <CardBody>
        <div className="d-flex mb-2 gap-sm align-items-center">
          <div className="text-black fs-5">Insights</div>
          <div className="spacer" />
          <div className="text-muted">
            {dayjs().subtract(1, "month").format("DD MMM YY")}
            {" - "}
            {dayjs().format("DD MMM YY")}
          </div>
          <div className={styles.header_duration}>Last 1 Month</div>
        </div>
        <div className={styles.tabs}>
          {INSIGHT_FILTERS.map((item) => {
            const itemsCount = insights.filter(
              (i) => PAGE_FILTER_MAPPING[i.attributes.page] === item
            ).length;
            return (
              itemsCount > 0 && (
                <div
                  key={item}
                  className={classNames(styles.tab_item, {
                    [styles.selected]: item === filter,
                  })}
                  onClick={() => setFilter(item)}
                >
                  <span>{INSIGHT_FILTERS_LABELS[item]}</span>
                  <div className={styles.badge}>{itemsCount}</div>
                </div>
              )
            );
          })}
        </div>
        <div className={styles.insight_card_container}>
          {filteredInsights.map(({ title, attributes }, i) => (
            <div className={styles.insight_tile} key={title + i}>
              <div className="mb-2 text-muted">{title}</div>
              {attributes.page && attributes.filters && (
                <Button
                  size="sm"
                  outline
                  color="primary"
                  onClick={() => navigate(...getNavParams(attributes))}
                >
                  Details
                </Button>
              )}
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

const HeaderCard = ({ headers }) => {
  const navigate = useNavigate();
  return (
    <Card className="mb-0">
      <CardBody>
        <div className="d-flex mb-2 gap-sm align-items-center">
          <div className="text-black fs-5">DataPilot Intelligence</div>
          <div className="spacer" />
          <div className="text-muted">
            {dayjs().subtract(1, "month").format("DD MMM YY")}
            {" - "}
            {dayjs().format("DD MMM YY")}
          </div>
          <div className={styles.header_duration}>Last 1 Month</div>
        </div>
        <div className="d-flex gap-sm overflow-x">
          {headers.map((item) => (
            <HeaderTile
              key={item.title}
              title={item.title}
              detail={item.description}
              value={item.value}
              onClick={() => navigate(...getNavParams(item.attributes))}
            />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

const TitleCompMap = {
  total_annual_savings: GreenTile,
  total_time_savings: BlueTile,
  "Serverless Breakdown": PurpleTile,
  "Compute Breakdown": DarkBlueTile,
  "Storage Breakdown": YellowOrangeTile,
};

const BreakdownCompMap = {
  total_annual_savings: LightGreenTile,
  total_time_savings: LightBlueTile,
  "Serverless Breakdown": LightPurpleTile,
  "Compute Breakdown": LightDarkBlueTile,
  "Storage Breakdown": LightYellowOrangeTile,
};
const BreakdownModal = ({
  title,
  value,
  attributes,
  type = "total_annual_savings",
}) => {
  const navigate = useNavigate();
  const TitleComp = TitleCompMap[type];
  const BreakdownComp = BreakdownCompMap[type];
  return (
    <div className="overflow-y p-2 h-100">
      <div className="d-flex flex-column gap-sm">
        <TitleComp title={title} value={value} />
        {attributes.breakdown.map((item) => (
          <BreakdownComp
            key={item.title}
            title={item.title}
            value={item.value}
            onClick={() => navigate(...getNavParams(item))}
          />
        ))}
      </div>
    </div>
  );
};

const BreakdownModalWithSelection = ({ attributes }) => {
  const [selection, setSelection] = useState("");
  const navigate = useNavigate();

  const TitleComp = useMemo(() => {
    if (selection && selection.title && TitleCompMap[selection.title]) {
      const DynamicTitleComp = TitleCompMap[selection.title];
      return (
        <DynamicTitleComp title={selection.title} value={selection.value} />
      );
    } else {
      return null;
    }
  }, [selection]);

  return (
    <>
      {selection ? (
        <div className="overflow-y p-2 h-100">
          <div className="d-flex flex-column gap-sm">
            <div
              className={styles.back_button}
              onClick={() => {
                setSelection("");
              }}
            >
              <div className={styles.arrow}>
                <ArrowDownIcon />
              </div>
            </div>
            {TitleComp}
            {selection.attributes.breakdown.map((item) => {
              const BreakdownComp = BreakdownCompMap[selection.title];
              return (
                <BreakdownComp
                  key={item.title}
                  title={item.title}
                  value={item.value}
                  onClick={() => navigate(...getNavParams(item))}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="overflow-y p-2 h-100">
          <div className="d-flex flex-column gap-sm">
            {attributes?.selections.map((item) => {
              const TitleComp = TitleCompMap[item.title];
              return (
                <TitleComp
                  key={item.title}
                  title={item.title}
                  value={item.value}
                  onClick={() => setSelection(item)}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const Cost = ({ setSideModal }) => {
  const { data: header, isLoading: isHeaderLoading } = useQuery({
    queryKey: [COST_HEADER],
    queryFn: () =>
      getSummaryInsights({
        insight_type: "cost",
        summary_type: "header",
      }),
  });
  const { data: sidePanel, isLoading: isSidePanelLoading } = useQuery({
    queryKey: [COST_SIDE_PANEL],
    queryFn: () =>
      getSummarySidepanel({
        insight_type: "cost",
        summary_type: "side_panel",
      }),
    retry: false,
  });
  const { data: insight, isLoading: isInsightLoading } = useQuery({
    queryKey: [COST_INSIGHT],
    queryFn: () =>
      getSummaryInsights({
        insight_type: "cost",
        summary_type: "insight",
      }),
  });
  const [costPeriod, setCostPeriod] = useState(30);
  const [autoPeriod, setAutoPeriod] = useState(30);
  const [insightType, setInsightType] = useState("cost");

  const getPeriod = () => {
    switch (insightType) {
      case "storage":
        return 365;
      case "auto":
        return autoPeriod;
      default:
        return costPeriod;
    }
  };

  const setPeriod = (newPeriod) => {
    switch (insightType) {
      case "auto":
        setAutoPeriod(newPeriod);
        break;
      default:
        setCostPeriod(newPeriod);
    }
  };

  const { data: costGraph, isLoading: isCostLoading } = useQuery({
    queryKey: [COST_GRAPH, getPeriod(), insightType],
    queryFn: () =>
      getSummaryGraph({ period: getPeriod(), insight_type: insightType }),
  });

  const { data: costOverallGraph, isLoading: isCostOverallLoading } = useQuery({
    queryKey: [COST_OVERALL_GRAPH, getPeriod()],
    queryFn: () =>
      getSummaryGraph({ period: getPeriod(), insight_type: "cost_overall" }),
  });

  if (isSidePanelLoading) return <RelativeComponentLoader />;
  return (
    <div className="d-flex flex-column gap-md">
      {isHeaderLoading ? (
        <RelativeComponentLoader />
      ) : (
        <HeaderCard headers={header.summary_data} />
      )}
      <div className="d-flex gap-md">
        <div className="flex-grow">
          {isCostLoading ? (
            <RelativeComponentLoader />
          ) : (
            <ChartTile
              graph_data={costGraph.graph_data}
              topInfo={costGraph?.top_info}
              period={getPeriod()}
              setPeriod={setPeriod}
              insightType={insightType}
              setInsightType={setInsightType}
            />
          )}
        </div>
        <div className="d-flex flex-column gap-md">
          <GreenTile
            {...sidePanel.total_annual_savings}
            onClick={() =>
              setSideModal({
                type: "total_annual_savings",
                args: sidePanel.total_annual_savings,
              })
            }
          />
          <OrangeTile
            {...sidePanel.total_annual_spend}
            onClick={() =>
              setSideModal({
                type: "total_annual_spend",
                args: sidePanel.total_annual_spend,
              })
            }
          />
          <BlueTile
            {...sidePanel.total_time_savings}
            onClick={() =>
              setSideModal({
                type: "total_time_savings",
                args: sidePanel.total_time_savings,
              })
            }
          />
          <div className="flex-grow">
            {isCostOverallLoading ? (
              <RelativeComponentLoader />
            ) : (
              <BreakdownTile
                data={costOverallGraph.graph_data}
                showVertical={insightType === "auto"}
              />
            )}
          </div>
        </div>
      </div>
      {isInsightLoading ? (
        <RelativeComponentLoader />
      ) : (
        <InsightCard insights={insight.summary_data} />
      )}
    </div>
  );
};

export {
  Cost,
  HeaderTile,
  GreenTile,
  OrangeTile,
  BreakdownTile,
  InsightCard,
  BreakdownModal,
  BreakdownModalWithSelection,
  HeaderCard,
};
