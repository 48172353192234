import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as InfoIcon } from "@/assets/icons/info_circle_1.svg";

const TimeInfoDisclaimer = ({ textToDisplay, numOfSecondsActive }) => {
  const [showInfo, setShowInfo] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInfo(false);
    }, numOfSecondsActive * 1000); // milliseconds
    return () => {
      clearTimeout(timer);
    };
  }, [numOfSecondsActive]);

  return (
    <div
      className={classNames("d-flex align-items-center", styles.info)}
      onClick={() => setShowInfo((prevSetinfo) => !prevSetinfo)}
    >
      <InfoIcon />
      {showInfo && textToDisplay}
    </div>
  );
};

export default TimeInfoDisclaimer;
