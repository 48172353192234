import styles from "./styles.module.scss";
import { useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import {
  Summary,
  TableInsightsSummary,
  TableStorageSummary,
} from "../Incidents";
import { CopyIconButton } from "../../Components/CopyButton";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getIconByDatastoreType } from "./DatastoreImage";
import { DatasetIncidents } from "./Incidents";
import { Lineage } from "../../Components/Lineage";
import { Tab } from "../../Components/Tab";
import { TableInsights } from "./TableInsights";
import {
  altimateToAmundsen,
  downstreamTables,
  getTableById,
  getTableInsightsById,
  getTableInsightsStatus,
  getTableRk,
  postColumns,
  postConnectedColumns,
  upstreamTables,
} from "../../helpers/backend_helper";
import { useQuery } from "react-query";
import { ComponentLoader } from "../../Components/Loader";
import { isLineage } from "../../helpers/tenant";
import { getScopeString } from "../../helpers/utils";
import { TableAccessHistory } from "./TableAccessHistory";
import { Schema } from "./Schema";
import { BackButton } from "../../Components/Button";
import { InsightSection } from "../Query/components";
import { GET_OPPORTUNITY_RESOURCE_DATA } from "@/services/constants";
import { getOpportunitiesResource } from "@/helpers/apis";
import { Stack } from "@/uiCore";

const GET_TABLE_INSIGHTS_STATUS = "GET-TABLE-INSIGHTS-STATUS";
export const GET_TABLE_RK = "GET-TABLE-RK";
export const GET_TABLE_BY_ID = "GET-TABLE-BY-ID";
const GET_TABLE_INSIGHTS_BY_ID = "GET-TABLE_INSIGHTS-BY-ID";

function SideContent({ setShowSideContent, opportunities }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOpportunityId = searchParams.get("selectedOpportunityId");
  const setSelectedOpportunityId = (v) =>
    setSearchParams((prev) => ({ ...prev, selectedOpportunityId: v }));

  return (
    <Card className="mb-0">
      <CardBody>
        <Stack direction="column">
          <InsightSection
            opportunities={opportunities?.filter(
              (o) =>
                o.opportunity_id === searchParams.get("selectedOpportunityId")
            )}
            sideContentMode={true}
            selectedOpportunityId={selectedOpportunityId}
            setOpportunityId={(t) => {
              setSelectedOpportunityId(t);
              setShowSideContent(true);
            }}
          />
        </Stack>
      </CardBody>
    </Card>
  );
}

const MainContent = ({
  tableId,
  setShowSideContent,
  showSideContent,
  setSelectedTags,
  defaultTab,
  opportunities,
  table_rk,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOpportunityId = searchParams.get("selectedOpportunityId");
  const setSelectedOpportunityId = (v) =>
    setSearchParams((prev) => ({ ...prev, selectedOpportunityId: v }));

  const navigate = useNavigate();
  const { state } = useLocation();
  const lineage = isLineage();

  const {
    data: tableInsightsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [GET_TABLE_INSIGHTS_BY_ID, tableId],
    queryFn: () => {
      return getTableInsightsById(tableId);
    },
    onSuccess: (data) => {
      setSelectedTags(data?.tags || []);
    },
  });

  const { data: tableInsights, isLoading: isInsightsLoading } = useQuery({
    queryKey: [GET_TABLE_INSIGHTS_STATUS, tableId],
    queryFn: () => getTableInsightsStatus(tableId),
  });
  const { data: table, isLoading: isTableLoading } = useQuery({
    queryKey: [GET_TABLE_BY_ID, tableId],
    queryFn: () => getTableById(tableId),
  });

  const tabs = [
    {
      label: "Schema",
      component: <Schema table={table} />,
    },
    {
      label: "Lineage",
      component: (
        <Lineage
          tableId={tableId}
          getStartingNode={altimateToAmundsen}
          postColumns={postColumns}
          upstreamTables={upstreamTables}
          downstreamTables={downstreamTables}
          postConnectedColumns={postConnectedColumns}
        />
      ),
      disabled: !lineage,
      betaMode: true,
    },
    {
      label: "Incidents",
      component: <DatasetIncidents table={table} />,
    },
    {
      label: "Opportunities",
      component: (
        <div>
          <div className="d-flex m-2">
            <div className="fs-4">Opportunities</div>
          </div>
          <InsightSection
            opportunities={opportunities}
            selectedOpportunityId={selectedOpportunityId}
            setOpportunityId={(item) => {
              setSelectedOpportunityId(item);
              setShowSideContent(true);
            }}
          />
        </div>
      ),
      disabled: opportunities?.length === 0,
    },
    {
      label: "Access History",
      component: <TableAccessHistory tableId={tableId} />,
    },
  ];
  const [selectedLabel, setSelectedLabel] = useState(tabs[defaultTab].label);

  if (isInsightsLoading || isTableLoading) return <ComponentLoader />;
  if (!table) return <Navigate to="/datasets" />;

  const handleSelectedTabChange = (label) => {
    setSelectedLabel(label);
  };
  const scope = getScopeString(table.scope);
  const tableName = table.scope[table.scope.length - 1];

  const handleBackButtonClick = () => {
    if (state?.from === "datasets") {
      navigate(-1);
    } else {
      navigate("/datasets");
    }
  };

  return (
    <div className={styles.dataset_details}>
      <div className="d-flex align-items-start">
        <BackButton onClick={handleBackButtonClick} />
        <div className="d-flex flex-column">
          <div className="d-flex">
            {getIconByDatastoreType(table.datasource_type, "me-2 icon-md")}
            <div className="text-primary fs-5 text-overflow">{tableName}</div>
          </div>
          <div className="d-flex align-items-center mt-2 mb-2">
            <div className="me-2 text-overflow">{scope}</div>
            <CopyIconButton
              value={scope}
              color="rgba(8, 34, 71, 0.5)"
              isLight
            />
          </div>
        </div>
        <div className="spacer" />
        <Button
          size="sm"
          onClick={() =>
            navigate("/experimentation/new", {
              state: { datasets: table_rk, tableId, name: tableName },
            })
          }
        >
          Start experiments
        </Button>
      </div>
      {selectedLabel == "Incidents" && <Summary table={tableId} />}
      {(selectedLabel == "Opportunities" || selectedOpportunityId) && (
        <>
          <TableStorageSummary tableId={tableId} />
          <TableInsightsSummary tableId={tableId} />
        </>
      )}
      <Card className="flex-grow" style={{ height: "93%" }}>
        <CardBody className="h-100">
          <Tab
            defaultTab={defaultTab}
            tabs={tabs}
            onSelectedTabChange={handleSelectedTabChange}
            fullscreen={true}
          />
        </CardBody>
      </Card>
    </div>
  );
};

const DatasetsDetails = () => {
  const { tableId } = useParams();
  const [showSideContent, setShowSideContent] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchParams] = useSearchParams();
  const { data: tableRk, isLoading: isTableRkLoading } = useQuery({
    queryKey: [GET_TABLE_RK, tableId],
    queryFn: () => getTableRk(tableId),
  });

  const opportunities = useQuery({
    queryKey: [GET_OPPORTUNITY_RESOURCE_DATA, tableRk?.table_rk],
    queryFn: () =>
      getOpportunitiesResource({ resource_uri: tableRk?.table_rk }),
    enabled: Boolean(tableRk?.table_rk),
  });

  if (isTableRkLoading || opportunities.isLoading) return <ComponentLoader />;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: showSideContent ? "2fr 1fr" : "1fr",
        gap: "1rem",
      }}
    >
      <MainContent
        tableId={tableId}
        setShowSideContent={setShowSideContent}
        showSideContent={showSideContent}
        setSelectedTags={setSelectedTags}
        defaultTab={parseInt(searchParams.get("tab")) || 1}
        opportunities={opportunities?.data?.data}
        table_rk={tableRk.table_rk}
      />
      {showSideContent && (
        <SideContent
          setShowSideContent={setShowSideContent}
          opportunities={opportunities?.data?.data}
        />
      )}
    </div>
  );
};

export { DatasetsDetails };
