import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RelativeComponentLoader } from "@/Components/Loader";
import { getSummaryPageOpportunities } from "@/helpers/apis";
import { Alert, Badge, Card, Col, NumberFormatter, Row } from "@uicore";
import { useQuery } from "react-query";
import classes from "../summary.module.scss";
import { Link } from "react-router-dom";
import { useAppState } from "@/modules/app/useAppContext";
const OpportunitiesList = () => {
    const { currency } = useAppState();
    const { isFetching, data, error } = useQuery({
        queryKey: [`summary-opportunities`],
        queryFn: () => getSummaryPageOpportunities({ period: "month" }),
        onError(err) {
            // @ts-ignore window property
            posthog.capture("OpportunitiesList Failed", { error: err });
        },
    });
    if (isFetching) {
        return _jsx(RelativeComponentLoader, { label: "Loading opportunities..." });
    }
    if (error) {
        return _jsx(Alert, { color: "danger", children: "Failed to load opportunities data" });
    }
    return (_jsx(Row, { className: classes.opportunitiesList, children: data === null || data === void 0 ? void 0 : data.map((opportunity) => (_jsx(Col, { className: "col-sm-3", children: _jsxs(Card, { tag: Link, to: `/explorer?${opportunity.params}`, children: [_jsx(Badge, { tooltip: "", pill: true, tag: "div", children: _jsx(NumberFormatter, { options: { currency, maximumFractionDigits: 1 }, value: opportunity.value }) }), _jsx("div", { style: { flex: 1 }, className: "text-truncate-two-lines", children: opportunity.label })] }) }))) }));
};
export default OpportunitiesList;
