import { Col, Container, Row, Select } from "@uicore";
import styles from "../styles.module.scss";
import {
  ResourceTagColors,
  STATUS_OPTIONS,
} from "../../../pages/Explorer/constants";
import { useUsers } from "../../../helpers/hooks";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import ResourceTag from "../../../pages/Explorer/components/ResourceTag/ResourceTag";
import { useMutation, useQueryClient } from "react-query";
import { getUser } from "@/services/auth";
import { putOpportunities } from "@/helpers/backend_helper";
import { e } from "@/lib/altimate/main";
import StatusAssignerDropdown from "@/pages/Explorer/components/StatusAssignerDropdown/StatusAssignerDropdown";
import OwnerAssignerDropdown from "@/pages/Explorer/components/OwnerAssignerDropdown/OwnerAssignerDropdown";
import EffortTag from "@/pages/Explorer/components/EffortTag/EffortTag";
import { getEffortLabel, getOpportunityStatus } from "@/pages/Explorer/utils";
import { useNavigate } from "react-router-dom";
import { getOpportunitiesRedirection } from "@/helpers/apis";

const Resource = ({ resources }) => {
  const navigate = useNavigate();
  const { mutate } = useMutation(getOpportunitiesRedirection, {
    onSuccess: (data, variables) => {
      navigate(`${data.url}${variables?.resource_type === 2 ? "?tab=3" : ""}`, {
        state: {
          resource_uri: variables?.resource_uri,
          resource_type: variables?.resource_type,
          opportunity_id: variables?.opportunity_id,
        },
      });
    },
  });
  const [shownResources, setShownResources] = useState(resources.slice(0, 3));
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.resources}>
      {shownResources.map((resource, i) => (
        <ResourceTag
          resource={resource}
          index={i}
          key={i}
          onClick={() => mutate(resource)}
        />
      ))}
      {resources.length > 3 && (
        <div
          className={styles.more}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
            setShownResources(expanded ? resources.slice(0, 3) : resources);
          }}
        >
          {expanded ? "Less" : "More"}
        </div>
      )}
    </div>
  );
};

const OpportunitySummary = ({ opportunity }) => {
  const [assignedStatus, setAssignedStatus] = useState(
    getOpportunityStatus(opportunity?.status)
  );
  const [assignedOwner, setAssignedOwner] = useState(
    Number(opportunity?.owner)
  );

  return (
    <Container className={styles.opportunitySummary}>
      <Row>
        <Row>
          <Col>
            <h6>Status</h6>
            <StatusAssignerDropdown
              opportunity_id={opportunity?.opportunity_id}
              assignedStatus={assignedStatus}
              setAssignedStatus={setAssignedStatus}
            />
          </Col>
          <Col>
            <h6>Effort</h6>
            <EffortTag effort={getEffortLabel(opportunity.effort)} />
          </Col>
          <Col>
            <h6>First Detected</h6>
            <div>{dayjs(opportunity.first_seen).fromNow()} ago</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h6>Owner</h6>
            <OwnerAssignerDropdown
              opportunity_id={opportunity?.opportunity_id}
              assignedOwner={assignedOwner}
              setAssignedOwner={setAssignedOwner}
            />
          </Col>
          <Col>
            <h6>Time Savings</h6>
            <div>{opportunity.time_savings}</div>
          </Col>
          <Col>
            <h6>Last Detected</h6>
            <div>{dayjs(opportunity.last_seen).fromNow()} old</div>
          </Col>
        </Row>

        <Row>
          <Col></Col>
          <Col>
            <h6>Money Savings</h6>
            <div>{opportunity.money_savings}</div>
          </Col>
          <Col>
            <h6>Count</h6>
            <div>{opportunity.count}</div>
          </Col>
        </Row>
      </Row>
      <div className="divider" />
      <div className="d-flex justify-content-between w-100">
        <h6>Resources</h6>
        <Resource resources={opportunity?.resources} />
      </div>
    </Container>
  );
};

export { OpportunitySummary };
