import { useMemo, useState } from "react";
import { Paginate } from "../../Components/Paginate";
import { useIncidentFilter } from "../../services/filters";
import { FilterWidget, IncidentList, IncidentModal } from "../Incidents";
import { NoIncidents } from "./NoIncidents";
import { ComponentLoader } from "../../Components/Loader";

const DatasetIncidents = ({ table }) => {
  const [incidentId, setIncidentId] = useState(undefined);

  let {
    incidents,
    sort,
    timeFilter,
    levelFilter,
    paginate,
    isLoading,
    isFilter,
    contractFilter,
    contractOptions,
  } = useIncidentFilter({ tableId: table.id });

  const [recentIncidentIds, setRecentIncidentIds] = useState({});
  const isIncident = typeof incidentId !== "undefined";
  useMemo(() => {
    setRecentIncidentIds((m) =>
      isIncident ? { ...m, [incidentId]: true } : {}
    );
  }, [incidentId, isIncident]);

  if (isLoading)
    return <ComponentLoader top={60} left={60} label="Loading incidents..." />;

  if (table.total_violations === 0)
    return (
      <NoIncidents
        num_contracts={table.num_contracts}
        timestamp={table.last_violation_timestamp}
        contractName={table.last_violation_contract}
      />
    );

  return (
    <>
      <FilterWidget
        isFilter={isFilter}
        levelFilter={levelFilter}
        contractFilter={contractFilter}
        timeFilter={timeFilter}
        contractOptions={contractOptions}
      />
      <IncidentList
        incidents={incidents}
        sort={sort}
        timeFilter={timeFilter}
        levelFilter={levelFilter}
        contractFilter={{
          options: contractOptions,
          value: contractFilter.getValue,
          onChange: contractFilter.toggleValue,
        }}
        onIncidentClick={setIncidentId}
      />
      <Paginate {...paginate} />
      {isIncident && (
        <IncidentModal
          isOpen={isIncident}
          incidentId={incidentId}
          recentIncidentIds={recentIncidentIds}
          toggle={() => setIncidentId(undefined)}
          onIncidentClick={setIncidentId}
        />
      )}
    </>
  );
};

export { DatasetIncidents };
