import styles from "./styles.module.scss";
import { useState } from "react";
import classnames from "classnames";
import { ReactComponent as LikeIcon } from "@/assets/icons/like.svg";
import { ReactComponent as DislikeIcon } from "@/assets/icons/dislike.svg";
import { ReactComponent as LikeSelectedIcon } from "@/assets/icons/like_selected.svg";
import { ReactComponent as DislikeSelectedIcon } from "@/assets/icons/dislike_selected.svg";
import { Button, Input } from "reactstrap";
import { useMutation, useQuery } from "react-query";
import { getAIFeedback, postAIFeedback } from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../Loader";
import classNames from "classnames";

const GET_AI_FEEDBACK = "GET-AI-FEEDBACK";

const ChatFeedbackSubmit = ({ onSubmit }) => {
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const handleSubmit = () => {
    onSubmit();
    setIsInputDisabled(true);
  };

  return (
    <div className="d-flex flex-column gap-md align-items-left">
      <div className={styles.feedback_title}>
        Many thanks for your feedback!
      </div>
      <div className="d-flex gap-sm align-items-center">
        <Button
          className={
            classNames(
              styles.feedback_submit_comp,
              "mb-2"
            )}
          onClick={handleSubmit}
          disabled={isInputDisabled}
        >
          Start new Agent
        </Button>
      </div>
    </div>
  );
};

const FeedbackForm = ({ formInputs, setFormInputs, isInputDisabled }) => {
  const SAVED_TIME = [
    { label: "5 minutes", value: 5 },
    { label: "15 minutes", value: 15 },
    { label: "30 minutes", value: 30 },
    { label: "1 hour", value: 60 },
  ];
  return formInputs.map((item) => (
    <>
      {
        item.type === "textTime" &&
        <>
          <div className={classnames(styles.feedback_title, "mt-2")}>
            {item.label}
          </div>
          <div className="d-flex gap-sm align-items-center mb-4">
            {SAVED_TIME.map((time) => {
              return (
                <Button
                  disabled={isInputDisabled}
                  outline
                  className={classnames(styles.saved_time, {
                    [styles.selected]: time.value === item.value,
                    [styles.unselected]: time.value !== item.value,
                  })}
                  key={time.value}
                  onClick={() => {
                    setFormInputs((_inputs) =>
                      _inputs.map((i) => {
                        if (i.label === item.label) i.value = time.value === i.value ? null : time.value
                        return i;
                      })
                    );
                  }}
                >
                  {time.label}
                </Button>
              );
            })}
            <span className={styles.saved_time_text}>Or</span>
            <Input
              key={item.label}
              disabled={isInputDisabled}
              type="textarea"
              className={styles.feedback_input_comp}
              onChange={(e) =>
                setFormInputs((_inputs) =>
                  _inputs.map((i) => {
                    if (i.label === item.label) i.value = e.target.value;
                    return i;
                  })
                )
              }
              value={item.value}
            />
            <span className={styles.saved_time_text}>minutes</span>
          </div>
        </>
      }
      {
        item.type === "text" &&
        <Input
          key={item.label}
          disabled={isInputDisabled}
          type="textarea"
          className={styles.feedback_input_comp}
          placeholder={item.label}
          onChange={(e) =>
            setFormInputs((_inputs) =>
              _inputs.map((i) => {
                if (i.label === item.label) i.value = e.target.value;
                return i;
              })
            )
          }
          value={item.value}
        />
      }
    </>
  ))
};

const Feedback = ({ feedback_id, feedback_type, resetWorkflow }) => {
  // state === 0 means like button active
  // state === 1 means dislike button active
  const [state, setState] = useState(-1);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [goodFormInputs, setGoodFormInputs] = useState([]);
  const [badFormInputs, setBadFormInputs] = useState([]);
  const like = state === 0;
  const dislike = state === 1;
  // Feedback for datapilot won't have an option of updatng once submitted.
  const isFeedbackDataPilot = feedback_type === "datapilot";

  const { isLoading } = useQuery({
    queryKey: [GET_AI_FEEDBACK],
    queryFn: () => getAIFeedback({ feedback_id, feedback_type }),
    onSuccess: (data) => {
      if (data.is_submitted) {
        setIsInputDisabled(true);
        setState(data.feedback_value === "good" ? 0 : 1);
      }
      setGoodFormInputs(data.full_form.good_form);
      setBadFormInputs(data.full_form.bad_form);
    },
  });
  const { mutate } = useMutation(
    postAIFeedback({ feedback_id, feedback_type })
  );

  const handleSubmit = () => {
    mutate({
      feedback_value: like ? "good" : "bad",
      full_form: {
        good_form: goodFormInputs,
        bad_form: badFormInputs,
      },
    });
    setIsInputDisabled(true);
  };

  const resetFormUpdater = (_input) => {
    const newInputs = [..._input];
    newInputs.forEach((item) => (item.value = ""));
    return newInputs;
  };

  const handleCancel = () => {
    if (isInputDisabled && isFeedbackDataPilot) return;
    setState(-1);
    setGoodFormInputs(resetFormUpdater);
    setBadFormInputs(resetFormUpdater);
  };

  if (isLoading) return <RelativeComponentLoader />;

  return (
    <div
      className={classnames(
        "d-flex flex-column gap-md align-items-left p-2",
        styles.feedback_card
      )}
    >
      <div className="d-flex align-items-center gap-md">
        <div className={styles.feedback_title}>Feedback</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (isInputDisabled && isFeedbackDataPilot) return;
            setGoodFormInputs(resetFormUpdater);
            setBadFormInputs(resetFormUpdater);
            setState(0);
            setIsInputDisabled(false);
          }}
        >
          {like ? <LikeSelectedIcon /> : <LikeIcon />}
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (isInputDisabled && isFeedbackDataPilot) return;
            setGoodFormInputs(resetFormUpdater);
            setBadFormInputs(resetFormUpdater);
            setState(1);
            setIsInputDisabled(false);
          }}
        >
          {dislike ? <DislikeSelectedIcon /> : <DislikeIcon />}
        </div>
      </div>
      {like && (
        <FeedbackForm
          formInputs={goodFormInputs}
          setFormInputs={setGoodFormInputs}
          isInputDisabled={isInputDisabled}
        />
      )}
      {dislike && (
        <FeedbackForm
          formInputs={badFormInputs}
          setFormInputs={setBadFormInputs}
          isInputDisabled={isInputDisabled}
        />
      )}
      {state !== -1 && (
        <div className="d-flex gap-sm align-items-center">
          <Button
            className={styles.feedback_submit_comp}
            onClick={handleSubmit}
            disabled={isInputDisabled}
          >
            Submit
          </Button>
          <Button
            disabled={isInputDisabled}
            outline
            className={styles.feedback_cancel}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
      {isInputDisabled && isFeedbackDataPilot && (
        <ChatFeedbackSubmit onSubmit={resetWorkflow} />
      )}
    </div>
  );
};

export { Feedback };
