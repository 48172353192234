import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Card, CardTitle, NumberFormatter, IconButton } from "@uicore";
import classes from "../summary.module.scss";
import classNames from "classnames";
import { ClockResponsiveIcon, DollarIcon, InfoIcon, LightBulbIcon, } from "@/assets/icons";
import { useAppState } from "@/modules/app/useAppContext";
const AssistedSavingsSection = ({ opportunities, time_saved, total_assisted_savings, }) => {
    const { currency } = useAppState();
    return (_jsxs(Stack, { direction: "column", className: "h-100", children: [_jsx("h5", { children: "Savings" }), _jsxs(Stack, { className: "flex-1", children: [_jsxs(Card, { className: classNames(classes.assistedSavingsCard, classes.green), children: [_jsxs(CardTitle, { children: [_jsx("i", { children: _jsx(DollarIcon, {}) }), _jsx("span", { children: "Money Savings" }), " ", _jsx(IconButton, { title: "Money saved during the period when users implemented the opportunities identified by the product.", children: _jsx(InfoIcon, {}) })] }), _jsx("p", { children: _jsx(NumberFormatter, { value: total_assisted_savings, options: { currency } }) })] }), _jsxs(Card, { className: classNames(classes.assistedSavingsCard, classes.blue), children: [_jsxs(CardTitle, { children: [_jsx("i", { children: _jsx(ClockResponsiveIcon, {}) }), _jsx("span", { children: "Time Savings" }), _jsx(IconButton, { title: "Time saved during the period when users implemented the opportunities identified by the product.", children: _jsx(InfoIcon, {}) })] }), _jsx("p", { children: time_saved })] }), _jsxs(Card, { className: classNames(classes.assistedSavingsCard, classes.warn), children: [_jsxs(CardTitle, { children: [_jsx("i", { children: _jsx(LightBulbIcon, {}) }), _jsx("span", { children: "Opportunities" }), _jsx(IconButton, { title: "No. of money or time savings opportunities implemented during the period", children: _jsx(InfoIcon, {}) })] }), _jsx("p", { children: opportunities })] })] })] }));
};
export default AssistedSavingsSection;
