import { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useQuery } from "react-query";
import { getExecutionHistory } from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";
import { GET_EXECUTION_HISTORY } from "./queryKey";
import { PeriodSelect } from "./GraphUtils";
import { ReactComponent as AlertIcon } from "../../assets/icons/freemium_alert.svg";
import { Tooltip } from "react-tooltip";

const TableHeader = ({ header }) => (
  <div className={styles.table_header}>
    {header.map((h) => (
      <div
        className={classNames("w-100 d-flex align-items-center", {
          [styles.first_col]: h.id === 1,
        })}
        key={h.id}
      >
        <span>{h.label}</span>
        <div className="spacer" />
      </div>
    ))}
  </div>
);

const periodsMap = {
  current: { dropdown: "Current Cycle" },
  all_time: { dropdown: "All Time" },
};

const ExecutionHistory = ({ currentPlanStart }) => {
  const [displayExecutions, setDisplayExecutions] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState("current");
  const { data: executionHistory, isLoading } = useQuery({
    queryKey: [GET_EXECUTION_HISTORY, currentPeriod],
    queryFn: () => getExecutionHistory(currentPeriod === "all_time"),
    onSuccess: (data) => {
      if (data.execution.length > 0) setDisplayExecutions(true);
      else setDisplayExecutions(false);
    },
  });

  if (isLoading) return <RelativeComponentLoader />;

  if (!executionHistory && !executionHistory.execution) return null;

  return (
    <div className={styles.history}>
      <div className={styles.header}>
        <div className={styles.title}>Credits Usage History</div>
        <div className={styles.dropdown_select}>
          <div className={styles.date_info}>
            {executionHistory.start_date} - Today
          </div>
          <PeriodSelect
            id="executions-history-period-select"
            currentPeriod={currentPeriod}
            setCurrentPeriod={setCurrentPeriod}
            periodsMap={periodsMap}
          />
        </div>
      </div>
      <div className={styles.execution_table}>
        <TableHeader
          header={[
            {
              id: 1,
              label: "Preview Features",
            },
            {
              id: 2,
              label: "Credits",
            },
          ]}
        />
        <div className={styles.table_body}>
          {executionHistory.execution.map((feature, idx) => (
            <div key={idx} className={styles.table_row}>
              <div className="condense-text cursor-pointer">{feature.name}</div>
              <div className="d-flex gap-md align-items-left">
                <div className="condense-text cursor-pointer">
                  {feature.value_charged}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExecutionHistory;
