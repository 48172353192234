import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Chart, ChartTypes, formatNumber, yAxisLabelProps } from "@/uiCore";
import BreakdownSkeleton from "./BreakdownSkeleton";
import dayjs from "dayjs";
import { CustomTooltip } from "@/Components/Graph/misc";
import { getKey, getTooltipNameValues } from "./utils";
import { Line, YAxis } from "recharts";
import { TotalCostEntities, BarColors } from "./constants";
import { useAppState } from "@/modules/app/useAppContext";
const AutonomousSavingsSection = ({ isFetching, yearMode, data, savingsType, aggregateBy, isFuture, }) => {
    const { currency } = useAppState();
    if (isFetching) {
        return _jsx(BreakdownSkeleton, { type: "chart" });
    }
    if (!data) {
        return null;
    }
    return (_jsx(Chart, { title: "Autonomous Savings", xAxisDataKey: "date", yAxisLabel: {
            value: "Savings",
        }, yAxisId: "value", xAxisLabelFormatter: (value) => `${dayjs(value).format(`${yearMode ? "MMM-YY" : "DD-MMM-YY"}`)}`, yAxisLabelFormatter: (value) => `${formatNumber(value, { currency })}`, type: ChartTypes.LineChart, data: data.graph, tooltipProps: {
            content: (
            // @ts-ignore TODO fix this
            _jsx(CustomTooltip, { timeRange: aggregateBy, nameValue: getTooltipNameValues(savingsType, currency) })),
        }, children: ({ selectedLegend }) => (_jsxs(_Fragment, { children: [_jsx(YAxis, { yAxisId: "number_of_decisions", orientation: "right", label: Object.assign(Object.assign({ dx: -30 }, yAxisLabelProps), { value: "AI Decisions" }) }), TotalCostEntities[savingsType].fields.map((d) => {
                    const k = getKey(d, isFuture);
                    return (_jsx(Line, { yAxisId: k, type: "monotone", name: d.label, dataKey: k, hide: Boolean(selectedLegend && selectedLegend !== k), stroke: BarColors[k], strokeWidth: 5, dot: false }));
                })] })) }));
};
export default AutonomousSavingsSection;
