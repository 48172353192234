import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import quickstartDatapilot from "../../assets/images/quickstart_datapilot.svg";
import quickstartDBT from "../../assets/images/quickstart_dbt.svg";
import arrow_down from "../../assets/icons/arrow_down.svg";
import extension from "../../assets/icons/extension.svg";
import quickstartMan from "../../assets/images/quickstart-man.png";
import { InfoTextBlock, SelectButton } from "../../Components/CopyButton";
import { getUser, saveUser } from "../../services/auth";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as InviteUser } from "../../assets/icons/invite_user_big.svg";
import { Button } from "reactstrap";

const quickstartButtons = {
  prodUI: "prodUI",
  dbt: "dbt",
};

const Dropdown = ({ title, subtitle = "", tutorial, borderColor }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleDropdownClick = () => {
    setIsExpanded((p) => !p);
  };

  return (
    <div
      className={classNames(styles.parent_dropdown, {
        [styles.open]: isExpanded,
      })}
      onClick={handleDropdownClick}
      style={{ "--borderColor": borderColor }}
    >
      <div className={styles.dropdown}>
        <div className={styles.texts}>
          <div className={styles.title}>{title}</div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
        <img
          src={arrow_down}
          className={classNames(styles.image, { [styles.rotate]: isExpanded })}
          alt="arrow"
        />
      </div>
      <div
        className={classNames(styles.tutorial, {
          [styles.open]: isExpanded,
        })}
        dangerouslySetInnerHTML={{ __html: tutorial }}
      />
    </div>
  );
};

const InviteBox = () => {
  const navigate = useNavigate();

  const handleInvite = () => {
    navigate("/settings/users", { state: { invite: true } });
  };

  return (
    <div className={styles.invite_box}>
      <div className="d-flex gap-sm align-items-center">
        <InviteUser />
        <div className="d-flex flex-column align-items-left gap-xs">
          <div className={styles.title}>Invite User</div>
          <div className={styles.text}>
            Invite your team members to collaborate and accelerate the work
            together
          </div>
        </div>
      </div>
      <Button
        color="light"
        className={styles.invite_button}
        onClick={handleInvite}
      >
        <div className={styles.invite_text}>Invite</div>
      </Button>
    </div>
  );
};

const Quickstart = () => {
  const navigate = useNavigate();

  const user = getUser();

  const [notShowAfterLogin, setNotShowAfterLogin] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);

  const onNext = () => {
    if (notShowAfterLogin) {
      saveUser({ ...user, is_onboarded: notShowAfterLogin });
      navigate("/incidents");
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="quickstart-head-container">
              <h2 className="header-text">
                TAKE A STEP,
                <br />
                IT'S <span className="text-purple">QUICK</span> &amp;{" "}
                <span className="text-purple">EASY!</span>
              </h2>
              <img src={quickstartMan} alt="" className="quickstart-man-img" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Card className="card-step1 quick-start-card">
              <CardBody>
                <div className="d-flex mb-3">
                  <span className="card-header-text">Quickstart</span>
                </div>
                <InfoTextBlock fontColor="#237efe" bgColor="#eaf3ff">
                  DataPilot is an AI teammate for data teams to accelerate dbt
                  development, reduce data warehouse costs, and improve data
                  governance.
                </InfoTextBlock>
                <InfoTextBlock fontColor="#ff754c" bgColor="#fff1ed">
                  DataPilot can be used in the Power User for dbt Core as well
                  as in the product UI.
                </InfoTextBlock>
                <div className="card-content-text d-flex align-items-left gap-sm flex-column">
                  <span>
                    Please choose an interface to view quick-start instructions
                  </span>
                </div>
                <div className="card-select d-flex align-items-left gap-sm">
                  <div className="d-flex align-items-left gap-sm">
                    <SelectButton
                      text="Power User for dbt Core"
                      color="#ff754c"
                      selected={selectedButton === quickstartButtons.dbt}
                      onClick={() => setSelectedButton(quickstartButtons.dbt)}
                    />
                    <SelectButton
                      text="Product UI"
                      color="#237efe"
                      selected={selectedButton === quickstartButtons.prodUI}
                      onClick={() =>
                        setSelectedButton(quickstartButtons.prodUI)
                      }
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {selectedButton === quickstartButtons.prodUI && (
          <Row>
            <Col lg={12}>
              <Card className="card-step1 quick-start-card">
                <CardBody>
                  <div className="d-flex mb-4 step-wrapper">
                    <img
                      src={quickstartDatapilot}
                      className="step-image"
                      alt="step1"
                    />
                  </div>

                  <Dropdown
                    title={"dbt documentation generation"}
                    subtitle={
                      "Generate descriptions for models and columns that include various details including purpose"
                    }
                    tutorial={`<iframe src="https://app.supademo.com/embed/Y1YX-VVWWs5YEd_X3euoM" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="width: 100%; height: 84vh"></iframe>`}
                    borderColor="#247efe"
                  />
                  <div className="mb-3"></div>

                  <Dropdown
                    title={"Query explanation"}
                    subtitle={
                      "Generate summary and explanation for the query to understand it better."
                    }
                    tutorial={`<iframe src="https://app.supademo.com/embed/zPaOgW-BqKtFxTxKrKbTS" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style=" width: 100%; height: 84vh;"></iframe>`}
                    borderColor="#247efe"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {selectedButton === quickstartButtons.dbt && (
          <Row>
            <Col lg={12}>
              <Card className="card-step2 quick-start-card">
                <CardBody>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex mb-4 step-wrapper">
                      <img
                        src={quickstartDBT}
                        className="step-image"
                        alt="step2"
                      />
                    </div>
                    <Link
                      className={styles.install_button}
                      to={
                        "https://marketplace.visualstudio.com/items?itemName=innoverio.vscode-dbt-power-user"
                      }
                    >
                      <img src={extension} alt="extension" />
                      <div className={styles.install_text}>
                        Install the extension
                      </div>
                    </Link>
                  </div>

                  <Dropdown
                    title={
                      "Install and setup Power User for dbt Core with the key"
                    }
                    tutorial={
                      '<iframe src="https://app.supademo.com/embed/clnjpwl3u07x4pedv9ifjfuf9" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="width: 100%; height: 84vh;"></iframe>'
                    }
                    borderColor="#fda58b"
                  />
                  <div className="mb-3"></div>

                  <Dropdown
                    title={"Column lineage"}
                    tutorial={
                      '<iframe src="https://app.supademo.com/embed/clncl1wuj44z8pezp7iw9vnj5" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="width: 100%; height: 84vh;"></iframe>'
                    }
                    borderColor="#fda58b"
                  />
                  <div className="mb-3"></div>

                  <Dropdown
                    title={"dbt query results preview, analysis and export"}
                    subtitle={
                      "Instantly test your dbt query, analyze the results or export them"
                    }
                    tutorial={
                      '<iframe src="https://app.supademo.com/embed/S4uCGtgs_jisbJs5SfJzZ" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="width: 100%; height: 84vh;"></iframe>'
                    }
                    borderColor="#fda58b"
                  />
                  <div className="mb-3"></div>

                  <Dropdown
                    title={"Auto-complete and quick navigation"}
                    subtitle={
                      "Smart auto-complete for models, macros, columns in VS Code Execute child / parent models and tests with a click"
                    }
                    tutorial={
                      '<iframe src="https://app.supademo.com/embed/clncaqx253xyypezplang48gr" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="width: 100%; height: 84vh;"></iframe>'
                    }
                    borderColor="#fda58b"
                  />
                  <div className="mb-3"></div>

                  <Dropdown
                    title={"dbt documentation generation and easy edition"}
                    subtitle={
                      "Smart auto-complete for models, macros, columns in VS Code. Execute child / parent models and tests with a click"
                    }
                    tutorial={`<iframe src="https://app.supademo.com/embed/clncbea4j3yl1pezp0zp0pfys" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="width: 100%; height: 84vh;"></iframe>`}
                    borderColor="#fda58b"
                  />
                  <div className="mb-3"></div>

                  <Dropdown
                    title={"Project health check"}
                    subtitle={
                      "Identify issues with your dbt project like missing models / columns in database, missing documentation etc."
                    }
                    tutorial={`<iframe src="https://app.supademo.com/embed/clof2pnhl262fpegd7straszw" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="width: 100%; height: 84vh;"></iframe>`}
                    borderColor="#fda58b"
                  />
                  <div className="mb-3"></div>

                  <Dropdown
                    title={"Query explanation"}
                    subtitle={
                      "Get summary and explanation of complex dbt queries"
                    }
                    tutorial={`<iframe src="https://app.supademo.com/embed/clog0tipl3n35pegdy28pxe06" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="width: 100%; height: 84vh;"></iframe>`}
                    borderColor="#fda58b"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      <InviteBox />
    </>
  );
};

export default Quickstart;
