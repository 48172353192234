import { Button, Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import { Paginate } from "../../Components/Paginate";
import { ColorInsightTags } from "../../Components/Tags";
import { CollapsibleField } from "../../Components/Text";
import { ReactComponent as DBTIcon } from "../../assets/icons/dbt.svg";
import classNames from "classnames";

const TableList = ({
  tables = [],
  onItemClick,
  paginate,
  onDetailsClick,
  onViewSchema,
  errorForRk = "",
  modelError = "",
}) => (
  <div className={styles.table_list}>
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs-5 text-muted">{paginate.itemCount} results</div>
        </div>
        {tables.map((table) => (
          <div
            key={table.rk}
            className={styles.card}
            onClick={(e) => {
              e.stopPropagation();
              onItemClick(table);
            }}
          >
            <div className="d-flex mb-2">
              <div className="me-2 icon-md">
                <DBTIcon />
              </div>
              <div className="text-primary fs-5 text-overflow">
                {table.model_name}
              </div>
            </div>
            <div className="d-flex align-items-center mt-2 mb-2">
              <div className="me-2 text-overflow">
                {table.database}.{table.model_schema}/{table.alias}
              </div>
            </div>
            <div className={classNames(styles.grid_3, "mb-2")}>
              <div className="d-flex">
                <div className="me-2">Project:</div>
                <div className="text-muted">{table.project_name}</div>
              </div>
              <div className="d-flex">
                <div className="me-2">Resource Type:</div>
                <div className="text-muted">{table.resource_type}</div>
              </div>
              <div className="d-flex">
                <div className="me-2">Materialization:</div>
                <div className="text-muted">{table.materialization}</div>
              </div>
            </div>
            <div className={classNames(styles.grid_3, "mb-2")}>
              <div className="d-flex">
                <div className="me-2">Database:</div>
                <div className="text-muted">{table.database}</div>
              </div>
              <div className="d-flex">
                <div className="me-2">Schema:</div>
                <div className="text-muted">{table.model_schema}</div>
              </div>
              <div className="d-flex">
                <div className="me-2">Total Columns:</div>
                <div className="text-muted">{table.columns.length}</div>
              </div>
            </div>
            {table.description && (
              <CollapsibleField
                label="Description"
                text={table.description}
                defaultLines={3}
              />
            )}
            {/* {table.tags.length > 0 && (
              <div className="mt-2 d-flex gap-sm">
                <div className="me-2">Tags:</div>
                <div className="d-flex flex-wrap gap-sm">
                  <ColorInsightTags tags={table.tags} />
                </div>
              </div>
            )} */}
            <div className="mt-2 d-flex justify-content-between align-items-center">
              {errorForRk && modelError && errorForRk === table?.rk ? (
                <div className="text-danger">{modelError}</div>
              ) : (
                <div />
              )}
              <div className="d-flex gap-sm align-items-center">
                <Button
                  outline
                  color="primary"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewSchema(table);
                  }}
                >
                  View Schema
                </Button>
                {onDetailsClick && (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDetailsClick(table);
                    }}
                  >
                    View Details
                  </Button>
                )}
              </div>
            </div>
          </div>
        ))}
        <Paginate {...paginate} />
      </CardBody>
    </Card>
  </div>
);

export { TableList };
