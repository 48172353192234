import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import styles from "./styles.module.scss";
import { MultiSelect, PhaseSelect, SingleSelect } from "./Select";
import { ScrollContainer } from "../ScrollContainer";
import { BetterPopover } from "../Popover";
import { uniqueId } from "lodash";
import { Button, Popover } from "reactstrap";
import classNames from "classnames";
import { DebouncedInput } from "./Input";

const Dropdown = ({
  children,
  options,
  onChange,
  id,
  value,
  isMultiSelect = false,
  popoverProps = {},
  label,
  showDivider,
  wrapperClass,
  optionStyles = {},
}) => {
  return (
    <>
      <div id={id} className={classNames("cursor-pointer", wrapperClass)}>
        {children}
      </div>
      <BetterPopover target={id} hideArrow placement="bottom" {...popoverProps}>
        {({ close }) => (
          <div className={styles.dropdown_container}>
            {label && (
              <>
                <div className="fs-5 fw-semibold m-2">{label}</div>
                <div className={styles.divider} />
              </>
            )}
            <ScrollContainer>
              {!isMultiSelect ? (
                <SingleSelect
                  options={options}
                  optionStyles={optionStyles}
                  onChange={(v) => {
                    onChange(v);
                    close();
                  }}
                  value={value}
                  showDivider={showDivider}
                />
              ) : (
                <MultiSelect
                  options={options}
                  onChange={onChange}
                  value={value}
                  showDivider={showDivider}
                />
              )}
            </ScrollContainer>
          </div>
        )}
      </BetterPopover>
    </>
  );
};

const AsyncMultiSelectDropdown = ({
  children,
  options,
  onChange,
  value,
  popoverProps = {},
  onSearch,
  onApply,
  disabled = false,
}) => {
  const id = useMemo(() => uniqueId("async-multi-select-dropdown-"), []);
  const isSearch = typeof onSearch === "function";
  return (
    <>
      <div id={id} className="w-100 cursor-pointer">
        {children}
      </div>
      <BetterPopover target={id} hideArrow placement="bottom" {...popoverProps}>
        {({ close }) => (
          <div
            className={classNames(
              styles.dropdown_container,
              styles.async_container
            )}
          >
            {isSearch && (
              <DebouncedInput
                size="sm"
                placeholder="Search"
                onChange={onSearch}
              />
            )}
            <ScrollContainer>
              <MultiSelect
                options={options}
                onChange={onChange}
                value={value}
                showDivider={false}
              />
            </ScrollContainer>
            <div className={styles.divider} />
            <div className="m-2 d-flex justify-content-end gap-sm">
              <Button
                className={styles.cancel_button}
                onClick={(e) => {
                  e.stopPropagation();
                  close();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  onApply();
                  close();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </BetterPopover>
    </>
  );
};

const ChildPhase = ({ hoveredOption, setHoveredOption, placement }) => {
  useEffect(() => {
    return () => {
      setHoveredOption(null);
    };
  }, []);

  return (
    <>
      <div
        id={hoveredOption.value}
        className={classNames("cursor-pointer")}
      ></div>
      <Popover
        target={hoveredOption.value}
        isOpen={true}
        toggle={() => {}}
        hideArrow
        placement={placement || "left"}
      >
        <div className={styles.dropdown_container}>
          {hoveredOption.subComponent}
        </div>
      </Popover>
    </>
  );
};

const MultiPhaseDropdown = ({
  children,
  options,
  id,
  popoverProps = {},
  label,
  showDivider,
  wrapperClass,
  optionStyles = {},
  placement,
}) => {
  const [hoveredOption, setHoveredOption] = useState(null);
  const handleOptionHover = (e) => {
    const option = options.find((x) => x.value === e.target.id);
    setHoveredOption(option);
  };

  const elem = document.getElementById(hoveredOption?.value);
  useEffect(() => {
    if (hoveredOption && !elem) {
      setHoveredOption(null);
    }
  }, [elem, hoveredOption]);

  return (
    <>
      <div id={id} className={classNames("cursor-pointer", wrapperClass)}>
        {children}
      </div>

      <BetterPopover target={id} hideArrow placement="bottom" {...popoverProps}>
        {({ close }) => (
          <div className={styles.dropdown_container}>
            {label && (
              <>
                <div className="fs-5 fw-semibold m-2">{label}</div>
                <div className={styles.divider} />
              </>
            )}
            <ScrollContainer>
              <PhaseSelect
                options={options}
                optionStyles={optionStyles}
                showDivider={showDivider}
                handleOptionHover={handleOptionHover}
              />
            </ScrollContainer>
            {hoveredOption && hoveredOption.subComponent && (
              <ChildPhase
                hoveredOption={hoveredOption}
                setHoveredOption={setHoveredOption}
                placement={placement}
              />
            )}
          </div>
        )}
      </BetterPopover>
    </>
  );
};

export { Dropdown, AsyncMultiSelectDropdown, MultiPhaseDropdown };
