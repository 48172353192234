import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { EffortTagColors } from "../../constants";
import { capitalize } from "lodash";
const EffortTag = ({ effort }) => {
    return (_jsx("div", { className: styles.effortTag, style: {
            backgroundColor: EffortTagColors[effort],
        }, children: capitalize(effort) }));
};
export default EffortTag;
