import styles from "./styles.module.scss";
import { Filter } from "./Filter";
import { TableList } from "./TableList";
import { useCallback, useContext, useEffect, useState } from "react";
import { ColumnsModal } from "./ColumnsModal";
import { NoDatasets } from "./NoDatasets";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchContext } from "../Home";
import { useDatasetFilter } from "../../services/filters";
import { ComponentLoader } from "../../Components/Loader";
import SidebarModal from "../../Components/SidebarModal";
import { FilterTagWrapper } from "../../Components/Tags";
import classNames from "classnames";
import { isEqual } from "lodash";

const Datasets = () => {
  const { search } = useContext(SearchContext);
  const [prepFilterTags, setPrepFilterTags] = useState({});
  const { state } = useLocation();

  const navigate = useNavigate();
  const onDetailsClick = (table) =>
    navigate(`/datasets/${table.id}`, { state: { from: "datasets" } });
  const [schemaTable, setSchemaTable] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const { isDataset, filter, tables, paginate, isLoading, isTableLoading } =
    useDatasetFilter({ search, defaultFilters: state?.filters || {} });
  useEffect(() => {
    if (!tables || tables.length === 0) return;
    if (!schemaTable) return;
    const t = tables.find((t) => t.id === schemaTable.id);
    if (t) setSchemaTable(t);
  }, [tables]);

  const filterComp = useCallback(
    (filters, onChange, labelName, prepFilterTags) => {
      let counter = 0;
      let preparedFilters = { ...prepFilterTags };
      if (filters)
        filters.forEach((d) => {
          if (d.checked) {
            preparedFilters[labelName + counter] = {
              label: labelName,
              filterStr: d.label,
              onclose: () => {
                onChange(d.value);
                setPrepFilterTags((prevTags) => {
                  const newTags = Object.keys(prevTags).reduce((obj, key) => {
                    if (prevTags[key].filterStr !== d.label) {
                      obj[key] = prevTags[key];
                    }
                    return obj;
                  }, {});
                  return newTags;
                });
              },
              bgColor: "white",
            };
            counter++;
          }
        });
      return preparedFilters;
    },
    []
  );

  useEffect(() => {
    let newPrepFilterTags = {
      ...filterComp(
        filter.datasources,
        filter.onDatasourceChange,
        "Data Store",
        prepFilterTags
      ),
      ...filterComp(
        filter.databases,
        filter.onDatabaseChange,
        "Database",
        prepFilterTags
      ),
      ...filterComp(
        filter.schemas,
        filter.onSchemaChange,
        "Schema",
        prepFilterTags
      ),
      ...filterComp(
        filter.tagOptions,
        filter.onTagChange,
        "Insight",
        prepFilterTags
      ),
      ...filterComp(
        filter.contractOptions,
        filter.onContractFilter,
        "Contract",
        prepFilterTags
      ),
    };

    // Only update state if newPrepFilterTags is different from prepFilterTags
    if (!isEqual(newPrepFilterTags, prepFilterTags)) {
      setPrepFilterTags(newPrepFilterTags);
    }
  }, [filter]);

  if (isDataset) return <NoDatasets />;

  const tableListProps = {
    paginate,
    tables,
    search,
    onDetailsClick,
    onItemClick: onDetailsClick,
    select: {
      label: "View Schema",
      color: "primary",
      outline: true,
      onSelect: (table) => {
        setSchemaTable?.(table);
      },
    },
  };

  if (firstLoad && (isLoading || isTableLoading)) {
    return <ComponentLoader left={60} label={"Loading datasets..."} />;
  } else if (firstLoad) {
    setFirstLoad(false);
  }

  return (
    <>
      <div>
        <div className="mb-4 d-flex gap-lg align-items-center">
          <h3 className="text-black">Datasets</h3>
          <div
            className={classNames(
              styles.filter_tags,
              "d-flex gap-sm align-items-center"
            )}
          >
            <FilterTagWrapper filters={prepFilterTags} />
          </div>
        </div>
        <div className={styles.dataset_container}>
          <Filter {...filter} />
          {isTableLoading ? (
            <ComponentLoader left={70} label="Loading datasets..." />
          ) : (
            <TableList {...tableListProps} />
          )}
        </div>
      </div>
      <SidebarModal
        isOpen={!!schemaTable}
        toggleModal={() => setSchemaTable(null)}
        width="446"
      >
        <ColumnsModal table={schemaTable} onDetailsClick={onDetailsClick} />
      </SidebarModal>
    </>
  );
};

export { Datasets };
