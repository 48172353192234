import "reactflow/dist/style.css";
import { Lineage } from "../../lib/altimate/altimate-components";
import styles from "./styles.module.scss";
import { useMemo } from "react";
import { destructTable } from "./utils";

const StaticLineage = ({
  selectedColumn,
  collectColumns: _collectColumns = {},
  columnEdges,
  tableEdges,
  tables,
  height = "40vh",
}) => {
  const details = useMemo(() => {
    const result = {};
    for (const curr of tables) {
      const [, schema, label] = destructTable(curr);
      result[curr] = { type: schema.split(".")[0], name: label };
    }
    return result;
  }, [tables]);
  const collectColumns = useMemo(() => {
    const result = {};
    for (const curr in _collectColumns) {
      result[curr] = _collectColumns[curr].map((column) => ({ column }));
    }
    return result;
  }, [_collectColumns]);
  return (
    <div style={{ height }}>
      <Lineage
        theme={"light"}
        staticLineage={{
          details,
          tableEdges,
          selectedColumn,
          collectColumns,
          columnEdges,
        }}
        lineageType={"static"}
      />
    </div>
  );
};

const SqlLineage = ({
  details,
  tableEdges,
  nodePositions,
  height,
  externalSidePanel = false,
}) => {
  return (
    <div className={styles.new_lineage_container} style={{ height }}>
      <Lineage
        theme={"light"}
        sqlLineage={{ details, tableEdges, nodePositions }}
        lineageType={"sql"}
        externalSidePanel={externalSidePanel}
      />
    </div>
  );
};

export { SqlLineage, StaticLineage };
