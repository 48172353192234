import styles from "./styles.module.scss";
import { RiFilter3Line } from "react-icons/ri";
import { IconContext } from "react-icons";
import { FilterDropdown, FilterText } from "../../Components/FilterDropdown";
import { ReactComponent as SortDefault } from "@/assets/icons/sort_default.svg";
import { ReactComponent as SortAscending } from "@/assets/icons/sort_ascending.svg";
import { ReactComponent as SortDescending } from "@/assets/icons/sort_descending.svg";
import { Dropdown } from "../Form";
import classNames from "classnames";

const Icon = ({ children }) => (
  <IconContext.Provider value={{ size: "1.2rem" }}>
    {children}
  </IconContext.Provider>
);

const SortIcon = ({ sort = "", onChange }) => {
  const handleClick = () => {
    const newSort = sort === "desc" ? "asc" : "desc";
    onChange(newSort);
  };

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      {sort === "" && <SortDefault />}
      {sort === "asc" && <SortAscending />}
      {sort === "desc" && <SortDescending />}
    </div>
  );
};

const TableFilter = ({ filter, label, resetPage }) => {
  const setChangedState = (currentState) => {
    filter.onChange(currentState);
    resetPage();
  };
  return (
    <>
      {filter.filterType === "text" && (
        <FilterText
          label={filter.label}
          onChange={setChangedState}
          value={filter.value}
          placeHolder={filter.placeHolder}
        >
          <Icon>
            <RiFilter3Line />
          </Icon>
        </FilterText>
      )}
      {filter.filterType === "dropdown" && (
        <FilterDropdown
          label={label}
          value={filter.value}
          options={filter.options}
          onChange={setChangedState}
        >
          <Icon>
            <RiFilter3Line />
          </Icon>
        </FilterDropdown>
      )}
      {filter.filterType === "dropdown_single" && (
        <Dropdown
          id={filter?.id}
          value={filter?.value}
          options={filter?.options}
          onChange={filter?.onChange}
        >
          <Icon>
            <RiFilter3Line />
          </Icon>
        </Dropdown>
      )}
    </>
  );
};

const TableHeader = ({ header, resetPage, gridTemplateColumns }) => {
  return (
    <div className={styles.table_header} style={{ gridTemplateColumns }}>
      {header.map((h) => (
        <div
          className="w-100 d-flex align-items-center"
          key={h.id}
          {...(h.id === 1 && { style: { width: "200px" } })}
        >
          <span>{h.label}</span>
          <div className="spacer" />
          {h.filter && (
            <TableFilter
              filter={h.filter}
              label={h.label}
              resetPage={resetPage}
            />
          )}
          {h.sort && (
            <SortIcon sort={h.sort.value} onChange={h.sort.onChange} />
          )}
        </div>
      ))}
    </div>
  );
};

const DisplayTableHeader = ({ header, resetPage, gridTemplateColumns }) => {
  return (
    <div
      className={styles.display_table_header}
      style={{ gridTemplateColumns }}
    >
      {header.map((h) => (
        <div
          className="w-100 d-flex align-items-center"
          key={h.id}
          {...(h.id === 1 && { style: { width: "200px" } })}
        >
          {h.id !== 1 && <div className={styles.divider}></div>}
          <span>{h.label}</span>
          <div className="spacer" />
        </div>
      ))}
    </div>
  );
};

const ListingTable = ({
  header,
  items,
  onItemClick,
  resetPage,
  templateColumns,
  idKey,
  rowClass = "",
  rowRender = (x) => <div>{x}</div>,
  clickable = false,
}) => {
  const gridTemplateColumns =
    templateColumns || `repeat(${header.length}, 1fr)`;
  return (
    <div className={styles.table}>
      <TableHeader
        header={header}
        resetPage={resetPage}
        gridTemplateColumns={gridTemplateColumns}
      />
      <div className={styles.table_body}>
        {items.map((item) => (
          <div
            key={
              typeof idKey === "function" ? idKey(item) : item[idKey || "id"]
            }
            className={classNames(styles.table_row, rowClass, {
              [styles.clickable]: clickable,
            })}
            style={{ gridTemplateColumns }}
            onClick={(e) => {
              e.stopPropagation();
              onItemClick?.(item);
            }}
          >
            {rowRender(item)}
          </div>
        ))}
      </div>
    </div>
  );
};

const DisplayTable = ({
  header,
  items,
  onItemClick,
  resetPage,
  templateColumns,
  idKey,
  rowRender = (x) => <div>{x}</div>,
}) => {
  const gridTemplateColumns =
    templateColumns || `repeat(${header.length}, 1fr)`;
  return (
    <div className={styles.table}>
      <DisplayTableHeader
        header={header}
        resetPage={resetPage}
        gridTemplateColumns={gridTemplateColumns}
      />
      <div className={styles.table_body}>
        {items.map((item) => (
          <div
            key={
              typeof idKey === "function" ? idKey(item) : item[idKey || "id"]
            }
            className={styles.display_table_row}
            style={{ gridTemplateColumns }}
          >
            {rowRender(item)}
          </div>
        ))}
      </div>
    </div>
  );
};

export { ListingTable, DisplayTable, SortIcon };
