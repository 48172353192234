import { jsx as _jsx } from "react/jsx-runtime";
import Tooltip from "../tooltip/Tooltip";
import { formatNumber, getCurrencySymbol } from "./format";
const NumberFormatter = ({ value, options }) => {
    if (value === undefined || value === null) {
        return null;
    }
    return (_jsx(Tooltip, { content: `${getCurrencySymbol(options === null || options === void 0 ? void 0 : options.currency)}${value.toFixed(2)}`, placement: "top", children: _jsx("span", { children: formatNumber(value, options) }) }));
};
export default NumberFormatter;
