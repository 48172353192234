var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __await = (this && this.__await) || function (v) { return this instanceof __await ? (this.v = v, this) : new __await(v); }
var __asyncGenerator = (this && this.__asyncGenerator) || function (thisArg, _arguments, generator) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var g = generator.apply(thisArg, _arguments || []), i, q = [];
    return i = {}, verb("next"), verb("throw"), verb("return", awaitReturn), i[Symbol.asyncIterator] = function () { return this; }, i;
    function awaitReturn(f) { return function (v) { return Promise.resolve(v).then(f, reject); }; }
    function verb(n, f) { if (g[n]) { i[n] = function (v) { return new Promise(function (a, b) { q.push([n, v, a, b]) > 1 || resume(n, v); }); }; if (f) i[n] = f(i[n]); } }
    function resume(n, v) { try { step(g[n](v)); } catch (e) { settle(q[0][3], e); } }
    function step(r) { r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r); }
    function fulfill(value) { resume("next", value); }
    function reject(value) { resume("throw", value); }
    function settle(f, v) { if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]); }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import axios from "axios";
import { getUser } from "../services/auth";
import { REACT_APP_API_URL, ENV } from "../config";
import { ZodArray, ZodDefault, ZodNullable, ZodObject, } from "zod";
axios.defaults.baseURL = REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
const user = getUser();
if (user && user.token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
}
const streamHeaders = {};
// intercepting to capture errors
axios.interceptors.response.use(function (response) {
    return response.data ? response.data : response;
}, function (error) {
    var _a, _b, _c, _d, _e, _f;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
        case 500:
            message = "Internal Server Error";
            break;
        case 401:
            message = "Invalid credentials";
            break;
        case 404:
            message = `Sorry! the data you are looking for could not be found. ${((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.detail)
                ? `Details: ${(_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.detail}`
                : ""}`;
            break;
        default:
            message = ((_f = (_e = error.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.detail) || (error === null || error === void 0 ? void 0 : error.message) || error;
    }
    return Promise.reject(message);
});
const setAuthorizationHeader = (authToken) => {
    console.log("Setting authorization header...");
    if (authToken) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        streamHeaders["Authorization"] = "Bearer " + authToken;
    }
    else {
        delete axios.defaults.headers.common["Authorization"];
        delete streamHeaders["Authorization"];
    }
};
const setTenantHeader = (tenant) => {
    console.log("Setting tenant...");
    if (tenant) {
        axios.defaults.headers.common["x-tenant"] = tenant;
        streamHeaders["x-tenant"] = tenant;
    }
    else {
        delete axios.defaults.headers.common["x-tenant"];
        delete streamHeaders["x-tenant"];
    }
};
const checkSecureConnection = () => {
    if (ENV === "staging" || ENV === "production") {
        if (location.protocol !== "https:" ||
            (REACT_APP_API_URL === null || REACT_APP_API_URL === void 0 ? void 0 : REACT_APP_API_URL.substring(0, 5)) !== "https") {
            return false;
        }
    }
    return true;
};
const applyCatchToSchema = (schema, defaultValue = "test") => {
    if (schema instanceof ZodObject) {
        const shape = schema.shape; // Access the shape correctly
        const newShape = Object.fromEntries(Object.entries(shape).map(([key, value]) => [
            key,
            applyCatchToSchema(value, defaultValue),
        ]));
        return new ZodObject(Object.assign(Object.assign({}, schema._def), { shape: () => newShape }));
    }
    else if (schema instanceof ZodArray) {
        return new ZodArray(Object.assign(Object.assign({}, schema._def), { type: applyCatchToSchema(schema._def.type, defaultValue) }));
    }
    else if (schema instanceof ZodNullable || schema instanceof ZodDefault) {
        return schema.catch("");
    }
    else if (schema._def.typeName === "ZodString" ||
        schema._def.typeName === "ZodNumber") {
        schema._def.defaultValue = undefined;
        // Applies .catch to string and number schemas
        return schema.catch("");
    }
    return schema;
};
const patchWithZodSchema = (response, endpoint, zodSchema) => {
    if (!zodSchema)
        return response;
    try {
        const validationResult = zodSchema.safeParse(response);
        if (validationResult.success) {
            return validationResult.data;
        }
        console.error("zod validation error", endpoint, validationResult.error, response);
        const formattedResponse = applyCatchToSchema(zodSchema).parse(response);
        return formattedResponse;
    }
    catch (e) {
        console.error("patchWithZodSchema", endpoint, e, response);
        return response;
    }
};
class APIClient {
    constructor() {
        Object.defineProperty(this, "controller", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /**
         * Fetches data from given url
         */
        Object.defineProperty(this, "get", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (url_1, params_1, ...args_1) => __awaiter(this, [url_1, params_1, ...args_1], void 0, function* (url, params, timeout = 0, zodSchema) {
                if (!checkSecureConnection()) {
                    return;
                }
                this.controller = new AbortController();
                const config = {
                    signal: this.controller.signal,
                };
                if (timeout) {
                    config.signal = AbortSignal.timeout(timeout);
                }
                if (!params)
                    return axios.get(`${url}`, config);
                const paramKeys = [];
                for (const k in params) {
                    const v = params[k];
                    if (!Array.isArray(v)) {
                        paramKeys.push(`${k}=${encodeURIComponent(v)}`);
                        continue;
                    }
                    for (const i of v) {
                        paramKeys.push(`${k}=${encodeURIComponent(i)}`);
                    }
                }
                return patchWithZodSchema(yield axios.get(`${url}?${paramKeys.join("&")}`, Object.assign(Object.assign(Object.assign({}, params), config), { signal: this.controller.signal })), url, zodSchema);
            })
        });
        /**
         * post given data to url
         */
        Object.defineProperty(this, "post", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (url, data, zodSchema) => __awaiter(this, void 0, void 0, function* () {
                if (!checkSecureConnection()) {
                    return;
                }
                this.controller = new AbortController();
                return patchWithZodSchema(yield axios.post(url, data, { signal: this.controller.signal }), url, zodSchema);
            })
        });
        Object.defineProperty(this, "postWithParams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (url, params, data, zodSchema) => __awaiter(this, void 0, void 0, function* () {
                if (!checkSecureConnection()) {
                    return;
                }
                this.controller = new AbortController();
                return patchWithZodSchema(yield axios.post(url, data, { params, signal: this.controller.signal }), url, zodSchema);
            })
        });
        /**
         * Updates data
         */
        Object.defineProperty(this, "update", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (url, data, zodSchema) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (!checkSecureConnection()) {
                    return;
                }
                return patchWithZodSchema(yield axios.patch(url, data, { signal: (_a = this.controller) === null || _a === void 0 ? void 0 : _a.signal }), url, zodSchema);
            })
        });
        Object.defineProperty(this, "put", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (url, data, zodSchema) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (!checkSecureConnection()) {
                    return;
                }
                return patchWithZodSchema(yield axios.put(url, data, { signal: (_a = this.controller) === null || _a === void 0 ? void 0 : _a.signal }), url, zodSchema);
            })
        });
        /**
         * Delete
         */
        Object.defineProperty(this, "delete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (url, config, zodSchema) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (!checkSecureConnection()) {
                    return;
                }
                return patchWithZodSchema(yield axios.delete(url, Object.assign(Object.assign({}, config), { signal: (_a = this.controller) === null || _a === void 0 ? void 0 : _a.signal })), url, zodSchema);
            })
        });
        Object.defineProperty(this, "cancel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.controller) === null || _a === void 0 ? void 0 : _a.abort();
            }
        });
    }
}
function getIterableStream(body) {
    return __asyncGenerator(this, arguments, function* getIterableStream_1() {
        const reader = body.getReader();
        const decoder = new TextDecoder();
        while (true) {
            const { value, done } = yield __await(reader.read());
            if (done) {
                break;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            yield yield __await(decodedChunk);
        }
    });
}
const generateStream = (url_1, onProgress_1, ...args_1) => __awaiter(void 0, [url_1, onProgress_1, ...args_1], void 0, function* (url, onProgress, requestBody = null) {
    var _a, e_1, _b, _c;
    const requestData = { method: "GET", headers: streamHeaders };
    if (requestBody) {
        requestData.method = "POST";
        requestData.headers["Content-Type"] = "application/json";
        requestData.body = JSON.stringify(requestBody);
    }
    const response = yield fetch(REACT_APP_API_URL + url, requestData);
    if (response.status !== 200)
        throw new Error(response.status.toString());
    if (!response.body)
        throw new Error("Response body does not exist");
    const stream = yield getIterableStream(response.body);
    try {
        for (var _d = true, stream_1 = __asyncValues(stream), stream_1_1; stream_1_1 = yield stream_1.next(), _a = stream_1_1.done, !_a; _d = true) {
            _c = stream_1_1.value;
            _d = false;
            const chunk = _c;
            onProgress(chunk);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (!_d && !_a && (_b = stream_1.return)) yield _b.call(stream_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
});
export { axios, APIClient, setAuthorizationHeader, setTenantHeader, getIterableStream, generateStream, };
export const api = new APIClient();
