import styles from "./styles.module.scss";
import { createElement, useMemo, useState } from "react";
import classNames from "classnames";
import { ColorTag } from "../../Components/Tags";
import { Button } from "reactstrap";
import {
  AGENT_CATEGORIES,
  SNOWFLAKE,
  WORKFLOWS,
  WORKFLOW_LABELS,
} from "./constants";
import { isFreeStack, showPremiumDatapilot } from "../../helpers/tenant";
import { useQuery } from "react-query";
import { GET_DATASTORE_TYPES } from "../Datastores/DatastoreImage";
import { getDatastoreTypes } from "../../helpers/backend_helper";
import { useIsEnterpriseUser } from "../../helpers/hooks";

const Workflow = ({ resetWorkflow, workflow, tasks, onItemClick }) => {
  // Handle Snowflake agents
  const [snowflakeFound, setSnowflakeFound] = useState(undefined);
  const { isEnterpriseUser } = useIsEnterpriseUser();
  const showEastman = showPremiumDatapilot();
  useQuery({
    queryKey: [GET_DATASTORE_TYPES],
    queryFn: getDatastoreTypes,
    enabled: snowflakeFound === undefined,
    onSettled: (data) => {
      if (data) {
        const snowflake = data.find((type) => type.value === "snowflake");
        if (snowflake) {
          setSnowflakeFound(true);
        } else {
          setSnowflakeFound(false);
        }
      }
    },
  });
  const [showCategories, setShowCategories] = useState(true);

  const [category, setCategory] = useState("ALL");
  const workflowTabs = useMemo(() => {
    const currentCategoryWorkflow = WORKFLOWS.map((workflow) => {
      if (
        workflow.enabled &&
        !isEnterpriseUser &&
        workflow.enterprise &&
        !showEastman
      ) {
        return {
          ...workflow,
          showTags: true,
          label: "Enterprise",
          label_color: "blue",
        };
      } else if (
        snowflakeFound === false &&
        workflow.categories.includes(SNOWFLAKE)
      ) {
        return {
          ...workflow,
          enabled: false,
          showTags: true,
          label: "Not Configured",
          label_color: "red",
        };
      }
      return workflow;
    }).filter((workflow) =>
      workflow.categories.includes(AGENT_CATEGORIES[category].label)
    );
    if (!workflow) return currentCategoryWorkflow;
    if (tasks.length === 0) return currentCategoryWorkflow;
    setShowCategories(false);
    return currentCategoryWorkflow.filter((t) => t.value === workflow);
  }, [workflow, tasks.length, isEnterpriseUser, snowflakeFound, category]);

  return (
    <div className="d-flex flex-column gap-sm">
      <div className="d-flex justify-content-between">
        <div className="fs-3 fw-500">Agents</div>
        <Button
          color="link"
          className="text-decoration-none"
          onClick={() => {
            resetWorkflow();
            setShowCategories(true);
          }}
        >
          New Agent
        </Button>
      </div>
      {showCategories && (
        <div className="pt-2 pb-2 ps-3 pe-3 br-2 bg-white d-flex justify-content-around">
          {Object.keys(AGENT_CATEGORIES).map((key) => (
            <div
              key={key}
              className={classNames(
                `px-2 py-1 rounded justify-start items-center gap-1 d-flex flex-row
               align-items-center justify-content-center
              ${
                key === category
                  ? "bg-blue-light cursor-default"
                  : "cursor-pointer"
              }`
              )}
              onClick={() => setCategory(key)}
            >
              {createElement(AGENT_CATEGORIES[key].icon)}
              {key === category && <div>{AGENT_CATEGORIES[key].label}</div>}
            </div>
          ))}
        </div>
      )}
      {workflowTabs.map((item) => (
        <div
          key={item.value}
          className={classNames(
            "pt-2 pb-2 ps-3 pe-3 br-2 white-border",
            styles.workflow_item,
            {
              [styles.selected]: workflow === item.value,
              [styles.disabled]: !item.enabled,
              "cursor-pointer": item.enabled,
            }
          )}
          onClick={(e) => {
            e.stopPropagation();
            if (!item.enabled) return;
            onItemClick({
              workflowType: item.value,
              inputType: item.inputType,
              premium: item?.enterprise,
            });
          }}
        >
          <div className="fs-5 d-flex justify-content-between">
            <div>{WORKFLOW_LABELS[item.value]}</div>
            {item.showTags && (
              <div className="fs-xs">
                <ColorTag color={item.label_color} label={item.label} />
              </div>
            )}
          </div>
          <div className="text-muted fs-xs">{item.description}</div>
        </div>
      ))}
    </div>
  );
};

export { Workflow };
