import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select, { components, } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Label, Stack } from "../../index";
import { CheckBlueIcon, SelectCheckedIcon, SelectUncheckedIcon, UncheckIcon, } from "@assets/icons";
import classes from "./select.module.scss";
import classNames from "classnames";
const { Option } = components;
const IconOption = (props) => {
    const { data: { label }, isMulti, isSelected, showOptionIcon, } = props;
    return (_jsx(Option, Object.assign({}, props, { children: _jsx("div", { className: "flex items-center gap-2", children: _jsxs(Label, { check: isMulti, children: [!showOptionIcon ? null : (_jsx("span", { style: { marginRight: 10 }, children: isSelected ? (isMulti ? (_jsx(SelectCheckedIcon, {})) : (_jsx(CheckBlueIcon, {}))) : isMulti ? (_jsx(SelectUncheckedIcon, {})) : (_jsx(UncheckIcon, {})) })), label] }) }) })));
};
export const filterOption = (option, inputValue) => (option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || [])
    .length > 0;
const AltimateSelect = (props) => {
    var _a, _b;
    const colourStyles = {
        menu: (styles) => (Object.assign(Object.assign({}, styles), { margin: 0, borderRadius: "0 0 4px 4px", backgroundColor: "#fff", right: 0, width: "auto", minWidth: "100%" })),
        menuPortal: (base) => (Object.assign(Object.assign({}, base), { zIndex: 9999 })),
        option: (styles, { isFocused, isSelected }) => (Object.assign(Object.assign({}, styles), { background: isSelected ? "#247efe33" : "transparent", cursor: "pointer", color: isFocused || isSelected ? "#247efe" : "#8390a3", borderBottom: props.showDivider ? "1px solid #e0e0e0" : "none", whiteSpace: props.allowOptionWrap ? "" : "nowrap" })),
        indicatorSeparator: (styles) => (Object.assign(Object.assign({}, styles), { display: "none" })),
        input: (styles) => (Object.assign(Object.assign({}, styles), { color: "#171717" })),
        singleValue: (styles) => (Object.assign(Object.assign({}, styles), { color: "#247efe" })),
        multiValue: (styles) => (Object.assign(Object.assign({}, styles), { color: "#171717", backgroundColor: "#d3e5ff", borderRadius: 26, padding: "0 6px" })),
        multiValueLabel: (styles) => (Object.assign(Object.assign({}, styles), { color: "inherit" })),
        control: (styles) => (Object.assign(Object.assign({}, styles), { backgroundColor: "#ffffff", borderColor: "#c4cad2", color: "#4a4d51" })),
        container: (styles, cprops) => {
            var _a, _b, _c;
            return (Object.assign(Object.assign(Object.assign({}, styles), (_b = (_a = props.styles) === null || _a === void 0 ? void 0 : _a.container) === null || _b === void 0 ? void 0 : _b.call(_a, styles, cprops)), { width: (_c = props.width) !== null && _c !== void 0 ? _c : "auto", margin: 0 }));
        },
    };
    const selectName = (_a = props.name) !== null && _a !== void 0 ? _a : `select-${Math.random()}`;
    const selectedValue = props.value
        ? (_b = props.options) === null || _b === void 0 ? void 0 : _b.find((o) => o.value === props.value)
        : null;
    if (props.isCreatable) {
        return (_jsxs(Stack, { className: classNames(classes.altimateSelect, props.className), children: [props.label && _jsx(Label, { children: props.label }), _jsx(CreatableSelect, Object.assign({}, props, { value: selectedValue, menuPortalTarget: document.body, styles: colourStyles, classNamePrefix: "altimate-select", 
                    // @ts-expect-error TODO fix this type
                    components: Object.assign(Object.assign({}, props.components), { Option: (optionProps) => (
                        // @ts-expect-error TODO fix this type
                        _jsx(IconOption, Object.assign({}, optionProps, { showOptionIcon: props.showOptionIcon, selectName: selectName }))) }) }))] }));
    }
    return (_jsxs(Stack, { className: classNames(classes.altimateSelect, props.className), children: [props.label && _jsx(Label, { children: props.label }), _jsx(Select, Object.assign({}, props, { menuPortalTarget: document.body, value: selectedValue, styles: colourStyles, filterOption: filterOption, onChange: (updates, actionMeta) => {
                    var _a, _b, _c;
                    if (props.isMulti) {
                        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, (_b = ((updates !== null && updates !== void 0 ? updates : []))) === null || _b === void 0 ? void 0 : _b.map((val) => val.value), actionMeta);
                    }
                    else {
                        (_c = props.onChange) === null || _c === void 0 ? void 0 : _c.call(props, updates.value, actionMeta);
                    }
                }, classNamePrefix: "altimate-select", 
                // @ts-expect-error TODO fix this type
                components: Object.assign(Object.assign({}, props.components), { Option: (optionProps) => (
                    // @ts-expect-error TODO fix this type
                    _jsx(IconOption, Object.assign({}, optionProps, { showOptionIcon: props.showOptionIcon, selectName: selectName }))) }) }))] }));
};
export default AltimateSelect;
