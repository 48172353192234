var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Popover } from "reactstrap";
const BetterPopover = (_a) => {
    var { children, containerClass } = _a, props = __rest(_a, ["children", "containerClass"]);
    const innerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!innerRef.current)
                return;
            if (innerRef.current.contains(event.target))
                return;
            setIsOpen(false);
        };
        const root = document.getElementById("root");
        root === null || root === void 0 ? void 0 : root.addEventListener("click", handleClickOutside, true);
        return () => {
            root === null || root === void 0 ? void 0 : root.removeEventListener("click", handleClickOutside, true);
        };
    }, [innerRef]);
    return (_jsx(Popover, Object.assign({}, props, { isOpen: isOpen, toggle: () => setIsOpen((b) => !b), children: _jsx("div", { ref: innerRef, className: containerClass, children: children({ close: () => setIsOpen(false) }) }) })));
};
export { BetterPopover };
