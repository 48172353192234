import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { getQueryGroupDetailsByHash } from "@/helpers/apis";
import { AsyncPopover, List } from "@uicore";
import dayjs from "dayjs";
import classes from "./styles.module.scss";
import { useSearchParams } from "react-router-dom";
const QueryHashWithHover = ({ queryHash }) => {
    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    const [searchParams] = useSearchParams();
    const period = searchParams.get("period") || "month";
    return (_jsx(AsyncPopover, { queryKey: "GET_QUERY_GROUP_BY_HASH", text: queryHash, apiFn: () => getQueryGroupDetailsByHash(queryHash, { period }), textClassName: classes.queryHashText, loadingLabel: "Fetching query group stats", children: ({ data }) => {
            return (_jsxs(List, { className: classes.queryHash, onClick: stopPropagation, children: [_jsxs("li", { children: ["Total runs: ", data.num_runs] }), _jsxs("li", { children: ["Total cost: ", data.total_cost] }), _jsxs("li", { children: ["Avg. execution time: ", data.avg_execution_time] }), _jsxs("li", { children: ["Max cost savings: ", data.max_cost_savings] }), _jsxs("li", { children: ["Max time savings: ", data.max_time_savings] }), _jsxs("li", { children: ["Last run time:", " ", dayjs(data.last_run_time).format("DD, MMM, HH:MM")] })] }));
        } }));
};
export default QueryHashWithHover;
