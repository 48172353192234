import styles from "./styles.module.scss";
import { useState, useMemo } from "react";
import { uniqueId } from "lodash";
import { BetterPopover } from "../Popover";
import classNames from "classnames";
import { Button } from "reactstrap";
import { ReactComponent as ArrowRightFullIcon } from "@/assets/icons/arrow-right-full.svg";

export const FilterText = ({
  label,
  value,
  children,
  onChange,
  placeHolder,
  popoverProps = {},
}) => {
  const id = useMemo(() => uniqueId("filter-text-"), []);
  const [defaultValue, changeDefaultValue] = useState(value || "");
  const numbersOnly = useMemo(() => /^[0-9]+$/, []);

  const isValidNumber = useMemo(() => {
    return numbersOnly.test(defaultValue) || defaultValue === "";
  }, [defaultValue, numbersOnly]);

  const isDisabled = defaultValue === 0 || defaultValue === "";

  return (
    <>
      <div id={id} className="d-flex gap-sm cursor-pointer">
        {children}
      </div>
      <BetterPopover target={id} hideArrow placement="bottom" {...popoverProps}>
        {({ close }) => (
          <div
            className={classNames(
              styles.dropdown_container,
              styles.async_container
            )}
          >
            <div className="m-2 d-flex justify-content-left align-items-left gap-xxs flex-column">
              <div className="fs-5 fw-semibold">{label}</div>
              <div className="fs-6 fw-lighter">(greater than)</div>
            </div>
            <div className="divider w-100" />
            <div className="m-2">
              <div className={styles.user_input}>
                <input
                  rows={1}
                  value={defaultValue}
                  onChange={(e) => {
                    if (
                      numbersOnly.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      changeDefaultValue(e.target.value);
                    }
                  }}
                  placeholder={placeHolder}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      e.stopPropagation();
                      close();
                      onChange(defaultValue);
                    }
                  }}
                />
                <Button
                  size="sm"
                  disabled={isDisabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    close();
                    onChange(defaultValue);
                  }}
                >
                  <ArrowRightFullIcon />
                </Button>
              </div>
            </div>
          </div>
        )}
      </BetterPopover>
    </>
  );
};
