import { Button, Card, CardBody } from "reactstrap";
import { DatastoreTypeIcons } from "../Datastores/DatastoreImage";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { QueryList } from "./QueryList";
import TimeInfoDisclaimer from "../../Components/TimeInfoDisclaimer";
import { Paginate } from "../../Components/Paginate";
import { useMutation, useQuery } from "react-query";
import {
  getAllQueries,
  getQueryFilters,
  getAllQueriesCSVFile,
  getLastFetchedTime,
} from "../../helpers/backend_helper";
import {
  ComponentLoader,
  RelativeComponentLoader,
} from "../../Components/Loader";
import { FilterTagWrapper } from "../../Components/Tags";
import {
  GET_LAST_UPDATED_TIME,
  GET_QUERY,
  GET_QUERY_FILTERS,
} from "./constants";
import { ReactComponent as CalendarIcon } from "../../assets/icons/datetime.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addSearchParams, removeSearchParams } from "../../helpers/utils";
import { SearchInput } from "../../Components/Form";
import dayjs from "dayjs";
import { CostGreenIcon, TimeGreenIcon } from "@assets/icons";
import { Select, Tooltip } from "@uicore";
import { DateRange, StaticDateRangeKeys } from "../../Components/DateRange";
import InsightLabel from "./InsightLabel";
import { PERIOD_OPTIONS } from "../Explorer/constants";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { useDateFromSearchParams } from "@/helpers/useTimeHooks";

const DS_TYPES = [
  { value: "snowflake", enabled: true },
  { value: "bigquery", enabled: false },
  { value: "postgres", enabled: false },
];

const QueryHeader = () => {
  const [datastoreType, setDatastoreType] = useState("snowflake");

  const { data: infoTimeString, isLoading } = useQuery({
    queryKey: [GET_LAST_UPDATED_TIME],
    queryFn: getLastFetchedTime,
  });

  return (
    <div className="bg-white p-3 border-radius-top">
      <div className="d-flex align-items-center gap-lg ">
        <div className="fs-4">Queries</div>
        <DatastoreTypeIcons
          datastoreType={datastoreType}
          setDatastoreType={setDatastoreType}
        />
        <div className="spacer" />
        {!isLoading && infoTimeString && (
          <TimeInfoDisclaimer
            numOfSecondsActive={3}
            textToDisplay={
              <div className="text-muted m-2">
                Queries with execution time {">"}10 secs are shown.
                <br />
                The data was last updated at{" "}
                <span className="text-black fw-semibold">
                  {dayjs
                    .utc(
                      `1970-01-01T${infoTimeString["time_string"]}Z`,
                      "HH:mm:ss"
                    )
                    .local()
                    .format("HH:mm:ss A")}{" "}
                  UTC
                </span>
              </div>
            }
          />
        )}
      </div>
      <p className="caption mt-2 mb-0">
        This page shows history of all the queries run during the selected time
        period.
      </p>
    </div>
  );
};

const QueryListWithFilter = ({ setSelectedQuery, queryHash = "" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(0);

  const [sortAttribute, setSortAttribute] = useState("query_cost");
  const [sortOrder, setSortOrder] = useState({
    query_cost: "",
    end_time: "",
    execution_time: "",
  });
  const [isDownloading, setIsDownloading] = useState(false);

  const queryFilters = {
    tags: searchParams.getAll("tags") || [],
    executionTime: searchParams.get("executionTime"),
    queryCost: searchParams.get("queryCost"),
    warehouses: searchParams.getAll("warehouses") || [],
    users: searchParams.getAll("users") || [],
    queryHash: searchParams.get("queryHash") || "",
    queryParameterizedHash: searchParams.get("queryParameterizedHash") || "",
    sessionID: searchParams.get("sessionID") || "",
  };
  const tagFilter = queryFilters?.tags || [];
  const executionTimeFilter = queryFilters?.executionTime;
  const queryCostFilter = queryFilters?.queryCost;
  const warehouseFilter = queryFilters?.warehouses || [];
  const userFilter = queryFilters?.users || [];
  const sessionIDFilter = queryFilters?.sessionID;
  const queryParameterizedHash = queryFilters?.queryParameterizedHash;

  const queryId = searchParams.get("queryId") || "";

  const { startDate, endDate } = useDateFromSearchParams(
    dayjs().subtract(28, "day").toDate(),
    dayjs().toDate()
  );

  const handleCostSortChange = (so) => {
    if (so) {
      setSortAttribute("query_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        query_cost: so,
      }));
    }
  };

  const handleTimestampSortChange = (so) => {
    if (so) {
      setSortAttribute("end_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        end_time: so,
      }));
    }
  };

  const handleExecutionTimeSortChange = (so) => {
    if (so) {
      setSortAttribute("execution_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        execution_time: so,
      }));
    }
  };

  const getQueryFilterParams = () => {
    const filters = {};
    if (queryHash) filters.query_hash = queryHash;
    return filters;
  };

  const { data: filters, isLoading: isfiltersLoading } = useQuery({
    queryKey: [GET_QUERY_FILTERS, queryHash],
    queryFn: () => getQueryFilters(getQueryFilterParams()),
  });

  const getFilterParams = () => {
    const params = { page: page + 1, size: 8 };
    if (queryHash) params.query_hash = queryHash;
    if (queryParameterizedHash)
      params.query_parameterized_hash = queryParameterizedHash;
    if (queryId) params.query_id = queryId;
    if (warehouseFilter.length > 0) params.warehouses = warehouseFilter;
    if (tagFilter.length > 0) params.tags = tagFilter;
    if (userFilter.length > 0) params.users = userFilter;
    if (sortAttribute) {
      params.sortAttribute = sortAttribute;
      params.sortOrder = sortOrder[sortAttribute];
    }
    if (executionTimeFilter)
      params.executionTimeFilter = executionTimeFilter * (60 * 1000);
    if (queryCostFilter) params.executionCostFilter = queryCostFilter;
    if (sessionIDFilter) params.session_id = sessionIDFilter;
    (params.start_date =
      getDateByStartOfDayWithoutTimeoffset(startDate).toISOString()),
      (params.end_date =
        getDateByEndOfDayWithoutTimeoffset(endDate).toISOString());
    return params;
  };

  const {
    data: queries,
    isLoading: isQueriesLoading,
    refetch,
  } = useQuery({
    queryKey: [
      GET_QUERY,
      page,
      ...warehouseFilter,
      ...tagFilter,
      ...userFilter,
      sortOrder[sortAttribute],
      sortAttribute,
      executionTimeFilter,
      queryCostFilter,
      sessionIDFilter,
      queryHash,
      queryParameterizedHash,
      queryId,
      startDate,
      endDate,
    ],
    queryFn: () => getAllQueries(getFilterParams()),
  });

  const { mutateAsync: downloadCSV } = useMutation(
    () => {
      const params = getFilterParams();
      params.responseType = "blob";
      return getAllQueriesCSVFile(params, setIsDownloading);
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleDownloadClick = async () => {
    setIsDownloading(true);
    try {
      await downloadCSV();
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };

  const handleCostFilterChange = (value) => {
    setSearchParams(addSearchParams("queryCost", value));
  };

  const handleExecutionTimeFilterChange = (value) => {
    setSearchParams(addSearchParams("executionTime", value));
  };

  const handleWarehouseFilterChange = (value) => {
    setSearchParams(addSearchParams("warehouses", value));
  };

  const handleUserFilterChange = (value) => {
    setSearchParams(addSearchParams("users", value));
  };

  const handleInsightsFilterChange = (value) => {
    setSearchParams(addSearchParams("tags", value));
  };

  if (isQueriesLoading || isfiltersLoading) {
    return <RelativeComponentLoader />;
  }

  return (
    <div className="p-3 d-flex flex-column gap-md bg-white border-radius-bottom">
      <div className="d-flex gap-md align-items-center">
        {queryHash && <div className="fs-4 fw-500 me-3">Query Executions</div>}
        <SearchInput
          defaultValue={queryId}
          onChange={(v) => {
            setSearchParams(addSearchParams("queryId", v));
          }}
          placeholder="Enter query id"
        />
        {queryId && queries.total === 0 && (
          <div className="text-muted">
            The query id may be outdated, or it will be retrieved within the
            next day
          </div>
        )}
        <div className="spacer" />
        {/* <DateRange
          startDate={startDate}
          endDate={endDate}
          onDateRangeSelect={(s, e) => {
            setStartDate(s);
            setEndDate(e);
            // Added timer to ensure the date range is updated before refetching
            setTimeout(() => refetch(), 0);
          }}
          availableStaticRanges={[
            StaticDateRangeKeys.LAST_DAY,
            StaticDateRangeKeys.LAST_7_DAYS,
            StaticDateRangeKeys.LAST_28_DAYS,
          ]}
          disableCalendarSelection
        /> */}
        <DateRange
          startDate={startDate}
          endDate={endDate}
          availableStaticRanges={["Last day", "Last 7 days", "Last 28 days"]}
          disableCalendarSelection
          onDateRangeSelect={(s, e) => {
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              params.set("start_date", s?.toISOString());
              params.set("end_date", e?.toISOString());
              return params.toString();
            });
          }}
        />
        {isDownloading && (
          <div
            className={classNames("text-primary m-2", styles.download_csv_text)}
          >
            CSV is being generated and will be ready soon!
          </div>
        )}
        {!isDownloading && (
          <Button
            outline
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadClick();
            }}
            disabled={!(queries && queries.total > 0 && !isDownloading)}
          >
            Download CSV file
          </Button>
        )}
      </div>
      <div className={styles.filter_tags}>
        <FilterTagWrapper
          filters={{
            tagFilter: {
              filterStr: tagFilter,
              label: "Tags",
              onclose: (removedFilter) => {
                setSearchParams(removeSearchParams("tags", removedFilter));
              },
            },
            warehouseFilter: {
              filterStr: warehouseFilter,
              label: "Warehouses",
              onclose: (removedFilter) => {
                setSearchParams(
                  removeSearchParams("warehouses", removedFilter)
                );
              },
            },
            userFilter: {
              filterStr: userFilter,
              label: "Users",
              onclose: (removedFilter) => {
                setSearchParams(removeSearchParams("users", removedFilter));
              },
            },
            executionTimeFilter: {
              filterStr: executionTimeFilter,
              label: "mins",
              onclose: () => {
                setSearchParams(removeSearchParams("executionTime"));
              },
            },
            queryCostFilter: {
              filterStr: queryCostFilter,
              label: "$",
              onclose: () => {
                setSearchParams(removeSearchParams("queryCost"));
              },
            },
            sessionIDFilter: {
              filterStr: sessionIDFilter,
              label: "Session ID",
              onclose: () => {
                setSearchParams(removeSearchParams("sessionID"));
              },
            },
            queryParameterizedHashFilter: {
              filterStr: queryParameterizedHash,
              label: "Parameterized Hash",
              onclose: () => {
                setSearchParams(removeSearchParams("queryParameterizedHash"));
              },
            },
            queryIdFilter: {
              filterStr: queryId,
              label: "Query ID",
              onclose: () => setSearchParams(removeSearchParams("queryId")),
            },
          }}
        />
      </div>
      <QueryList
        header={[
          { id: 1, label: "Query Text" },
          { id: 111, label: "Query Hash" },
          {
            id: 2,
            label: "Est. Cost",
            filter: {
              filterType: "text",
              value: queryCostFilter,
              placeHolder: "Specify cost in $",
              onChange: handleCostFilterChange,
              label: "Estimate Cost",
              searchParamKey: "queryCost",
            },
            sort: {
              onChange: handleCostSortChange,
              value: sortOrder.query_cost,
            },
          },
          {
            id: 3,
            label: "Exec. Time",
            filter: {
              filterType: "text",
              value: executionTimeFilter,
              placeHolder: "Specify time in mins",
              onChange: handleExecutionTimeFilterChange,
              label: "Execution Time",
              searchParamKey: "executionTime",
            },
            sort: {
              onChange: handleExecutionTimeSortChange,
              value: sortOrder.execution_time,
            },
          },
          {
            id: 4,
            label: "Insights",
            filter: {
              filterType: "dropdown",
              searchParamKey: "tags",
              value: tagFilter,
              searchBy: "value",
              options: filters?.tags
                ? filters.tags.map((t) => ({
                    label: <InsightLabel tag={t} />,
                    value: t.name,
                  }))
                : [],
              onChange: handleInsightsFilterChange,
            },
          },
          {
            id: 5,
            label: "Timestamp",
            sort: {
              onChange: handleTimestampSortChange,
              value: sortOrder.end_time,
            },
          },
          { id: 6, label: "Query Type" },
          {
            id: 7,
            label: "Warehouse",
            filter: {
              filterType: "dropdown",
              searchParamKey: "warehouses",
              value: warehouseFilter,
              options: filters?.warehouses
                ? filters.warehouses.map((w) =>
                    w === ""
                      ? { label: "None", value: w }
                      : { label: w, value: w }
                  )
                : [],
              onChange: handleWarehouseFilterChange,
            },
          },
          {
            id: 8,
            label: "User",
            filter: {
              filterType: "dropdown",
              searchParamKey: "users",
              value: userFilter,
              options: filters?.users
                ? filters.users.map((w) => ({
                    label: w,
                    value: w,
                  }))
                : [],
              onChange: handleUserFilterChange,
            },
          },
        ]}
        onRowClick={setSelectedQuery}
        queries={queries}
        resetPage={setPage}
      />
      <Paginate
        itemCount={queries.total}
        page={page}
        pageSize={queries.size}
        numPages={queries.pages}
        onPageClick={setPage}
      />
    </div>
  );
};

const AllQueries = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get("start_date");
  const endDate = searchParams.get("end_date");
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column">
      <QueryHeader />
      <div className="divider" />
      <QueryListWithFilter
        setSelectedQuery={(v) =>
          navigate(
            `/query/${v}${startDate ? "?start_date=" + startDate : ""}${
              endDate ? "&end_date=" + endDate : ""
            }`
          )
        }
      />
    </div>
  );
};

export { AllQueries, QueryHeader, QueryListWithFilter };
