import { Button } from "reactstrap";
import styles from "./styles.module.scss";
import classnames from "classnames";
import no_incident from "../../assets/images/no_incident.svg";
import no_incident_contract from "../../assets/images/no_incident_contract.svg";
import { Link } from "react-router-dom";

const NoIncidents = ({ num_contracts, timestamp, contractName }) => {
  if (num_contracts === 0) {
    return <NoIncidentsContract />;
  }
  return (
    <NoIncidentsValidation timestamp={timestamp} contractName={contractName} />
  );
};

const NoIncidentsContract = () => {
  return (
    <div className={styles.no_incidents}>
      <div className="d-flex h-100 justify-content-center">
        <div
          className={classnames(
            "d-flex flex-column align-items-center p-4",
            styles.container
          )}
        >
          <img
            src={no_incident_contract}
            alt="No contracts for incidents"
            className={styles.img}
          />
          <h3 className="mt-3 mb-3 text-black">No Incident Found</h3>
          <p className="text-muted text-center">
            There is no data contract on this dataset!
          </p>
          <Link to="/copilot">
            <Button color="primary" style={{ borderRadius: "5px" }}>
              Generate Contract
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const NoIncidentsValidation = ({ timestamp, contractName }) => {
  return (
    <div className={styles.no_incidents}>
      <div className="d-flex h-100 justify-content-center">
        <div
          className={classnames(
            "d-flex flex-column align-items-center p-4",
            styles.container
          )}
        >
          <img src={no_incident} alt="No Incidents" className={styles.img} />
          <h3 className="mt-3 mb-3 text-black">Great Job, No Incident Found</h3>
          {timestamp && (
            <p className={styles.subtext}>
              Last validation was done at {timestamp} for contract{" "}
              {contractName}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export { NoIncidents };
