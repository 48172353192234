import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorBoundary } from "react-error-boundary";
import { useRef, useState } from "react";
import { Tooltip as ReactStrapTooltip } from "reactstrap";
const Tooltip = (props) => {
    var _a, _b;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const idRef = useRef(((_a = props.id) !== null && _a !== void 0 ? _a : `tooltip-${Math.random().toString(36).substring(3, 9)}`).replace(/\s/g, "-"));
    return (_jsxs(ErrorBoundary, { fallback: _jsx("span", { id: idRef.current, children: props.children }), children: [_jsx("span", { id: idRef.current, className: props.tooltipContentWrapperClassName, children: props.children }), props.content ? (_jsx(ReactStrapTooltip, { isOpen: tooltipOpen, target: idRef.current, toggle: toggle, className: props.className, placement: (_b = props.placement) !== null && _b !== void 0 ? _b : "auto", style: { maxWidth: props.maxWidth }, children: props.content })) : null] }));
};
export default Tooltip;
