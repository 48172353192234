import { Button } from "reactstrap";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getAllPlans } from "../../helpers/backend_helper";
import styles from "./styles.module.scss";
import {
  CheckFAQ,
  DowngradeForm,
  EditionTag,
  InfoTooltip,
  getGradientLineColor,
  useCheckoutSessionRedirectMutation,
  useDowngradePlanMutation,
} from "./utils";
import { useIntercom } from "react-use-intercom";
import { ReactComponent as SelectIcon } from "../../assets/icons/slider-select-icon.svg";
import { ReactComponent as InfoDangerIcon } from "../../assets/icons/info_danger_icon.svg";
import { ReactComponent as SelectedIcon } from "../../assets/icons/slider-selected-icon.svg";
import { ReactComponent as SelectedIconDanger } from "../../assets/icons/slider-selected-icon-danger.svg";
import classNames from "classnames";
import { GET_ALL_SUBSCRIPTION_PLANS } from "./queryKey";
import { ComponentLoader } from "../../Components/Loader";
import { InfoMessages } from "./constants";
import { Tooltip } from "react-tooltip";

const SelectTenure = ({ currentTenure, setTenure }) => (
  <div className={styles.select_tenure}>
    <div
      className={classNames(styles.tenure, {
        [styles.tenure_selected]: currentTenure === "month",
      })}
      onClick={() => setTenure("month")}
    >
      Monthly
    </div>
    <div
      className={classNames(styles.tenure, {
        [styles.tenure_selected]: currentTenure === "year",
      })}
      onClick={() => setTenure("year")}
    >
      Yearly (Save 20%)
    </div>
  </div>
);

const SmallLine = ({ planState = "" }) => (
  <div
    className={classNames(styles.select_line, {
      [styles.plan_upgrade]: planState === "upgrade",
      [styles.plan_downgrade]: planState === "downgrade",
    })}
  ></div>
);

const CheckCircle = ({ planState }) => (
  <div
    className={classNames(styles.select_circle, {
      [styles.plan_upgrade]: planState === "upgrade",
      [styles.plan_downgrade]: planState === "downgrade",
      [styles.default]: planState === "default",
    })}
  ></div>
);

const GetSelectIcon = ({ planState = "" }) => {
  if (planState === "downgrade") return <SelectedIconDanger />;
  if (planState === "upgrade") return <SelectedIcon />;
  return <SelectIcon />;
};

const DowngradeInfoTooltip = ({
  endDate,
  planName,
  cancelPlanDowngradeMutation,
}) => (
  <>
    <div
      data-tooltip-id="downgrade-info"
      data-tooltip-variant=""
      data-tooltip-place="bottom"
      onClick={(e) => e.stopPropagation()}
    >
      <InfoDangerIcon />
    </div>
    <Tooltip id="downgrade-info" clickable>
      <div className={styles.downgrade_tooltip}>
        <div className={styles.downgrade_tooltip_text}>
          A downgrade is Scheduled at{" "}
          <span className={styles.highlight}>{endDate}</span> and the current
          plan will be downgraded to{" "}
          <span className={styles.highlight}>{planName}</span>.
        </div>
        <Button
          className={classNames(styles.button, {
            [styles.button_tooltip]: true,
          })}
          onClick={() => cancelPlanDowngradeMutation.mutate()}
        >
          <div className={styles.text}>Cancel Downgrade</div>
        </Button>
      </div>
    </Tooltip>
  </>
);

const Slider = ({
  plans,
  currentPlan,
  initialPlanName,
  setCurrentStatus,
  initialExecutions,
  currentExecutions,
  planChangeState,
  tenureCheck,
  downgradeInfo,
  currentTenure,
  cancelPlanDowngradeMutation,
}) => {
  const [hoveredPlan, setHoveredPlan] = useState(null);

  const handleMouseOver = (e, plan) => {
    setHoveredPlan(plan);
  };

  return (
    <div className={styles.slider_container}>
      <div
        className={styles.slider_line}
        style={{
          background: getGradientLineColor(
            plans,
            currentPlan,
            initialPlanName,
            planChangeState
          ),
        }}
      >
        {plans.map((plan, index) => (
          <div
            key={index}
            className={classNames(styles.plan_description, {
              [styles.default]:
                hoveredPlan &&
                hoveredPlan.plan_name === plan.plan_name &&
                currentPlan !== plan.plan_name,
              [styles.upgrade_plan]:
                currentPlan === plan.plan_name && planChangeState === "upgrade",
              [styles.downgrade_plan]:
                currentPlan === plan.plan_name &&
                planChangeState === "downgrade",
            })}
            onMouseOver={(e) => handleMouseOver(e, plan)}
            onMouseOut={() => setHoveredPlan(null)}
          >
            <div
              className={
                initialPlanName === plan.plan_name ||
                currentPlan === plan.plan_name ||
                (hoveredPlan && hoveredPlan.plan_name === plan.plan_name) ||
                (downgradeInfo &&
                  downgradeInfo.tenure === currentTenure &&
                  downgradeInfo.plan_name === plan.plan_name)
                  ? "mb-1"
                  : "mb-4"
              }
            >
              {plan.num_executions === "Custom"
                ? "Enterprise"
                : plan.num_executions}
            </div>
            <div
              className={styles.plan_checkbox}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentStatus(plan);
              }}
            >
              {initialPlanName === plan.plan_name ? (
                initialPlanName === currentPlan ? (
                  tenureCheck ? (
                    <SelectedIcon />
                  ) : (
                    <GetSelectIcon planState={planChangeState} />
                  )
                ) : (
                  <SelectIcon />
                )
              ) : currentPlan === plan.plan_name ? (
                <CheckCircle planState={planChangeState} />
              ) : downgradeInfo &&
                downgradeInfo.tenure === currentTenure &&
                downgradeInfo.plan_name === plan.plan_name ? (
                <DowngradeInfoTooltip
                  endDate={downgradeInfo.current_subscription_end_date}
                  planName={downgradeInfo.plan_display_name}
                  cancelPlanDowngradeMutation={cancelPlanDowngradeMutation}
                />
              ) : hoveredPlan && hoveredPlan.plan_name === plan.plan_name ? (
                <CheckCircle planState="default" />
              ) : (
                <SmallLine
                  planState={
                    (plan.num_executions < initialExecutions &&
                      plan.num_executions < currentExecutions) ||
                    (plan.num_executions > initialExecutions &&
                      plan.num_executions < currentExecutions)
                      ? "upgrade"
                      : plan.num_executions > currentExecutions &&
                        plan.num_executions < initialExecutions
                      ? "downgrade"
                      : ""
                  }
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SliderSelect = ({
  initialPlanName,
  initialTenure,
  currentPlan,
  setPlanName,
  planChangeState,
  setPlanChangeState,
  setShowSuccessMessage,
  planToEditionMap,
  downgradeInfo,
  cancelPlanDowngradeMutation,
}) => {
  const [showBuyPlanMessage, setShowBuyPlanMessage] = useState(false);
  const [showInfo, setShowInfo] = useState(undefined);
  const [donwgradable, setDonwgradable] = useState(false);
  const [currentTenure, setCurrentTenure] = useState(initialTenure);
  const checkoutSessionRedirectMutation = useCheckoutSessionRedirectMutation();
  const downgradePlanMutation = useDowngradePlanMutation(
    setShowSuccessMessage,
    currentTenure,
    setPlanChangeState
  );
  const [executionCount, setExecutionCount] = useState(100);
  const [initialexecutionCount, setInitialExecutionCount] = useState(100);
  const [initialPrice, setInitialPrice] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [plans, setPlans] = useState([]);
  const { show } = useIntercom();

  const {
    data: initialPlans,
    isLoading,
    error,
  } = useQuery(
    [GET_ALL_SUBSCRIPTION_PLANS, currentTenure],
    () => getAllPlans(currentTenure),
    {
      onSuccess: (data) => {
        setPlans(data.plans);
        setInitialExecutionCount(data.current_execuiton_count);
        setExecutionCount(data.current_execuiton_count);
        setInitialPrice(`$${data.current_price}`);
      },
    }
  );

  const setCurrentStatus = (currPlan) => {
    let inf = {
      message: "",
      type: "",
    };
    setShowBuyPlanMessage(false);
    setDonwgradable(false);
    setPlanName(currPlan.plan_name);
    setCurrentPrice(`$${currPlan.price}`);
    setExecutionCount(currPlan.num_executions);
    if (currPlan.plan_name === "free100" && initialPlanName !== "free100") {
      setPlanChangeState("downgrade");
      inf.message = InfoMessages.cancelSubscription;
      inf.type = "warning";
    } else if (
      initialexecutionCount === currPlan.num_executions &&
      initialPlans &&
      currentTenure === initialPlans.current_tenure
    ) {
      setPlanChangeState("");
      inf = undefined;
    } else if (
      Number(initialPrice.substring(1)) * (initialTenure === "year" ? 12 : 1) >
      currPlan.price * (currentTenure === "year" ? 12 : 1)
    ) {
      setPlanChangeState("downgrade");
      inf.message = InfoMessages.downgradeSubscription;
      inf.type = "warning";
    } else {
      setPlanChangeState("upgrade");
      inf.message =
        initialPlanName === "free100"
          ? InfoMessages.upgradeSubscription
          : InfoMessages.upgradeSubscriptionProration;
      inf.type = "info_1";
    }
    setShowInfo(inf);
  };

  const setTenure = (tenure) => {
    setCurrentTenure(tenure);
    setPlanChangeState("");
    setShowInfo(undefined);
    setPlanName(initialPlanName);
    const initialCount = plans.find(
      (plan) => plan.plan_name === initialPlanName
    );
    if (initialCount) {
      setExecutionCount(initialCount.num_executions);
    }
  };

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      {isLoading && <ComponentLoader />}
      {plans && plans.length > 0 && (
        <div className={styles.purchase}>
          <div className={styles.top_bar}>
            <SelectTenure currentTenure={currentTenure} setTenure={setTenure} />
            <div className={styles.head_tags}>
              <CheckFAQ numOfSecondsActive={5} />
              <EditionTag editionName={planToEditionMap[currentPlan]} />
            </div>
          </div>
          <Slider
            plans={plans}
            currentPlan={currentPlan}
            initialPlanName={initialPlanName}
            setCurrentStatus={setCurrentStatus}
            initialExecutions={initialexecutionCount}
            currentExecutions={executionCount}
            planChangeState={planChangeState}
            tenureCheck={initialTenure === currentTenure}
            downgradeInfo={downgradeInfo}
            currentTenure={currentTenure}
            cancelPlanDowngradeMutation={cancelPlanDowngradeMutation}
          />
          <div className={styles.slide_bottom_container}>
            <div className={styles.plans_info}>
              <div className={styles.plan_info}>
                <div className="font-500">
                  Current Subscription: ({initialTenure})
                </div>
                <div className="font-400">
                  {initialexecutionCount === "Custom"
                    ? "More credits per month"
                    : `${initialexecutionCount} Credits for ${initialPrice} per month`}
                </div>
              </div>
              {planChangeState !== "" && (
                <div
                  className={classNames(styles.plan_info, {
                    [styles.plan_upgrade]: planChangeState === "upgrade",
                    [styles.plan_downgrade]: planChangeState === "downgrade",
                  })}
                >
                  <div className="font-500">
                    New Subscription: ({currentTenure})
                  </div>
                  <div
                    className={classNames(styles.plan_cjange_text, "font-400")}
                  >
                    {executionCount === "Custom"
                      ? "More credits per month"
                      : `${executionCount} Credits for ${currentPrice} per month`}
                  </div>
                </div>
              )}
            </div>
            {currentPlan === "Custom" ||
            (initialPlans && !initialPlans.subscription_management_allowed) ? (
              <Button className={styles.button} onClick={show}>
                <div className={styles.text}>Contact Us!</div>
              </Button>
            ) : (
              <>
                {planChangeState === "" && downgradeInfo && (
                  <Button
                    className={styles.button}
                    onClick={() => cancelPlanDowngradeMutation.mutate()}
                  >
                    <div className={styles.text}>Cancel Downgrade</div>
                  </Button>
                )}
                {planChangeState === "downgrade" && (
                  <Button
                    className={classNames(styles.button, {
                      [styles.button_red]: donwgradable,
                      [styles.button_disabled]: !donwgradable,
                    })}
                    onClick={() => {
                      if (donwgradable)
                        downgradePlanMutation.mutate({
                          creditType: currentPlan,
                          tenure: currentTenure,
                        });
                    }}
                  >
                    <div className={styles.text}>Downgrade Plan</div>
                  </Button>
                )}
                {(planChangeState === "upgrade" ||
                  (!downgradeInfo && planChangeState !== "downgrade")) && (
                  <Button
                    className={styles.button}
                    onClick={() => {
                      if (
                        initialPlanName === currentPlan &&
                        (initialTenure === currentTenure ||
                          initialPlanName === "free100")
                      )
                        setShowBuyPlanMessage(true);
                      else
                        return checkoutSessionRedirectMutation.mutate({
                          creditType: currentPlan,
                          tenure: currentTenure,
                        });
                    }}
                  >
                    <div className={styles.text}>Buy Subscription</div>
                  </Button>
                )}
              </>
            )}
          </div>
          {(downgradePlanMutation.isLoading ||
            checkoutSessionRedirectMutation.isLoading ||
            cancelPlanDowngradeMutation.isLoading) && <ComponentLoader />}
          {showBuyPlanMessage && (
            <InfoTooltip
              handleSuccessMessageClose={() => setShowBuyPlanMessage(false)}
              message="Move the slider above to select the credit count for a new subscription."
              type="info"
              showCloseButton={true}
              pageMessage={false}
            />
          )}
          {initialPlans && !initialPlans.subscription_management_allowed && (
            <InfoTooltip
              handleSuccessMessageClose={() => setShowInfo(undefined)}
              message="Only tenant creator can manage the subscriptions. Please contact us if you need any help."
              type="warning"
              showCloseButton={false}
              pageMessage={false}
            />
          )}
          {showInfo && (
            <InfoTooltip
              handleSuccessMessageClose={() => setShowInfo(undefined)}
              {...showInfo}
              pageMessage={false}
              showCloseButton={false}
            />
          )}
          {planChangeState === "downgrade" && !donwgradable && (
            <DowngradeForm setDonwgradable={setDonwgradable} />
          )}
        </div>
      )}
    </>
  );
};

export default SliderSelect;
