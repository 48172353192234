import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useState } from "react";
import classes from "./tile.module.scss";
import { ArrowDownIcon, ArrowUpIcon, InfoIcon } from "@assets/icons";
import IconButton from "../iconButton/IconButton";
import Stack from "../stack/Stack";
const ColorTile = ({ color = "#ffffff", children, title, value, onClick, helpText, className, }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const shouldClick = !!children;
    const handleClick = () => {
        onClick === null || onClick === void 0 ? void 0 : onClick();
        setIsExpanded((prev) => !prev);
    };
    return (_jsxs("div", { className: classNames(classes.tile, className, {
            "cursor-pointer": shouldClick,
            [classes.visible]: isExpanded,
        }), style: { background: color }, onClick: handleClick, children: [_jsxs("div", { className: classes.title, children: [title, _jsxs(Stack, { style: { gap: 5 }, children: [helpText && (_jsx(IconButton, { className: "p-0", title: helpText, children: _jsx(InfoIcon, {}) })), shouldClick && (_jsx("div", { className: classes.expand_btn, children: _jsx(IconButton, { className: "p-0", children: isExpanded ? _jsx(ArrowUpIcon, {}) : _jsx(ArrowDownIcon, {}) }) }))] })] }), _jsx("div", { className: classes.value, children: value }), children ? (_jsx("div", { className: classes.children, style: { color }, children: children })) : null] }));
};
export default ColorTile;
