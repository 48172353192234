import { CodeSQLEditor } from "../../Components/CodeEditor";
import styles from "./styles.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getQueryGroupDetail,
  getQueryGroupLatestQuery,
} from "../../helpers/backend_helper";
import { Feedback } from "../../Components/Feedback";
import { Alert, Button, Card, CardBody, Input } from "reactstrap";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  QueryExplanation,
  InsightSection,
  SideContent,
  QuerySqlLineage,
} from "./components";
import classNames from "classnames";
import QueryInsightsRobot from "@/assets/images/query_insights_robot.png";
import Typewriter from "typewriter-effect";
import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/arrow_left.svg";
import { TypeWriterLoader } from "@/Components/Loader";
import { getQueryGroupSqlLineageById } from "@/helpers/apis";
import * as Sentry from "@sentry/browser";
import { QueryListWithFilter } from "./Query";
import { Paginate } from "../../Components/Paginate";
import { TableHeader } from "./QueryList";
import {
  getAllQueryGroupCSVFile,
  getAllQueryGroups,
  getQueryFilters,
  updateQueryGroupName,
} from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import {
  GET_ALL_QUERY_GROUPS,
  GET_QUERY_FILTERS,
  GET_QUERY_GROUP_BY_ID,
  QUERY_TEXT_API_KEY,
  QueryGroupTabs,
} from "./constants";
import {
  ColorInsightTags,
  ColorTag,
  FilterTagWrapper,
  Tags,
} from "../../Components/Tags";
import SidebarModal from "../../Components/SidebarModal";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/datetime.svg";
import { useCopilot } from "../Copilot";
import { useCopilotActions } from "../Copilot/useCopilotActions";
import { addSearchParams, removeSearchParams } from "../../helpers/utils";
import { DateRange, StaticDateRangeKeys } from "../../Components/DateRange";
import dayjs from "dayjs";
import QueryTextWithHover from "./QueryText";
import InsightLabel from "./InsightLabel";
import { GhostTab, Tab } from "../../Components/Tab/Tab";
import { QueryGroup } from "./types";
import { getQueryTextByHash } from "@/helpers/apis";
import { Select } from "@/uiCore";
import { PERIOD_OPTIONS } from "../Explorer/constants";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { useDateFromSearchParams } from "@/helpers/useTimeHooks";

const GroupSelectedHeader = ({
  queryHash,
  trimmedQuery,
  metrics,
  close,
  tags,
  query_name,
}) => {
  const [isNameEditing, setIsNameEditing] = useState(false);
  const queryClient = useQueryClient();
  const [groupName, setGroupName] = useState(trimmedQuery);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate } = useMutation({
    mutationFn: (data) => updateQueryGroupName(queryHash, data),
    onSuccess: (data) => {
      if (data.ok) {
        setIsNameEditing(false);
        queryClient.invalidateQueries({ queryKey: [GET_QUERY_GROUP_BY_ID] });
      }
    },
  });
  return (
    <div className="bg-blue-light p-3 border-radius-top d-flex align-items-start gap-sm">
      <div
        className="p-1 br-2 bg-blue text-white cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          navigate("/query/groups");
        }}
      >
        <ArrowLeftIcon className="icon-sm" />
      </div>
      <div className="d-flex flex-column gap-sm w-100">
        <div className="d-flex gap-xs align-items-center">
          {!isNameEditing ? (
            <>
              <div className="text-primary">{groupName}</div>
              <EditIcon
                className={classNames("cursor-pointer", styles.edit_icon, {
                  [styles.active]: isNameEditing,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isNameEditing) {
                    setIsNameEditing(false);
                  } else {
                    setIsNameEditing(true);
                  }
                }}
              />
            </>
          ) : (
            <>
              <Input
                disabled={!isNameEditing}
                rows={1}
                className={styles.name_input}
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                placeholder={trimmedQuery}
              />
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  mutate({ group_name: groupName });
                }}
              >
                Save
              </Button>
              <Button outline onClick={(e) => setIsNameEditing(false)}>
                Cancel
              </Button>
            </>
          )}
          <div className="spacer" />
          {/* <Button
            size="sm"
            onClick={() =>
              navigate("/experimentation/new", {
                state: { queryHash, name: query_name },
              })
            }
          >
            Start experiments
          </Button> */}
        </div>
        <div className="d-flex flex-wrap">
          {metrics.map((item) => (
            <div key={item.label} className="me-4">
              <span className="text-muted">{item.label}: </span>
              <span>{item.value}</span>
            </div>
          ))}
        </div>
        <ColorInsightTags tags={tags} />
      </div>
    </div>
  );
};

const GroupTabs = ({
  selectedGroup,
  sqlLineage,
  queryHash,
  query,
  parentEditorRef,
  selectedInsightId,
  setSelectedInsightId,
  setShowSideContent,
  setSelectedNode,
  selectedInsight,
  showSideContent,
}) => {
  const [selectedTab, setSelectedTab] = useState(QueryGroupTabs.Opportunities);
  const [selectedOpportunityTab, setSelectedOpportunityTab] =
    useState("sql-visualizer");
  const editorRef = useRef(null);

  const navigate = useNavigate();
  const { startDate, endDate } = useDateFromSearchParams(
    dayjs().subtract(28, "day").toDate(),
    dayjs().toDate()
  );
  const tabs = [
    { label: "SQL Visualizer", value: "sql-visualizer" },
    { label: "Code", value: "code" },
  ];

  const onTabChange = (value) => {
    setSelectedOpportunityTab(value);
  };
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className={`bg-white pt-4 ${styles.groupTabs}`}>
      <Tab
        onSelectedTabChange={(v) => {
          setSelectedTab(v);
          if (v === QueryGroupTabs.Queries) setShowSideContent(false);
        }}
        tabs={[{ label: "Opportunities" }, { label: "Queries" }]}
      />

      {selectedTab === QueryGroupTabs.Opportunities ? (
        <div className="p-2 pt-0">
          <div className="d-inline-block mb-3">
            <GhostTab
              setSelectedTab={onTabChange}
              tabs={tabs}
              selectedTab={selectedOpportunityTab}
            />
          </div>
          <div className="d-flex flex-column gap-sm">
            {selectedOpportunityTab === "sql-visualizer" ? (
              <QuerySqlLineage
                sqlLineage={sqlLineage}
                selectedInsight={selectedInsight}
                setSelectedNode={setSelectedNode}
                setShowSideContent={setShowSideContent}
                showSideContent={showSideContent}
                setSelectedInsight={setSelectedInsightId}
              />
            ) : null}

            {selectedOpportunityTab === "code" && (
              <CodeSQLEditor
                ref={parentEditorRef}
                value={query.query_text}
                height="36vh"
                lineNumbers={true}
                minimap
              />
            )}
            <QueryExplanation queryHash={queryHash} />
            <div>
              <div className="d-flex m-2">
                <div className="fs-4">Opportunities</div>
                <div className="spacer" />
                <DateRange
                  startDate={startDate}
                  endDate={endDate}
                  availableStaticRanges={[
                    "Last day",
                    "Last 7 days",
                    "Last 28 days",
                  ]}
                  disableCalendarSelection
                  onDateRangeSelect={(s, e) => {
                    setSearchParams((prev) => {
                      const params = new URLSearchParams(prev);
                      params.set("start_date", s?.toISOString());
                      params.set("end_date", e?.toISOString());
                      return params.toString();
                    });
                  }}
                />
              </div>
              <InsightSection
                opportunities={sqlLineage?.tags}
                totalRuns={selectedGroup.total_run}
                selectedOpportunityId={
                  sqlLineage?.tags?.find((t) => t.id === selectedInsightId)
                    ?.opportunity_id || searchParams.get("opportunityId")
                }
                setOpportunityId={(item) => {
                  if (!sqlLineage) return;
                  parentEditorRef.current?.onParentResize();
                  const insightId = sqlLineage?.tags?.find(
                    (t) => t.opportunity_id === item
                  )?.id;
                  setShowSideContent(true);
                  if (!insightId) {
                    setSelectedNode("");
                    document.dispatchEvent(
                      new CustomEvent("onSelectedNodes", { detail: "" })
                    );
                    setSearchParams({ opportunityId: item });
                    return;
                  }
                  setSelectedInsightId(insightId);
                  for (const key in sqlLineage.query_tags_meta) {
                    for (const t of sqlLineage.query_tags_meta[key] || []) {
                      if (insightId === t.id) {
                        setSelectedNode(key);
                        document.dispatchEvent(
                          new CustomEvent("onSelectedNodes", { detail: key })
                        );
                        return;
                      }
                    }
                  }
                  setSelectedNode("");
                  document.dispatchEvent(
                    new CustomEvent("onSelectedNodes", { detail: "" })
                  );
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {selectedTab === QueryGroupTabs.Queries ? (
        <QueryListWithFilter
          setSelectedQuery={(v) =>
            navigate(
              `/query/${v}?from=/query/groups/${selectedGroup.query_hash}`
            )
          }
          queryHash={selectedGroup.query_hash}
        />
      ) : null}
    </div>
  );
};

const MainContent = ({
  groupDetails,
  queryHash,
  query,
  selectedInsight,
  sqlLineage,
  setSelectedNode,
  parentEditorRef,
  setSelectedTab,
  setShowSideContent,
  showSideContent,
}) => {
  const [fromPage, setFromPage] = useState("/query/groups");
  const location = useLocation();
  useEffect(() => {
    if (location.state?.from) setFromPage(location.state.from);
  }, []);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedInsightId = searchParams.get("selectedInsightId");
  const setSelectedInsightId = (v) =>
    setSearchParams((prev) => ({ ...prev, selectedInsightId: v }));

  useEffect(() => {
    if (!selectedInsightId) return;
    for (const key in sqlLineage.query_tags_meta) {
      for (const t of sqlLineage.query_tags_meta[key] || []) {
        if (selectedInsight === t.tag_rk) {
          setSelectedNode(key);
          document.dispatchEvent(
            new CustomEvent("onSelectedNodes", { detail: key })
          );
          setShowSideContent(true);
          return;
        }
      }
    }
  }, []);

  const queryFilters = {
    tags: searchParams.getAll("tags") || [],
    executionTime: searchParams.get("executionTime"),
    queryCost: searchParams.get("queryCost"),
    warehouses: searchParams.getAll("warehouses") || [],
    users: searchParams.getAll("users") || [],
    queryHash: searchParams.get("queryHash") || "",
    queryParameterizedHash: searchParams.get("queryParameterizedHash") || "",
    sessionID: searchParams.get("sessionID") || "",
  };

  return (
    <div className={classNames("d-flex flex-column", styles.query_details)}>
      <GroupSelectedHeader
        trimmedQuery={groupDetails.trimmed_query}
        tags={groupDetails.tags}
        metrics={[
          { label: "Total run", value: groupDetails.total_run },
          {
            label: "Average execution time",
            value: groupDetails.avg_exec_time,
          },
          {
            label: "Average cost",
            value: groupDetails.avg_cost,
          },
          {
            label: "Total cost",
            value: groupDetails.total_cost,
          },
          {
            label: "Annualized cost",
            value: groupDetails.annualized_cost,
          },
          {
            label: "Distinct warehouses",
            value: groupDetails.distinct_warehouses,
          },
          { label: "Distinct users", value: groupDetails.distinct_users },
        ]}
        queryHash={groupDetails.query_hash}
        query_name={groupDetails.query_name}
      />
      <GroupTabs
        selectedGroup={groupDetails}
        sqlLineage={sqlLineage}
        queryHash={groupDetails.query_hash}
        query={query}
        parentEditorRef={parentEditorRef}
        selectedInsightId={selectedInsightId}
        setSelectedInsightId={setSelectedInsightId}
        setShowSideContent={setShowSideContent}
        setSelectedNode={setSelectedNode}
        selectedInsight={selectedInsight}
        showSideContent={showSideContent}
      />

      <div className="divider w-100" />
      <div className="mt-3 mb-3 ms-4 me-4">
        <Feedback feedback_id={queryHash} feedback_type="query_tag" />
      </div>
    </div>
  );
};

const GroupDetails = () => {
  const { queryHash } = useParams();

  const editorRef = useRef(null);
  const [selectedNode, setSelectedNode] = useState("");
  const [selectedTab, setSelectedTab] = useState("SQL Visualizer");
  const [showSideContent, setShowSideContent] = useState(false);
  const [searchParams] = useSearchParams();

  const { startDate, endDate } = useDateFromSearchParams(
    dayjs().subtract(28, "day").toDate(),
    dayjs().toDate()
  );

  const { data: querydetails, isLoading } = useQuery({
    queryKey: ["GET_QUERY_GROUP_LATEST_QUERY", queryHash],
    queryFn: () => getQueryGroupLatestQuery(queryHash),
  });
  const { data: sqlLineage, isLoading: isSqlLineageLoading } = useQuery({
    queryKey: [
      "GET_QUERY_GROUP_SQL_LINEAGE_BY_ID",
      queryHash,
      startDate,
      endDate,
    ],
    queryFn: () =>
      getQueryGroupSqlLineageById(queryHash, {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
        end_date: getDateByEndOfDayWithoutTimeoffset(endDate).toISOString(),
      }),
    retry: false,
    onError: (err) => {
      if (err === "canceled") {
        Sentry.captureException(new Error("SQL Lineage timeout"));
      }
    },
  });
  const {
    data: groupDetails,
    isLoading: isGrouploading,
    error,
  } = useQuery({
    queryKey: [GET_QUERY_GROUP_BY_ID, queryHash, startDate, endDate],
    queryFn: () =>
      getQueryGroupDetail(queryHash, {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
        end_date: getDateByEndOfDayWithoutTimeoffset(endDate).toISOString(),
      }),
  });

  const selectedInsightId = searchParams.get("selectedInsightId");
  const selectedInsight = useMemo(
    () =>
      sqlLineage?.tags?.find((t) => t.id === selectedInsightId)?.tag_rk || "",
    [selectedInsightId, sqlLineage?.tags]
  );

  if (isLoading || isSqlLineageLoading || isGrouploading) {
    return (
      <TypeWriterLoader
        label="Analyzing query might take a few seconds"
        loadingTexts={[
          "Looking at query metadata",
          "Analyzing query pattern",
          "Fetching query insights",
        ]}
      />
    );
  }
  const { query } = querydetails;

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  if (!groupDetails) {
    return <Alert color="danger">No data for query group found</Alert>;
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: showSideContent ? "2fr 1fr" : "1fr",
        gap: "1rem",
      }}
    >
      <MainContent
        queryHash={queryHash}
        groupDetails={groupDetails}
        parentEditorRef={editorRef}
        query={query}
        selectedInsight={selectedInsight}
        setSelectedNode={setSelectedNode}
        sqlLineage={sqlLineage}
        setSelectedTab={setSelectedTab}
        setShowSideContent={setShowSideContent}
        showSideContent={showSideContent}
      />
      {showSideContent && (
        <SideContent
          selectedNode={selectedNode}
          query={query.query_text}
          sqlLineage={sqlLineage}
          parentEditorRef={editorRef}
          selectedTab={selectedTab}
          setShowSideContent={setShowSideContent}
          opportunities={sqlLineage?.tags}
        />
      )}
    </div>
  );
};

export { GroupDetails };
