import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Dropdown, Stack } from "@uicore";
import classes from "../summary.module.scss";
import CostBreakdown from "./CostBreakdown";
import SavingsBreakdown from "./SavingsBreakdown";
import dayjs from "dayjs";
import { useState } from "react";
import { ClockBlackIcon } from "@/assets/icons";
const FutureTimeRanges = [
    // "Next 1 month",
    // "Next 3 months",
    "This year",
    // "End-of-contract",
];
const FutureAnalysis = () => {
    const currentStartDate = dayjs().startOf("year").toDate();
    const [currentEndDate, setCurrentEndDate] = useState(dayjs().endOf("year").toDate());
    const [selectedTimerange, setAggregateBy] = useState(FutureTimeRanges[0]);
    const handleItemClick = (value) => {
        setAggregateBy(value);
        if (value === "Next 1 month") {
            setCurrentEndDate(dayjs().add(1, "month").toDate());
        }
        switch (value) {
            case "Next 1 month":
                setCurrentEndDate(dayjs().add(1, "month").toDate());
                break;
            case "Next 3 months":
                setCurrentEndDate(dayjs().add(3, "month").toDate());
                break;
            case "This year":
                setCurrentEndDate(dayjs().endOf("year").toDate());
                break;
            case "End-of-contract":
                setCurrentEndDate(null);
                break;
            default:
                break;
        }
    };
    return (_jsxs(Card, { className: classes.past_analysis, id: "future", children: [_jsxs(Stack, { className: "justify-content-between mb-2", children: [_jsx("h3", { className: "text-black", children: "Future State" }), _jsx(Dropdown, { value: selectedTimerange, onOptionSelect: handleItemClick, prefix: _jsx(ClockBlackIcon, {}), options: FutureTimeRanges.map((value) => ({
                            label: value,
                            value,
                        })) })] }), _jsx("p", { className: classes.caption, children: "This section shows your projected costs and savings for the specified time period" }), _jsx(CostBreakdown, { isFuture: true, currentEndDate: currentEndDate, currentStartDate: currentStartDate }), _jsx(SavingsBreakdown, { isFuture: true, currentEndDate: currentEndDate, currentStartDate: currentStartDate })] }));
};
export default FutureAnalysis;
