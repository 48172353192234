var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from "../../";
import classes from "./styles.module.scss";
const IconButton = (_a) => {
    var _b, _c;
    var { title } = _a, props = __rest(_a, ["title"]);
    return (_jsx(Tooltip, { content: title, placement: "top", children: _jsx("button", Object.assign({}, props, { className: `btn ${props.color ? `btn-${props.color}` : ""} ${(_b = props.className) !== null && _b !== void 0 ? _b : ""} ${classes.iconButton}`, type: (_c = props.type) !== null && _c !== void 0 ? _c : "button", children: props.children })) }));
};
export default IconButton;
