import { Form, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormAlert, FormPassword, FormSubmit } from "./components/form";
import { BaseContainer, Header } from "./components/base";
import { isFormFieldError, passwordValidator } from "./helper";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { useMutation } from "react-query";
import { postResetPassword } from "../../helpers/backend_helper";
import { useState } from "react";
import classNames from "classnames";
import styles from "./components/styles.module.scss";
import { ReactComponent as DangerCircleCheck } from "../../assets/icons/circle_check_danger.svg";
import { ReactComponent as SuccessCircleCheck } from "../../assets/icons/circle_check_enabled.svg";
import { ReactComponent as DisabledCircleCheck } from "../../assets/icons/circle_check_disabled.svg";
import { validateFormPassword } from "./helper";

const PASSWORD_INPUT_ID = "free-user-password-input";

const Reset = () => {
  const navigate = useNavigate();
  const [validatePassword, setValidatePassword] = useState([0, 0, 0, 0]);
  const { token } = useParams();
  const decodedToken = decodeToken(token);
  const { mutate, error } = useMutation(postResetPassword, {
    onSuccess: (data) => {
      if (data.ok) {
        navigate("/login");
      }
    },
  });

  const passwordValidations = [
    "Use at least 8 characters",
    "Use a mix of upper and lower case characters",
    "Use 1 or more numbers",
    "Use 1 or more special characters",
  ];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: passwordValidator,
    }),
    onSubmit: (values) => mutate({ ...values, token }),
  });

  if (decodedToken && decodedToken.exp < Date.now() / 1000) {
    const state = { inviteDate: decodedToken.nbf };
    return <Navigate to="/expired" state={state} />;
  }

  if (!decodedToken) {
    return <Navigate to="/expired" />;
  }

  const isFormDisabled = () => {
    if (validatePassword.includes(-1) || validatePassword.includes(0))
      return true;

    return false;
  };

  const showFormFieldError = isFormFieldError(validation);
  const _error = error || showFormFieldError("password");

  return (
    <BaseContainer>
      <Header>Set New Password</Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
        className="mt-3"
      >
        <div className="mb-3">
          <Label htmlFor="password" className="form-label">
            New Password
          </Label>
          <div
            className={classNames(styles.tenant_form_password, {
              [styles.in_focus]:
                document.activeElement.id === PASSWORD_INPUT_ID ||
                validatePassword.includes(-1),
              [styles.is_invalid]: validatePassword.includes(-1),
            })}
          >
            <FormPassword
              id={PASSWORD_INPUT_ID}
              name="password"
              placeholder="Password"
              onBlur={() => {
                setValidatePassword((prev) =>
                  prev.map((element) => (element === 0 ? -1 : element))
                );
              }}
              onChange={(e) => {
                // Update Formik's state
                validation.handleChange(e);
                // Then validate the password
                validateFormPassword(e.target.value, setValidatePassword);
              }}
              disabled={validation.isSubmitting}
            />
          </div>
          {(document.activeElement.id === PASSWORD_INPUT_ID ||
            validatePassword.includes(-1)) && (
            <div
              className={classNames(styles.form_feedback, {
                [styles.is_error]: validatePassword.includes(-1),
                [styles.is_success]:
                  !validatePassword.includes(-1) &&
                  !validatePassword.includes(0),
              })}
            >
              <span className="text-black">Password Should</span>
              {validatePassword.map((val, idx) => (
                <span
                  key={idx}
                  className={
                    val === 0
                      ? "text-muted"
                      : val === 1
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {val === 0 ? (
                    <DisabledCircleCheck />
                  ) : val === 1 ? (
                    <SuccessCircleCheck />
                  ) : (
                    <DangerCircleCheck />
                  )}
                  {passwordValidations[idx]}
                </span>
              ))}
            </div>
          )}
        </div>

        <FormAlert error={_error} />

        <div className="mt-4">
          <FormSubmit disabled={isFormDisabled()}>Change Password</FormSubmit>
        </div>
      </Form>
    </BaseContainer>
  );
};

export { Reset };
