import { Button, Card, CardBody, Form, Input, Label } from "reactstrap";
import styles from "./styles.module.scss";
import { useCallback, useState } from "react";
import { ReactComponent as SaveIcon } from "@/assets/icons/save.svg";
import { ReactComponent as CheckIcon } from "@/assets/icons/check.svg";
import NoChannelImage from "@/assets/images/no_channel.svg";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getSlackWebhook,
  postSlackWebhook,
} from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import { debounce } from "lodash";
import { INTERACTION_TIME } from "../../helpers/constants";
import { DisplayTable } from "../../Components/Tables";
import { FormInput } from "../Authentication/components/form";
import { useNavigate } from "react-router-dom";

const GET_NOTIFICATION = "GET-NOTIFICATION";

const NoChannels = ({ toggleAddNotificationChannel }) => {
  return (
    <div className={styles.no_channel}>
      <img src={NoChannelImage} alt="no_channel" />
      <div className={styles.title}>No notification channel found</div>
      <div className={styles.subtitle}>Add channel for notification</div>
      <Button className={styles.button} onClick={toggleAddNotificationChannel}>
        Add notification channel
      </Button>
    </div>
  );
};

const NotificationChannels = ({ toggleAddNotificationChannel }) => {
  const [channels, setChannels] = useState([]);

  const { isLoading } = useQuery({
    queryKey: [GET_NOTIFICATION],
    queryFn: getSlackWebhook,
    onSuccess: (res) => {
      setChannels(
        res["notifications"].map((n) => {
          return {
            id: n?.id,
            name: n.config?.name,
            type: n?.type,
            url: n?.config?.webhook_url,
          };
        })
      );
    },
  });

  const header = [
    { id: 1, label: "Name" },
    { id: 2, label: "Types" },
    { id: 3, label: "URL" },
  ];

  if (isLoading) return <ComponentLoader />;
  return (
    <div className={styles.channels}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className={styles.title}>Notification Channels</div>
        <Button
          className={styles.button}
          onClick={toggleAddNotificationChannel}
        >
          Add notification channel
        </Button>
      </div>
      {channels.length === 0 ? (
        <NoChannels
          toggleAddNotificationChannel={toggleAddNotificationChannel}
        />
      ) : (
        <DisplayTable
          header={header}
          items={channels}
          templateColumns={"20% 10% 65%"}
          rowRender={(i) => {
            return (
              <>
                <div className={styles.element_overflow}>{i.name}</div>
                <div className={styles.element_overflow}>{i.type}</div>
                <div className={styles.element_overflow}>{i.url}</div>
              </>
            );
          }}
        />
      )}
    </div>
  );
};

const AddNotificationChannel = ({ toggleAddNotificationChannel }) => {
  const navigate = useNavigate();
  const [channelName, setChannelName] = useState("");
  const [url, setURL] = useState("");

  const queryClient = useQueryClient();

  const label = channelName && url;

  const { mutate } = useMutation({
    mutationFn: postSlackWebhook,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [GET_NOTIFICATION] });
      toggleAddNotificationChannel();
    },
  });

  return (
    <div className={styles.add_notification}>
      <div className={styles.title}>Add Notification Channel</div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          mutate({
            webhook_url: url,
            name: channelName,
          });
        }}
        className={styles.form}
      >
        <div className="mb-3">
          <Label className="form-label" htmlFor="name">
            Name
          </Label>
          <FormInput
            id="name"
            name="name"
            type="name"
            placeholder="Enter slack channel name"
            onChange={(e) => setChannelName(e.target.value)}
            value={channelName}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="type">
            Type
          </Label>
          <FormInput
            id="type"
            name="type"
            type="name"
            placeholder="slack"
            disabled
            value={"slack"}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="name">
            Webhook URL
          </Label>
          <FormInput
            id="url"
            name="url"
            type="url"
            placeholder="Paste the webhook url here"
            onChange={(e) => setURL(e.target.value)}
            value={url}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            className={styles.cancel_button}
            outline
            onClick={toggleAddNotificationChannel}
          >
            <span className={styles.text}>Cancel</span>
          </Button>
          <Button
            type="submit"
            className={styles.save_button}
            disabled={!label}
          >
            <span className={styles.text}>Save</span>
          </Button>
        </div>
      </Form>
    </div>
  );
};

const Notification = () => {
  const [showAddNotificationChannel, setShowAddNotificationChannel] =
    useState(false);

  const toggleAddNotificationChannel = () => {
    setShowAddNotificationChannel((prev) => !prev);
  };

  return (
    <>
      {showAddNotificationChannel ? (
        <AddNotificationChannel
          toggleAddNotificationChannel={toggleAddNotificationChannel}
        />
      ) : (
        <NotificationChannels
          toggleAddNotificationChannel={toggleAddNotificationChannel}
        />
      )}
    </>
  );
};

export { Notification };
