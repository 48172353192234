import { useState } from "react";
import styles from "./styles.module.scss";
import { Dropdown } from "../../Components/Form";
import { capitalize } from "lodash";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { Button, Input } from "reactstrap";
import { useQuery } from "react-query";
import { getMaskData, setMaskData } from "../../helpers/backend_helper";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import SidebarModal from "../../Components/SidebarModal";
import EditSquareIcon from "../../assets/icons/edit_square_security.svg";
import { getUser } from "../../services/auth";
import { PermissionsEnum } from "../../helpers/constants";

const GET_MASK_DATA = "GET-MASK-DATA";
const Security = () => {
  const user = getUser();
  const navigate = useNavigate();
  const [maskType, setMaskType] = useState({});
  const { data: maskData, isLoading } = useQuery({
    queryKey: [GET_MASK_DATA],
    queryFn: () => getMaskData(),
    onSuccess: (res) => {
      let d = {};
      res?.mask_data.map((item) => {
        d[item.label] = item.masking_type;
      });
      setMaskType(d);
    },
  });
  const [temp, setTemp] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [label, setLabel] = useState("");
  const [defaultVal, setDefaultVal] = useState("");
  const [customMaskType, setCustomMaskType] = useState("allow");
  const [regex, setRegex] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editTitle, setEditTitle] = useState(null);

  const { mutate: setMask, isLoading: isSetMaskLoading } = useMutation(
    (data) => setMaskData(data),
    {
      onSuccess: (res) => {},
    }
  );

  const { mutate: setMaskWithRefresh } = useMutation(
    (data) => setMaskData(data),
    {
      onSuccess: (res) => {
        navigate(0);
      },
    }
  );

  const handleMaskTypeChange = (e, i) => {
    let d = maskType;
    d[i] = e;
    setMaskType(d);
    maskData?.mask_data.map((item) => {
      item.masking_type = d[item.label];
    });

    setMask(maskData);
    // To rerender
    setTemp(!temp);
  };

  const toggleSidebar = () => {
    if (showSidebar) {
      setEditMode(false);
      setEditTitle(null);
      setLabel("");
      setRegex("");
      setCustomMaskType("");
      setDefaultVal("");
    }
    setShowSidebar((prev) => !prev);
  };

  const handleLabel = (e) => {
    setLabel(e.target.value);
  };

  const handleCustomMaskTypeChange = (e) => {
    setCustomMaskType(e);
  };
  const handleRegex = (e) => {
    setRegex(e.target.value);
  };
  const handleDefaultVal = (e) => {
    setDefaultVal(e.target.value);
  };

  const handleSave = () => {
    let customMaskTypeData = {
      label: label,
      masking_type: customMaskType,
    };
    if (regex) {
      customMaskTypeData["regex"] = regex;
    }
    if (defaultVal) {
      customMaskTypeData["default"] = defaultVal;
    }
    if (editMode) {
      maskData.mask_data = maskData?.mask_data.map((d) => {
        if (d.label !== editTitle) {
          return d;
        } else {
          return customMaskTypeData;
        }
      });
    } else {
      maskData?.mask_data.push(customMaskTypeData);
    }
    setMaskWithRefresh(maskData);
  };

  const handleDelete = () => {
    const filteredMaskData = maskData?.mask_data.filter(
      (item) => item.label !== label
    );
    maskData.mask_data = filteredMaskData;
    setMaskWithRefresh(maskData);
  };

  const handleAddButtonClick = () => {
    setEditMode(false);
    toggleSidebar();
  };

  const handleEditButtonClick = (item) => {
    setEditMode(true);
    setEditTitle(item.label);
    setLabel(item.label);
    setRegex(item.regex);
    setCustomMaskType(item.masking_type);
    setDefaultVal(item.default);
    toggleSidebar();
  };
  if (!user.permission.includes(PermissionsEnum.SECURITY_VIEW)) {
    return (<div>Not Enough Permissions to access this page</div>);
  }
  return (
    <div className={styles.security}>
      <div className={styles.security_options}>
        <div className={styles.heading}>Data Protection</div>
        {maskData?.mask_data.map((item, i) => {
          return (
            <div
              key={i}
              className={classNames(
                "d-flex align-items-center justify-content-between",
                styles.mask_items
              )}
            >
              <div
                className={styles.normal_text}
                onClick={() => handleEditButtonClick(item)}
              >
                {item.label}:
              </div>
              <div className="d-flex align-items-center justify-content-between gap-sm">
                <Dropdown
                  popoverProps={{ placement: "bottom", offset: [0, 0] }}
                  id={"mask-dropdown" + i}
                  onChange={(e) => {
                    handleMaskTypeChange(e, item.label);
                  }}
                  value={maskType[item.label]}
                  options={["allow", "block", "mask"].map((v) => ({
                    label: (
                      <div className={styles.dropdown_text}>
                        {capitalize(v)}
                      </div>
                    ),
                    value: v,
                  }))}
                  showDivider
                >
                  <div className={styles.mask_dropdown}>
                    {!maskType[item.label] ? (
                      <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className={styles.mask_dropdown_text}>Select</div>
                        <div className={styles.icon}>
                          <ArrowDown />
                        </div>
                      </div>
                    ) : (
                      <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className={styles.mask_dropdown_text}>
                          {capitalize(maskType[item.label])}
                        </div>
                        <div className={styles.icon}>
                          <ArrowDown />
                        </div>
                      </div>
                    )}
                  </div>
                </Dropdown>
                <div className={styles.edit_icon_container}>
                  <img
                    className={styles.edit_icon}
                    src={EditSquareIcon}
                    alt="edit_square"
                    onClick={() => handleEditButtonClick(item)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Button className={styles.normal_button} onClick={handleAddButtonClick}>
        Add
      </Button>
      <SidebarModal
        isOpen={showSidebar}
        toggleModal={toggleSidebar}
        width="446"
      >
        <div className={styles.gray_box}>
          {editMode ? (
            <div className={styles.title}>{editTitle}</div>
          ) : (
            <div className={styles.title}>Add New Entity</div>
          )}
          <div
            className={classNames(
              "d-flex justify-content-between align-items-center mt-4",
              styles.gray_items
            )}
          >
            <div className={styles.normal_text}>Entity:</div>
            <Input
              className={styles.input}
              placeholder={"Birthdate"}
              onChange={handleLabel}
              value={label}
            />
          </div>
          <div
            className={classNames(
              "d-flex justify-content-between align-items-center",
              styles.gray_items
            )}
          >
            <div className={styles.normal_text}>Regex:</div>
            <Input
              className={styles.input}
              placeholder="^(0[1-9]|1[0-2])/(0[1-9]|1\d|2\d|3[01])/(\d{4})$"
              onChange={handleRegex}
              value={regex}
            />
          </div>
          <div
            className={classNames(
              "d-flex justify-content-between align-items-center",
              styles.gray_items
            )}
          >
            <div className={styles.normal_text}>Action:</div>
            <Dropdown
              popoverProps={{ placement: "bottom", offset: [0, 0] }}
              id={"mask-dropdown" + "custom"}
              onChange={(e) => {
                handleCustomMaskTypeChange(e);
              }}
              options={["allow", "block", "mask"].map((v) => ({
                label: (
                  <div className={styles.custom_dropdown_text}>
                    {capitalize(v)}
                  </div>
                ),
                value: v,
              }))}
              showDivider
            >
              <div className={styles.custom_dropdown}>
                {!customMaskType ? (
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className={styles.mask_dropdown_text}>Select</div>
                    <div className={styles.icon}>
                      <ArrowDown />
                    </div>
                  </div>
                ) : (
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className={styles.mask_dropdown_text}>
                      {capitalize(customMaskType)}
                    </div>
                    <div className={styles.icon}>
                      <ArrowDown />
                    </div>
                  </div>
                )}
              </div>
            </Dropdown>
          </div>
          {customMaskType === "mask" && (
            <div
              className={classNames(
                "d-flex justify-content-between align-items-center",
                styles.gray_items
              )}
            >
              <div className={styles.normal_text}>Replace With:</div>
              <Input
                className={styles.input}
                placeholder={"01/01/1997"}
                onChange={handleDefaultVal}
                value={defaultVal}
              />
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center">
            {editMode ? (
              <Button
                className={styles.delete_button}
                outline
                color="danger"
                onClick={handleDelete}
              >
                <span className={styles.text}>Delete</span>
              </Button>
            ) : (
              <div></div>
            )}
            <div className="d-flex gap-md">
              <div>
                <Button
                  className={styles.cancel_button}
                  outline
                  onClick={toggleSidebar}
                >
                  <span className={styles.text}>Cancel</span>
                </Button>
              </div>
              <div>
                <Button
                  className={styles.save_button}
                  onClick={handleSave}
                  disabled={!label}
                >
                  <span className={styles.text}>Save</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SidebarModal>
    </div>
  );
};

export { Security };
