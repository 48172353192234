import styles from "./styles.module.scss";
import { RiFilter3Line } from "react-icons/ri";
import { IconContext } from "react-icons";
import dayjs from "dayjs";
import { ColorTag, Tags } from "../../Components/Tags";
import { FilterDropdown, FilterText } from "../../Components/FilterDropdown";
import classNames from "classnames";
import { SortIcon } from "../../Components/Tables";
import { useNavigate } from "react-router-dom";
import { getQueryTextByHash } from "../../helpers/apis";
import { AsyncPopover, List, NumberFormatter } from "@uicore";
import QueryTextWithHover from "./QueryText";
import QueryHashWithHover from "./QueryHash";

const Icon = ({ children }) => (
  <IconContext.Provider value={{ size: "1.2rem" }}>
    {children}
  </IconContext.Provider>
);

const TableFilter = ({ filter, label, resetPage }) => {
  const setChangedState = (currentState) => {
    filter.onChange(currentState);
    resetPage(0);
  };
  return (
    <>
      {filter.filterType === "text" && (
        <FilterText
          label={filter.label}
          onChange={setChangedState}
          value={filter.value}
          placeHolder={filter.placeHolder}
        >
          <Icon>
            <RiFilter3Line />
          </Icon>
        </FilterText>
      )}
      {filter.filterType === "dropdown" && (
        <FilterDropdown
          label={label}
          value={filter.value}
          options={filter.options}
          onChange={setChangedState}
          searchBy={filter.searchBy}
        >
          <Icon>
            <RiFilter3Line />
          </Icon>
        </FilterDropdown>
      )}
    </>
  );
};

const TableHeader = ({ header, resetPage }) => {
  return (
    <div className={styles.table_header}>
      {header.map((h) => (
        <div
          className="w-100 d-flex align-items-center"
          key={h.id}
          {...(h.id === 1 && { style: { width: "200px" } })}
        >
          <span>{h.label}</span>
          <div className="spacer" />
          {h.filter && (
            <TableFilter
              filter={h.filter}
              label={h.label}
              resetPage={resetPage}
            />
          )}
          {h.sort && (
            <SortIcon sort={h.sort.value} onChange={h.sort.onChange} />
          )}
        </div>
      ))}
    </div>
  );
};

const colors = ["blue", "orange", "yellow", "purple", "dark_green"];
const colorsMap = {};
let i = 0;

const QueryList = ({ header, queries, onRowClick, resetPage }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.table}>
      <TableHeader header={header} resetPage={resetPage} />
      <div className={styles.table_body}>
        {queries["items"].map((item) => {
          return (
            <div
              key={item.id}
              className={styles.table_row}
              onClick={(e) => {
                e.preventDefault();
                onRowClick(item.query_id);
              }}
            >
              <div
                className={classNames(
                  styles.trimmed_query,
                  "text-primary condense-text cursor-pointer"
                )}
              >
                <QueryTextWithHover
                  queryText={item.query_trimmed}
                  queryHash={item.query_hash}
                />
              </div>
              <QueryHashWithHover queryHash={item.query_hash} />
              <div className={styles.break_word}>{item.query_cost}</div>
              <div className={styles.break_word}>{item.execution_time}</div>
              <Tags>
                {item?.insights.map((tag_rk) => {
                  if (!colorsMap[tag_rk])
                    colorsMap[tag_rk] = colors[i++ % colors.length];
                  return (
                    <ColorTag
                      key={tag_rk}
                      color={colorsMap[tag_rk]}
                      label={tag_rk}
                    />
                  );
                })}
              </Tags>
              <div>{item.end_time}</div>
              <div className={styles.break_word}>{item.query_type}</div>
              <div
                className={classNames(
                  styles.break_word,
                  "text-primary condense-text cursor-pointer"
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/warehouse?warehouse=" + item.warehouse_name);
                }}
              >
                {item.warehouse_name}
              </div>
              <div className={styles.break_word}>{item.user_name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { QueryList, TableHeader };
