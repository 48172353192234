import React, { useState } from "react";
import { Label, Form } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isFreeStack } from "../../helpers/tenant";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { decodeToken } from "react-jwt";
import {
  FormAlert,
  FormInput,
  FormPassword,
  FormSubmit,
} from "./components/form";
import { BaseContainer, Header } from "./components/base";
import {
  isFormFieldError,
  passwordValidator,
  validateFormPassword,
} from "./helper";
import { useMutation } from "react-query";
import { postRegisterInvite } from "../../helpers/backend_helper";
import { loginUser } from "../../services/auth";
import classNames from "classnames";
import styles from "./components/styles.module.scss";
import { ReactComponent as DangerCircleCheck } from "../../assets/icons/circle_check_danger.svg";
import { ReactComponent as SuccessCircleCheck } from "../../assets/icons/circle_check_enabled.svg";
import { ReactComponent as DisabledCircleCheck } from "../../assets/icons/circle_check_disabled.svg";
import { PasswordValidation } from "./PasswordValidator";

const PASSWORD_INPUT_ID = "register-user-password-input";

const Register = () => {
  const navigate = useNavigate();
  const isFreeUser = isFreeStack();
  const { token } = useParams();
  const { mutate, error } = useMutation(postRegisterInvite, {
    onSuccess: (response) => {
      if (response.auth_token) {
        loginUser(response);
        if (isFreeUser) {
          navigate("/settings/api-key");
        } else {
          navigate("/quickstart");
        }
      }
    },
  });
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      password: passwordValidator,
    }),
    onSubmit: (values) => mutate({ ...values, token }),
  });
  const [validatePassword, setValidatePassword] = useState([0, 0, 0, 0]);
  const [activeElement, setActiveElement] = useState(null);

  const decodedToken = decodeToken(token);
  if (decodedToken && decodedToken.exp < Date.now() / 1000) {
    const state = { inviteDate: decodedToken.nbf };
    return <Navigate to="/expired" state={state} />;
  }

  if (!decodedToken) {
    return <Navigate to="/expired" />;
  }

  const { email, company: company_name } = decodedToken;
  const showFormFieldError = isFormFieldError(validation);
  const _error =
    error || showFormFieldError("password") || showFormFieldError("name");

  const setPasswordElement = (e) => {
    setActiveElement(e.target);
  };
  const isFormDisabled = () => {
    if (validatePassword.includes(-1) || validatePassword.includes(0))
      return true;

    return false;
  };
  return (
    <BaseContainer
      footer={
        <div>
          <span className="text-muted fw-400">Already have an account? </span>
          <Link to="/login" className="text-primary">
            Sign In
          </Link>
        </div>
      }
    >
      <Header>Sign Up</Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
        className="mt-3"
      >
        <div className="mb-3">
          <Label htmlFor="company_name" className="form-label">
            Organization
          </Label>
          <FormInput
            name="company_name"
            type="text"
            value={company_name}
            disabled={true}
            onFocus={setPasswordElement}
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="useremail" className="form-label">
            Email
          </Label>
          <FormInput
            name="email"
            type="email"
            value={email}
            disabled={true}
            onFocus={setPasswordElement}
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="name" className="form-label">
            Name
          </Label>
          <FormInput
            name="name"
            type="text"
            placeholder="Enter Name"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name}
            onFocus={setPasswordElement}
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="password" className="form-label">
            Password
          </Label>

          <div
            className={classNames(styles.tenant_form_password, {
              [styles.in_focus]:
                activeElement?.id === PASSWORD_INPUT_ID ||
                validatePassword.includes(-1),
              [styles.is_invalid]: validatePassword.includes(-1),
            })}
          >
            <FormPassword
              id={PASSWORD_INPUT_ID}
              name="password"
              placeholder="Enter password"
              onBlur={() => {
                setValidatePassword((prev) =>
                  prev.map((element) => (element === 0 ? -1 : element))
                );
                setPasswordElement("");
              }}
              onChange={(e) => {
                validation.handleChange(e);
                validateFormPassword(e.target.value, setValidatePassword);
              }}
              value={validation.values.password}
              disabled={validation.isSubmitting}
              onFocus={setPasswordElement}
            />
          </div>
          {(activeElement?.id === PASSWORD_INPUT_ID ||
            validatePassword.includes(-1)) && (
            <PasswordValidation validatePassword={validatePassword} />
          )}
        </div>

        <div className="mb-4">
          <p className="mb-0 fs-12 text-muted">
            <span>By creating an account, you agree to the </span>
            <Link
              to={{ pathname: "https://www.altimate.ai/terms" }}
              target="_blank"
              className="text-black fst-bold fw-medium"
            >
              Terms & Conditions
            </Link>
            <span> and our </span>
            <Link
              to={{ pathname: "https://www.altimate.ai/privacy" }}
              target="_blank"
              className="text-black fst-bold fw-medium"
            >
              Privacy Policy
            </Link>
          </p>
        </div>

        <FormAlert error={_error} />

        <div className="mt-4">
          <FormSubmit disabled={isFormDisabled()}>Sign Up</FormSubmit>
        </div>
      </Form>
    </BaseContainer>
  );
};

export { Register };
