import dayjs from "dayjs";
import styles from "./styles.module.scss";
import { ReactComponent as InsightIcon } from "@/assets/icons/insight.svg";
import { BarGraph, TimeFrameSelectorDropdown } from "../../Components/Graph";
import { useQuery } from "react-query";
import { useState } from "react";
import {
  getGovernanceRoleDetails,
  getGovernanceRolesCostGraph,
  getGovernanceUserDetails,
  getGovernanceUsersCostGraph,
} from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";

const COLORS_MAP = {
  cost: "#5B9FFF",
  // storage: "#FFCE73",
  // serverless: "#9382D9",
};

const GET_USER_GRAPH = "GET-USER-GRAPH";
const GET_USER_DETAILS = "GET-USER-DETAILS";
const GET_ROLE_GRAPH = "GET-ROLE-GRAPH";
const GET_ROLE_DETAILS = "GET-ROLE-DETAILS";

const TIME_FRAMES = [
  { label: "Day", value: 1 },
  { label: "Week", value: 7 },
  { label: "Month", value: 30 },
];

const UserDetailsModal = ({ user_name }) => {
  const [period, setPeriod] = useState(30);
  const { data: graphData, isLoading: isGraphLoading } = useQuery({
    queryKey: [GET_USER_GRAPH, period],
    queryFn: () => getGovernanceUsersCostGraph({ period, user_name }),
  });
  const { data: user, isLoading: isUserLoading } = useQuery({
    queryKey: [GET_USER_DETAILS],
    queryFn: () => getGovernanceUserDetails(user_name),
  });
  if (isGraphLoading || isUserLoading) return <RelativeComponentLoader />;
  return (
    <div className="p-3 h-100 d-flex flex-column gap-sm">
      <div className="mb-2 d-flex gap-sm align-items-center">
        <div className="fs-4">Username: </div>
        <div className="text-primary fs-4">{user.user_name}</div>
        <div className="spacer" />
        <div className="text-muted">
          {dayjs().subtract(1, "month").format("DD MMM YY")}
          {" - "}
          {dayjs().format("DD MMM YY")}
        </div>
        <div className={styles.duration}>Last 1 Month</div>
      </div>
      <div className="mb-2 d-flex gap-xl align-items-center">
        <div className="d-flex gap-xs">
          <span className="fw-semibold">Queries:</span>
          <span>{user.num_of_queries}</span>
        </div>
        <div className="d-flex gap-xs">
          <span className="fw-semibold">Last Logged In:</span>
          <span className="text-muted">
            {user.last_success_login &&
              dayjs(user.last_success_login).format("D MMM YYYY")}
          </span>
        </div>
      </div>
      <div className={styles.graph_container}>
        <div className={styles.timeframe_inside_graph}>
          <TimeFrameSelectorDropdown
            options={TIME_FRAMES}
            period={period}
            setPeriod={setPeriod}
          />
        </div>
        <BarGraph
          graphData={graphData.items}
          colorsMap={COLORS_MAP}
          yLabel="Cost"
          dataKeys={["cost"]}
        />
      </div>
      {user.insights && user.insights.length > 0 && (
        <>
          <div className="text-black fs-5">Insights</div>
          <div className={styles.insight_container}>
            {user.insights.map((tag) => (
              <div className={styles.insight_card} key={tag.label}>
                <div>
                  <div className="d-flex gap-sm mb-2">
                    <InsightIcon />
                    <div className="fs-5">{tag.label}</div>
                  </div>
                  <div className="text-muted">{tag?.reason}</div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const RoleDetailsModal = ({ role_name }) => {
  const [period, setPeriod] = useState(30);
  const { data: graphData, isLoading: isGraphLoading } = useQuery({
    queryKey: [GET_ROLE_GRAPH, period],
    queryFn: () => getGovernanceRolesCostGraph({ period, role_name }),
  });
  const { data: role, isLoading: isRoleLoading } = useQuery({
    queryKey: [GET_ROLE_DETAILS],
    queryFn: () => getGovernanceRoleDetails(role_name),
  });
  if (isGraphLoading || isRoleLoading) return <RelativeComponentLoader />;
  return (
    <div className="p-3 h-100 d-flex flex-column gap-sm">
      <div className="mb-2 d-flex gap-sm align-items-center">
        <div className="fs-4">Role: </div>
        <div className="text-primary fs-4">{role.role_name}</div>
        <div className="spacer" />
        <div className="text-muted">
          {dayjs().subtract(1, "month").format("DD MMM YY")}
          {" - "}
          {dayjs().format("DD MMM YY")}
        </div>
        <div className={styles.duration}>Last 1 Month</div>
      </div>
      <div className="mb-2 d-flex gap-xl align-items-center">
        <div className="d-flex gap-xs">
          <span className="fw-semibold">Queries:</span>
          <span>{role.num_of_queries}</span>
        </div>
      </div>
      <div className={styles.graph_container}>
        <div className={styles.timeframe_inside_graph}>
          <TimeFrameSelectorDropdown
            options={TIME_FRAMES}
            period={period}
            setPeriod={setPeriod}
          />
        </div>
        <BarGraph
          graphData={graphData.items}
          colorsMap={COLORS_MAP}
          yLabel="Cost"
          dataKeys={["cost"]}
        />
      </div>
      {role.insights && role.insights.length > 0 && (
        <>
          <div className="text-black fs-5">Insights</div>
          <div className={styles.insight_container}>
            {role.insights.map((tag) => (
              <div className={styles.insight_card} key={tag.label}>
                <div>
                  <div className="d-flex gap-sm mb-2">
                    <InsightIcon />
                    <div className="fs-5">{tag.label}</div>
                  </div>
                  <div className="text-muted">{tag?.reason}</div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export { UserDetailsModal, RoleDetailsModal };
