import { Card, CardBody, Input } from "reactstrap";
import styles from "./styles.module.scss";
import { DebouncedInput } from "../../Components/Form";
import {
  addSearchParams,
  isEmpty,
  removeSearchParams,
} from "../../helpers/utils";
import { useEffect, useState } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow_left.svg";
import classnames from "classnames";
import { useBoolean } from "../../helpers/hooks";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import { useSearchParams } from "react-router-dom";

const TILE_LIST_ITEMS_LIMIT = 5;

const Tile = ({ label, onChange, options, isSearchOption = true }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const { value: isExpand, toggle } = useBoolean();
  useEffect(() => {
    setFilteredOptions(options);
  }, [options, setFilteredOptions]);

  if (isEmpty(options)) return null;
  return (
    <Card className={styles.card}>
      <div className="m-3">
        <div className="text-black fw-regular mb-2">{label}</div>
        {isSearchOption && (
          <DebouncedInput
            size="sm"
            debounceDelay={100}
            placeholder="Search"
            onChange={(_s) => {
              const s = _s.toLowerCase();
              setFilteredOptions(
                options.filter(
                  (item) => item.label.includes(s) || item.value.includes(s)
                )
              );
            }}
          />
        )}
      </div>
      <div className={styles.divider} />
      <ul
        className={classnames(
          "m-3",
          !isSearchOption ? "mt-0" : "",
          styles.list
        )}
      >
        {(isExpand
          ? filteredOptions
          : filteredOptions.slice(0, TILE_LIST_ITEMS_LIMIT)
        ).map((item) => (
          <li
            key={item.label}
            onClick={(e) => {
              e.stopPropagation();
              onChange(item.value);
            }}
          >
            <div>
              <Input
                type="checkbox"
                checked={item.checked}
                className="me-2"
                readOnly
              />
              {item.label}
            </div>
            <div>{item.count}</div>
          </li>
        ))}
      </ul>
      {filteredOptions.length > TILE_LIST_ITEMS_LIMIT && (
        <button
          className={styles.show_more_button}
          onClick={(e) => {
            e.stopPropagation();
            toggle();
          }}
        >
          {isExpand ? "- Hide" : "+ Show"}
        </button>
      )}
    </Card>
  );
};

const Filter = ({ filterOptions, resetFilter }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const expandedParam = searchParams.get("expanded") || "true";
  const expanded = expandedParam === "true";

  const expandFilter = () => {
    setSearchParams(removeSearchParams("expanded"));
  };
  const collapseFilter = () => {
    setSearchParams(addSearchParams("expanded", "false"));
  };

  if (!expanded) {
    return (
      <div className={styles.filter_icon} onClick={expandFilter}>
        <FilterIcon />
      </div>
    );
  }

  return (
    <div className={styles.filter}>
      <Card>
        <CardBody>
          <div className="w-100 mb-3 d-flex justify-content-between align-items-baseline">
            <div className="text-black fs-5">Filter</div>
            <div className="d-flex align-items-center text-muted fs-6 cursor-pointer">
              <div
                className="me-2"
                onClick={(e) => {
                  e.stopPropagation();
                  resetFilter();
                }}
              >
                Reset
              </div>
              <div
                className={styles.collapse_button_open}
                onClick={collapseFilter}
              >
                <ArrowLeft />
              </div>
            </div>
          </div>
          <div className={styles.tile_container}>
            {filterOptions.map((item) => (
              <Tile
                key={item.label}
                label={item.label}
                options={item.options}
                onChange={item.onChange}
                isSearchOption={item.isSearchOption}
              />
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export { Filter };
