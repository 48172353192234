import React from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import warning from "../../assets/icons/warning.svg";
import styles from "./styles.module.scss";
import { getScopeString } from "../../helpers/utils";
import classnames from "classnames";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getIncidentById,
  getDebugSQLByIncidentId,
  postSnoozeIncident,
} from "../../helpers/backend_helper";
import { formatDateV2, isSnoozedFor1Day } from "./helper";
import { ReactComponent as CloseIcon } from "@/assets/icons/close.svg";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ComponentLoader, Loader } from "../../Components/Loader";
import { ROCodeEditor, CueCodeEditor } from "@/Components/CodeEditor";
import { CopyIconButtonFab } from "../../Components/CopyButton";
import DangerCircle from "../../assets/icons/danger_circle.svg";
import { Tooltip } from "react-tooltip";
import UTC from "dayjs/plugin/utc";
import { GET_INCIDENTS } from "../../services/filters";
import { useNavigate } from "react-router-dom";
dayjs.extend(UTC);

const GET_INCIDENT_ID = "GET-INCIDENT-ID";
const GET_DEBUG_SQL = "GET-DEBUG-SQL";

const IncidentModal = ({
  incidentId,
  isOpen,
  recentIncidentIds,
  toggle,
  onIncidentClick,
}) => {
  const { data, isLoading } = useQuery({
    queryKey: [GET_INCIDENT_ID, incidentId],
    queryFn: () => getIncidentById(incidentId),
  });
  const navigate = useNavigate();
  const { data: debugSQLData, isLoading: isDebugSQLLoading } = useQuery({
    queryKey: [GET_DEBUG_SQL, incidentId],
    queryFn: () => getDebugSQLByIncidentId(incidentId),
  });
  const [sortedIncidents, setSortedIncidents] = useState([]);
  const [showDebugData, setShowDebugData] = useState(false);

  useEffect(() => {
    if (!data) return;
    setSortedIncidents([...data.last_violations_for_term]);
  }, [data]);

  const sortIncidents = (key) => (order) => {
    const last_violations = data?.last_violations_for_term || [];
    if (order === "") {
      setSortedIncidents([...last_violations]);
      return;
    }
    const i = order === "asc" ? 1 : -1;
    let compareFn = (a, b) => i * (a[key] - b[key]);
    if (key === "violated_on") {
      compareFn = (a, b) => i * (dayjs(a[key]).isAfter(dayjs(b[key])) ? 1 : -1);
    }
    setSortedIncidents([...last_violations.sort(compareFn)]);
  };

  const queryClient = useQueryClient();
  const { mutate } = useMutation(postSnoozeIncident, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_INCIDENT_ID, incidentId],
      });
      queryClient.invalidateQueries({ queryKey: [GET_INCIDENTS] });
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  const toggleDebugSQL = () => {
    setShowDebugData(!showDebugData);
  };

  const closeBtn = (
    <CloseIcon
      className="cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        toggle();
      }}
    />
  );

  return (
    <Modal centered isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader tag="div" toggle={toggle} close={closeBtn}>
        <div className="fs-2">Incident: #{incidentId}</div>
      </ModalHeader>
      <ModalBody>
        <div className={styles.incident_modal}>
          <div className="d-flex align-items-center gap-sm mb-3">
            <div className={styles.date}>{data?.timestamp}</div>
            <div className="spacer" />
            {data.datastore_type !== "s3" && (
              <Button
                size="sm"
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/copilot", {
                    state: { debug_copilot: data.debug_copilot },
                  });
                }}
              >
                Use data pilot
              </Button>
            )}
            <div className="d-flex justify-content-between">
              {debugSQLData && debugSQLData["debug_sql"] && (
                <>
                  {!showDebugData && (
                    <Card
                      className={styles.help_me_debug_sql_button}
                      onClick={toggleDebugSQL}
                    >
                      <CardBody>
                        <div className={styles.help_me_debug_sql_text}>
                          Help Me Debug!
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {showDebugData && (
                    <Card
                      className={styles.hide_debug_workflow_button}
                      onClick={toggleDebugSQL}
                    >
                      <CardBody>
                        <div className={styles.hide_debug_sql_text}>
                          Hide Debug Agent
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </>
              )}

              {isDebugSQLLoading && <ComponentLoader />}
            </div>
          </div>
          {debugSQLData && debugSQLData["debug_sql"] && showDebugData && (
            <Card className={styles.debug}>
              <CardBody>
                <div className="d-flex flex-column">
                  <div className={styles.debug_sql_box_title}>
                    CLI command to start the debug agent:{" "}
                    <img
                      src={DangerCircle}
                      data-tooltip-id="debug-info"
                      data-tooltip-content="Information about command line Incident"
                      className={styles.debug_info}
                      alt="debug-info"
                    />
                    <Tooltip id="debug-info" className={styles.tooltip} />
                  </div>
                  <Card className={styles.command}>
                    <CardBody>
                      <div className="d-flex flex-column">
                        <div className={styles.command_text}>
                          altimate incident debug -i{" "}
                          <span style={{ color: "#247EFE" }}>{incidentId}</span>
                        </div>
                        <div className={styles.copy_button}>
                          <CopyIconButtonFab
                            value={`altimate incident debug -i ${incidentId}`}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <div className={styles.debug_sql_box_title}>
                    SQL query to view bad data:
                  </div>
                  <ROCodeEditor
                    value={debugSQLData["debug_sql"]}
                    height="207px"
                  />
                </div>
              </CardBody>
            </Card>
          )}
          <Card className={styles.warning}>
            <CardBody>
              <div className="d-flex align-items-center">
                <img src={warning} alt="Warning" className="me-4" />
                <div className={styles.warning_normal_text}>
                  {/* 
                  The code below will split the term message
                  based on @ and % and then a class will be applied based on that
                  text inside @@ will be light orange
                  text inside %% will be bold orange
                  */}
                  {data?.term_message.split("\n").map((line, index) => (
                    <div key={index}>
                      {line.split(/(@[^@]*@|%[^%]*%)/g).map((text, index) => {
                        const match = text.match(/@([^@]*)@|%([^%]*)%/);
                        if (match) {
                          return (
                            <span
                              key={index}
                              className={classnames({
                                [styles.warning_orange_text]: match[1],
                                [styles.warning_bold_orange_text]: match[2],
                              })}
                            >
                              {match[1] || match[2]}
                            </span>
                          );
                        } else {
                          return text;
                        }
                      })}
                      <br />
                    </div>
                  ))}
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className={styles.info}>
            <CardBody>
              <div className="d-flex flex-column">
                <div className={`d-flex ${data?.filters ? "mb-2" : ""}`}>
                  <div className={styles.info_title}>Scope :</div>
                  <div className={styles.info_detail}>
                    {data?.scope.map(getScopeString).join(", ")}
                  </div>
                </div>
                {data?.filters && (
                  <div className="d-flex">
                    <div className={styles.info_title}>Filter&nbsp;&nbsp;:</div>
                    <div className={styles.info_detail}>{data?.filters}</div>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
          <Card className={styles.policy}>
            <CardBody className={styles.policy_box}>
              <div className={styles.policy_title}>Policy</div>
              <div className={styles.policy_header}>
                <div className={styles.policy_heading}>Policy Name:</div>
                <div className={styles.break_word}>{data?.policy_name}</div>
              </div>
              <div className={styles.policy_details}>
                <div className={styles.policy_heading}>Policy Term:</div>
                <div className="d-flex flex-column">
                  <div className={styles.policy_term}>
                    <CueCodeEditor value={data?.policy_term} height="20vh" />
                  </div>
                  <div className="d-flex gap-sm mb-2">
                    {[
                      {
                        value: "24h",
                        predicate:
                          data?.status === "snoozed" &&
                          isSnoozedFor1Day(data.snoozed_till),
                        label: "Snooze (24 hours)",
                      },
                      {
                        value: "1w",
                        predicate:
                          data?.status === "snoozed" &&
                          !isSnoozedFor1Day(data.snoozed_till),
                        label: "Snooze (1 week)",
                      },
                    ].map((item) => (
                      <Button
                        key={item.value}
                        color={item.predicate ? "success" : "primary"}
                        disabled={
                          data?.status !== "active" &&
                          data?.status !== "snoozed"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          mutate({ id: incidentId, snoozed_till: item.value });
                        }}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className={styles.actual}>
            <CardBody className={styles.actual_body}>
              <div className={styles.actual_title}>
                Actual Value&nbsp;&nbsp;:
              </div>
              <div className={styles.actual_detail}>{data?.actual}</div>
            </CardBody>
          </Card>
          <Card outline className={styles.last_incident}>
            <CardBody>
              <div className={styles.last_incident_title}>
                Past Incidents for the Policy Term:
              </div>
              <div className={styles.history_table_header}>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Incident ID</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Timestamp</span>
                </div>
                <div>Actual Value</div>
              </div>
              <div>
                {(sortedIncidents || []).map((item) => (
                  <div
                    key={item.id}
                    className={classnames(
                      styles.history_table_row,
                      styles.cursor_pointer
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      onIncidentClick(item.id);
                    }}
                  >
                    <div
                      className={
                        recentIncidentIds[item.id]
                          ? "text-muted"
                          : "text-primary"
                      }
                    >
                      {item.id}
                    </div>
                    <div>{formatDateV2(item.violated_on)}</div>
                    <div>{item.actual}</div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </div>
      </ModalBody>
    </Modal>
  );
};

export { IncidentModal };
