export const formatNumber = (value, options) => {
    const currency = options === null || options === void 0 ? void 0 : options.currency;
    const formatOptions = options || {};
    delete formatOptions.currency;
    try {
        const number = Number(value);
        const formatter = Intl.NumberFormat("en", Object.assign({ notation: "compact", maximumFractionDigits: 2, currency, style: currency ? "currency" : "decimal", 
            // @ts-ignore valid
            trailingZeroDisplay: 'stripIfInteger' }, formatOptions));
        return formatter.format(number);
    }
    catch (e) {
        console.error("Error in formatNumber", e, currency);
        return `${currency || ''}${value}`;
    }
};
export const getCurrencySymbol = (currencyCode) => {
    if (!currencyCode) {
        return "";
    }
    try {
        return (0)
            .toLocaleString(undefined, {
            style: "currency",
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
            .replace(/\d/g, "")
            .trim();
    }
    catch (e) {
        console.error("Error in getCurrencySymbol", e, currencyCode);
        return "$";
    }
};
