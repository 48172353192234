import { REACT_APP_HOST, REACT_APP_API_URL } from "../config";
export const FREE_USER_DOMAIN = "app.myaltimate.com";
export const FREE_MEGATENANT = "freemegatenant.demo.tryaltimate.com";
export const getTenant = () => {
    const myDomain = window.location.host;
    if (!myDomain.includes("." + REACT_APP_HOST))
        return "";
    return myDomain.replace("." + REACT_APP_HOST, "");
};
const CODE_QUERY = "codeQuery";
const LINEAGE = "lineage";
const FREE_STACK = "freeStack";
const WAREHOUSE = "warehouse";
const IS_INSTANCE = "isInstance";
const SHOW_PREMIUM_DATAPILOT = "showPremiumDatapilot";
const JOBS = "jobs";
const featureFlags = {
    [CODE_QUERY]: getTenant() !== "silq",
    [JOBS]: getTenant() !== "shs",
    [LINEAGE]: getTenant() !== "silq",
    [WAREHOUSE]: ["heyday", "rakuten", "asapp", "hydy", "shs", "block"].includes(getTenant()),
    [FREE_STACK]: window.location.hostname.includes(FREE_USER_DOMAIN) ||
        window.location.hostname.includes(FREE_MEGATENANT),
    [IS_INSTANCE]: window.location.hostname.includes("localhost")
        ? window.location.hostname !== (REACT_APP_HOST === null || REACT_APP_HOST === void 0 ? void 0 : REACT_APP_HOST.split(":")[0])
        : window.location.hostname !== REACT_APP_HOST,
    [SHOW_PREMIUM_DATAPILOT]: getTenant() === "eastman",
};
export const isCodeQuery = () => featureFlags[CODE_QUERY];
export const isLineage = () => featureFlags[LINEAGE];
export const isFreeStack = () => featureFlags[FREE_STACK];
export const showPremiumDatapilot = () => featureFlags[SHOW_PREMIUM_DATAPILOT];
export const showJobs = () => featureFlags[JOBS];
export const isInstanceStack = () => featureFlags[IS_INSTANCE];
export const isWarehouse = () => featureFlags[WAREHOUSE];
export const getDefaultRoute = (tenant) => tenant === "silq" ? "/datasets" : "/summary";
export const getBackendUrl = () => {
    return REACT_APP_API_URL;
};
