import { useState } from "react";
import styles from "./styles.module.scss";

import { useQuery } from "react-query";
import { GET_EXECUTION_ANALYTICS } from "./queryKey";
import { getExecutionAnalytics } from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import {
  CustomPieChart,
  ExecutionUsagePerUsers,
  PeriodSelect,
  TrendBarGraph,
} from "./GraphUtils";
import { ReactComponent as AlertIcon } from "../../assets/icons/freemium_alert.svg";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import noUsageTrends from "../../assets/images/analytics/empty_usage_trend.svg";
import noUserUsage from "../../assets/images/analytics/empty_users_usage.svg";
import noHoursSaved from "../../assets/images/analytics/empty_hours_saved.svg";
const periodsMap = {
  month: { title: "Monthly Analytics", dropdown: "Last month" },
  week: { title: "Weekly Analytics", dropdown: "Last week" },
  all_time: { title: "All Time Analytics", dropdown: "All Time" },
};

export const ExecutionsAnalytics = () => {
  const [currentPeriod, setCurrentPeriod] = useState("month");
  const { data: analyticsData, isLoading } = useQuery({
    queryKey: [GET_EXECUTION_ANALYTICS, currentPeriod],
    queryFn: () => getExecutionAnalytics(currentPeriod),
  });

  if (isLoading)
    return (
      <div className={styles.history}>
        <ComponentLoader />
      </div>
    );

  if (!analyticsData) return null;
  return (
    <div className={classNames(styles.history, styles.analytics)}>
      <div className={styles.header}>
        <div className={styles.title}>{periodsMap[currentPeriod].title}</div>
        <div className={styles.dropdown_select}>
          <div className={styles.date_info}>
            {analyticsData.start_date} - Today
          </div>
          <PeriodSelect
            id="analytics-period-select"
            currentPeriod={currentPeriod}
            setCurrentPeriod={setCurrentPeriod}
            periodsMap={periodsMap}
          />
        </div>
      </div>
      <div className={styles.charts}>
        {analyticsData.total_executions === 0 ? (
          <>
            <img
              className={styles.empty_chart}
              src={noHoursSaved}
              alt="no-usage-trends"
            />{" "}
            <img
              className={styles.empty_chart}
              src={noUsageTrends}
              alt="no-usage-trends"
            />
            <img
              className={styles.empty_chart}
              src={noUserUsage}
              alt="no-user-usage"
            />{" "}
          </>
        ) : (
          <>
            <div className={styles.chart}>
              <div className={styles.heading}>
                Time Saved{" "}
                {currentPeriod !== "all_time" && (
                  <div
                    className={styles.info_icon}
                    data-tooltip-id="time-save-info"
                    data-tooltip-variant=""
                    data-tooltip-place="top"
                  >
                    <AlertIcon />
                  </div>
                )}
              </div>
              <Tooltip id="time-save-info" className={styles.general_tooltip}>
                On average, we see about {analyticsData.total_time_saved_str}{" "}
                saved in a {currentPeriod} by a team using DataPilot
              </Tooltip>
              <div className={styles.time_saved_pie}>
                <CustomPieChart
                  data={[
                    {
                      name: "savedTime",
                      value: analyticsData.total_time_saved_hrs,
                    },
                    {
                      name: "aboveAvgTime",
                      value: Math.max(
                        0,
                        analyticsData.time_saved_avg_hrs -
                          analyticsData.total_time_saved_hrs
                      ),
                    },
                  ]}
                  infoText="Hours"
                  infoData={analyticsData.total_time_saved_hrs}
                  showColor={analyticsData.total_time_saved_hrs !== 0}
                  customPieStyle={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            {analyticsData.show_usage_trend ? (
              <div className={styles.chart}>
                <div className={styles.heading}>Usage Trend</div>
                <TrendBarGraph
                  analyticsTrendData={analyticsData.analytics_history}
                  period={currentPeriod}
                />
              </div>
            ) : (
              <img
                className={styles.empty_chart}
                src={noUsageTrends}
                alt="no-usage-trends"
              />
            )}
            <div className={styles.chart}>
              <div className={styles.heading}>Top 5 Users</div>
              <ExecutionUsagePerUsers
                executionData={analyticsData.execution_history_by_user}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
