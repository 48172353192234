// config constants
export const MAX_EXPAND_TABLE = 5;

// value constants
export const COLUMNS_SIDEBAR = "columns";
export const TABLES_SIDEBAR = "tables";
export const COLUMN_PREFIX = "column-";
export const SEE_MORE_PREFIX = "see-more-";

// dimensions
export const F_OFFSET_X = 100;
export const F_OFFSET_Y = 100;
export const T_NODE_W = 272;
export const T_NODE_H = 80;
export const C_OFFSET_X = 12;
export const C_OFFSET_Y = T_NODE_H + 10;
export const C_NODE_H = 30;
export const C_PADDING_Y = 4;
export const LEVEL_SEPARATION = 250;
export const LEVEL_SEPARATION_VERTICAL = 80;
export const T_NODE_Y_SEPARATION = 80;
export const T_NODE_Y_SEPARATION_VERTICAL = 250;

// node styles
const DEFAULT_COLOR = "#7A899E";
const HIGHLIGHT_COLOR = "#E38E00";

export const LENS_TYPE_COLOR = {
  Original: "#FDD835",
  Alias: "#51E5E6",
  Transformation: "#EE0015",
  Unchanged: "#8454FF",
  "Not sure": "#247efe",
};

export const defaultEdgeStyle = { stroke: DEFAULT_COLOR, strokeWidth: 1 };
export const highlightEdgeStyle = { stroke: HIGHLIGHT_COLOR, strokeWidth: 2 };
const defaultMarker = {
  type: "arrow",
  strokeWidth: 1,
  width: 24,
  height: 24,
  color: DEFAULT_COLOR,
};
export const highlightMarker = {
  type: "arrow",
  strokeWidth: 1,
  width: 16,
  height: 16,
  color: HIGHLIGHT_COLOR,
};

export const getSeeMoreId = (t, right) =>
  SEE_MORE_PREFIX + t + "-" + (right ? "1" : "0");

export const destructColumn = (c) => {
  const splits = c.split("/");
  const column = splits.pop();
  const table = splits.join("/");
  return [table, column];
};
export const destructTable = (table) => {
  const [datastore_type, splits] = table.split("://");
  if (datastore_type === "dbt") {
    const [_, uniqueId] = splits.split("/");
    const _splits = uniqueId.split(".");
    const n = _splits.length;
    return [datastore_type, _splits.slice(0, n - 1).join("."), _splits[n - 1]];
  }
  // TODO: fix this
  if (!splits) {
    return ["dbt", datastore_type, datastore_type];
  }
  const [schema, tableName] = splits.split("/");
  return [datastore_type, schema, tableName];
};
export const isColumn = (x) => x.id.startsWith(COLUMN_PREFIX);
export const isNotColumn = (x) => !x.id.startsWith(COLUMN_PREFIX);
export const isSeeMore = (x) => x.id.startsWith(SEE_MORE_PREFIX);

const getSourceTargetHandles = (l0, l1, isVertical) => {
  if (isVertical) {
    if (l0 < l1) return ["bottom", "top"];
    if (l0 > l1) return ["top", "bottom"];
    if (l0 < 0) return ["top", "top"];
    return ["bottom", "bottom"];
  }
  if (l0 < l1) return ["right", "left"];
  if (l0 > l1) return ["left", "right"];
  if (l0 < 0) return ["left", "left"];
  return ["right", "right"];
};

export const createTableNode = (_table, level, parent) => {
  return {
    id: _table.table,
    data: { ..._table, level, parent },
    position: { x: 100, y: 100 },
    type: "table",
    width: T_NODE_W,
    height: T_NODE_H,
  };
};

export const createOpNode = (id, level, parent, data) => {
  return {
    id,
    data: { ...data, level, parent },
    position: { x: 100, y: 100 },
    type: "operator",
    width: T_NODE_W,
    height: T_NODE_H,
  };
};

export const applyEdgeStyling = (e, highlight) => {
  e.style = highlight ? highlightEdgeStyle : defaultEdgeStyle;
  e.markerEnd = highlight ? highlightMarker : defaultMarker;
};

export const createColumnEdge = (source, target, srcLevel, dstLevel) => {
  const edgeId = getColumnEdgeId(source, target);
  const [sourceHandle, targetHandle] = getSourceTargetHandles(
    srcLevel,
    dstLevel,
    false
  );
  return {
    id: edgeId,
    data: {},
    source,
    target,
    sourceHandle,
    targetHandle,
    style: highlightEdgeStyle,
    zIndex: 1000,
    markerEnd: highlightMarker,
    type: srcLevel === dstLevel ? "smoothstep" : "default",
  };
};

export const getColumnEdgeId = (source, target) =>
  COLUMN_PREFIX + `${source}-${target}`;

export const getColY = (columnNum, tableNum = 1) =>
  columnNum * (C_NODE_H + C_PADDING_Y) + tableNum * C_PADDING_Y;

export const createTableEdge = (
  n1Level,
  n2Level,
  n1,
  n2,
  right,
  isVertical = false
) => {
  const [src, dst] = right ? [n1, n2] : [n2, n1];
  let [sourceHandle, targetHandle] = isVertical
    ? ["bottom", "top"]
    : ["right", "left"];
  if (n1 !== n2) {
    [sourceHandle, targetHandle] = right
      ? getSourceTargetHandles(n1Level, n2Level, isVertical)
      : getSourceTargetHandles(n2Level, n1Level, isVertical);
  }
  return {
    id: `${src}-${dst}`,
    source: src,
    target: dst,
    sourceHandle,
    targetHandle,
    style: defaultEdgeStyle,
    markerEnd: defaultMarker,
    type:
      n1 === n2
        ? "selfConnecting"
        : n1Level === n2Level
        ? "smoothstep"
        : "default",
  };
};

export const getColumnId = (t, c) => COLUMN_PREFIX + `${t}/${c}`;
