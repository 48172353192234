import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Stack } from "@uicore";
import CostBreakdown from "./CostBreakdown";
import SavingsBreakdown from "./SavingsBreakdown";
import { DateRange } from "../../../Components/DateRange";
import { useSummaryDispatch } from "../SummaryNew";
import useSummaryState from "../useSummaryState";
import { setCurrentEndDate, setCurrentStartDate, } from "../Context/summarySlice";
import classes from "../summary.module.scss";
const PastAnalysis = () => {
    const dispatch = useSummaryDispatch();
    const { currentStartDate, currentEndDate } = useSummaryState();
    return (_jsxs(Card, { className: classes.past_analysis, children: [_jsxs(Stack, { className: "justify-content-between mb-2", children: [_jsx("h3", { className: "text-black", children: "Current State" }), _jsx(DateRange, { startDate: currentStartDate, endDate: currentEndDate, onDateRangeSelect: (startDate, endDate) => {
                            dispatch(setCurrentStartDate(startDate));
                            dispatch(setCurrentEndDate(endDate));
                        }, id: "past-date-range", maxDate: new Date() })] }), _jsx("p", { className: classes.caption, children: "This section shows you costs and savings occurred in your Snowflake environment during the selected time period" }), _jsx(CostBreakdown, { currentEndDate: currentEndDate, currentStartDate: currentStartDate }), _jsx(SavingsBreakdown, { currentEndDate: currentEndDate, currentStartDate: currentStartDate }), _jsx("div", { id: "future" })] }));
};
export default PastAnalysis;
