import { Button, Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { ColumnDatatype } from "../../Components/Column";
import { useMemo, useState } from "react";
import { ColorInsightTags, ColorTag } from "../../Components/Tags";
import { DebouncedInput } from "../../Components/Form";
import { scroll_style } from "../../Components/ScrollContainer";
import { ReactComponent as DBTIcon } from "../../assets/icons/dbt.svg";
import { PurposeSection } from "../../Components/Lineage";

const ColumnCard = ({ column }) => {
  return (
    <div className={styles.column_card}>
      <div className="d-flex align-items-center">
        <ColumnDatatype datatype={column.datatype} />
        <div className="ms-2">{column.name}</div>
        <div className="spacer" />
      </div>
      <ColorTag label={column.datatype} color={styles.column_tag} />
      {column.description && (
        <>
          <div className="mb-1 divider" />
          <div className="d-flex gap-xs align-items-center">
            <div>Description</div>
          </div>
          <div className="text-muted font-normal">{column.description}</div>
        </>
      )}
      <ColorInsightTags tags={column.tags || []} />
    </div>
  );
};

const ColumnSection = ({ columns }) => {
  const [search, setSearch] = useState("");
  const filteredColumns = useMemo(() => {
    if (!search) return columns;
    const _search = search.toLowerCase();
    return columns.filter((c) => c.name.includes(_search));
  }, [columns, search]);

  return (
    <Card className={classnames("mb-0", styles.bg_gray)}>
      <CardBody className="d-flex flex-column gap-sm h-100">
        <div className="fs-5 fw-semibold mb-2">Columns</div>
        <DebouncedInput placeholder="Search Column" onChange={setSearch} />
        {search ? (
          <div className="text-primary">
            {filteredColumns.length} results found
          </div>
        ) : (
          <div>{columns.length} columns</div>
        )}
        <div className="d-flex flex-column gap-sm">
          {filteredColumns.map((c) => (
            <ColumnCard key={c.rk} column={c} />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

const HeaderSection = ({ table, onDetailsClick }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="me-2 icon-md">
        <DBTIcon />
      </div>
      <div className={styles.column_table_name}>{table.model_name}</div>
      <div className="spacer" />
      <Button
        className="mw-8"
        color="primary"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          onDetailsClick(table);
        }}
      >
        View Details
      </Button>
    </div>
  );
};

const ColumnsModal = ({ table, onDetailsClick }) => {
  return (
    <div
      className={classnames(
        "d-flex flex-column h-100 p-3 gap-md m-1",
        scroll_style
      )}
    >
      <HeaderSection table={table} onDetailsClick={onDetailsClick} />
      {table.description && (
        <PurposeSection table={table.rk} purpose={table.description} />
      )}
      <ColumnSection columns={table.columns} />
    </div>
  );
};

export { ColumnsModal };
