var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, } from "react-date-range";
import styles from "./styles.module.scss";
import { ArrowDownIcon, ClockBlackIcon } from "@assets/icons";
import { isSameDay } from "date-fns";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { BetterPopover } from "../Popover";
import dayjs from "dayjs";
import { Button } from "reactstrap";
import { Stack } from "@uicore";
export const StaticDateRangeKeys = {
    LAST_DAY: "Last day",
    LAST_7_DAYS: "Last 7 days",
    LAST_28_DAYS: "Last 28 days",
    LAST_3_MONTHS: "Last 3 months",
    LAST_6_MONTHS: "Last 6 months",
    LAST_12_MONTHS: "Last 12 months",
};
const DateRange = (_a) => {
    var { id = "date-picker", wrapperClass, popoverProps = {}, onDateRangeSelect, startDate, endDate, availableStaticRanges = Object.values(StaticDateRangeKeys), disableCalendarSelection, minDate } = _a, rest = __rest(_a, ["id", "wrapperClass", "popoverProps", "onDateRangeSelect", "startDate", "endDate", "availableStaticRanges", "disableCalendarSelection", "minDate"]);
    const ref = useRef(null);
    const [state, setState] = useState([
        {
            startDate: startDate || new Date(),
            endDate: endDate || new Date(),
            key: "selection",
        },
    ]);
    useEffect(() => {
        setState([
            {
                startDate: startDate || new Date(),
                endDate: endDate || new Date(),
                key: "selection",
            },
        ]);
    }, [startDate, endDate]);
    const isSelectedRange = (selectedRange, definedRange) => {
        return (isSameDay(selectedRange.startDate, definedRange.startDate) &&
            isSameDay(selectedRange.endDate, definedRange.endDate));
    };
    const StaticRanges = {
        [StaticDateRangeKeys.LAST_DAY]: {
            label: "Last day",
            hasCustomRendering: true,
            range: () => ({
                startDate: dayjs().subtract(1, "day").toDate(),
                endDate: dayjs().subtract(1, "day").toDate(),
            }),
            isSelected(range) {
                return isSelectedRange(range, this.range());
            },
        },
        [StaticDateRangeKeys.LAST_7_DAYS]: {
            label: "Last 7 days",
            hasCustomRendering: true,
            range: () => ({
                startDate: dayjs().subtract(7, "day").toDate(),
                endDate: new Date(),
            }),
            isSelected(range) {
                return isSelectedRange(range, this.range());
            },
        },
        [StaticDateRangeKeys.LAST_28_DAYS]: {
            label: "Last 28 days",
            hasCustomRendering: true,
            range: () => ({
                startDate: dayjs().subtract(28, "day").toDate(),
                endDate: new Date(),
            }),
            isSelected(range) {
                return isSelectedRange(range, this.range());
            },
        },
        [StaticDateRangeKeys.LAST_3_MONTHS]: {
            label: "Last 3 months",
            hasCustomRendering: true,
            range: () => ({
                startDate: dayjs().subtract(90, "day").toDate(),
                endDate: new Date(),
            }),
            isSelected(range) {
                return isSelectedRange(range, this.range());
            },
        },
        [StaticDateRangeKeys.LAST_6_MONTHS]: {
            label: "Last 6 months",
            hasCustomRendering: true,
            range: () => ({
                startDate: dayjs().subtract(180, "day").toDate(),
                endDate: new Date(),
            }),
            isSelected(range) {
                return isSelectedRange(range, this.range());
            },
        },
        [StaticDateRangeKeys.LAST_12_MONTHS]: {
            label: "Last 12 months",
            hasCustomRendering: true,
            range: () => ({
                startDate: dayjs().subtract(365, "day").toDate(),
                endDate: new Date(),
            }),
            isSelected(range) {
                return isSelectedRange(range, this.range());
            },
        },
    };
    const staticRanges = availableStaticRanges.map((r) => StaticRanges[r]);
    const calculateDateRangeFromTwoDates = (beginDate, endDate) => {
        const selectedRange = staticRanges.find((r) => r.isSelected({
            startDate: beginDate.toDate(),
            endDate: endDate.toDate(),
            key: "selection",
        }));
        return selectedRange ? selectedRange.label : false;
    };
    const handleDatePick = (item, close) => {
        setState([item.selection]);
        if (item.selection.startDate &&
            item.selection.endDate &&
            calculateDateRangeFromTwoDates(dayjs(item.selection.startDate), dayjs(item.selection.endDate))) {
            close();
            onDateRangeSelect(item.selection.startDate, item.selection.endDate);
        }
    };
    const handleApply = (close) => {
        if (state.length) {
            const [selection] = state;
            if (selection.startDate && selection.endDate) {
                close();
                onDateRangeSelect(selection.startDate, selection.endDate);
            }
        }
    };
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    const props = disableCalendarSelection
        ? Object.assign(Object.assign({}, rest), { maxDate: new Date(), minDate: new Date() }) : rest;
    const staticRangeLabel = calculateDateRangeFromTwoDates(dayjs(startDate), dayjs(endDate));
    const renderDateRange = () => {
        if (!startDate || !endDate)
            return null;
        return (_jsxs(Stack, { children: [_jsx("div", { children: startDate.toLocaleDateString("en-US", options) }), !isSameDay(startDate, endDate) && (_jsxs(_Fragment, { children: [_jsx("div", { children: "-" }), _jsx("div", { children: endDate === null || endDate === void 0 ? void 0 : endDate.toLocaleDateString("en-US", options) })] }))] }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { id: id, className: classNames(styles.datePickerWrapper, wrapperClass), children: [staticRangeLabel ? renderDateRange() : null, _jsxs("div", { className: styles.datePicker, children: [_jsx(ClockBlackIcon, {}), _jsx("div", { className: styles.time, children: staticRangeLabel || renderDateRange() }), _jsx("div", { className: styles.arrow_down, children: _jsx(ArrowDownIcon, {}) })] })] }), _jsx(BetterPopover, Object.assign({ target: id, hideArrow: true, placement: "bottom" }, popoverProps, { children: ({ close }) => (_jsxs(Stack, { direction: "column", children: [_jsx(DateRangePicker, Object.assign({ ref: ref, 
                            // disable add/substract days input
                            inputRanges: [], renderStaticRangeLabel: (item) => item.label, onChange: (item) => handleDatePick(item, close), 
                            // @ts-ignore prop exists
                            showSelectionPreview: true, moveRangeOnFirstSelection: false, months: 2, ranges: state, staticRanges: staticRanges, direction: "horizontal", showDateDisplay: false, minDate: minDate }, props)), _jsx("div", { className: styles.apply_div, children: _jsx(Button, { size: "sm", onClick: () => handleApply(close), children: "Apply" }) })] })) }))] }));
};
export { DateRange };
