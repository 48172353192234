import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { Select } from "@/uiCore";
import { useOpportunities } from "../../useOpportunity";
import { getUser } from "@/services/auth";
import { useUsers } from "@/helpers/hooks";
import { useEffect, useState } from "react";
const OwnerAssignerDropdown = ({ opportunity_id, assignedOwner, setAssignedOwner = () => { }, disableUpdateOnChange = false, }) => {
    const { mutation } = useOpportunities("");
    const { mutate } = mutation;
    const user = getUser();
    const { users } = useUsers();
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        if (users) {
            setUserList(users
                .filter((user) => user.first_name || user.last_name)
                .map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
            })));
        }
    }, [users]);
    const handleOwnerChange = ({ e, opportunity_id, }) => {
        mutate({
            changes: [
                {
                    opportunity_id,
                    owner: e,
                    status: undefined,
                },
            ],
            user_id: user === null || user === void 0 ? void 0 : user.id,
        });
    };
    return (_jsx(Select, { id: `state-assigner`, onChange: (e) => {
            setAssignedOwner(e);
            if (!disableUpdateOnChange) {
                handleOwnerChange({ e, opportunity_id });
            }
        }, value: Number(assignedOwner), options: userList, showDivider: true, placeholder: "Un-assigned", className: styles.bulk_select_dropdown }));
};
export default OwnerAssignerDropdown;
