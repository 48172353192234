import { api } from "../../helpers/api_helper";
import { z } from "zod";
export const DbtDocsFiltersSchema = z.object({
    owners: z.array(z.object({
        created_at: z.string(),
        display_name: z.string().nullable(),
        email: z.string(),
        first_name: z.string().nullable(),
        id: z.number(),
        is_active: z.boolean(),
        is_invited: z.boolean(),
        is_onboarded: z.boolean(),
        is_verified: z.boolean(),
        last_name: z.string().default(""),
        phone: z.string().default(""),
        role_title: z.string().nullable(),
        uuid: z.string(),
    })),
    names: z.array(z.string()),
});
export const getDbtDocsShareListFilters = () => api.get(`dbt/dbt_docs_share/filters`, {}, 0, DbtDocsFiltersSchema);
