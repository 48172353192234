import { Button } from "@uicore";
import { Tab } from "../../Components/Tab";
import { BreakdownModal, BreakdownModalWithSelection, Cost } from "./Cost";
import { Governance } from "./Governance";
import { useState } from "react";
import SidebarModal from "../../Components/SidebarModal";
import styles from "./styles.module.scss";
import { Dropdown } from "../../Components/Form";
import { ReactComponent as ArrowDownIcon } from "@/assets/icons/arrow_down.svg";
import { useMutation, useQuery } from "react-query";
import {
  getSummaryConfig,
  postSummaryConfig,
} from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";
import { useNavigate } from "react-router-dom";

const GET_SUMMARY_CONFIG = "GET-SUMMARY-CONFIG";

const PERIOD_OPTIONS = [
  { label: "30 days", value: 30 },
  { label: "60 days", value: 60 },
  { label: "90 days", value: 90 },
];

const ConfigureModal = () => {
  const navigate = useNavigate();
  const [period, setPeriod] = useState(30);
  const { isLoading } = useQuery({
    queryKey: [GET_SUMMARY_CONFIG],
    queryFn: getSummaryConfig,
    onSuccess: (data) => {
      setPeriod(data.dormant_period);
    },
  });
  const { mutate, isLoading: isUpdating } = useMutation({
    mutationFn: postSummaryConfig,
    onSuccess: () => {
      navigate(0);
    },
  });
  if (isLoading || isUpdating) return <RelativeComponentLoader />;
  return (
    <div className="overflow-y p-2 h-100">
      <div className="d-flex flex-column gap-sm">
        <h3 className="text-black">Summary Page Configuration</h3>
        <div className="text-dark-grey">
          You can change default configurations used for the summary view
        </div>
        <div className={styles.configure_card}>
          <div className="fw-500">Dormant Tables Duration</div>
          <div className="spacer" />
          <Dropdown
            id="configure-duration-period"
            options={PERIOD_OPTIONS}
            onChange={setPeriod}
            value={period}
            popoverProps={{ placement: "bottom" }}
            wrapperClass="w-100"
          >
            <div className={styles.configure_duration}>
              {PERIOD_OPTIONS.find((x) => x.value === period)?.label}
              <ArrowDownIcon />
            </div>
          </Dropdown>
          <Button size="sm" onClick={() => mutate({ dormant_period: period })}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

const Summary = () => {
  const [sideModal, setSideModal] = useState({ type: "" });
  return (
    <>
      <div className="h-100 d-flex flex-column">
        <div className="mb-4 d-flex align-items-center">
          <h3 className="text-black">Summary</h3>
          <div className="spacer" />
          <Button size="sm" onClick={() => setSideModal({ type: "configure" })}>
            Configure
          </Button>
        </div>
        {/* <Tab
        tabs={[
          { label: "Cost", component: <Cost /> },
          { label: "Governance", component: <Governance /> },
        ]}
      /> */}
        <Cost setSideModal={setSideModal} />
      </div>
      <SidebarModal
        isOpen={sideModal.type}
        toggleModal={() => setSideModal({ type: "" })}
      >
        {["total_annual_savings", "total_time_savings"].includes(
          sideModal.type
        ) && <BreakdownModal {...sideModal.args} type={sideModal.type} />}
        {sideModal.type === "configure" && <ConfigureModal />}
        {sideModal.type === "total_annual_spend" && (
          <BreakdownModalWithSelection
            {...sideModal.args}
            type={sideModal.type}
          />
        )}
      </SidebarModal>
    </>
  );
};

export { Summary };
