import { useCopilot } from "./CopilotContext";
import { useCallback } from "react";
import dayjs from "dayjs";
import { uniqueId } from "lodash";
import * as Sentry from "@sentry/react";
import { ACTIONS, getInitialState, processChatItem, reducer } from "./state";
import { cancelRequest, postChat } from "../../helpers/backend_helper";
import { createSession } from "../../helpers/backend_helper";

export const useCopilotActions = () => {
  const {
    workflow,
    scrollRef,
    setWorkflow,
    setTasks,
    setTaskStatus,
    autoApprove,
    setPremium,
    setShowSuggestions,
    setResource,
    dispatch,
    getLoaderArgs,
    getErrorMessage,
    setConfig,
  } = useCopilot();
  const newChatId = () => uniqueId("chat-item-");

  const addNewChat = useCallback(
    (type, isUser, timestamp, args = {}) => {
      const time = timestamp ? dayjs(timestamp) : dayjs();
      dispatch({
        type: ACTIONS.ADD_ITEM,
        payload: { id: newChatId(), type, isUser, args, time },
      });
      if (scrollRef.current) {
        scrollRef.current.scrollToBottom();
      }
    },
    [dispatch, scrollRef]
  );

  const handleAgentError = (error) => {
    if (error === "canceled") return;
    dispatch({ type: ACTIONS.DELETE_ITEM });
    addNewChat("chat-agent-error", true, null, {
      data: getErrorMessage(error),
    });
    Sentry.captureException(error);
  };

  const resetWorkflow = useCallback(() => {
    if (workflow) {
      try {
        cancelRequest();
      } catch {
        /* empty */
      }
    }
    dispatch({ type: ACTIONS.RESET });
    setTasks([]);
    setTaskStatus({});
    setResource(null);
    setWorkflow("");
    setShowSuggestions(true);
    setPremium(false);
    setConfig({});
  }, [workflow]);

  const handleChatResponse = useCallback(
    (
      session_id,
      { chat_output, tasks: _taskStatus, next_message },
      workflow_type // hacked so that it can be passed to chat action
    ) => {
      setTaskStatus(_taskStatus);
      const proceedWithChat = async (args) => {
        addNewChat(
          "chat-loader",
          false,
          null,
          getLoaderArgs(args, next_message)
        );
        try {
          const data = await postChat(session_id, args);
          dispatch({ type: ACTIONS.DELETE_ITEM });
          handleChatResponse(session_id, data, workflow_type);
        } catch (error) {
          handleAgentError(error);
        }
      };
      processChatItem({
        session_id,
        workflow: workflow_type,
        dispatch,
        addNewChat,
        proceedWithChat,
        autoApprove,
        item: chat_output,
        isLive: true,
        resetWorkflow,
      });
    },
    [addNewChat, autoApprove, workflow]
  );

  const _createSession = useCallback(
    async (_tasks, requestData) => {
      requestData.tasks = _tasks;
      setTasks(_tasks);
      const _tasksStatus = {};
      _tasks.forEach((t) => (_tasksStatus[t] = "TODO"));
      setTaskStatus(_tasksStatus);
      addNewChat("chat-loader", false, null, {
        label: "Launching agent - may take a few seconds",
      });
      try {
        const { session_id } = await createSession(requestData);
        dispatch({ type: ACTIONS.SET_SESSION, payload: { session_id } });
        const data = await postChat(session_id, {});
        dispatch({ type: ACTIONS.DELETE_ITEM });
        handleChatResponse(session_id, data, requestData.workflow_type);
      } catch (error) {
        handleAgentError(error);
      }
    },
    [addNewChat, handleChatResponse]
  );

  return {
    addNewChat,
    handleAgentError,
    resetWorkflow,
    handleChatResponse,
    _createSession,
  };
};
