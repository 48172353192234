import { useEffect, useMemo, useRef, useState } from "react";
import { CodeSQLEditor } from "../../../Components/CodeEditor";
import styles from "../styles.module.scss";
import classNames from "classnames";
import { isEmpty } from "../../../helpers/utils";
import { Tab } from "../../../Components/Tab";
import { InsightSection } from "./InsightSection";
import { DebouncedInput, SimpleInput } from "../../../Components/Form";
import { scroll_style } from "../../../Components/ScrollContainer";
import { useSearchParams } from "react-router-dom";
import { Card, CardBody, CodeMarkdown, Stack, Tooltip } from "@uicore";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Close2Icon,
  WarningIcon,
} from "@assets/icons";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import SingleLineEllipses from "@/uiCore/components/singleLineEllipses/SingleLineEllipses";

const getNavClass = (b) =>
  classNames("icon-sm", b ? "text-black cursor-pointer" : "text-muted");

function getSubstringSpan(query, indices) {
  if (!indices) return null;
  const [start, end] = indices;
  const [startLine, startCol] = start;
  const [endLine, endCol] = end;
  const lines = query.split("\n");

  if (startLine === endLine) {
    return lines[startLine].substring(startCol, endCol);
  }

  const result = [lines[startLine].substring(startCol)];
  for (let i = startLine + 1; i < endLine; i++) {
    result.push(lines[i]);
  }
  result.push(lines[endLine].substring(0, endCol));

  return result.join("\n");
}

function cleanNodeName(str) {
  return str.replace(/_(\d{10,})$/g, "");
}

const ColumnSection = ({ columns }) => {
  const [filteredColumn, setFilteredColumn] = useState([...columns]);

  if (columns.length === 0) {
    return (
      <Stack direction="column">
        <div className="text-muted">No columns</div>
      </Stack>
    );
  }

  return (
    <Stack direction="column">
      <div className={classNames(styles.card, "mb-0 flex-grow column-section")}>
        <div className="d-flex flex-column gap-sm h-100">
          <div className="d-flex align-items-center gap-xs">
            <div className="fs-5 fw-semibold">Column</div>
            <div className="spacer" />
          </div>
          <SimpleInput
            size="sm"
            placeholder="Search by column name"
            onChange={(v) => {
              const _search = v.toLowerCase();
              setFilteredColumn(
                columns.filter((c) => c.name.toLowerCase().includes(_search))
              );
            }}
          />
          <div className="d-flex align-items-center gap-xs">
            <div>{filteredColumn?.length} columns</div>
          </div>
          <div className="d-flex flex-column gap-sm overflow-y">
            {filteredColumn?.map((_column) => (
              <div
                key={_column.name}
                className={classNames(styles.column_card)}
              >
                <div className="d-flex align-items-center gap-xs">
                  <div>{_column.name}</div>
                  <div className="spacer" />
                </div>
                {_column.expression && (
                  <>
                    <div className="divider" />
                    <SyntaxHighlighter
                      style={vscDarkPlus}
                      language="sql"
                      wrapLines={true}
                      wrapLongLines={true}
                      PreTag="div"
                    >
                      {_column.expression}
                    </SyntaxHighlighter>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Stack>
  );
};

export function SideContent({
  selectedNode,
  query,
  sqlLineage,
  parentEditorRef,
  selectedTab,
  setShowSideContent,
  opportunities,
}) {
  const [spans, setSpans] = useState([]);
  const [spanIndex, setSpanIndex] = useState(-1);
  const editorRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedInsightId = searchParams.get("selectedInsightId");
  const setSelectedInsightId = (v) => setSearchParams({ selectedInsightId: v });
  const opportunityId = searchParams.get("opportunityId");
  const _columns = useMemo(() => {
    return (
      sqlLineage?.details?.[selectedNode]?.columns?.map((item) => ({
        ...item,
        description: item.expression,
      })) || []
    );
  }, [selectedNode, sqlLineage?.details]);

  const tabs = [
    {
      label: "Insights",
      component: (
        <InsightSection
          sideContentMode={true}
          opportunities={
            (selectedNode && sqlLineage.query_tags_meta[selectedNode]) ||
            (selectedInsightId &&
              sqlLineage.tags.filter((t) => t.id === selectedInsightId)) ||
            (opportunityId &&
              [
                opportunities.find((o) => o.opportunity_id === opportunityId),
              ].filter((item) => Boolean(item))) ||
            []
          }
          selectedOpportunityId={
            (selectedInsightId &&
              opportunities.find((o) => o.id === selectedInsightId)
                ?.opportunity_id) ||
            opportunityId
          }
          setOpportunityId={(item) => {
            const opportunity = opportunities.find(
              (o) => o.opportunity_id === item
            );
            if (opportunity?.id) setSelectedInsightId(opportunity?.id);
          }}
        />
      ),
    },
    {
      label: `Columns (${_columns.length})`,
      component: <ColumnSection columns={_columns} />,
    },
  ];

  useEffect(() => {
    if (selectedTab !== "Code") return;
    parentEditorRef.current?.removeHighlight();
    if (!spans[spanIndex]?.parent_indices) return;
    parentEditorRef.current?.setHighlight(
      spans[spanIndex].parent_indices.map((index) => ({ index }))
    );
    parentEditorRef.current?.highlightSpan({
      index: spans[spanIndex].parent_indices,
    });
  }, [parentEditorRef, selectedTab, spans, spanIndex]);

  useEffect(() => {
    const highlightCode = (indices) => {
      editorRef.current?.removeHighlight();
      editorRef.current?.setHighlight(indices.map((index) => ({ index })));
      editorRef.current?.highlightSpan({ index: indices[0] });
    };

    if (selectedInsightId) {
      const reports = sqlLineage.query_tags_meta[selectedNode];
      if (!reports) {
        editorRef.current?.removeHighlight();
        setSpans([]);
        return;
      }
      const _spans = reports;
      setSpans(_spans);
      const i = _spans.findIndex((r) => r.id === selectedInsightId);
      const _spanIndex = i >= 0 ? i : 0;
      setSpanIndex(_spanIndex);
      const indices = _spans[_spanIndex]?.parent_indices;
      if (indices) {
        setTimeout(() => highlightCode([indices]), 100);
      }
    } else if (selectedNode) {
      const indices = sqlLineage.details[selectedNode].indices || [
        [0, 0],
        [0, 0],
      ];
      setTimeout(() => highlightCode([indices]), 100);
    } else if (opportunityId) {
      editorRef.current?.removeHighlight();
      const opportunity = opportunities.find(
        (o) => o.opportunity_id === opportunityId
      );
      setSpans([opportunity]);
      setSpanIndex(0);
    }
  }, [
    opportunities,
    opportunityId,
    selectedInsightId,
    selectedNode,
    sqlLineage,
  ]);

  return (
    <Card className="mb-0">
      <CardBody>
        <Stack direction="column">
          <div className="d-flex">
            {selectedNode ? (
              <SingleLineEllipses content={cleanNodeName(selectedNode)} />
            ) : null}
            <div className="spacer" />
            <Close2Icon
              onClick={() => setShowSideContent(false)}
              className="cursor-pointer"
            />
          </div>
          {query && (
            <CodeSQLEditor
              value={query}
              height="28vh"
              ref={editorRef}
              lineNumbers
            />
          )}
          {spans[spanIndex]?.opportunity_name && (
            <div className="br-2 bg-grey">
              <div className="d-flex align-items-center p-2">
                <div className="fs-5 fw-semibold">
                  Details: "{spans[spanIndex]?.opportunity_name}"
                </div>
                <div className="spacer" />
              </div>
              <div className="divider" />
              <div className="bg-white p-3 br-2 m-2">
                <div className="d-flex align-items-center">
                  <div className="fs-4 fw-500">Problem</div>
                  <div className="spacer" />
                  {!spans[spanIndex]?.parent_indices && (
                    <Tooltip
                      content={
                        "Query mapping incomplete: The opportunity details may reference expanded " +
                        "views or temporary objects not present in the original query. Please review " +
                        "the full query execution plan for more context."
                      }
                      placement="top"
                    >
                      <div className="icon-xs d-flex align-items-center">
                        <WarningIcon />
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className={styles.markdown_description}>
                  <CodeMarkdown
                    value={
                      spans[spanIndex]?.markdown_description ||
                      spans[spanIndex]?.description ||
                      spans[spanIndex]?.reason
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <Tab tabs={tabs} pagedTab={false} />
        </Stack>
      </CardBody>
    </Card>
  );
}
