import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { Stack, Tabs, Tile, Card, NumberFormatter } from "@uicore";
import { useState, useEffect } from "react";
import classes from "../summary.module.scss";
import AggregateFilter from "./AggregateFilter";
import BreakdownSkeleton from "./BreakdownSkeleton";
import { getSavingsBreakdown } from "@/helpers/apis";
import AssistedSavingsSection from "./AssistedSavingsSection";
import { AggregateByValues } from "./constants";
import SavingsInFuture from "./SavingsInFuture";
import AutonomousSavingsSection from "./AutonomousSavingsSection";
import { formatDate } from "./utils";
import AccordionTitle from "./AccordionTitle";
import FailureComponent from "./FailureComponent";
import { useAppState } from "@/modules/app/useAppContext";
import { getDateByStartOfDayWithoutTimeoffset, getDateByEndOfDayWithoutTimeoffset, } from "@/Components/DateRange/utils";
const SavingsBreakdown = ({ isFuture, currentStartDate, currentEndDate, }) => {
    const { currency } = useAppState();
    const [savingsType, setSavingsType] = useState("autonomous");
    const [aggregateBy, setAggregateBy] = useState(isFuture ? AggregateByValues.Monthly : AggregateByValues.Daily);
    const yearMode = aggregateBy === AggregateByValues.Monthly;
    const onAggregateSelect = (value) => {
        setAggregateBy(value);
    };
    const getEndPoint = () => {
        if (isFuture) {
            return `${savingsType}/estimate`;
        }
        return savingsType;
    };
    const { isLoading, isFetching, data, error, refetch } = useQuery({
        queryKey: [
            `savingsBreakdown-${isFuture ? "future" : "past"}`,
            currentStartDate,
            currentEndDate,
            aggregateBy,
        ],
        queryFn: () => {
            var _a;
            return getSavingsBreakdown(getEndPoint(), {
                start_date: getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
                end_date: (_a = getDateByEndOfDayWithoutTimeoffset(currentEndDate)) === null || _a === void 0 ? void 0 : _a.toISOString(),
                aggregation_level: aggregateBy,
            });
        },
        enabled: Boolean(currentStartDate && currentEndDate),
        onError(err) {
            // @ts-expect-error window prop
            posthog.capture("savingsBreakdownFailed", { error: err });
        },
    });
    const { data: overallSavingsData, refetch: refetchOverallSavings } = useQuery({
        queryKey: [
            `overallSavingsBreakdown-${isFuture ? "future" : "past"}`,
            currentStartDate,
            currentEndDate,
            aggregateBy,
        ],
        queryFn: () => {
            var _a;
            return getSavingsBreakdown(isFuture ? `overall/estimate` : "overall", {
                start_date: getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
                end_date: (_a = getDateByEndOfDayWithoutTimeoffset(currentEndDate)) === null || _a === void 0 ? void 0 : _a.toISOString(),
                aggregation_level: aggregateBy,
            });
        },
        enabled: Boolean(currentStartDate && currentEndDate),
        onError(err) {
            // @ts-expect-error window prop
            posthog.capture("savingsBreakdownFailed", { error: err });
        },
    });
    useEffect(() => {
        refetch();
        refetchOverallSavings();
    }, [
        savingsType,
        refetch,
        aggregateBy,
        currentEndDate,
        currentStartDate,
        refetchOverallSavings,
        isFuture,
    ]);
    if (isLoading) {
        return _jsx(BreakdownSkeleton, { type: "section" });
    }
    return (_jsxs(Card, { className: classes.section_card, children: [_jsx(AccordionTitle, { title: "Savings", howToText: "How to read the charts", howToContent: _jsx("p", { children: isFuture
                        ? "These section show the Snowflake savings data for the period specified for Future State. Saving may be coming from autonomous agent actions or your team implementing the recommendations shown as opportunities."
                        : "These charts show the Snowflake savings data for the period specified for Current state. Saving may be coming from autonomous agent actions or your team implementing the recommendations shown as opportunities. Charts can aggregate data at daily, weekly or monthly level. Don't miss the DataPilot icon in the top right corner of the chart to start talking with the data!" }) }), _jsx(Stack, { children: error ? (_jsx(FailureComponent, { title: "Failed to load savings breakdown. Please try again later." })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: classes.tileWrap, children: [_jsx(Tile, { color: "#01CD8C", title: "Total Money Savings", helpText: isFuture
                                        ? "Projected money savings by the product during this year"
                                        : `Total money saved by the product during the period selected: ${formatDate(currentStartDate, yearMode)} to ${formatDate(currentEndDate, yearMode)}`, value: _jsx(NumberFormatter, { value: overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_savings, options: { currency } }), children: _jsxs(Stack, { direction: "column", children: [_jsx(Tile, { title: "Autonomous Money Savings", helpText: isFuture
                                                    ? "Projected money saved automatically by the product during this year, without users doing anything"
                                                    : "Money saved automatically by the product during the period, without users doing anything", value: _jsx(NumberFormatter, { value: overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_autonomous_savings, options: { currency } }) }), _jsx(Tile, { title: "Assisted Money Savings", helpText: isFuture
                                                    ? "Project money saved during this year when users implement the opportunities identified by the product."
                                                    : "Money saved during the period when users implemented the opportunities identified by the product.", value: _jsx(NumberFormatter, { value: overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_assisted_savings, options: { currency } }) })] }) }), _jsx(Tile, { title: "Total Time Savings", helpText: isFuture
                                        ? "Projected time savings by the product during this year when users implement the opportunities identified by the product"
                                        : `Time saved during the period when users implemented the opportunities identified by the product.`, value: overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.time_saved, className: classes.time_saved })] }), _jsx(Stack, { direction: "column", className: classes.graphs, children: isFuture ? (_jsx(SavingsInFuture, { data: overallSavingsData })) : (_jsxs(_Fragment, { children: [_jsxs(Stack, { className: "justify-content-between", children: [" ", _jsx(Tabs, { items: [
                                                    { label: "Autonomous", value: "autonomous" },
                                                    { label: "Assisted", value: "assisted" },
                                                ], selectedTab: savingsType, onTabSelect: setSavingsType }), savingsType !== "assisted" && (_jsx(AggregateFilter, { onSelect: onAggregateSelect, aggregateBy: aggregateBy, isFuture: isFuture, startDate: currentStartDate, endDate: currentEndDate }))] }), _jsxs("div", { className: "flex-1", children: [savingsType === "autonomous" ? (_jsx("div", { style: { height: 400, padding: "1rem" }, children: _jsx(AutonomousSavingsSection, { yearMode: yearMode, currency: currency, data: data, isFetching: isFetching, savingsType: savingsType, aggregateBy: aggregateBy, isFuture: isFuture }) })) : null, savingsType === "assisted" ? (_jsx(AssistedSavingsSection, Object.assign({}, data))) : null] })] })) })] })) })] }));
};
export default SavingsBreakdown;
