import { Button, Card, CardBody } from "reactstrap";
import { getUser } from "../../services/auth";
import styles from "./styles.module.scss";
import { CopyButton } from "../../Components/CopyButton";
import { RobotBGCard } from "../../Components/Card";
import { useNavigate } from "react-router-dom";
import { getTenant, isFreeStack } from "../../helpers/tenant";
import alertIcon from "../../assets/icons/freemium_alert.svg";
import freemium_api_image from "../../assets/images/freemium_api.svg";
import { useQuery } from "react-query";
import { getDatastores } from "../../helpers/backend_helper";
import { useState } from "react";

export const GET_DATASTORES = "GET-DATASTORES";

const SettingsCard = ({ cardTitle, cardText, copyText }) => {
  return (
    <Card>
      <CardBody className="p-4">
        <h3>{cardTitle}</h3>
        <div className="text-muted mt-2 mb-2 fs-5">{cardText}</div>
        <div className={styles.token}>{copyText}</div>
        <CopyButton value={copyText} />
      </CardBody>
    </Card>
  );
};

const ApiKey = () => {
  const isFreeUser = isFreeStack();
  const navigate = useNavigate();
  const [showAddDatastore, setShowAddDatastore] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: [GET_DATASTORES],
    queryFn: getDatastores,
    onSuccess: (res) => {
      if (res["data_stores"].length === 0) {
        setShowAddDatastore(true);
      }
    },
  });
  const { auth_token } = getUser();
  const tenant = getTenant();

  const [showVSCodeImage, setShowVSCodeImage] = useState(false);

  const toggleVSCodeImage = () => {
    setShowVSCodeImage((prev) => !prev);
  };
  return (
    <>
      <div className={styles.api_container}>
        <SettingsCard
          cardTitle="Your API Key"
          cardText="Please use the below API key with Altimate CLI and Extensions"
          copyText={auth_token}
        />
        <SettingsCard
          cardTitle="Your Instance name"
          cardText="Please use the below Instance name with Altimate CLI and API"
          copyText={tenant}
        />
        {!isFreeUser && showAddDatastore && (
          <RobotBGCard>
            <div className="h-100 d-flex flex-column align-items-start">
              <h3>Add datastore for better results.</h3>
              <div className="text-muted mt-2 fs-5">
                Only metadata will be used to give better context to AI agent.
              </div>
              <div className="spacer" />
              <Button onClick={() => navigate("/settings/datastores/add")}>
                Add Datastores
              </Button>
            </div>
          </RobotBGCard>
        )}
      </div>
      {isFreeUser && (
        <div className={styles.freemium_api}>
          <div className="d-flex gap-sm">
            <img
              src={alertIcon}
              alt="alert-icon"
              onClick={toggleVSCodeImage}
              className={styles.info}
            />
            <div className={styles.text}>
              Where to add API key in VS Code Extension
            </div>
          </div>
          {showVSCodeImage && (
            <img
              src={freemium_api_image}
              alt="freemium_api_image"
              className={styles.vscode}
            />
          )}
        </div>
      )}
    </>
  );
};

export { ApiKey };
