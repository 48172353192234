import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
const useDateFromSearchParams = (initialStartDate, initialEndDate) => {
    const [searchParams] = useSearchParams();
    const [startDate, setStartDate] = useState(initialStartDate || dayjs().subtract(1, "week").toDate());
    const [endDate, setEndDate] = useState(initialEndDate || dayjs().toDate());
    useEffect(() => {
        const searchParamStartDate = searchParams.get("start_date");
        const searchParamEndDate = searchParams.get("end_date");
        if (searchParamStartDate) {
            setStartDate(new Date(searchParamStartDate));
        }
        if (searchParamEndDate) {
            setEndDate(new Date(searchParamEndDate));
        }
    }, [searchParams, setStartDate, setEndDate]);
    const onUpdate = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };
    return { startDate, endDate, onUpdate };
};
export { useDateFromSearchParams };
