import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { useQuery } from "react-query";
import { getPaymentIntent } from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../../config";
import altimate_ai from "../../assets/images/altimate_ai.svg";
import altimate_logo from "../../assets/images/altimate_logo.svg";
import { useLocation, useNavigate } from "react-router-dom";

const GET_PAYMENT_INTENT = "GET-PAYMENT-INTENT";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          window.location.protocol +
          "//" +
          window.location.host +
          "/settings/credits",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    business: { name: "Altimate AI" },
  };

  return (
    <form
      className={styles.payment_form}
      id="payment-form"
      onSubmit={handleSubmit}
    >
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        className={styles.payment_element}
      />
      <LinkAuthenticationElement
        id="link-authentication-element"
        className={styles.email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <AddressElement options={{ mode: "billing" }} />
      <button
        className={styles.payment_button}
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

const StripeForm = () => {
  const [clientSecret, setClientSecret] = useState("");

  const { data, isLoading: isPaymentIntentLoading } = useQuery({
    queryKey: [GET_PAYMENT_INTENT],
    queryFn: () => getPaymentIntent(),
    onSuccess: (res) => {
      setClientSecret(res?.clientSecret);
    },
  });

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  if (isPaymentIntentLoading) return <ComponentLoader left={90} />;

  return (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm clientSecret={clientSecret} />
    </Elements>
  );
};

const PaymentDetails = ({ amount }) => {
  const navigate = useNavigate();
  if (amount > 50 || amount <= 0) {
    navigate("/settings/executions");
  }
  return (
    <div className={styles.payment_details}>
      <div className="d-flex gap-xs mb-4">
        <img src={altimate_logo} alt="altimate_logo" />
        <img src={altimate_ai} alt="altimate_ai" />
      </div>
      <div className={styles.pay_text}>Pay Altimate Stripe Account</div>
      <div className={styles.amount}>${amount.toFixed(2)}</div>
      <div className={styles.amount_info}>500 credit for 50$</div>
      <div className={styles.charges}>
        <div className={styles.text_info}>Altimate Credits</div>
        <div className={styles.text_amount}>${amount.toFixed(2)}</div>
      </div>
    </div>
  );
};

const Payment = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [amount, setAmount] = useState(50);
  useEffect(() => {
    if (state && "remaining" in state) {
      setAmount((500 - state?.remaining) / 10);
    } else {
      navigate("/settings/executions");
    }
  }, [state]);

  return (
    <div className="d-flex justify-content-between">
      <PaymentDetails amount={amount} />
      <StripeForm />
    </div>
  );
};

export { Payment };
