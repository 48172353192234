var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { ArrowDownIcon, ArrowUpIcon } from "@/assets/icons";
import { useState } from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown as ReactstrapDropdown, } from "reactstrap";
import classes from "./dropdown.module.scss";
const Dropdown = (_a) => {
    var _b;
    var { options, onOptionSelect, prefix } = _a, rest = __rest(_a, ["options", "onOptionSelect", "prefix"]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const handleItemClick = (e) => {
        // @ts-expect-error valid
        onOptionSelect(e.target.value);
    };
    const selectedLabel = (_b = options.find((option) => option.value === rest.value)) === null || _b === void 0 ? void 0 : _b.label;
    const hasOptions = options.length > 1;
    return (_jsxs(ReactstrapDropdown, Object.assign({}, rest, { isOpen: dropdownOpen, toggle: toggle, className: classes.dropdown, children: [_jsxs(DropdownToggle, { className: "btn-ghost-secondary", outline: true, children: [prefix, " ", _jsxs("b", { children: [selectedLabel, " "] }), hasOptions ? !dropdownOpen ? _jsx(ArrowDownIcon, {}) : _jsx(ArrowUpIcon, {}) : null] }), hasOptions ? (_jsx(DropdownMenu, { children: options.map(({ label, value }) => (_jsx(DropdownItem, { onClick: handleItemClick, value: value, children: label }, value))) })) : null] })));
};
export default Dropdown;
