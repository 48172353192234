import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { StatusTagColors } from "../../constants";
import { capitalize } from "lodash";
const StatusTag = ({ status }) => {
    return (_jsx("div", { className: styles.statusTag, style: {
            backgroundColor: StatusTagColors[status].color,
            color: StatusTagColors[status].textColor,
        }, children: capitalize(status) }));
};
export default StatusTag;
