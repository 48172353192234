import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import classNames from "classnames";
import {
  getUserList,
  addNewUser,
  deleteUser,
  updateUser,
  updateUserRole,
} from "../../helpers/backend_helper";
import styles from "./styles.module.scss";
import vEllipsis from "../../assets/icons/vertical_ellipsis.svg";
import { ReactComponent as ArrowDownIcon } from "@/assets/icons/arrow_down.svg";
import { getUser } from "../../services/auth";
import { getTenant } from "../../helpers/tenant";
import dayjs from "dayjs";
import { useParams, useLocation } from "react-router-dom";
import { capitalize } from "lodash";
import { BetterPopover } from "../../Components/Popover";
import { GET_USER_LIST } from "../../services/constants";
import { Dropdown } from "../../Components/Form";
import { isFreeStack } from "../../helpers/tenant";
import { PermissionsEnum, RolesEnum } from "../../helpers/constants";
import MailChecker from "mailchecker";
import { FormInput } from "../Authentication/components/form";
import { ReactComponent as TickSquareIcon } from "../../assets/icons/tick_square.svg";
import { ReactComponent as InfoSquareIcon } from "../../assets/icons/info_square.svg";
import ApiSuccess from "../../assets/icons/datastore_form_success.svg";
import { freeEmailDomains } from "../Authentication/freeEmailDomains";
import { ListingTable } from "../../Components/Tables";
import { RelativeComponentLoader } from "../../Components/Loader";

const EMAIL_INPUT_ID = "free-user-email-input";

const getUserStatus = (user) => {
  if (user.is_active) return "active";
  if (user.is_verified) return "inactive";
  if (user.is_invited) return "invited";
  return "";
};

const styleMap = {
  active: styles.status_active,
  inactive: styles.status_inactive,
  invited: styles.status_invited,
};

const getStyledRolesList = (isPremium) => {
  return isPremium
    ? [
        {
          label: (
            <div className={styles.freemium_roles_list}>
              <div>{RolesEnum.USER}</div> <div></div>{" "}
              {/* <div className={styles.tag}>Enterprise</div> */}
            </div>
          ),
          value: RolesEnum.USER,
          // disabled: true,
        },
        {
          label: (
            <div className={styles.freemium_roles_list}>
              <div>{RolesEnum.ADMIN}</div> <div></div>{" "}
              <div className={styles.tag}>Enterprise</div>
            </div>
          ),
          value: RolesEnum.ADMIN,
          disabled: true,
        },
        {
          label: (
            <div className={styles.freemium_roles_list}>
              <div>{RolesEnum.OWNER}</div> <div></div> <div></div>
            </div>
          ),
          value: RolesEnum.OWNER,
        },
      ]
    : [
        { label: RolesEnum.OWNER, value: RolesEnum.OWNER },
        { label: RolesEnum.ADMIN, value: RolesEnum.ADMIN },
        { label: RolesEnum.USER, value: RolesEnum.USER },
      ];
};

const RolesDropdown = ({
  id,
  userRole,
  setUserRole,
  isFreemium,
  dispDropdown,
}) => (
  <>
    {dispDropdown ? (
      <Dropdown
        id={id}
        onChange={setUserRole}
        value={userRole}
        showDivider={true}
        options={getStyledRolesList(isFreemium)}
      >
        <div className={styles.select_role}>
          {userRole ? userRole : "Role"}
          <ArrowDownIcon />
        </div>
      </Dropdown>
    ) : (
      userRole
    )}
  </>
);

const UserList = () => {
  const { state } = useLocation();
  const [showApiSuccess, setShowApiSuccess] = useState(false);
  const [emailError, setEmailError] = useState("");
  const user = getUser();
  const tenant = getTenant();
  const isFreeUser = isFreeStack();
  let { company } = useParams();
  if (tenant) {
    company = tenant;
  }

  const updateRole = useMutation({
    mutationFn: (data) => {
      return updateUserRole(data);
    },
    onSuccess: (data) => {
      if (data.ok) {
        queryClient.invalidateQueries(GET_USER_LIST);
      }
    },
  });

  const queryClient = useQueryClient();
  const { data: users } = useQuery({
    queryKey: GET_USER_LIST,
    queryFn: () => getUserList(company),
    cacheTime: 300000,
  });
  const [showModal, setShowModal] = useState(state?.invite || false);
  const [email, setEmail] = useState("");
  const [inviteUserRole, setInviteUserRole] = useState("");
  const isPublicTenant = tenant === "";
  const changeRolePermission = isPublicTenant
    ? false
    : user.permission.includes(PermissionsEnum.USER_ROLE_EDIT);
  useEffect(() => {
    if (isPublicTenant) {
      setInviteUserRole(RolesEnum.OWNER);
    } else if (!changeRolePermission) {
      setInviteUserRole(user.role_FK.role_title);
    }
  }, []);
  const { mutate: _inviteUser } = useMutation(
    () => addNewUser({ email, company, role: inviteUserRole }),
    {
      onSuccess: (data) => {
        setShowApiSuccess(true);
        setTimeout(() => {
          setShowModal(false);
          setShowApiSuccess(false);
          setEmail("");
          setInviteUserRole("");
          setEmailError("");
          if (isPublicTenant) {
            setInviteUserRole(RolesEnum.OWNER);
          } else if (!changeRolePermission) {
            setInviteUserRole(user.role_FK.role_title);
          }
          queryClient.invalidateQueries(GET_USER_LIST);
        }, 3000);
      },
    }
  );
  const { mutate: _updateUser } = useMutation(updateUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_USER_LIST);
    },
  });

  function isFreeEmailProvider(email) {
    if (email.includes("@")) {
      const domain = email.split("@")[1];
      return freeEmailDomains.includes(domain);
    }
    return false;
  }

  const validateEmail = (e) => {
    if (!e) {
      setEmailError("");
    } else if (isFreeEmailProvider(e)) {
      setEmailError("Please use a valid business email address");
    } else if (!MailChecker.isValid(e)) {
      setEmailError("Please use a valid email address");
    } else {
      setEmailError("");
    }
  };

  const submitDisabled = !email || emailError !== "" || !inviteUserRole;
  const header = [
    {
      id: 1,
      label: "Name",
    },
    {
      id: 2,
      label: "Email",
    },
    {
      id: 3,
      label: "Status",
    },
    {
      id: 4,
      label: "Created at (UTC)",
    },
    {
      id: 5,
      label: "Role",
    },
  ];

  return (
    <>
      <Card>
        <CardBody className="p-4">
          <div className="d-flex justify-content-between align-items-center">
            <h1>Users</h1>
            <Button
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              disabled={
                !isPublicTenant &&
                !user.permission.includes(PermissionsEnum.USER_ADD)
              }
            >
              Invite user
            </Button>
          </div>
          {users ? (
            <ListingTable
              header={header}
              items={users}
              idKey={(item) => item.uuid}
              templateColumns="4fr 6fr 2fr 2fr 2fr 1fr"
              rowRender={(item) => {
                const status = getUserStatus(item);
                const actionId = "option-" + item.uuid;
                return (
                  <>
                    <div className="condense-text">
                      {item.first_name} {item.last_name}
                    </div>
                    <div className="condense-text">{item.email}</div>
                    <div className={styleMap[status]}>{capitalize(status)}</div>
                    <div>{item.created_at}</div>
                    <div>
                      <RolesDropdown
                        id={"role-" + actionId}
                        userRole={item.role_title}
                        setUserRole={(role_title) => {
                          updateRole.mutateAsync({
                            user_uuid: item.uuid,
                            role_title: role_title,
                          });
                        }}
                        isFreemium={isFreeUser}
                        dispDropdown={
                          !isPublicTenant &&
                          changeRolePermission &&
                          user.uuid !== item.uuid
                        }
                        currUserRole={
                          isPublicTenant ? null : user.role_FK.role_title
                        }
                      />
                    </div>
                    <div
                      className={classNames(styles.action, "cursor-pointer")}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img id={actionId} src={vEllipsis} alt="Options" />
                      <BetterPopover
                        target={actionId}
                        hideArrow
                        placement="left-start"
                      >
                        {({ close }) => (
                          <ul className={styles.list}>
                            <li
                              className={
                                status === "active" &&
                                !isPublicTenant &&
                                user.uuid !== item.uuid &&
                                user.permission.includes(
                                  PermissionsEnum.USER_EDIT
                                ) &&
                                item.email !== "serviceuser@altimate.ai"
                                  ? styles.enabled
                                  : styles.disabled
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                _updateUser({
                                  _id: item.uuid,
                                  is_active: false,
                                  is_verified: false,
                                });
                                close();
                              }}
                            >
                              Deactivate
                            </li>
                            <li
                              className={
                                status === "invited" &&
                                (isPublicTenant ||
                                  (user.uuid !== item.uuid &&
                                    user.permission.includes(
                                      PermissionsEnum.USER_ADD
                                    )))
                                  ? styles.enabled
                                  : styles.disabled
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                addNewUser({
                                  email: item.email,
                                  company,
                                  role: item.role_title,
                                });
                                close();
                              }}
                            >
                              Reinvite
                            </li>
                          </ul>
                        )}
                      </BetterPopover>
                    </div>
                  </>
                );
              }}
            />
          ) : (
            <RelativeComponentLoader />
          )}
        </CardBody>
      </Card>
      <Modal isOpen={showModal} toggle={() => setShowModal((b) => !b)} centered>
        <ModalHeader toggle={() => setShowModal((b) => !b)}>
          Invite User
        </ModalHeader>
        <ModalBody>
          <p className="text-muted">
            An invitation email will be sent to the user
          </p>
          <div className={styles.role_input}>
            <div className="d-flex flex-column">
              <div
                className={classNames(styles.tenant_form_input, {
                  [styles.in_focus]:
                    document.activeElement.id === EMAIL_INPUT_ID,
                  [styles.is_invalid]: emailError !== "",
                })}
              >
                <FormInput
                  id={EMAIL_INPUT_ID}
                  name="email"
                  type="email"
                  placeholder="name@company.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                />
                {email !== "" && (
                  <div className={styles.icon}>
                    {emailError === "" ? (
                      <TickSquareIcon />
                    ) : (
                      <InfoSquareIcon />
                    )}
                  </div>
                )}
              </div>
              {emailError !== "" && (
                <div
                  className={classNames(styles.form_feedback, {
                    [styles.is_error]: emailError !== "",
                  })}
                >
                  <span>{emailError}</span>
                </div>
              )}
            </div>
            <div></div>
            <RolesDropdown
              id={"send-invite-" + user.uuid}
              userRole={inviteUserRole}
              setUserRole={setInviteUserRole}
              isFreemium={isFreeUser}
              dispDropdown={!isPublicTenant && changeRolePermission}
            />
          </div>
          <Button
            className={classNames("mt-4", styles.send_invite, {
              [styles.send_invite_disabled]: submitDisabled,
            })}
            disabled={submitDisabled}
            onClick={() => _inviteUser()}
          >
            Send Invite
          </Button>
          {showApiSuccess && (
            <div className="mt-3 d-flex gap-sm">
              <img src={ApiSuccess} />
              <span className={styles.api_success}>
                Invitation sent successfully
              </span>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export { UserList };
