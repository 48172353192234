import { useContext, useMemo, useState } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  Handle,
  Position,
  getBezierPath,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import styles from "./styles.module.scss";
import { getIconByDatastoreType } from "../../pages/Datasets/DatastoreImage";
import classNames from "classnames";
import {
  COLUMNS_SIDEBAR,
  LENS_TYPE_COLOR,
  TABLES_SIDEBAR,
  T_NODE_W,
  destructTable,
  getColY,
  getSeeMoreId,
} from "./utils";
import { LineageContext, StaticLineageContext } from "./Lineage";
import {
  layoutElementsOnCanvas,
  highlightTableConnections,
  processColumnLineage,
  removeRelatedNodesEdges,
  resetTableHighlights,
  createNewNodesEdges,
} from "./graph";
import { ReactComponent as LineageBiDirIcon } from "../../assets/icons/lineage-bidir.svg";
import { BetterTooltip } from "../Tooltip";
import { ReactComponent as CodeIcon } from "../../assets/icons/code3.svg";

import { ReactComponent as SqlFilterLightIcon } from "../../assets/icons/sql_filter_light.svg?react";
import { ReactComponent as SqlGroupByLightIcon } from "../../assets/icons/sql_group_by_light.svg?react";
import { ReactComponent as SqlInnerJoinLightIcon } from "../../assets/icons/sql_inner_join_light.svg?react";
import { ReactComponent as SqlLeftJoinLightIcon } from "../../assets/icons/sql_left_join_light.svg?react";
import { ReactComponent as SqlLimitLightIcon } from "../../assets/icons/sql_limit_light.svg?react";
import { ReactComponent as SqlOrderByLightIcon } from "../../assets/icons/sql_order_by_light.svg?react";
import { ReactComponent as SqlOuterJoinLightIcon } from "../../assets/icons/sql_outer_join_light.svg?react";
import { ReactComponent as SqlRightJoinLightIcon } from "../../assets/icons/sql_right_join_light.svg?react";
import { ReactComponent as SqlUnionLightIcon } from "../../assets/icons/sql_union_light.svg?react";

import { ReactComponent as ModelIcon } from "../../assets/icons/model.svg?react";
import { ReactComponent as SeedIcon } from "../../assets/icons/seed.svg?react";
import { ReactComponent as SourceIcon } from "../../assets/icons/source.svg?react";
import { ReactComponent as ExposureIcon } from "../../assets/icons/exposure.svg?react";
import { ReactComponent as AnalysisIcon } from "../../assets/icons/analysis.svg?react";
import { ReactComponent as SnapshotIcon } from "../../assets/icons/snapshot.svg?react";
import { ReactComponent as MetricsIcon } from "../../assets/icons/metrics.svg?react";
import { ReactComponent as MacrosIcon } from "../../assets/icons/macros.svg?react";

const HANDLE_OFFSET = "-1px";

const BidirectionalHandles = () => (
  <>
    <Handle
      id="left"
      type="source"
      className="invisible"
      isConnectable={false}
      position={Position.Left}
      style={{ left: HANDLE_OFFSET }}
    />
    <Handle
      id="right"
      type="source"
      className="invisible"
      isConnectable={false}
      position={Position.Right}
      style={{ right: HANDLE_OFFSET }}
    />
    <Handle
      id="left"
      type="target"
      className="invisible"
      isConnectable={false}
      position={Position.Left}
      style={{ left: HANDLE_OFFSET }}
    />
    <Handle
      id="right"
      type="target"
      className="invisible"
      isConnectable={false}
      position={Position.Right}
      style={{ right: HANDLE_OFFSET }}
    />
    <Handle
      id="top"
      type="source"
      className="invisible"
      isConnectable={false}
      position={Position.Top}
      style={{ top: HANDLE_OFFSET }}
    />
    <Handle
      id="bottom"
      type="source"
      className="invisible"
      isConnectable={false}
      position={Position.Bottom}
      style={{ bottom: HANDLE_OFFSET }}
    />
    <Handle
      id="top"
      type="target"
      className="invisible"
      isConnectable={false}
      position={Position.Top}
      style={{ top: HANDLE_OFFSET }}
    />
    <Handle
      id="bottom"
      type="target"
      className="invisible"
      isConnectable={false}
      position={Position.Bottom}
      style={{ bottom: HANDLE_OFFSET }}
    />
  </>
);

export const TableNode = ({ data }) => {
  const { table, upstreamCount, downstreamCount, level } = data;
  const flow = useReactFlow();
  // hack to force re-render the component
  const [_, _rerender] = useState(0);
  const rerender = () => _rerender((x) => x + 1);

  const {
    selectedTable,
    setSelectedTable,
    selectedColumn,
    setShowSidebar,
    setSidebarScreen,
    collectColumns,
    setCollectColumns,
    downstreamTables,
    upstreamTables,
    postConnectedColumns,
  } = useContext(LineageContext);

  const _columnLen = Object.keys(collectColumns[table] || {}).length;
  const _showColumns = _columnLen > 0;
  const selected = selectedTable === table;

  const expand = async (t, tables, right) => {
    tables.sort((a, b) => {
      const [, , tableA] = destructTable(a.table);
      const [, , tableB] = destructTable(b.table);
      return tableA.localeCompare(tableB);
    });
    let [nodes, edges] = [flow.getNodes(), flow.getEdges()];
    createNewNodesEdges(nodes, edges, tables, t, right, level);
    if (selectedColumn) {
      const {
        nodes: _nodes,
        edges: _edges,
        collect_columns,
      } = await processColumnLineage(
        nodes,
        edges,
        selectedColumn,
        selectedTable,
        postConnectedColumns
      );
      nodes = _nodes;
      edges = _edges;
      setCollectColumns(collect_columns);
    } else if (selectedTable) {
      const [_nodes, _edges] = highlightTableConnections(
        nodes,
        edges,
        selectedTable
      );
      nodes = _nodes;
      edges = _edges;
    }
    layoutElementsOnCanvas(nodes, edges);
    flow.setNodes(nodes);
    flow.setEdges(edges);
    rerender();
  };

  const expandRight = async (t) => {
    const { tables } = await upstreamTables(t);
    await expand(t, tables, true);
  };

  const expandLeft = async (t) => {
    const { tables } = await downstreamTables(t);
    await expand(t, tables, false);
  };

  const collapse = (right) => (t) => {
    const [nodes, edges] = removeRelatedNodesEdges(
      flow.getNodes(),
      flow.getEdges(),
      t,
      right,
      level
    );
    layoutElementsOnCanvas(nodes, edges);
    flow.setNodes(nodes);
    flow.setEdges(edges);
    rerender();
  };

  const collapseLeft = collapse(false);
  const collapseRight = collapse(true);
  const toggleTableSelection = () =>
    setSelectedTable((prev) => (prev === table ? "" : table));

  const highlightTable = () => {
    if (selectedColumn) return;
    const _nodes = flow.getNodes();
    const _edges = flow.getEdges();
    const [nodes, edges] = selected
      ? resetTableHighlights(_nodes, _edges)
      : highlightTableConnections(_nodes, _edges, table);
    flow.setNodes(nodes);
    flow.setEdges(edges);
  };

  const onNodeClick = (e) => {
    e.stopPropagation();
    toggleTableSelection();
    highlightTable();
  };

  const onDetailsClick = (e) => {
    if (!selected) return;
    e.stopPropagation();
    setShowSidebar(true);
    setSidebarScreen(COLUMNS_SIDEBAR);
  };

  const [datastore_type, schema, tableName] = destructTable(table);
  const _edges = flow.getEdges();
  const processed = [
    downstreamCount === _edges.filter((e) => e.target === table).length ||
      flow.getNode(getSeeMoreId(table, false)),
    upstreamCount === _edges.filter((e) => e.source === table).length ||
      flow.getNode(getSeeMoreId(table, true)),
  ];

  return (
    <div
      className="position-relative"
      style={{
        opacity: !selectedColumn ? 1 : _showColumns ? 1 : 0.5,
      }}
    >
      <div className={styles.table_node} onClick={onNodeClick}>
        <div
          className={classNames(
            styles.header,
            "d-flex flex-column align-items-start",
            {
              [styles.selected]: selected,
              [styles.collapse]: !_showColumns,
            }
          )}
        >
          <div className={styles.table_header}>
            <div>
              {datastore_type && getIconByDatastoreType(datastore_type)}
            </div>
            <div className="lines-2">{tableName}</div>
            <div />
            {schema && <div className="text-muted text-overflow">{schema}</div>}
          </div>
          <div>
            {upstreamCount > 0 && (
              <div
                className={classNames(
                  "nodrag",
                  styles.table_handle,
                  styles.right_handle
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  if (processed[1]) {
                    collapseRight(table);
                  } else {
                    expandRight(table);
                  }
                }}
              >
                {processed[1] ? "-" : "+"}
              </div>
            )}
            {downstreamCount > 0 && (
              <div
                className={classNames(
                  "nodrag",
                  styles.table_handle,
                  styles.left_handle
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  if (processed[0]) {
                    collapseLeft(table);
                  } else {
                    expandLeft(table);
                  }
                }}
              >
                {processed[0] ? "-" : "+"}
              </div>
            )}
            <div
              className={classNames(
                "nodrag ms-3",
                selected ? "text-primary" : "text-muted"
              )}
              onClick={onDetailsClick}
            >
              View Details
            </div>
          </div>
        </div>
        {_showColumns && (
          <div
            className={classNames(styles.content, {
              [styles.selected]: selected,
            })}
            style={{ height: getColY(_columnLen) }}
          />
        )}
      </div>

      <BidirectionalHandles />
    </div>
  );
};

const NODE_TYPE_STYLES = {
  seed: styles.seed,
  model: styles.model,
  source: styles.source,
  exposure: styles.exposure,
  snapshot: styles.snapshot,
  semantic_model: styles.metrics,
  macros: styles.macros,
  analysis: styles.analysis,
  cte: styles.model,
  unknown: styles.exposure,
};

const NODE_TYPE_SHORTHAND = {
  seed: "SED",
  model: "MDL",
  source: "SRC",
  exposure: "EXP",
  snapshot: "SNP",
  semantic_model: "MET",
  macros: "SEM",
  analysis: "ANY",
  cte: "CTE",
  unknown: "UNK",
};

export const NodeTypeIcon = ({ nodeType }) => (
  <div className={classNames(styles.node_icon)}>
    {nodeType === "seed" && <SeedIcon />}
    {nodeType === "model" && <ModelIcon />}
    {nodeType === "cte" && <ModelIcon />}
    {nodeType === "source" && <SourceIcon />}
    {nodeType === "exposure" && <ExposureIcon />}
    {nodeType === "analysis" && <AnalysisIcon />}
    {nodeType === "snapshot" && <SnapshotIcon />}
    {nodeType === "semantic_model" && <MetricsIcon />}
    {nodeType === "macros" && <MacrosIcon />}
    {nodeType === "unknown" && <ModelIcon />}
    <div className={styles.node_type_text}>{NODE_TYPE_SHORTHAND[nodeType]}</div>
  </div>
);

export const StaticTableNode = ({ data }) => {
  const { table } = data;

  const {
    selectedColumn,
    collectColumns,
    detailColumns,
    setSelectedTable,
    isDBT,
  } = useContext(StaticLineageContext);

  const _columnLen = Object.keys(collectColumns[table] || {}).length;
  const _showColumns = _columnLen > 0;
  const selected = selectedColumn?.table === table;

  const [datastore_type, schema, tableName] = destructTable(table);
  const nodeType = detailColumns?.[table]?.node_type || "unknown";
  return (
    <div
      className="position-relative"
      style={{
        opacity: !selectedColumn ? 1 : _showColumns ? 1 : 0.5,
      }}
    >
      <div className={styles.table_node}>
        <div
          className={classNames(
            styles.header,
            "d-flex flex-column align-items-start",
            {
              [styles.selected]: selected,
              [styles.collapse]: !_showColumns,
            }
          )}
        >
          <div className={styles.table_header}>
            <div>
              {isDBT ? (
                <NodeTypeIcon nodeType={nodeType} />
              ) : datastore_type ? (
                getIconByDatastoreType(datastore_type)
              ) : null}
            </div>
            <div className="lines-2">{tableName}</div>
            <div />
            {schema && <div className="text-muted text-overflow">{schema}</div>}
          </div>
          <div
            className={classNames(
              "nodrag ms-3",
              detailColumns ? "text-primary" : "text-muted"
            )}
          >
            <span
              className={!detailColumns ? styles.cursor_disabled : ""}
              onClick={(e) => {
                e.stopPropagation();
                if (!detailColumns) return;
                setSelectedTable(table);
              }}
            >
              View Details
            </span>
          </div>
        </div>
        {_showColumns && (
          <div
            className={classNames(styles.content, {
              [styles.selected]: selected,
            })}
            style={{ height: getColY(_columnLen) }}
          />
        )}
      </div>
      <BidirectionalHandles />
    </div>
  );
};

export const SeeMoreNode = ({ data: { tables, prevTable, right, level } }) => {
  const { setShowSidebar, setMoreTables, setSidebarScreen } =
    useContext(LineageContext);
  const flow = useReactFlow();
  return (
    <div
      className={classNames("d-flex", styles.see_more_node)}
      onClick={(e) => {
        e.stopPropagation();
        setShowSidebar(true);
        setSidebarScreen(TABLES_SIDEBAR);
        setMoreTables({ tables, prevTable, right, level });
      }}
    >
      <div className="fw-semibold">See more</div>
      <div className="spacer" />
      <div>{tables.filter((t) => !flow.getNode(t.table)).length || ""}</div>
      <BidirectionalHandles />
    </div>
  );
};

export const LensTypeBadge = ({ lensType }) => (
  <BetterTooltip tooltipContent={lensType}>
    <div
      style={{ "--lens-color": LENS_TYPE_COLOR[lensType] }}
      className={styles.lens_type_badge}
    >
      {lensType[0]}
    </div>
  </BetterTooltip>
);

export const ColumnNode = ({
  data: { column, table, lensType, lensCodes },
}) => {
  const { selectedColumn, setLensCodeModal } = useContext(LineageContext);
  const lensColor = lensType && LENS_TYPE_COLOR[lensType];
  const customStyles = lensColor ? { border: `1px solid ${lensColor}` } : {};

  const viewsCodesFlat = useMemo(() => {
    const arr = Object.values(lensCodes || {})
      .flat()
      .filter(([, type]) => type === "transform")
      .map(([code]) => code);
    const result = [];
    for (const item of arr) {
      if (result.includes(item)) continue;
      result.push(item);
    }
    return result;
  }, [lensCodes]);

  return (
    <div
      className={classNames(styles.column_node, {
        [styles.selected]: selectedColumn === `${table}/${column}`,
      })}
      style={customStyles}
    >
      <div className={styles.column_name}>{column}</div>
      <BidirectionalHandles />
      <div className={styles.column_top_right}>
        {viewsCodesFlat.length > 0 && (
          <BetterTooltip tooltipContent={"Click to view code"}>
            <div
              className={styles.column_code_icon}
              onClick={(e) => {
                e.stopPropagation();
                if (Object.keys(lensCodes).length === 0) return;
                setLensCodeModal({
                  table,
                  column,
                  lensType,
                  lensCodes,
                });
              }}
            >
              <CodeIcon />
            </div>
          </BetterTooltip>
        )}
        {lensType && lensType !== "Non select" && (
          <LensTypeBadge lensType={lensType} />
        )}
      </div>
    </div>
  );
};

export const SelfConnectingEdge = (props) => {
  const { sourceX, sourceY, targetX, targetY, id, markerEnd } = props;
  const radiusX = (sourceX - targetX) * 0.6;
  const radiusY = 50;
  const edgePath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${
    targetX + 2
  } ${targetY}`;

  return <BaseEdge path={edgePath} markerEnd={markerEnd} />;
};

export const IconReverseEdge = (props) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
  } = props;
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
          className={classNames("nodrag nopan", styles.lineage_bidir_icon)}
        >
          <LineageBiDirIcon />
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

const LIGHT_ICONS = {
  INNER_JOIN: <SqlInnerJoinLightIcon />,
  OUTER_JOIN: <SqlOuterJoinLightIcon />,
  LEFT_JOIN: <SqlLeftJoinLightIcon />,
  RIGHT_JOIN: <SqlRightJoinLightIcon />,
  FILTER: <SqlFilterLightIcon />,
  GROUP: <SqlGroupByLightIcon />,
  LIMIT: <SqlLimitLightIcon />,
  SORT: <SqlOrderByLightIcon />,
  UNION: <SqlUnionLightIcon />,
};

export const OpNode = ({ data }) => {
  const { type, expression } = data;
  return (
    <div style={{ width: T_NODE_W, display: "flex", justifyContent: "center" }}>
      <BidirectionalHandles />
      <BetterTooltip tooltipContent={expression}>
        <div className="d-flex flex-column">
          <div className={styles.op_node}>{LIGHT_ICONS[type]}</div>
          <div className={styles.op_type_text}>{type}</div>
        </div>
      </BetterTooltip>
    </div>
  );
};
