import { APIClient } from "./api_helper";

import * as url from "./url_helper";
import { downloadBlob } from "./utils";

export const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => getLoggedInUser() !== null;

// Register Method
export const postLogin = (data) => api.post("/auth/login", data);

export const postRegisterInvite = (data) =>
  api.post("/auth/register-invite", data);

export const postForgotPassword = (data) =>
  api.post("/auth/forgot-password", data);

export const postResetPassword = (data) =>
  api.post("/auth/reset-password", data);

export const getEmailInstanceList = (email) =>
  api.get(`/companies/get-all-companies/${email}`);

// postForgetPwd
export const postForgetPwd = (data) => api.post(url.POST_PASSWORD_FORGET, data);

export const postProfile = (data) =>
  api.update(url.POST_EDIT_PROFILE + "/" + data.idx, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.post(url, data).catch((err) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support company";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// postSocialLogin
export const postSocialLogin = (data) => api.post(url.SOCIAL_LOGIN, data);

// Settings
// get User
export const getUserList = (company, with_service_user = false) =>
  api.get("/users/", { company, with_service_user });
export const addNewUser = (data) => api.post("/users/invite", data);
export const updateUser = (user) => api.update(`/users/${user._id}`, user);
export const deleteUser = ({ uuid }, company) =>
  api.delete(`/users/${uuid}?company=${company}`);
export const postUserOnboard = () => api.post("/users/onboard", {});
export const updateUserRole = (data) => api.post("/users/role_edit/", data);

// get User Permissions
export const getUserPermissions = (roleUuid) =>
  api.get(`/permissions/${roleUuid}`);

// companies
export const getCompanyList = () => api.get("/companies/");
export const addNewCompany = (company) => api.post("/companies/", company);
export const updateCompany = (company) =>
  api.update(`/companies/${company._id}`, company);
export const deleteCompany = (company) =>
  api.delete(`/companies/${company.uuid}`, company);
export const companyAvailable = (company) =>
  api.get(`/companies/available/${company}`);
export const companyRegister = (data) => api.post("/companies/register", data);
export const verifyFreeUser = (data) =>
  api.post("/companies/verify-user", data);

// incidents
export const getIncidents = (data) => api.get("/violations/", data);
export const getSummary = (data) => api.get("/violations/summary", data);
export const getIncidentById = (id) => api.get(`/violations/id/${id}`);
export const postSnoozeIncident = ({ id, snoozed_till }) =>
  api.post(`/violations/${id}/snooze/${snoozed_till}`);

// contracts
export const getContracts = () => api.get("/contracts/");
export const getContractList = (data) => api.get("/contracts/list", data);
export const getContractDetail = (contractId) =>
  api.get(`/contracts/details/${contractId}`);
export const validateContract = ({ registryId, contractId }) =>
  api.post(`/registries/${registryId}/contracts/${contractId}/validate`);

// datasets
export const getTableFilter = () => api.get("/tables/filters");
export const getTables = (data) => api.get("/tables/", data);
export const getTableById = (id) => api.get(`/tables/items/${id}`);
export const getTableDescriptionById = (id) =>
  api.get(`/tables/table_description/${id}`);
export const updateTableSchemaDesc = (id, data) =>
  api.update(`/tables/column/${id}`, data);
export const updateTablePurpose = (id, data) =>
  api.update(`/tables/${id}`, data);
export const getTags = () => api.get("/tables/columns/tags/");
export const getTablesAccessed = () => api.get("/tables/recent");
export const generateDescription = (id, data) =>
  api.post(`/recommendations/description/${id}`, data);
export const getTableInsightsById = (id) => api.get(`/tables/insights/${id}`);
export const postCustomTableInsight = ({ id, data }) =>
  api.post(`/tables/custom/${id}`, data);
export const getTableInsightsStatus = (id) =>
  api.get(`/tables/insights_status/${id}`);
export const getTableRk = (id) => api.get(`/tables/table_rk/${id}`);
export const getTableId = (table_rk) =>
  api.post(`/tables/table_id`, { table_rk });
export const getTableStorageOverTimeById = (id) =>
  api.get(`/tables/storage_details/${id}`);
export const getColumnAccessHistory = (page, size, data) =>
  api.post(`/tables/access_history/?page=${page}&size=${size}`, data);
export const getColumnUserAccessHistoryFilters = (tableID) =>
  api.get(`/tables/access_history/filters/?table_id=${tableID}`);
export const getColumnUserAccessHistory = (data) =>
  api.post(`/tables/access_history/column/`, data);
export const getDatasetList = (params) => api.get("/tables/tabledata/", params);
export const getDatasetListFilters = () =>
  api.get("/tables/tabledata/filters/");

// Timeline events
export const getTimelineEventsByContractId = (id) => api.get(`/events/${id}`);
export const getValidations = (data) => api.get(`/events/validations/`, data);

// recommendations
export const postRules = (data) => api.post("/recommendations/rules/", data);
export const postTerms = (data) => api.post("/recommendations/terms/", data);
export const postFeedbackRule = (data) => api.post("/feedbacks/rule", data);
export const postFeedbackTerm = (data) => api.post("/feedbacks/term", data);
export const postFeedback = (data) => api.post("/feedbacks", data);

// debug sql
export const getDebugSQLByIncidentId = (id) => api.get(`/debug_sql/${id}`);

//Datastores
export const getDatastores = () => api.get(`/data_stores/`);
export const testDatastoreConnection = (id) =>
  api.get(`/data_stores/${id}/validate`);
export const addDatastore = (data) => api.post("/data_stores/form/", data);
export const testDatastoreConnectionDuringCreation = (data) =>
  api.post("/data_stores/form/test_connection/", data);
export const getDatastoreDetail = (datastoreId) =>
  api.get(`/data_stores/${datastoreId}`);
export const getDatastoreTypes = () => api.get("/data_stores/types/");

// copilot workflow
export const createSession = (data) => api.post("/copilot/session", data);
export const getChat = (id) => api.get(`/copilot/session/${id}`);
export const postChat = (id, data) => api.post(`/copilot/session/${id}`, data);
export const getAllTables = () => api.get("/copilot/tables");
export const postTasks = (data) => api.post("/copilot/tasks", data);
export const getAllChatSessions = () => api.get("/copilot/session");
export const processWorkflowInputs = (data) =>
  api.post("/copilot/process_inputs", data);
export const generateDocs = (data) => api.post("/copilot/generate_docs", data);

export const cancelRequest = () => api.cancel();

// notification
export const getSlackWebhook = () => api.get("/notification");
export const postSlackWebhook = (data) => api.post("/notification", data);

// sync apis
export const syncDatastore = (datastoreId) => api.post(`/sync/${datastoreId}`);
export const getSyncHistory = (datastoreId, data) =>
  api.get(`/events/sync_history/${datastoreId}`, data);

// lineage
export const postColumns = (data) => api.get(`/lineage/schema`, data);
export const postConnectedColumns = (data) =>
  api.post(`/lineage/connected`, data);
export const upstreamTables = (fqn) => api.post(`/lineage/upstream`, { fqn });
export const downstreamTables = (fqn) =>
  api.post(`/lineage/downstream`, { fqn });
export const altimateToAmundsen = (params) =>
  api.get(`/lineage/terminal`, params);

// query
export const getAllQueries = (params) => api.get("/query/v2/", params);
export const getLastFetchedTime = () => api.get("/query/last_fetched_time");
export const getAllQueriesCSVFile = (params) =>
  api
    .get("/query/v2/download", params)
    .then((blob) => {
      downloadBlob(blob);
    })
    .catch((error) => {
      console.error(error);
    });
export const getQueryById = (id, params) =>
  api.get(`/query/v2/items/${id}`, params);
export const getQueryGroupLatestQuery = (id) =>
  api.get(`/query/v2/group_latest_query/${id}`);
export const getQueryFilters = (params) =>
  api.get("/query/v2/filters/", params);
export const getQueryExplanation = (id) =>
  api.get(`/copilot/quickchat/query-explain/${id}`);
export const getQueryGroupExplanation = (id) =>
  api.get(`/copilot/quickchat/query-group-explain/${id}`);
export const getAllQueryGroups = (params) =>
  api.get("/query/v2/groups", params);
export const getQueryGroupDetail = (hash, params) =>
  api.get(`/query/v2/groups/${hash}`, params);
export const updateQueryGroupName = (hash, data) =>
  api.update(`/query/groups/${hash}`, data);
export const getAllQueryGroupCSVFile = (params) =>
  api
    .get("/query/download/groups", params)
    .then((blob) => {
      downloadBlob(blob);
    })
    .catch((error) => {
      console.error(error);
    });

// dbt config apis
export const getAllDBTConfigs = (params) => api.get("/dbtconfig/", params);
export const getDBTConfigById = (id) => api.get(`/dbtconfig/${id}`);
export const addDBTConfig = (data) => api.post("/dbtconfig/", data);
export const updateDBTConfig = (id, data) =>
  api.update(`/dbtconfig/${id}`, data);
export const getDBTConfigFilters = () => api.get("/dbtconfig/filters/list");
export const getDBTConfigChecks = (params) =>
  api.get("/dbtconfig/checks/list", params);
export const getDBTConfigChecksFilters = () =>
  api.get("/dbtconfig/checks/filters");

// schedule apis
export const toggleContractSchedule = (contractId) =>
  api.post(`/scheduler/${contractId}`);
export const toggleDatastoreSyncSchedule = (data_store_id) =>
  api.post(`/scheduler/sync/${data_store_id}`);

// mask apis
export const getMaskData = () => api.get("/mask/");
export const setMaskData = (data) => api.post("/mask/", data);

// feedback api
export const getAIFeedback = (params) => api.get("/feedbacks/ai", params);
export const postAIFeedback = (params) => (data) =>
  api.postWithParams("/feedbacks/ai", params, data);

// Payment apis
export const getPaymentIntent = () => api.get("/payment/intent");
export const createCheckoutSession = (data) =>
  api.post("/payment/checkout", data);
export const postCheckoutSession = (data) =>
  api.post("/payment/checkout/update", data);
export const downgradePlan = (data) => api.post("/payment/downgrade", data);
export const cancelPlanDowngrade = () => api.post("/payment/downgrade/cancel");
export const downgradePlanFeedback = (data) =>
  api.post("/payment/downgrade/feedback", data);
export const getCreditUsage = () => api.get("/payment/credits");
export const getExecutionHistory = (all_time) =>
  api.get(`/events/execution_history/?all_time=${all_time}`);
export const getExecutionAnalytics = (period) =>
  api.get(`/events/analytics_history/?period=${period}`);
export const getAllPlans = (tenure) => api.get(`/payment/plans/${tenure}`);

// Warehouse apis
// Will need to change apis
export const getAllWarehouses = (params) => api.get("/warehouse/", params);
export const getWarehouse = (warehouse_rk, params) =>
  api.get(`/warehouse/warehouse_rk/${warehouse_rk}/`, params);
export const getWarehouseFilters = (params) =>
  api.get("/warehouse/filters/", params);
export const getWarehouseCost = (warehouse_rk, params) =>
  api.get(`/warehouse/cost/warehouse_rk/${warehouse_rk}/`, params);
export const getWarehouseCostV2 = (warehouse_rk, params) =>
  api.get(`/warehouse/cost/warehouse_rk/v2/${warehouse_rk}/`, params);
export const getWarehouseLastEventTime = () =>
  api.get("/warehouse/last_event_time");
export const getWarehouseMetadata = () => api.get("/warehouse/metadata");
export const getWarehouseInsights = (data) =>
  api.post(`/warehouse/insights`, data);

// Summary apis
export const getSummaryGraph = (data) => api.post("/summary/", data);
export const getSummaryStorageComparison = (data) =>
  api.post("/summary/comparison", data);
export const getSummaryInsights = (data) => api.get("/summary/insights", data);
export const getSummarySidepanel = (data) =>
  api.get("/summary/side_panel", data);
export const getSummaryConfig = () => api.get("/summary/config");
export const postSummaryConfig = (data) => api.post("/summary/config", data);
export const getExperimentationSavings = () =>
  api.get("/summary/experimentation_savings");

// Governance apis
export const getGovernanceUsers = (data) => api.get("/governance/users", data);
export const getGovernanceRoles = (data) => api.get("/governance/roles", data);
export const getGovernanceSummary = () => api.get("/governance/summary");
export const getGovernanceFilters = () => api.get("/governance/filters");
export const getGovernanceUsersCostGraph = (data) =>
  api.get("/governance/users/cost", data);
export const getGovernanceRolesCostGraph = (data) =>
  api.get("/governance/roles/cost", data);
export const getGovernanceUserDetails = (param) =>
  api.get(`/governance/users/details/${param}`);
export const getGovernanceRoleDetails = (param) =>
  api.get(`/governance/roles/details/${param}`);

// alerts
export const getAlertEvents = (data) => api.get("/events/alerts/", data);
export const getAlertRules = (data) => api.get("/alerts/rules/", data);
export const getAllSlackChannels = () =>
  api.get("/notification/slack_channels");

// dbt integration
export const getIntegrations = () => api.get("/dbt/v1/project_integrations");
export const getIntegrationEnvironments = (integrationId) =>
  api.get(`/dbt/v1/integration_environments/${integrationId}`);
export const getIntegrationByID = (intergrationId, intergrationEnv) =>
  api.get(`/dbt/v1/project_integrations/${intergrationId}/${intergrationEnv}`);
export const postIntegration = (data) =>
  api.post("/dbt/v1/project_integration", data);
export const deleteDBTIntegration = (id) =>
  api.delete(`/dbt/v1/project_integration/${id}`);

// dbt share query result
export const getSharedQueryResult = (data) =>
  api.get("/dbt/v3/query-result/list", data);
export const getSharedQueryResultById = (id) =>
  api.get(`/dbt/v3/query-result/list/${id}`);
export const deleteSharedQueryResultById = (id) =>
  api.delete(`/dbt/v3/query-result/list/${id}`);
export const updateSharedQueryResultById = (id, data) =>
  api.update(`/dbt/v3/query-result/list/${id}`, data);

// Dbt docs conversation apis
export const getDbtDocsShareList = (params) =>
  api.get(`dbt/dbt_docs_share`, params);
export const getDbtDocsShareListFilters = () =>
  api.get(`dbt/dbt_docs_share/filters`);
export const getDbtDocsShareUrl = (shareId) =>
  api.get(`dbt/dbt_docs_share/${shareId}`);
export const deleteDbtDocsShare = (shareId) =>
  api.delete(`dbt/dbt_docs_share/${shareId}`);
export const getCurrentUser = () => api.get("/dbt/dbt_docs_share/user/details");

// experimentation
export const getAllExperiments = (data) => api.get("/experimentation", data);
export const getExperimentById = (id) => api.get(`/experimentation/${id}`);
export const getExperimentSummary = () =>
  api.get(`/experimentation/metadata/summary`);
export const createExperiment = (data) => api.post(`/experimentation`, data);
export const updateExperiment = (id, data) =>
  api.update(`/experimentation/${id}`, data);
export const updateExperimentStatus = (id, data) =>
  api.update(`/experimentation/${id}/status`, data);
export const getSchemaMetadata = () =>
  api.get(`/experimentation/schema/metadata`);

// query jobs
export const getQueryJobs = (params) => api.get("/query/jobs", params);
export const getQueryJobExecutions = (id, params) =>
  api.get(`/query/jobs/executions/${id}`, params);
export const getJobDefinitions = (id) =>
  api.get(`/query/jobs/definitions/${id}`);
export const updateQueryJobName = (id, data) =>
  api.post(`/query/job/edit_name/${id}`, data);
export const getQueryJobFilters = (params) =>
  api.get("/query/job/filters/", params);

// experiment graph data
export const getQueryJobData = (id, params) =>
  api.get(`/query/jobs/graph_data/${id}`, params);
export const getQueryGroupData = (id, params) =>
  api.get(`/query/groups/graph_data/${id}`, params);
export const getWarehouseAutoSavingsData = (warehouse_rk, params) =>
  api.get(`/warehouse/auto_savings/${warehouse_rk}`, params);
export const getWarehouseAutoSavingsDataV2 = (warehouse_rk, params) =>
  api.get(`/warehouse/auto_savings/v2/${warehouse_rk}`, params);
export const toggleWarehouseAutoSavings = (warehouse_rk) =>
  api.post(`/warehouse/toggle-auto-manage/${warehouse_rk}`);
export const getWarehouseAutoSavings = (warehouse_rk) =>
  api.get(`/warehouse/auto-savings-config/${warehouse_rk}`);
export const getWarehouseAutoSavingsAuditEvents = (warehouse_rk, params) =>
  api.get(`/warehouse/audit-events/${warehouse_rk}`, params);
export const getWarehouseDecisionData = (warehouse_rk, params) =>
  api.get(`/warehouse/decision-chart/${warehouse_rk}`, params);
export const getWarehouseDecisionsCount = (params) =>
  api.get("/warehouse/decision-chart-all", params);
export const getWarehouseQueryExecutionTimes = (warehouse_rk, params) =>
  api.get(`/warehouse/query-execution/${warehouse_rk}`, params);

// dbt models
export const getDBTModels = (params) => api.get(`/dbt/models/`, params);
export const getDBTModelByRk = (params) =>
  api.post(`/dbt/models/items`, params);
export const getDBTModelFilters = () => api.get(`/dbt/models/filters`);
export const postColumnsDBT = (data) => api.get(`/dbt/models/schema`, data);
export const postConnectedColumnsDBT = (data) =>
  api.post(`/dbt/models/connected`, data);
export const upstreamTablesDBT = (fqn) =>
  api.post(`/dbt/models/upstream`, { fqn });
export const downstreamTablesDBT = (fqn) =>
  api.post(`/dbt/models/downstream`, { fqn });
export const startingNodeDBT = (params) =>
  api.get(`/dbt/models/terminal`, params);
export const getSQLLineage = (params) =>
  api.post(`/dbt/models/sql_lineage`, params);
