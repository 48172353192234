var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LineChart, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, BarChart, AreaChart, Tooltip, PieChart, } from "recharts";
import { ChartTypes } from "./types";
import { useState } from "react";
import classes from "./chart.module.scss";
import IconButton from "../iconButton/IconButton";
import { FullscreenIcon } from "@assets/icons";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Stack from "../stack/Stack";
import { yAxisLabelProps } from "./constants";
const ChartsByType = (_a) => {
    var { type, children } = _a, rest = __rest(_a, ["type", "children"]);
    switch (type) {
        case ChartTypes.LineChart:
            return _jsx(LineChart, Object.assign({}, rest, { children: children }));
        case ChartTypes.BarChart:
            return _jsx(BarChart, Object.assign({}, rest, { children: children }));
        case ChartTypes.AreaChart:
            return _jsx(AreaChart, Object.assign({}, rest, { children: children }));
        case ChartTypes.PieChart:
            return _jsx(PieChart, Object.assign({}, rest, { children: children }));
    }
};
const CustomYAxisTick = ({ x, y, payload, yAxisLabelFormatter, }) => (_jsx("g", { transform: `translate(${x},${y})`, children: _jsx("text", { textAnchor: "end", dy: 5, fill: "#66768D", children: yAxisLabelFormatter ? yAxisLabelFormatter(payload.value) : payload.value }) }));
const CustomXAxisTick = ({ x, y, payload, xAxisLabelFormatter, }) => payload ? (_jsx("g", { transform: `translate(${x},${y})`, children: _jsx("text", { x: 0, y: 0, dx: -30, dy: 4, transform: "rotate(270)", textAnchor: "middle", fill: "#66768D", children: xAxisLabelFormatter
            ? xAxisLabelFormatter(payload.value)
            : payload.value }) })) : null;
const getXAxisInterval = (length, interval) => {
    if (interval) {
        return interval;
    }
    return length > 100 ? Math.round(length / 10) : 0;
};
const ChartComponent = ({ data, type, children, xAxisDataKey, yAxisId, yAxisLabel, xAxisLabelFormatter, yAxisLabelFormatter, tooltipProps, height = 400, xAxisInterval, }) => {
    const [selectedLegend, setSelectedLegend] = useState();
    const [barSize, setBarSize] = useState(7);
    const onLegendClick = (legend) => {
        setSelectedLegend((prev) => (prev === legend ? "" : legend));
    };
    const onRender = (ref) => {
        if (ref) {
            const width = ref.getBoundingClientRect().width;
            setBarSize(width / ((data === null || data === void 0 ? void 0 : data.length) || 1) - 10);
        }
    };
    return (_jsx(ResponsiveContainer, { width: "100%", height: "100%", ref: onRender, children: _jsxs(ChartsByType, { type: type, data: data, height: height, margin: {
                top: 25,
                bottom: 25,
                right: 5,
            }, children: [type === ChartTypes.PieChart ? null : (_jsxs(_Fragment, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: xAxisDataKey, interval: getXAxisInterval((data || []).length, xAxisInterval), tick: _jsx(CustomXAxisTick, { xAxisLabelFormatter: xAxisLabelFormatter }) }), _jsx(YAxis, { yAxisId: yAxisId, label: Object.assign(Object.assign({}, yAxisLabelProps), yAxisLabel), tick: 
                            //   @ts-expect-error other values will be injected
                            _jsx(CustomYAxisTick, { yAxisLabelFormatter: yAxisLabelFormatter }) })] })), _jsx(Tooltip, Object.assign({}, tooltipProps)), _jsx(Legend, { align: "center", verticalAlign: "bottom", wrapperStyle: { bottom: -20 }, onClick: (data) => onLegendClick(data.dataKey), formatter: (value) => _jsx("span", { className: classes.legend, children: value }) }), children === null || children === void 0 ? void 0 : children({ barSize, selectedLegend })] }) }));
};
const Chart = (props) => {
    var _a;
    const [fullscreen, setFullscreen] = useState(false);
    const handleFullScreenClick = () => {
        setFullscreen((prev) => !prev);
    };
    return (_jsxs(_Fragment, { children: [fullscreen ? (_jsxs(Modal, { isOpen: fullscreen, toggle: handleFullScreenClick, fullscreen: true, children: [_jsx(ModalHeader, { toggle: handleFullScreenClick, children: props.title }), _jsx(ModalBody, { children: _jsx(ChartComponent, Object.assign({}, props)) })] })) : null, _jsxs("div", { className: classes.chart, children: [_jsx(ChartComponent, Object.assign({}, props)), props.hideFullscreenButton ? null : (_jsx(IconButton, { className: classes.fullscreen, onClick: handleFullScreenClick, children: _jsx(FullscreenIcon, {}) })), ((_a = props.data) === null || _a === void 0 ? void 0 : _a.length) ? null : (_jsx(Stack, { className: classes.no_data, children: "No data available" }))] })] }));
};
export default Chart;
