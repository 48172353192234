import dayjs from "dayjs";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";

const ExplorerContext = createContext();

const initialState = {
  sortMethod: "last_detected",
  filterMethod: null,
  gridSort: "cost_savings_max",
  gridSortDirection: "desc",
  page: 0,
  pageSize: 10,
  ownerList: null,
  userList: null,
  startDate: dayjs().subtract(1, "day").toDate(),
  endDate: dayjs().subtract(1, "day").toDate(),
  selectedRows: [],
  effortFilter: null,
  statusFilter: null,
  resourceNameFilter: null,
  opportunityName: null,
  resourceTypeFilter: null,
  ownerFilter: null,
  period: "day",
  isDataLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SORT_METHOD":
      return { ...state, sortMethod: action.payload };
    case "SET_FILTER_METHOD":
      return { ...state, filterMethod: action.payload };
    case "SET_GRID_SORT":
      return { ...state, gridSort: action.payload };
    case "SET_GRID_SORT_DIRECTION":
      return { ...state, gridSortDirection: action.payload };
    case "SET_PAGE":
      return { ...state, page: action.payload };
    case "SET_PAGE_SIZE":
      return { ...state, pageSize: action.payload };
    case "SET_OWNER_LIST":
      return { ...state, ownerList: action.payload };
    case "SET_DATA":
      return { ...state, data: action.payload };
    case "SET_DATA_LOADING":
      return { ...state, isDataLoading: action.payload };
    case "SET_OWNER":
      return { ...state, owner: action.payload };
    case "SET_ASSIGNED_OWNER":
      return { ...state, assignedOwner: action.payload };
    case "SET_ASSIGNED_STATUS":
      return { ...state, assignedStatus: action.payload };
    case "SET_USER_LIST":
      return { ...state, userList: action.payload };
    case "SET_START_DATE":
      return { ...state, startDate: action.payload };
    case "SET_END_DATE":
      return { ...state, endDate: action.payload };
    case "SET_SELECTED_ROWS":
      return { ...state, selectedRows: action.payload };
    case "SET_EFFORT_FILTER":
      return { ...state, effortFilter: action.payload };
    case "SET_RESOURCE_NAME_FILTER":
      return { ...state, resourceNameFilter: action.payload };
    case "SET_OPPORTUNITY_NAME_FILTER":
      return { ...state, opportunityName: action.payload };
    case "SET_RESOURCE_TYPE_FILTER":
      return { ...state, resourceTypeFilter: action.payload };
    case "SET_OWNER_FILTER":
      return { ...state, ownerFilter: action.payload };
    case "SET_STATUS_FILTER":
      return { ...state, statusFilter: action.payload };
    case "SET_PERIOD_FILTER":
      return { ...state, period: action.payload };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export const ExplorerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridApiRef = useRef(null);

  const setOwnerList = (ownerList) => {
    dispatch({ type: "SET_OWNER_LIST", payload: ownerList });
  };

  const setStartDate = (date) => {
    dispatch({ type: "SET_START_DATE", payload: date });
  };

  const setEndDate = (date) => {
    dispatch({ type: "SET_END_DATE", payload: date });
  };

  const setPage = (page) => {
    dispatch({ type: "SET_PAGE", payload: page });
  };

  const setPageSize = (pageSize) => {
    dispatch({ type: "SET_PAGE_SIZE", payload: pageSize });
  };

  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const setDataLoading = (loading) => {
    dispatch({ type: "SET_DATA_LOADING", payload: loading });
  };

  const setUserList = (userList) => {
    dispatch({ type: "SET_USER_LIST", payload: userList });
  };

  const setFilter = (filter, value) => {
    switch (filter) {
      case "effort":
        dispatch({ type: "SET_EFFORT_FILTER", payload: value });
        break;
      case "resource_name":
        dispatch({ type: "SET_RESOURCE_NAME_FILTER", payload: value });
        break;
      case "opportunity_name":
        dispatch({ type: "SET_OPPORTUNITY_NAME_FILTER", payload: value });
        break;
      case "resource_type":
        dispatch({ type: "SET_RESOURCE_TYPE_FILTER", payload: value });
        break;
      case "owner":
        dispatch({ type: "SET_OWNER_FILTER", payload: value });
        break;
      case "status":
        dispatch({ type: "SET_STATUS_FILTER", payload: value });
        break;
      case "period":
        dispatch({ type: "SET_PERIOD_FILTER", payload: value });
        break;
      default:
        throw new Error(`Unknown filter: ${filter}`);
    }
  };

  const setAssignedOwner = (owner) => {
    dispatch({ type: "SET_ASSIGNED_OWNER", payload: owner });
  };

  const setAssignedStatus = (status) => {
    dispatch({ type: "SET_ASSIGNED_STATUS", payload: status });
  };

  const setSelectedRows = (selectedRows) => {
    dispatch({ type: "SET_SELECTED_ROWS", payload: selectedRows });
  };

  const removeSelectedRows = () => {
    setSelectedRows([]);
    gridApiRef.current.deselectAll();
  };

  const handleSortMethodChange = (method) => {
    dispatch({ type: "SET_SORT_METHOD", payload: method });
  };

  const handleFilterMethodChange = (method) => {
    dispatch({ type: "SET_FILTER_METHOD", payload: method });
  };

  const handleGridSortChange = (newSort) => {
    if (state.gridSort === newSort) {
      dispatch({
        type: "SET_GRID_SORT_DIRECTION",
        payload: state.gridSortDirection === "desc" ? "asc" : "desc",
      });
    } else {
      dispatch({ type: "SET_GRID_SORT", payload: newSort });
      dispatch({ type: "SET_GRID_SORT_DIRECTION", payload: "desc" });
    }
  };

  const handleOwnerChange = (owner, opportunity_id) => {
    const ownerList = state.ownerList;

    setOwnerList(
      ownerList.map((item) =>
        item.opportunity_id === opportunity_id ? { ...item, owner } : item
      )
    );
  };

  const onSelectionChanged = useCallback((event) => {
    if (
      event?.source === "checkboxSelected" ||
      event?.source === "uiSelectAll"
    ) {
      const selectedRows = event.api.getSelectedRows();
      setSelectedRows(selectedRows);
    }
  }, []);

  const contextValue = {
    state,
    dispatch,
    gridApiRef,
    setOwnerList,
    setStartDate,
    setEndDate,
    setPage,
    setPageSize,
    setData,
    setDataLoading,
    setUserList,
    setFilter,
    setAssignedOwner,
    setAssignedStatus,
    removeSelectedRows,
    handleSortMethodChange,
    handleFilterMethodChange,
    handleGridSortChange,
    handleOwnerChange,
    onSelectionChanged,
  };

  return (
    <ExplorerContext.Provider value={contextValue}>
      {children}
    </ExplorerContext.Provider>
  );
};

export const useExplorer = () => {
  const context = useContext(ExplorerContext);
  if (!context) {
    throw new Error("useExplorer must be used within a ExplorerProvider");
  }
  return context;
};
