import classNames from "classnames";
import { PieColorsMap, UserColorsMap } from "./constants";
import styles from "./styles.module.scss";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { Dropdown } from "../../Components/Form";
import {
  Bar,
  BarChart,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

import { Tooltip as ReactTooltip } from "react-tooltip";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.chart_tooltip}>
        <div className={styles.tooltip_header}>{label}</div>
        <div className={styles.tooltip_execs}>
          {payload[0].value === 0
            ? "No Credits"
            : `${payload[0].value} credits`}
        </div>
      </div>
    );
  }

  return null;
};

export const TrendBarGraph = ({ analyticsTrendData, period }) => {
  const isWeek = period === "week";
  const customXTicks = analyticsTrendData
    .map((data) => data.day)
    .filter((_, index) => index >= (isWeek ? 0 : 3));

  const dataWithZeroExecutions = analyticsTrendData.map((data) => ({
    ...data,
    zeroExecutions: data.executions === 0 ? (isWeek ? 2 : 5) : 0,
  }));

  return (
    <div className={styles.trend_chart}>
      <ResponsiveContainer>
        <BarChart data={dataWithZeroExecutions}>
          <XAxis
            dataKey="day"
            interval={isWeek ? 0 : 7}
            ticks={customXTicks}
            label={{ value: "Day", position: "right", fill: "black" }}
            tickLine={false}
            axisLine={false}
            tick={{ fontSize: 8 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            barSize={isWeek ? 8 : 3}
            dataKey="executions"
            fill="#247EFE"
            stackId="executions"
            radius={[5, 5, 5, 5]}
          />
          <Bar
            barSize={isWeek ? 8 : 3}
            dataKey="zeroExecutions"
            fill="#D3E5FF"
            stackId="executions"
            radius={[5, 5, 5, 5]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const Bullet = ({ idx }) => (
  <div
    class="w-1.5 h-1.5 rounded-full"
    style={{
      width: "6px",
      height: "6px",
      borderRadius: 9999,
      background: UserColorsMap[idx],
    }}
  />
);

const PercentageLine = ({ idx, percentage }) => {
  const baseColor = `${UserColorsMap[idx]}1A`; // 10% opacity
  const percentageColor = UserColorsMap[idx];

  return (
    <div
      style={{
        width: "100%",
        height: "6px",
        position: "relative",
        background: baseColor,
        borderRadius: 5,
      }}
    >
      <div
        style={{
          width: `${percentage}%`,
          height: "100%",
          position: "absolute",
          background: percentageColor,
          borderRadius: 5,
        }}
      />
    </div>
  );
};

const ExecutionUsagePerUser = ({ idx, name, percentage, executions }) => (
  <>
    <div
      className={styles.user_execution_chart}
      data-tooltip-id={`user-execution-info-${idx}`}
      data-tooltip-variant=""
      data-tooltip-place="right"
    >
      <div className={styles.user_execution_name}>
        <Bullet idx={idx} />
        <div className={styles.name}>{name}</div>
      </div>
      <PercentageLine idx={idx} percentage={percentage} />
    </div>

    <ReactTooltip
      id={`user-execution-info-${idx}`}
      className={styles.general_tooltip}
    >
      {executions} Credits ({percentage.toFixed(2)}%)
    </ReactTooltip>
  </>
);

export const ExecutionUsagePerUsers = ({ executionData }) => (
  <div className={styles.user_execution_charts}>
    {executionData.map((user, idx) => (
      <ExecutionUsagePerUser
        key={idx}
        idx={idx}
        name={user.display_name}
        percentage={user.percentage_executions}
        executions={user.executions}
      />
    ))}
  </div>
);

export const CustomPieChart = ({
  data,
  infoText,
  infoData,
  customStyle = "",
  customPieStyle = undefined,
  showColor = true,
}) => (
  <div className={styles.pie} style={customPieStyle}>
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          innerRadius={60}
          outerRadius={80}
          fill={showColor ? "#8884d8" : "#EEEFF2"}
          label={false}
          paddingAngle={5}
          cornerRadius={20}
        >
          {data.map((d, index) => (
            <Cell key={`cell-${index}`} fill={PieColorsMap[d.name]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
    <div className={styles.remaining}>
      <div className={styles.text}>{infoText}</div>
      <div
        className={classNames(styles.number, {
          [styles.extra_credits]: customStyle === "extra",
        })}
      >
        {infoData}
      </div>
    </div>
  </div>
);

export const PeriodSelect = ({
  id,
  currentPeriod,
  setCurrentPeriod,
  periodsMap,
}) => (
  <div className={styles.dropdowns}>
    <Dropdown
      id={id}
      onChange={setCurrentPeriod}
      value={currentPeriod}
      options={Object.entries(periodsMap).map(([key, val]) => ({
        label: <div>{val.dropdown}</div>,
        value: key,
      }))}
      showDivider
    >
      <div className={styles.dropdown}>
        <div className={styles.label}>{periodsMap[currentPeriod].dropdown}</div>
        <div className={styles.arrow_down}>
          <ArrowDown />
        </div>
      </div>
    </Dropdown>
  </div>
);
