import * as monaco from "monaco-editor";
import { language } from "./sql";

monaco.languages.register({ id: "sql" });
monaco.languages.setMonarchTokensProvider("sql", language);

const theme = {
  inherit: false,
  base: "vs-dark",
  colors: {
    "editor.background": "#082247",
    "editor.foreground": "#FFFFFF",
    "editor.selectionBackground": "#FFFFFF20",
    "editor.lineHighlightBackground": "#082247",
    "editorCursor.foreground": "#FFFFFF",
    "editorWhitespace.foreground": "#00000000",
  },
  rules: [
    { fontStyle: "italic", token: "emphasis" },
    { fontStyle: "bold", token: "strong" },
    { token: "keyword", foreground: "#87CEEB" },
    { token: "comment", foreground: "#778899" },
    { token: "operator", foreground: "#87CEEB" },
    { token: "string", foreground: "#F0E68C" },
    { token: "number", foreground: "#FFA07A" },
    { token: "predefined", foreground: "#FFB6C1" },
  ],
};

async function createMonacoEditor(
  id,
  { readOnly = false, language = "", minimap = false, lineNumbers = false }
) {
  monaco.editor.defineTheme("altimate-dark", theme);
  const editor = monaco.editor.create(document.getElementById(id), {
    language,
    theme: "altimate-dark",
    readOnly,
    minimap: { enabled: minimap },
    folding: false,
    foldingHighlight: false,
    smoothScrolling: true,
    automaticLayout: true,
    wordWrap: "on",
    scrollbar: {
      verticalScrollbarSize: 0,
      verticalSliderSize: 6,
      horizontalScrollbarSize: 0,
      horizontalSliderSize: 6,
      useShadows: false,
      vertical: "visible",
      horizontal: "visible",
    },
    lineNumbers,
    guides: {
      highlightActiveBracketPair: false,
      highlightActiveIndentation: false,
      indentation: false,
    },
  });

  return editor;
}

export { createMonacoEditor };
