import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from "dayjs";
import { Badge, List, NumberFormatter, Stack } from "@uicore";
import { BarColors, TotalCostEntities } from "./constants";
import { InfoIcon } from "@/assets/icons";
import classes from "../summary.module.scss";
import { useAppState } from "@/modules/app/useAppContext";
const CostChartTooltip = ({ payload, timeRange, type, isFuture }) => {
    const { currency } = useAppState();
    const data = payload && payload[0] && payload[0].payload;
    if (!data) {
        return null;
    }
    const allFields = TotalCostEntities[type].fields;
    const totalObject = allFields.find((item) => item.isTotal);
    const otherFields = allFields.filter((item) => !item.isTotal);
    const totalKey = totalObject === null || totalObject === void 0 ? void 0 : totalObject.key["current"];
    const isCurrentMonth = dayjs().isSame(dayjs(data.date), "month");
    const isBeforeMonth = dayjs(data.date).isBefore(dayjs(), "month");
    const isAfterMonth = dayjs(data.date).isAfter(dayjs(), "month");
    const showCurrent = !isFuture || isBeforeMonth || isCurrentMonth;
    const showFuture = isFuture && (isCurrentMonth || isAfterMonth);
    const changePercent = (showChangePercentage, key, data) => {
        if (!showChangePercentage) {
            return null;
        }
        const change = data[`percentage_${key}`];
        if (change) {
            const number = parseFloat(`${change}`);
            return (_jsxs("span", { className: number >= 0 ? "negative" : "positive", children: ["(", number > 0 ? "+" : "", number === null || number === void 0 ? void 0 : number.toFixed(2), "%)"] }));
        }
    };
    const sticker = (field, key) => {
        if (field.hideSticker) {
            return null;
        }
        return (_jsx("span", { className: classes.sticker, style: {
                background: BarColors[key],
            } }));
    };
    let hasChangePercentage = false;
    // For future estimates for current month, we need to add both current and estimate to get full estimated value
    const getEstimatedValue = (key) => {
        if (isCurrentMonth) {
            return data[key["estimate"]] + data[key["current"]];
        }
        return data[key["estimate"]];
    };
    return (_jsxs("div", { className: classes.costChartTooltip, children: [_jsxs("h6", { children: [dayjs(data.date).format("DD-MMM-YY"), " (Aggregation: ", timeRange, ")"] }), totalKey ? (_jsxs(Stack, { children: [showCurrent ? (_jsxs(Badge, { tooltip: "", children: ["Total Cost:", " ", _jsx(NumberFormatter, { options: { currency }, value: data[totalKey] }), !isCurrentMonth ? changePercent(true, totalKey, data) : null] })) : null, showFuture ? (_jsxs(Badge, { color: "dark", tooltip: "", children: ["Projected:", " ", _jsx(NumberFormatter, { options: { currency }, value: getEstimatedValue(totalObject.key) }), changePercent(true, totalKey, data)] })) : null] })) : null, _jsx(List, { children: otherFields.map((field) => {
                    const key = field.key["current"];
                    hasChangePercentage =
                        hasChangePercentage || Boolean(field.showChangePercentage);
                    return (_jsx("li", { children: _jsxs(Stack, { className: isFuture && isCurrentMonth
                                ? "justify-content-between"
                                : "flex-start", children: [isFuture && isCurrentMonth
                                    ? null
                                    : sticker(field, (isFuture
                                        ? field.key["estimate"]
                                        : key)), field.label, ":", " ", showCurrent ? (_jsx(_Fragment, { children: _jsx(NumberFormatter, { options: { currency }, value: data[key] }) })) : null, _jsxs("span", { children: [showFuture ? (_jsx(_Fragment, { children: _jsx(NumberFormatter, { options: { currency }, value: data[field.key["estimate"]] }) })) : null, changePercent(Boolean(field.showChangePercentage), key, data)] })] }) }, key));
                }) }), hasChangePercentage && timeRange ? (_jsx("div", { style: { background: "#F5FAFF", padding: 8 }, children: _jsxs("div", { style: { maxWidth: 215 }, children: [_jsx(InfoIcon, {}), " Change is calculated from the value for the previous", " ", timeRange] }) })) : null] }));
};
export default CostChartTooltip;
