import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  generateDescription,
  getTableDescriptionById,
  updateTablePurpose,
} from "../../helpers/backend_helper";
import { ComponentLoader } from "../Loader";
import { useContext, useState } from "react";
import { LineageContext, StaticLineageContext } from "./Lineage";
import { useReactFlow } from "reactflow";
import styles from "./styles.module.scss";
import { ColumnDatatype } from "../Column";
import { ColorTag, ModifiedByTag } from "../Tags";
import { destructTable } from "./utils";
import { DebouncedInput } from "../Form";
import { getIconByDatastoreType } from "../../pages/Datasets/DatastoreImage";
import classNames from "classnames";
import { Button, Card, CardBody, Input } from "reactstrap";
import { ScrollContainer } from "../ScrollContainer";
import { createNewNodesEdges, processColumnLineage } from "./graph";
import { ReactComponent as ExpandLineageIcon } from "../../assets/icons/expand_lineage.svg";
import { useTenantUsers } from "../../services/common";
import { useBoolean } from "../../helpers/hooks";
import { GET_TABLES } from "../../services/filters";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import { ReactComponent as RobotLogo } from "@/assets/icons/robot1.svg";
import { ReactComponent as RotateIcon } from "@/assets/icons/rotate.svg";
import ProcessingScreen from "@/assets/icons/processing_screen.gif";
import { BetterTooltip } from "../Tooltip";
import CodeSQLEditor from "../CodeEditor/SQLCodeEditor";
import { NodeTypeIcon } from "./CustomNodes";

const GET_COLUMN = "GET-LINEAGE-COLUMN";
const GET_SINGLE_TABLE_DESCRIPTION = "GET-TABLE-DESCRIPTION";

const colors = ["blue", "orange", "yellow", "purple", "dark_green"];
const colorsMap = {};
let i = 0;

const ColumnCard = ({
  column,
  handleClick,
  selected,
  users,
  isDescriptionEnabled,
}) => {
  return (
    <div
      className={classNames(styles.column_card, {
        [styles.selected]: selected,
      })}
      onClick={handleClick}
    >
      <div className="d-flex align-items-center gap-xs">
        {column.datatype && <ColumnDatatype datatype={column.datatype} />}
        <div>{column.name}</div>
        <div className="spacer" />
        {column.can_lineage_expand && (
          <div className={styles.expand_lineage_icon}>
            <ExpandLineageIcon />
          </div>
        )}
        {isDescriptionEnabled && (
          <ModifiedByTag
            users={users}
            lastModifiedBy={column.last_modified_by}
          />
        )}
      </div>
      {column.datatype && (
        <ColorTag label={column.datatype} color={styles.column_tag} />
      )}
      {column.description && (
        <>
          <div className="divider" />
          <div className="d-flex flex-column">
            {isDescriptionEnabled && <div>Description</div>}
            <div className="text-muted font-normal">{column.description}</div>
          </div>
        </>
      )}
      <div className="d-flex gap-sm flex-wrap">
        {(column.tags || []).map((t) => {
          if (!colorsMap[t.name])
            colorsMap[t.name] = colors[i++ % colors.length];
          return (
            <BetterTooltip tooltipContent={t.description} key={t.name}>
              <ColorTag color={colorsMap[t.name]} label={t.name} />
            </BetterTooltip>
          );
        })}
      </div>
    </div>
  );
};

const HeaderSection = ({ table, nodeType, isDBT }) => {
  const [datastore_type, schema, tableName] = destructTable(table);
  return (
    <div className={styles.header_section}>
      {isDBT ? (
        <NodeTypeIcon nodeType={nodeType} />
      ) : datastore_type ? (
        getIconByDatastoreType(datastore_type)
      ) : null}
      <div className="text-overflow">{tableName}</div>
      <div />
      <div className="text-primary">{schema}</div>
    </div>
  );
};

const PurposeSection = ({
  tableId,
  purpose: defaultPurpose,
  users,
  lastModifiedBy,
  table,
}) => {
  const [purpose, setPurpose] = useState(defaultPurpose);
  const queryClient = useQueryClient();
  const [datastore_type, ,] = destructTable(table);
  const isDescriptionEnabled = datastore_type !== "dbt";

  const {
    value: isPurposeEditing,
    setTrue: setPurposeEditing,
    setFalse: unsetPurposeEditing,
  } = useBoolean();
  const { mutate } = useMutation({
    mutationFn: (data) => updateTablePurpose(tableId, data),
    onSuccess: (data) => {
      if (data.ok) {
        unsetPurposeEditing();
        queryClient.invalidateQueries({ queryKey: [GET_TABLES] });
      }
    },
  });

  const setDescription = useMutation({
    mutationFn: (data) => {
      return generateDescription(tableId, data);
    },
    onSuccess: (data) => {
      if (data.ok) {
        queryClient.invalidateQueries(GET_TABLES);
        queryClient.invalidateQueries(GET_SINGLE_TABLE_DESCRIPTION);
      }
    },
  });

  useQuery(
    GET_SINGLE_TABLE_DESCRIPTION,
    {
      queryFn: () => {
        if (isDescriptionEnabled) {
          return getTableDescriptionById(tableId);
        }
      },
      onSuccess: (resp) => {
        if (resp) {
          setPurpose(resp.description);
        }
      },
    },
    { enabled: setDescription.isSuccess }
  );
  if (!isDescriptionEnabled && !purpose) return null;
  if (!isDescriptionEnabled) {
    return (
      <Card className={classNames("mb-0", styles.bg_blue)}>
        <CardBody className="d-flex flex-column gap-sm">
          <div className="d-flex gap-xs flex-column">
            <div className="fs-5 fw-semibold">Description</div>
            <div className={classNames(styles.column_card)}>
              <div className="text-muted font-normal">{purpose}</div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
  return (
    <Card className={classNames("mb-0", styles.bg_blue)}>
      <CardBody className="d-flex flex-column gap-sm">
        <div className="d-flex gap-xs flex-column">
          <div className="fs-5 fw-semibold">
            Generate Description for the table and columns
          </div>
          <div className={classNames(styles.column_card)}>
            {purpose ? (
              <>
                <div className="d-flex gap-xs align-items-center">
                  <div>Description</div>
                  <EditIcon
                    className={classNames("cursor-pointer", styles.edit_icon, {
                      [styles.active]: isPurposeEditing,
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isPurposeEditing) {
                        setPurpose(defaultPurpose);
                        unsetPurposeEditing();
                      } else {
                        setPurposeEditing();
                      }
                    }}
                  />
                  <div className="spacer" />
                  <ModifiedByTag
                    users={users}
                    lastModifiedBy={lastModifiedBy}
                  />
                  <Button
                    color="white"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDescription.mutateAsync({});
                    }}
                  >
                    <RotateIcon />
                  </Button>
                </div>
                <div className="divider" />
              </>
            ) : (
              <div className="d-flex gap-xs align-items-center">
                <div>Description</div>
                <div className="spacer" />
                <Button
                  color="primary"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDescription.mutateAsync({});
                  }}
                >
                  <RobotLogo />
                  {setDescription.isLoading
                    ? "Generating..."
                    : "Generate Description"}
                </Button>
              </div>
            )}

            {setDescription.isLoading ? (
              <div
                className={classNames(
                  "d-flex gap-xs align-items-center flex-column",
                  styles.processing_div
                )}
              >
                <img
                  src={ProcessingScreen}
                  className={classNames(styles.gif_img)}
                />
                <div className="spacer" />
                <div className="text-muted font-normal">
                  Checking query history
                </div>
              </div>
            ) : isPurposeEditing ? (
              <>
                <Input
                  type="textarea"
                  disabled={!isPurposeEditing}
                  rows={defaultPurpose ? 8 : 2}
                  className={styles.multiline_input}
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                  placeholder="Enter the purpose for the table"
                />
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    mutate({ purpose });
                  }}
                >
                  Update
                </Button>
              </>
            ) : (
              <div className="text-muted font-normal">{purpose}</div>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const ColumnSection = ({
  columns,
  filteredColumn,
  setFilteredColumn,
  handleColumnClick,
  selectedColumn,
  users,
  table,
}) => {
  const [datastore_type, ,] = destructTable(table);
  const isDescriptionEnabled = datastore_type !== "dbt";

  return (
    <Card
      className={classNames("mb-0 flex-grow overflow-y-hidden", styles.bg_gray)}
    >
      <CardBody className="d-flex flex-column gap-sm h-100">
        <div className="d-flex align-items-center gap-xs">
          <div className="fs-5 fw-semibold">Column</div>
        </div>
        <DebouncedInput
          size="sm"
          placeholder="Search by column name"
          onChange={(v) => {
            const _search = v.toLowerCase();
            setFilteredColumn(
              columns.filter((c) => c.name.toLowerCase().includes(_search))
            );
          }}
        />
        <div className="d-flex align-items-center gap-xs">
          <div className="text-muted">{filteredColumn.length} columns</div>
        </div>
        <ScrollContainer>
          <div className="d-flex flex-column gap-sm">
            {filteredColumn.map((_column) => (
              <ColumnCard
                key={_column.rk}
                column={_column}
                handleClick={() => handleColumnClick(_column)}
                selected={_column.rk === selectedColumn}
                users={users}
                isDescriptionEnabled={isDescriptionEnabled}
              />
            ))}
          </div>
        </ScrollContainer>
      </CardBody>
    </Card>
  );
};

const TableDetails = () => {
  const {
    selectedTable: table,
    selectedColumn,
    setSelectedColumn,
    setCollectColumns,
    setShowSidebar,
    postColumns,
    upstreamTables,
    downstreamTables,
    postConnectedColumns,
  } = useContext(LineageContext);
  const flow = useReactFlow();
  const [filteredColumn, setFilteredColumn] = useState([]);

  const { data, isLoading } = useQuery({
    queryKey: [GET_COLUMN, table],
    queryFn: () => postColumns({ table_rk: table }),
    onSuccess: ({ columns }) => {
      columns.sort((a, b) => a.name.localeCompare(b.name));
      setFilteredColumn(columns);
    },
  });

  const { users } = useTenantUsers();

  if (isLoading) return <ComponentLoader />;

  const handleColumnClick = async (_column) => {
    let _nodes = flow.getNodes();
    let _edges = flow.getEdges();
    const _t = flow.getNode(table);
    const { upstreamCount, downstreamCount, level } = _t.data;
    if (downstreamCount > 0 || upstreamCount > 0) {
      const expand = async (t, tables, right) => {
        tables.sort((a, b) => {
          const [, , tableA] = destructTable(a.table);
          const [, , tableB] = destructTable(b.table);
          return tableA.localeCompare(tableB);
        });
        createNewNodesEdges(_nodes, _edges, tables, t, right, level);
      };
      if (
        upstreamCount > 0 &&
        upstreamCount < _edges.filter((e) => e.source === table).length
      ) {
        const { tables } = await upstreamTables(_t.id);
        await expand(_t.id, tables, true);
      }
      if (
        downstreamCount > 0 &&
        downstreamCount < _edges.filter((e) => e.target === table).length
      ) {
        const { tables } = await downstreamTables(_t.id);
        await expand(_t.id, tables, false);
      }
    }
    const { nodes, edges, collect_columns } = await processColumnLineage(
      _nodes,
      _edges,
      _column.rk,
      table,
      postConnectedColumns
    );

    flow.setNodes(nodes);
    flow.setEdges(edges);
    setSelectedColumn(_column.rk);
    setCollectColumns(collect_columns);
    setShowSidebar(false);
  };

  return (
    <div className="p-2 h-100 d-flex flex-column gap-md">
      <HeaderSection table={table} />
      <PurposeSection
        table={table}
        tableId={data.id}
        purpose={data.purpose}
        users={users}
        lastModifiedBy={data.last_modified_by}
      />
      <ColumnSection
        table={table}
        selectedColumn={selectedColumn}
        filteredColumn={filteredColumn}
        setFilteredColumn={setFilteredColumn}
        columns={data.columns}
        handleColumnClick={handleColumnClick}
        users={users}
      />
    </div>
  );
};

const StaticTableDetails = () => {
  const { detailColumns, selectedTable } = useContext(StaticLineageContext);
  const columns = (detailColumns?.[selectedTable]?.columns || []).map(
    (item) => ({ ...item, description: item.expression })
  );
  const sql = detailColumns?.[selectedTable]?.sql;
  const [filteredColumn, setFilteredColumn] = useState(columns);

  return (
    <div className="p-2 h-100 d-flex flex-column gap-md">
      <HeaderSection
        table={selectedTable}
        nodeType={detailColumns?.[selectedTable]?.node_type || "unknown"}
        isDBT
      />
      {sql && (
        <Card className={classNames("mb-0", styles.bg_blue)}>
          <CardBody className="d-flex flex-column gap-sm">
            <div className="fs-5 fw-semibold">SQL</div>
            <CodeSQLEditor value={sql} height="20vh" />
          </CardBody>
        </Card>
      )}
      <Card
        className={classNames(
          "mb-0 flex-grow overflow-y-hidden",
          styles.bg_gray
        )}
      >
        <CardBody className="d-flex flex-column gap-sm h-100">
          <div className="d-flex align-items-center gap-xs">
            <div className="fs-5 fw-semibold">Column</div>
          </div>
          <DebouncedInput
            size="sm"
            placeholder="Search by column name"
            onChange={(v) => {
              const _search = v.toLowerCase();
              setFilteredColumn(
                columns.filter((c) => c.name.toLowerCase().includes(_search))
              );
            }}
          />
          <div className="d-flex align-items-center gap-xs">
            <div className="text-muted">{filteredColumn.length} columns</div>
          </div>
          <ScrollContainer>
            <div className="d-flex flex-column gap-sm">
              {filteredColumn.map((_column) => (
                <div
                  key={_column.name}
                  className={classNames(styles.column_card)}
                >
                  <div className="d-flex align-items-center gap-xs">
                    {_column.datatype && (
                      <ColumnDatatype datatype={_column.datatype} />
                    )}
                    <div>{_column.name}</div>
                    <div className="spacer" />
                  </div>
                  {_column.datatype && (
                    <ColorTag
                      label={_column.datatype}
                      color={styles.column_tag}
                    />
                  )}
                  {_column.description && (
                    <>
                      <div className="divider" />
                      <CodeSQLEditor
                        value={_column.description}
                        height="20px"
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
          </ScrollContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export { StaticTableDetails, TableDetails, PurposeSection, HeaderSection };
