import classnames from "classnames";
import {
  ScrollContainer,
  scroll_style,
} from "../../Components/ScrollContainer";
import { useTenantUsers } from "../../services/common";
import { getIconByDatastoreType } from "./DatastoreImage";
import styles from "./styles.module.scss";
import { Card, CardBody } from "reactstrap";
import { useState, useMemo, useEffect } from "react";
import { DebouncedInput } from "../../Components/Form";
import { useBoolean } from "../../helpers/hooks";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { updateTableSchemaDesc } from "../../helpers/backend_helper";
import { GET_TABLES } from "../../services/filters";
import { ColumnDatatype } from "../../Components/Column";
import { ColorTag, ModifiedByTag } from "../../Components/Tags";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import { ReactComponent as RobotIcon } from "@/assets/icons/robot.svg";
import { ReactComponent as WriteIcon } from "@/assets/icons/write_icon.svg";
import { ReactComponent as ReloadIcon } from "@/assets/icons/reload.svg";
import { Button, Input } from "reactstrap";
import {
  updateTablePurpose,
  generateDescription,
  getTableDescriptionById,
} from "../../helpers/backend_helper";
import classNames from "classnames";
import { ReactComponent as RobotLogo } from "@/assets/icons/robot1.svg";
import ProcessingScreen from "@/assets/icons/processing_screen.gif";
import { GET_TABLE_BY_ID } from "./DatasetsDetails";

const GET_SINGLE_TABLE_DESCRIPTION = "GET-TABLE-DESCRIPTION";

const ButtonTag = ({ type = "Generate", handleClick }) => {
  return (
    <div className={styles.button_tag} onClick={handleClick}>
      {type === "Generate" || type === "Generating..." ? (
        <RobotIcon />
      ) : type === "Write" ? (
        <WriteIcon />
      ) : (
        <></>
      )}
      <div>{type}</div>
    </div>
  );
};

const ColumnCard = ({ column, users, colDescription }) => {
  const { value: isEditing, setTrue, setFalse } = useBoolean();
  const [description, setDescription] = useState(null);
  useEffect(() => {
    setDescription(colDescription);
  }, [colDescription]);
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (data) => updateTableSchemaDesc(column.table_schema_id, data),
    onSuccess: (data) => {
      if (data.ok) {
        setFalse();
        queryClient.invalidateQueries(GET_TABLE_BY_ID);
      }
    },
  });

  return (
    <div className={styles.column_card}>
      <div className="d-flex align-items-center">
        <ColumnDatatype datatype={column.datatype} />
        <div className="ms-2">{column.name}</div>
        <div className="spacer" />
        <ColorTag label={column.datatype} color={styles.column_tag} />
      </div>
      <div className="mb-1 divider" />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex gap-xs align-items-center">
          <div>Description</div>
          {description && (
            <EditIcon
              className={classnames("cursor-pointer", styles.edit_icon, {
                [styles.active]: isEditing,
              })}
              onClick={(e) => {
                e.stopPropagation();
                if (isEditing) {
                  setFalse();
                  setDescription(colDescription);
                } else {
                  setTrue();
                }
              }}
            />
          )}
        </div>
        <div className="d-flex gap-xs align-items-center">
          {description ? (
            <>
              {/* {(column.last_modified_by === undefined ||
                column.last_modified_by === null) && (
                <div
                  className={styles.reload}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDescription.mutateAsync({});
                  }}
                >
                  <ReloadIcon />
                </div>
              )} */}

              <ModifiedByTag
                users={users}
                lastModifiedBy={column.last_modified_by}
              />
            </>
          ) : (
            <>
              <ButtonTag
                type="Write"
                handleClick={(e) => {
                  e.stopPropagation();
                  if (isEditing) {
                    setFalse();
                    setDescription(colDescription);
                  } else {
                    setTrue();
                  }
                }}
              />
              {/* <ButtonTag type="Generate" /> */}
            </>
          )}
        </div>
      </div>
      {isEditing ? (
        <>
          <Input
            type="textarea"
            className={classnames("mb-1", styles.multiline_input)}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter the column description"
          />
          <div className="d-flex gap-xs align-items-center">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                mutate({ description });
              }}
            >
              Save
            </Button>
            <Button
              outline
              className={styles.cancel_button}
              onClick={() => {
                setDescription(colDescription);
                setFalse();
              }}
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <div className="text-muted font-normal">{description}</div>
      )}
    </div>
  );
};

const HeaderSection = ({ table }) => {
  const tableName = table.scope[table.scope.length - 1];
  return (
    <div className="d-flex align-items-center">
      {getIconByDatastoreType(table.datasource_type, "me-2 icon-md")}
      <div className={styles.column_table_name}>{tableName}</div>
    </div>
  );
};

const ColumnSection = ({ columns, users }) => {
  const [search, setSearch] = useState("");
  const filteredColumns = useMemo(() => {
    if (!search) return columns;
    const _search = search.toLowerCase();
    return columns.filter((c) => c.name.includes(_search));
  }, [columns, search]);
  return (
    <Card className={classnames(styles.column_section, "mb-0", styles.bg_gray)}>
      <CardBody
        className={classNames(styles.column_body, "d-flex flex-column gap-sm")}
      >
        <div className="d-flex justify-content-between align-items-center mb-1">
          <div className="d-flex justify-content-between align-items-center gap-sm">
            <div className="fs-5 fw-semibold">Columns</div>
            <div className={styles.searchBox}>
              <DebouncedInput
                size="sm"
                placeholder="Search Column"
                onChange={setSearch}
              />
            </div>
          </div>
          {search ? (
            <div className="text-primary">
              {filteredColumns.length} results found
            </div>
          ) : (
            <div>{columns.length} columns</div>
          )}
        </div>
        <div className={styles.grid}>
          <div className={styles.columnGrid}>
            {filteredColumns.map((c) => (
              <ColumnCard
                key={c.table_schema_id}
                column={c}
                users={users}
                colDescription={c.description}
              />
            ))}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const PurposeSection = ({
  tableId,
  purpose: defaultPurpose,
  users,
  lastModifiedBy,
}) => {
  const [purpose, setPurpose] = useState(defaultPurpose);
  const queryClient = useQueryClient();
  const {
    value: isPurposeEditing,
    setTrue: setPurposeEditing,
    setFalse: unsetPurposeEditing,
  } = useBoolean();
  const { mutate } = useMutation({
    mutationFn: (data) => updateTablePurpose(tableId, data),
    onSuccess: (data) => {
      if (data.ok) {
        unsetPurposeEditing();
        queryClient.invalidateQueries({ queryKey: [GET_TABLES] });
      }
    },
  });

  const setDescription = useMutation({
    mutationFn: (data) => {
      return generateDescription(tableId, data);
    },
    onSuccess: (data) => {
      if (data.ok) {
        queryClient.invalidateQueries(GET_TABLE_BY_ID);
        queryClient.invalidateQueries(GET_SINGLE_TABLE_DESCRIPTION);
      }
    },
  });

  const { data, isLoading } = useQuery(
    GET_SINGLE_TABLE_DESCRIPTION,
    {
      queryFn: () => getTableDescriptionById(tableId),
      onSuccess: (resp) => {
        setPurpose(resp.description);
      },
    },
    { enabled: setDescription.isSuccess }
  );

  return (
    <>
      <Card className={classNames("mb-0", styles.bg_gray)}>
        <CardBody className="d-flex flex-column gap-sm">
          <div className="d-flex gap-xs align-items-center justify-content-between">
            <div className="fs-5 fw-semibold">
              Generate Description for the table and columns
            </div>
            {purpose ? (
              <Button
                className={styles.generate_button_disabled}
                size="sm"
                disabled
              >
                <div className="d-flex gap-xs align-items-center">
                  <RobotLogo />
                  Generate
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setDescription.mutateAsync({});
                }}
              >
                <div className="d-flex gap-xs align-items-center">
                  <RobotLogo />
                  {setDescription.isLoading ? "Generating..." : "Generate"}
                </div>
              </Button>
            )}
          </div>
        </CardBody>
      </Card>

      {purpose && (
        <Card className={styles.table_description}>
          <CardBody>
            <div className="d-flex gap-xs align-items-center mb-4">
              <div>Description</div>
              <EditIcon
                className={classNames("cursor-pointer", styles.edit_icon, {
                  [styles.active]: isPurposeEditing,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isPurposeEditing) {
                    setPurpose(defaultPurpose);
                    unsetPurposeEditing();
                  } else {
                    setPurposeEditing();
                  }
                }}
              />
              <div className="spacer" />
              {/* <div
                className={styles.reload}
                onClick={(e) => {
                  e.stopPropagation();
                  setDescription.mutateAsync({});
                }}
              >
                <ReloadIcon />
              </div> */}
              <ModifiedByTag users={users} lastModifiedBy={lastModifiedBy} />
            </div>
            <div>
              {isPurposeEditing ? (
                <>
                  <Input
                    type="textarea"
                    disabled={!isPurposeEditing}
                    rows={defaultPurpose ? 4 : 2}
                    className={styles.multiline_input}
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                    placeholder="Enter the purpose for the table"
                  />
                  <div className="d-flex align-items-center gap-xs mt-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        mutate({ purpose });
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      outline
                      className={styles.cancel_button}
                      onClick={() => {
                        setPurpose(defaultPurpose);
                        unsetPurposeEditing();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <div className="text-muted font-normal">{purpose}</div>
              )}
            </div>
          </CardBody>
        </Card>
      )}

      {setDescription.isLoading && (
        <Card className={styles.table_description}>
          <CardBody>
            <div
              className={classNames(
                "d-flex gap-xs align-items-center flex-column",
                styles.processing_div
              )}
            >
              <img
                src={ProcessingScreen}
                className={classNames(styles.gif_img)}
              />
              <div className="spacer" />
              <div className="text-muted font-normal">
                Checking query history
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

const Schema = ({ table }) => {
  const { users } = useTenantUsers();

  return (
    <div className={classnames("d-flex flex-column h-100 gap-md")}>
      <HeaderSection table={table} />
      <PurposeSection
        tableId={table.id}
        purpose={table.description}
        users={users}
        lastModifiedBy={table.last_modified_by}
        tableName={table.name}
        mode="page"
      />
      <div className="mb-1"></div>
      <ColumnSection columns={table.columns} users={users} />
    </div>
  );
};

export { Schema };
