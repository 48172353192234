import { Button, Card, CardBody } from "reactstrap";
import no_resource from "../../assets/images/no_resource.svg";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { Link } from "react-router-dom";

const NoIncident = () => {
  return (
    <div className={styles.no_incident}>
      <Card className={styles.card}>
        <CardBody className="d-flex justify-content-center">
          <div
            className={classnames(
              "d-flex flex-column align-items-center p-4",
              styles.container
            )}
          >
            <img src={no_resource} alt="No Incident" className={styles.img} />
            <h2 className="mt-4 mb-4 text-black">No Incident Found</h2>
            <p className="text-muted text-center">
              This means either all of your data contract validations have
              passed successfully or there are some issues with your onboarding
              configuration.
            </p>
            <Link to="/quickstart">
              <Button color="primary">Go to Quickstart</Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export { NoIncident };
