var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classes from "./singleLineEllipses.module.scss";
import Tooltip from "../tooltip/Tooltip";
const SingleLineEllipses = (_a) => {
    var { content = "" } = _a, rest = __rest(_a, ["content"]);
    return (_jsx(Tooltip, { content: content, placement: "top", tooltipContentWrapperClassName: classes.singleLineEllipsesTooltip, children: _jsx("div", Object.assign({}, rest, { className: `${rest.className} ${classes.singleLineEllipses}`, children: content })) }));
};
export default SingleLineEllipses;
