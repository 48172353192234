import { useMemo } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getIncidentById } from "../../helpers/backend_helper";
import { IncidentModal } from "./IncidentModal";
import { IncidentList } from "./IncidentList";
import { NoIncident } from "./NoIncident";
import { Summary } from "./Summary";
import styles from "./styles.module.scss";
import { Card, CardBody } from "reactstrap";
import { Paginate } from "../../Components/Paginate";
import { DebouncedInput } from "../../Components/Form";
import { useIncidentFilter } from "../../services/filters";
import { executeOnlyIfNumber } from "./helper";
import { ComponentLoader } from "../../Components/Loader";
import { FilterTagWrapper, Tags } from "../../Components/Tags";

const GET_INCIDENTS_ID = "GET-INCIDENTS-ID";

const FilterWidget = ({
  isFilter,
  contractFilter,
  timeFilter,
  levelFilter,
  contractOptions,
}) => {
  if (!isFilter) return null;
  const contractFilterCount = contractFilter.getTrueValues().length;

  let contractFilterTags = {
    durationFilter: {
      label: "Duration",
      filterStr: timeFilter.getLabel(),
      onclose: () => timeFilter.onChange(""),
    },
    contractFilter: {
      label: "Contracts",
      filterStr: contractFilter
        .getTrueValues()
        .map(
          (value) =>
            contractOptions.find((option) => option.value.includes(value))
              ?.label
        ),
      onclose: (removeKey) => {
        contractFilter.removeFilter(removeKey, contractOptions);
      },
    },
  };
  levelFilter.getLabel().forEach((i) => {
    contractFilterTags[i.value] = {
      label: "Level",
      filterStr: i.label,
      onclose: () => levelFilter.onChange(i.value),
    };
  });
  return (
    <Tags>
      <FilterTagWrapper filters={contractFilterTags} />
    </Tags>
  );
};

const Incidents = () => {
  const { incidentId, eventId } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  let {
    incidents,
    isFilter,
    sort,
    timeFilter,
    levelFilter,
    paginate,
    contractFilter,
    contractOptions,
    isLoading,
  } = useIncidentFilter({ eventId });

  const { data: incidentById, isLoading: isIncidentByIdLoading } = useQuery({
    queryKey: [GET_INCIDENTS_ID, search],
    queryFn: () => {
      if (!search) return;
      return getIncidentById(search);
    },
  });

  if (search && incidentById) {
    incidents = [incidentById];
    paginate = {
      ...paginate,
      itemCount: 1,
      pageSize: 1,
      numPages: 1,
    };
  }

  const [recentIncidentIds, setRecentIncidentIds] = useState({});
  const isIncident = typeof incidentId !== "undefined";
  useMemo(() => {
    setRecentIncidentIds((m) =>
      isIncident ? { ...m, [incidentId]: true } : {}
    );
  }, [incidentId, isIncident]);

  const toggle = () => navigate("/incidents");
  const onIncidentClick = (id) => navigate(`/incidents/${id}`);

  if (paginate.itemCount === 0 && !isFilter) {
    return <NoIncident />;
  }

  return (
    <>
      <div className="h-100 d-flex flex-column">
        <h3 className="mb-4 text-black">Incidents</h3>
        <Summary />
        {isLoading || isIncidentByIdLoading ? (
          <ComponentLoader top={60} left={60} label="Loading incidents..." />
        ) : (
          <>
            <div className={styles.listing}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-lg align-items-center">
                      <div className="text-black fs-4">Incidents</div>
                      <FilterWidget
                        isFilter={isFilter}
                        levelFilter={levelFilter}
                        contractFilter={contractFilter}
                        timeFilter={timeFilter}
                        contractOptions={contractOptions}
                      />
                    </div>
                    <div className="invisible">
                      <DebouncedInput
                        size="sm"
                        placeholder="Search"
                        onChange={executeOnlyIfNumber(setSearch)}
                      />
                    </div>
                  </div>

                  <IncidentList
                    incidents={incidents}
                    sort={sort}
                    timeFilter={timeFilter}
                    levelFilter={levelFilter}
                    contractFilter={{
                      options: contractOptions,
                      value: contractFilter.getValue,
                      onChange: contractFilter.toggleValue,
                    }}
                    onIncidentClick={onIncidentClick}
                  />
                  <Paginate {...paginate} />
                </CardBody>
              </Card>
            </div>
          </>
        )}
      </div>
      {isIncident && (
        <IncidentModal
          isOpen={isIncident}
          incidentId={incidentId}
          recentIncidentIds={recentIncidentIds}
          toggle={toggle}
          onIncidentClick={onIncidentClick}
        />
      )}
    </>
  );
};

export { Incidents, FilterWidget };
