import { Input, Label } from "reactstrap";
import styles from "./styles.module.scss";
import { CopyButton } from "../../Components/CopyButton";
import { getUser } from "../../services/auth";
import { getBackendUrl, getTenant, isFreeStack } from "../../helpers/tenant";
import { useQuery } from "react-query";
import { GET_INTEGRATION_BY_ID } from "./constants";
import { getIntegrationByID } from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";
import { DisplayTable } from "../../Components/Tables";
import classNames from "classnames";
import dayjs from "dayjs";
import { ScrollContainer } from "../../Components/ScrollContainer";
import { ReactComponent as InfoGray } from "@/assets/icons/info_gray.svg";
import ROCodeEditor from "../../Components/CodeEditor/ROCodeEditor";

export const IntegrationDetails = ({
  dbtCoreIntegrationId,
  dbtCoreIntegrationEnv,
}) => {
  const { data, isLoading } = useQuery({
    queryKey: [
      GET_INTEGRATION_BY_ID,
      dbtCoreIntegrationId,
      dbtCoreIntegrationEnv,
    ],
    refetchInterval: 10000,
    queryFn: () =>
      getIntegrationByID(dbtCoreIntegrationId, dbtCoreIntegrationEnv),
  });

  if (isLoading) {
    return <RelativeComponentLoader />;
  }

  const { auth_token } = getUser();
  const tenant = getTenant();
  const title = data.name || "";
  const type = data.type || "dbt core";
  const env = data.environment_name;
  const backendURL = getBackendUrl();
  const command1 = `dbt compile`;
  const command2 = `dbt docs generate`;
  const command3 = `datapilot dbt onboard --backend-url ${backendURL} --token ${auth_token} --instance-name ${tenant} --dbt_core_integration_id ${dbtCoreIntegrationId} --dbt_core_integration_environment ${env} --manifest-path ./target/manifest.json --catalog-path ./target/catalog.json`;
  const command = `${command1}\n${command2}\n${command3}`;

  return (
    <ScrollContainer>
      <div className={styles.command}>
        <h3 className="text-black">Details</h3>
        {title && (
          <div className="mb-3">
            <Label className="form-label">Integration Name</Label>
            <Input disabled value={title} />
          </div>
        )}

        {type && (
          <div className="mb-3">
            <Label className="form-label">Type of Integration</Label>
            <Input disabled value={type} />
          </div>
        )}

        {env && (
          <div className="mb-3">
            <Label className="form-label">Environment</Label>
            <Input disabled value={env} />
          </div>
        )}

        {command && (
          <>
            <div className="mb-1">
              <Label className="form-label">Command</Label>
              <ROCodeEditor
                lineNumbers={true}
                height="16em"
                value={command}
                showCopy={false}
              />
            </div>

            <div className={styles.command_info}>
              <div>
                <InfoGray />
              </div>
              <div className={styles.text}>
                You need to run these commands from the dbt project root
                directory. If you are not using default manifest and catalog
                paths, you will need to update the paths in the command
              </div>
            </div>

            <div>
              <CopyButton
                value={command}
                label="Copy Command"
                copiedLabel="Command Copied"
              />
            </div>
          </>
        )}

        <div className="mb-3">
          <Label className="form-label">Sync History</Label>
          <DisplayTable
            header={[
              { id: 1, label: "Type" },
              { id: 2, label: "Time" },
            ]}
            items={data.sync_history}
            templateColumns="3fr 2fr"
            rowRender={(i) => {
              return (
                <>
                  <div className={classNames(styles.element_overflow)}>
                    {i.type}
                  </div>
                  <div className={styles.element_overflow}>
                    {dayjs(i.time).format("DD/MM/YYYY hh:mm a")}
                  </div>
                </>
              );
            }}
          />
        </div>
      </div>
    </ScrollContainer>
  );
};
