import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Paginate } from "../Paginate";
const DataGrid = ({ headers, data, onCellClicked, onRowClicked, onSelectionChanged, page, pageSize, setPage, setPageSize, gridApiRef, loading = false, }) => {
    const getColumnDefs = useCallback(() => {
        return headers.map((col) => (Object.assign({ field: col.field, sortable: false, filter: false, autoHeight: true }, col)));
    }, [headers]);
    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
        gridApiRef.current = params.api;
    }, [gridApiRef]);
    const rowData = useMemo(() => (data === null || data === void 0 ? void 0 : data.items) || [], [data]);
    return (_jsxs("div", { className: "ag-theme-alpine", style: { width: "100%" }, children: [_jsx(AgGridReact, { rowData: rowData, columnDefs: getColumnDefs(), onGridReady: onGridReady, onCellClicked: onCellClicked, onRowClicked: onRowClicked, onSelectionChanged: onSelectionChanged, domLayout: "autoHeight", suppressPaginationPanel: true, suppressRowClickSelection: true, rowSelection: "multiple", loading: loading }), data && (_jsx(Paginate, { itemCount: data.total, page: page, pageSize: pageSize, numPages: data.pages, onPageClick: setPage, showPageSize: true, onPageSizeChange: setPageSize }))] }));
};
export default DataGrid;
