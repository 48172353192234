import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from "reactstrap";
import styles from "./styles.module.scss";
export const MoneySavingsTag = ({ savings = "0" }) => {
    return (_jsx(Badge, { label: "Money Savings", color: "success", pill: true, className: styles.moneySavings, children: savings }));
};
export const TimeSavingsTag = ({ savings = "0" }) => {
    return (_jsx(Badge, { label: "Time Savings", color: "warning", pill: true, className: styles.timeSavings, children: savings }));
};
export const CountTag = ({ count = 0 }) => {
    return (_jsxs(Badge, { label: "Count", color: "info", pill: true, className: styles.count, children: ["Count: ", count] }));
};
export const LinenumberTag = ({ linenumber }) => {
    return (_jsx(Badge, { label: "Linenumber", color: "info", pill: true, className: styles.linenumber, children: linenumber }));
};
