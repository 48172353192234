import { Button, Card, CardBody } from "reactstrap";
import { FlexListSummary } from "../../Components/ListSummary";
import { NoExperimentation } from "./NoExperimentation";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ListingTable } from "../../Components/Tables";
import { useEffect, useState } from "react";
import { ColorInsightTags, FilterTagWrapper } from "../../Components/Tags";
import { useQuery } from "react-query";
import styles from "./styles.module.scss";
import {
  getAllExperiments,
  getExperimentSummary,
  getWarehouseMetadata,
} from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";
import dayjs from "dayjs";
import {
  GET_EXPERIMENTATION,
  GET_EXPERIMENTATION_SUMMARY,
  GET_WAREHOUSE_METADATA,
} from "./constants";
import { Paginate } from "../../Components/Paginate";
import { useUsersWithServiceUserWithMapping } from "../../helpers/hooks";
import { addSearchParams, removeSearchParams } from "../../helpers/utils";
import classNames from "classnames";

const Experimentation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const userFilter = searchParams.getAll("userFilter") || [];
  const statusFilter = searchParams.getAll("statusFilter") || [];
  const entityFilter = searchParams.getAll("entityFilter") || [];
  const [nameSort, setNameSort] = useState("");
  const { users, userNameToID, userIDtoName } =
    useUsersWithServiceUserWithMapping();
  const [warehouseNameToRkMap, setWarehouseNameToRkMap] = useState({});
  const [rkToWarehouseNameMap, setRkToWarehouseNameMap] = useState({});

  const { data: experiments, isLoading } = useQuery({
    queryKey: [
      GET_EXPERIMENTATION,
      page,
      nameSort,
      ...userFilter,
      ...statusFilter,
      ...entityFilter,
      warehouseNameToRkMap,
      userNameToID,
    ],
    queryFn: () => {
      const params = {
        page: page + 1,
        size: 10,
        sort_key: nameSort ? "name" : "",
        sort_order: nameSort || "desc",
      };
      if (userNameToID && Object.keys(userNameToID).length && userFilter.length)
        params.users = userFilter.map((u) => userNameToID[u]);
      if (statusFilter.length) params.status = statusFilter;
      if (
        warehouseNameToRkMap &&
        Object.keys(warehouseNameToRkMap).length &&
        entityFilter.length
      )
        params.entities = entityFilter.map((e) => warehouseNameToRkMap[e]);
      return getAllExperiments(params);
    },
  });
  const { data: warehouseMetadata, isLoading: isWarehouseLoading } = useQuery({
    queryKey: [GET_WAREHOUSE_METADATA],
    queryFn: () => getWarehouseMetadata(),
    onSuccess: (data) => {
      const warehouseNameToRk = {};
      const rkToWarehouseName = {};
      data.forEach((w) => {
        warehouseNameToRk[w.warehouse_name] = w.rk;
        rkToWarehouseName[w.rk] = w.warehouse_name;
      });
      setWarehouseNameToRkMap(warehouseNameToRk);
      setRkToWarehouseNameMap(rkToWarehouseName);
    },
  });

  const { data: experimentSummary, isLoading: isSummaryLoading } = useQuery({
    queryKey: [GET_EXPERIMENTATION_SUMMARY],
    queryFn: () => getExperimentSummary(),
  });

  const onFilterUpdate = (filterName, fromData) => {
    return (value) => {
      if (fromData) {
        setSearchParams(
          addSearchParams(
            filterName,
            value.map((v) => fromData[v])
          )
        );
      } else {
        setSearchParams(addSearchParams(filterName, value));
      }
    };
  };

  if (isLoading || isSummaryLoading || isWarehouseLoading)
    return <RelativeComponentLoader />;
  if (experimentSummary.total_experiments === 0) return <NoExperimentation />;
  return (
    <div className="h-100 d-flex flex-column">
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <h3 className="text-black">Experimentation</h3>
        <Link to="/experimentation/new">
          <Button color="primary">New Experimentation</Button>
        </Link>
      </div>
      <FlexListSummary
        summary={[
          {
            label: "Total Experiments",
            value: experimentSummary.total_experiments,
          },
          {
            label: "In Progress",
            value: experimentSummary.inprogress_experiments,
          },
          {
            label: "Success",
            value: experimentSummary.success_experiments,
          },
        ]}
      />

      <Card>
        <CardBody>
          <div className={styles.filter_tags}>
            <FilterTagWrapper
              filters={{
                statusFilter: {
                  filterStr: statusFilter,
                  label: "Status",
                  onclose: (removedFilter) => {
                    setSearchParams(
                      removeSearchParams("statusFilter", removedFilter)
                    );
                  },
                },
                entityFilter: {
                  filterStr: entityFilter,
                  label: "Entity",
                  onclose: (removedFilter) =>
                    setSearchParams(
                      removeSearchParams("entityFilter", removedFilter)
                    ),
                },
                userFilter: {
                  filterStr: userFilter,
                  label: "Users",
                  onclose: (removedFilter) => {
                    setSearchParams(
                      removeSearchParams("userFilter", removedFilter)
                    );
                  },
                },
              }}
            />
          </div>
          <ListingTable
            header={[
              {
                id: 1,
                label: "Experimentation Name",
                sort: {
                  onChange: (so) => setNameSort(so),
                  value: nameSort,
                },
              },
              {
                id: 2,
                label: "Status",
                filter: {
                  filterType: "dropdown",
                  value: statusFilter,
                  options: [
                    { label: "Planned", value: "Planned" },
                    { label: "In Progress", value: "In Progress" },
                    { label: "Completed", value: "Completed" },
                    { label: "Aborted", value: "Aborted" },
                  ],
                  onChange: onFilterUpdate("statusFilter"),
                },
              },
              {
                id: 3,
                label: "Owner",
                filter: {
                  filterType: "dropdown",
                  value: userFilter,
                  options: (users || []).map((u) => ({
                    label: `${u.first_name || ""} ${u.last_name || ""}`,
                    value: u.id,
                  })),
                  onChange: onFilterUpdate("userFilter", userIDtoName),
                },
              },
              {
                id: 4,
                label: "Period",
              },
              {
                id: 5,
                label: "Insights",
              },
              {
                id: 6,
                label: "Entities",
                filter: {
                  filterType: "dropdown",
                  value: entityFilter,
                  options: warehouseMetadata.map((w) => ({
                    label: w.warehouse_name,
                    value: w.rk,
                  })),
                  onChange: onFilterUpdate(
                    "entityFilter",
                    rkToWarehouseNameMap
                  ),
                },
              },
              {
                id: 7,
                label: "Created On",
              },
              {
                id: 8,
                label: "Start Time",
              },
            ]}
            items={experiments.items}
            idKey={(item) => item["id"]}
            onItemClick={(item) => navigate(`/experimentation/${item["id"]}`)}
            resetPage={() => {}}
            templateColumns="3fr 1fr 1fr 2fr 3fr 4fr 1fr 1fr"
            rowRender={(item) => (
              <>
                <div
                  className={classNames(
                    styles.break_word,
                    "text-primary cursor-pointer"
                  )}
                >
                  {item.name}
                </div>
                <div className={styles.break_word}>
                  {item.experiment_status}
                </div>
                <div className={styles.break_word}>
                  {users?.find((u) => u.id === item.created_by)?.first_name}
                </div>
                <div className={styles.break_word}>
                  {dayjs(item.experiment_start).format("DD MMM YYYY")}
                  {" - "}
                  {dayjs(item.experiment_end).format("DD MMM YYYY")}
                </div>
                <div className={styles.break_word}>
                  {Array.from(
                    new Set(
                      (item.entities || [])
                        .map((e) => e.insights || [])
                        .flat()
                        .map((i) => i.tag_rk)
                    )
                  ).join(", ")}
                </div>
                <div className={styles.break_word}>
                  <ColorInsightTags
                    tags={item.entities || []}
                    labelFn={(e) => e.entity_name}
                  />
                </div>
                <div className={styles.break_word}>
                  {dayjs(item.created_on).format("DD MMM YYYY")}
                </div>
                <div className={styles.break_word}>
                  {item.experiment_start
                    ? dayjs(item.experiment_start).format("DD MMM YYYY")
                    : "N/A"}
                </div>
              </>
            )}
          />
          <Paginate
            itemCount={experiments.total}
            page={page}
            pageSize={experiments.size}
            numPages={experiments.pages}
            onPageClick={setPage}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export { Experimentation };
