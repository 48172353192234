import { createPortal } from "react-dom";
import Lottie from "react-lottie";
import * as animationData from "../../assets/animation/Loader.json";
import styles from "./styles.module.scss";
import { ReactComponent as RobotIcon } from "@/assets/icons/robot.svg";
import classNames from "classnames";
import QueryInsightsRobot from "@/assets/images/query_insights_robot.png";
import Typewriter from "typewriter-effect";

const loaderElem = document.getElementById("loader");

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loader = () => {
  return createPortal(
    <div className={styles.container}>
      <Lottie options={defaultOptions} width={200} height={200} />
    </div>,
    loaderElem
  );
};

const ComponentLoader = ({ top = 50, left = 50, label }) => {
  return (
    <div
      className={styles.component}
      style={{ top: `${top}%`, left: `${left}%` }}
    >
      <Lottie options={defaultOptions} width={200} height={200} />
      <div style={{ marginTop: "-70px" }}>{label}</div>
    </div>
  );
};

const RelativeComponentLoader = ({
  width = 200,
  height = 200,
  label,
  componentHeight = 100,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `${componentHeight}vh`,
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Lottie options={defaultOptions} width={width} height={height} />
        <div style={{ marginTop: "-70px" }}>{label}</div>
      </div>
    </div>
  );
};

const TypeWriterLoader = ({ loadingTexts, label }) => {
  return (
    <div className={classNames(styles.explanation_popup, styles.generating)}>
      <img
        className={styles.robot_image}
        src={QueryInsightsRobot}
        alt="query_insights_robot"
      />
      <Typewriter
        options={{
          strings: loadingTexts,
          autoStart: true,
          loop: true,
          wrapperClassName: styles.rotating_text,
        }}
      />
      <div className={styles.text}>{label}</div>
    </div>
  );
};

export { Loader, ComponentLoader, RelativeComponentLoader, TypeWriterLoader };
