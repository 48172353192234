import { useState } from "react";
import styles from "./styles.module.scss";
import EditSquareIcon from "../../assets/icons/edit_square.svg";
import PlusIcon from "../../assets/icons/plus.svg";
import ContractSchedule from "../../assets/icons/contract_schedule.svg";
import DeletePolicy from "../../assets/icons/delete_policy.svg";
import DataContract from "../../assets/icons/data_contract.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";
import TimelineIcon from "../../assets/images/timeline.svg";
import { useQuery } from "react-query";
import { Card, CardBody } from "reactstrap";
import { getTimelineEventsByContractId } from "../../helpers/backend_helper";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SidebarModal from "../../Components/SidebarModal";
import dayjs from "dayjs";

const timeline_contentStyle = {
  fontFamily: "Lexend",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: "#082247",
  display: "flex",
  alignItems: "center",
  marginLeft: "40px",
  marginTop: "6px",
  width: "282px",
  padding: "14px",
  gap: "10px",
  border: "1px solid #E7E8EA",
  boxShadow: "none",
  borderRadius: "12px",
};

const iconStyle = {
  width: "24px",
  height: "24px",
  display: "flex",
  background: "white",
  boxShadow: "none",
  border: "1px solid #E7E8EA",
  padding: "5px",
  marginTop: "-32px",
  marginLeft: "8px",
};
const GET_TIMELINE_DATA = "GET-TIMELINE-DATA";
const Timeline = ({ contractId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const { data: timelineEvents } = useQuery({
    queryKey: [GET_TIMELINE_DATA, contractId],
    queryFn: () => getTimelineEventsByContractId(contractId),
  });
  return (
    <div>
      <Card className={styles.timeline_button} onClick={toggleModal}>
        <CardBody>
          <div className="d-flex">
            <div className="me-2 level-orange">
              <img src={TimelineIcon} alt="" />
            </div>
            <div>Timeline</div>
          </div>
        </CardBody>
      </Card>
      <SidebarModal isOpen={isOpen} toggleModal={toggleModal}>
        <Card style={{ borderRadius: "0px" }}>
          <CardBody style={{ height: "100%", marginLeft: "-16px" }}>
            <div className={styles.sidemodal_timeline_text}>Timeline</div>
            <button className={styles.close_timeline_btn} onClick={toggleModal}>
              <CrossIcon />
            </button>
            <div className={styles.vertical_timeline_scroll}>
              <VerticalTimeline layout={"1-column-left"} lineColor={"#E7E8EA"}>
                {timelineEvents &&
                  timelineEvents["timeline_events"].map((event) => (
                    <VerticalTimelineElement
                      key={event.id}
                      className="vertical-timeline-element"
                      contentStyle={timeline_contentStyle}
                      contentArrowStyle={{
                        display: "none",
                      }}
                      iconStyle={iconStyle}
                      icon={TimelineEventIconWithDate(
                        event.event_type,
                        event.created_on
                      )}
                    >
                      {event.event_message}
                    </VerticalTimelineElement>
                  ))}
              </VerticalTimeline>
            </div>
          </CardBody>
        </Card>
      </SidebarModal>
    </div>
  );
};

const TimelineEventIconWithDate = (eventType, eventTime) => {
  const isoDatetime = new Date(eventTime).toISOString();
  const formattedDatetime = dayjs(isoDatetime).format("hh:mm A, MMM DD, YYYY");
  switch (eventType) {
    case "contract_created":
      return (
        <>
          <img src={PlusIcon} alt="" />
          <div className={styles.timeline_event_icon}>{formattedDatetime}</div>
        </>
      );
    case "contract_updated":
      return (
        <>
          <img src={EditSquareIcon} alt="" />
          <div className={styles.timeline_event_icon}>{formattedDatetime}</div>
        </>
      );
    case "contract_status_active":
      return (
        <>
          <img src={ContractSchedule} alt="" />
          <div className={styles.timeline_event_icon}>{formattedDatetime}</div>
        </>
      );
    case "contract_status_inactive":
      return (
        <>
          <img src={ContractSchedule} alt="" />
          <div className={styles.timeline_event_icon}>{formattedDatetime}</div>
        </>
      );
    case "policy_removed":
      return (
        <>
          <img src={DeletePolicy} alt="" />
          <div className={styles.timeline_event_icon}>{formattedDatetime}</div>
        </>
      );
    case "contract_metadata_changed":
      return (
        <>
          <img src={DataContract} alt="" />
          <div className={styles.timeline_event_icon}>{formattedDatetime}</div>
        </>
      );
    default:
    // code
  }
};

export { Timeline };
