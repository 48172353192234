import { Alert, Button, Card, CardBody } from "reactstrap";
import { CopyIconButton } from "../../Components/CopyButton";
import { CodeSQLEditor } from "../../Components/CodeEditor";
import styles from "./styles.module.scss";
import QueryInsightsRobot from "../../assets/images/query_insights_robot.png";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Feedback } from "../../Components/Feedback";
import classNames from "classnames";
import Typewriter from "typewriter-effect";
import { GET_QUERY_BY_ID } from "./constants";
import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/arrow_left.svg";
import { ReactComponent as InfoIcon } from "@/assets/icons/info_circle_2.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/cross.svg";
import { addSearchParams, isStrDateOlderThan } from "../../helpers/utils";
import { Tab } from "../../Components/Tab";
import {
  QueryExplanation,
  InsightSection,
  SideContent,
  QuerySqlLineage,
} from "./components";
import { TypeWriterLoader } from "@/Components/Loader";
import { getQueryById } from "@/helpers/backend_helper";
import { getQuerySqlLineageById } from "@/helpers/apis";
import * as Sentry from "@sentry/browser";
import { Select } from "@/uiCore";
import { PERIOD_OPTIONS } from "../Explorer/constants";
import dayjs from "dayjs";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { useDateFromSearchParams } from "@/helpers/useTimeHooks";
import { DateRange } from "@/Components/DateRange";
import { ColorInsightTags } from "@/Components/Tags";

function MainContent({
  queryId,
  query,
  selectedInsight,
  sqlLineage,
  setSelectedNode,
  parentEditorRef,
  setSelectedTab,
  setShowSideContent,
  showSideContent,
  from,
  startDate,
  endDate,
}) {
  const [insightUnavailable, setInsightUnavailable] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedInsightId = searchParams.get("selectedInsightId");
  const setSelectedInsightId = (v) => setSearchParams({ selectedInsightId: v });

  useEffect(() => {
    if (!selectedInsightId) return;
    for (const key in sqlLineage?.query_tags_meta || []) {
      for (const t of sqlLineage?.query_tags_meta[key] || []) {
        if (selectedInsightId === t.id) {
          setSelectedNode(key);
          document.dispatchEvent(
            new CustomEvent("onSelectedNodes", { detail: key })
          );
          setShowSideContent(true);
          return;
        }
      }
    }
  }, []);
  const tabs = [];
  if (sqlLineage) {
    tabs.push({
      label: "SQL Visualizer",
      component: (
        <QuerySqlLineage
          sqlLineage={sqlLineage}
          selectedInsight={selectedInsight}
          setSelectedNode={setSelectedNode}
          setShowSideContent={setShowSideContent}
          showSideContent={showSideContent}
          setSelectedInsight={setSelectedInsightId}
        />
      ),
    });
  }
  tabs.push({
    label: "Code",
    component: (
      <CodeSQLEditor
        ref={parentEditorRef}
        value={query.query_text}
        height="36vh"
        lineNumbers={true}
        minimap
      />
    ),
  });
  return (
    <div className={styles.query_details}>
      <Card>
        <CardBody>
          <div className="d-flex flex-column gap-md">
            <div className="d-flex align-items-center gap-sm">
              <div
                className="p-1 br-2 bg-blue text-white cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(from || `/query/all`);
                }}
              >
                <ArrowLeftIcon className="icon-sm" />
              </div>
              <div className="fs-4">Query ID:</div>
              <div className="fs-4 text-primary">{queryId}</div>
              <CopyIconButton value={queryId} color="#247EFE" />
              <div className="spacer" />
              <Button
                className={
                  isStrDateOlderThan(query.timestamp, 14)
                    ? styles.query_insight_btn
                    : ""
                }
                disabled={isStrDateOlderThan(query.timestamp, 14)}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/copilot", {
                    state: {
                      query_insights_copilot: {
                        value: query.query_text,
                        tags: sqlLineage.tags,
                        data_store_id: query.data_store_id,
                        query_id: queryId,
                        start_time: query.start_time,
                      },
                    },
                  });
                }}
              >
                Query Insights Agent
              </Button>
            </div>
            {isStrDateOlderThan(query.timestamp, 14) && insightUnavailable && (
              <div
                className={classNames(
                  styles.insights_unavailable,
                  "align-items-center"
                )}
              >
                <div
                  className={classNames(
                    styles.unavailable_text,
                    "align-items-center"
                  )}
                >
                  <InfoIcon />
                  <div>
                    Query Insights agent is not available for queries older than
                    2 weeks
                  </div>
                </div>
                <div
                  className={styles.close_icon}
                  onClick={() => setInsightUnavailable(false)}
                >
                  <CrossIcon />
                </div>
              </div>
            )}
            <div className={styles.query_metrics_container}>
              {[
                {
                  label: "Est. Cost",
                  value: query.cost,
                },
                {
                  label: "Exec. time",
                  value: query.execution_time,
                },
                {
                  label: "Partitions Scanned",
                  value: query.percent_partition_scanned,
                },
                {
                  label: "Query Type",
                  value: query.query_type,
                },
                {
                  label: "Warehouse",
                  value: query.warehouse_name,
                },
                {
                  label: "Bytes spilled",
                  value: query.bytes_spilled,
                },
                {
                  label: "User",
                  value: query.user_name,
                },
                {
                  label: "Timestamp",
                  value: query.timestamp,
                },
                {
                  label: "Cache fetched",
                  value: query.percentage_scanned_from_cache,
                },
                {
                  label: "Resource Utilization",
                  value: query.query_load_percent,
                },
                {
                  label: "Warehouse Size",
                  value: query.warehouse_size,
                },
              ].map(({ label, value }) => (
                <div className="d-flex" key={label}>
                  <div className="fw-semibold me-1">{label}:</div>
                  <div className="text-muted">{value}</div>
                </div>
              ))}
            </div>
            <ColorInsightTags tags={query.tags} labelFn={(t) => t.tag_rk} />
            <Tab
              tabs={tabs}
              pagedTab={false}
              onSelectedTabChange={setSelectedTab}
            />
          </div>
        </CardBody>
      </Card>
      <QueryExplanation queryId={queryId} />
      <div>
        <div className="d-flex m-2">
          <div className="fs-4">Opportunities</div>
          <div className="spacer" />
          <DateRange
            startDate={startDate}
            endDate={endDate}
            availableStaticRanges={["Last day", "Last 7 days", "Last 28 days"]}
            disableCalendarSelection
            onDateRangeSelect={(s, e) => {
              setSearchParams((prev) => {
                const params = new URLSearchParams(prev);
                params.set("start_date", s?.toISOString());
                params.set("end_date", e?.toISOString());
                return params.toString();
              });
            }}
          />
        </div>
        <InsightSection
          opportunities={sqlLineage?.tags}
          totalRuns={query.query_hash_count}
          selectedOpportunityId={
            sqlLineage?.tags?.find((t) => t.id === selectedInsightId)
              ?.opportunity_id || searchParams.get("opportunityId")
          }
          setOpportunityId={(item) => {
            if (!sqlLineage) return;
            parentEditorRef.current?.onParentResize();
            const insightId = sqlLineage?.tags?.find(
              (t) => t.opportunity_id === item
            )?.id;
            setShowSideContent(true);
            if (!insightId) {
              setSelectedNode("");
              document.dispatchEvent(
                new CustomEvent("onSelectedNodes", { detail: "" })
              );
              setSearchParams({ opportunityId: item });
              return;
            }
            setSelectedInsightId(insightId);
            for (const key in sqlLineage.query_tags_meta) {
              for (const t of sqlLineage.query_tags_meta[key] || []) {
                if (insightId === t.id) {
                  setSelectedNode(key);
                  document.dispatchEvent(
                    new CustomEvent("onSelectedNodes", { detail: key })
                  );
                  return;
                }
              }
            }
            setSelectedNode("");
            document.dispatchEvent(
              new CustomEvent("onSelectedNodes", { detail: "" })
            );
          }}
        />
      </div>
      <Feedback feedback_id={queryId} feedback_type="query_tag" />
    </div>
  );
}

const QueryDetails = () => {
  const { queryId } = useParams();
  const editorRef = useRef(null);
  const [selectedNode, setSelectedNode] = useState("");
  const [selectedTab, setSelectedTab] = useState("SQL Visualizer");
  const [showSideContent, setShowSideContent] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { startDate, endDate } = useDateFromSearchParams(
    dayjs().subtract(28, "day").toDate(),
    dayjs().toDate()
  );

  const {
    data: querydetails,
    isLoading,
    error,
  } = useQuery({
    queryKey: [GET_QUERY_BY_ID, queryId, startDate, endDate],
    queryFn: () =>
      getQueryById(queryId, {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
        end_date: getDateByEndOfDayWithoutTimeoffset(endDate).toISOString(),
      }),
  });

  const { data: sqlLineage, isLoading: isSqlLineageLoading } = useQuery({
    queryKey: ["GET_QUERY_SQL_LINEAGE_BY_ID", queryId, startDate, endDate],
    queryFn: () =>
      getQuerySqlLineageById(queryId, {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
        end_date: getDateByEndOfDayWithoutTimeoffset(endDate).toISOString(),
      }),
    retry: false,
    onError: (err) => {
      if (err === "canceled") {
        Sentry.captureException(new Error("SQL Lineage timeout"));
      }
    },
  });

  const selectedInsightId = searchParams.get("selectedInsightId");
  const selectedInsight = useMemo(
    () =>
      sqlLineage?.tags?.find((t) => t.id === selectedInsightId)?.tag_rk || "",
    [selectedInsightId, sqlLineage?.tags]
  );

  if (isLoading || isSqlLineageLoading) {
    return (
      <TypeWriterLoader
        label="Analyzing query might take a few seconds"
        loadingTexts={[
          "Looking at query metadata",
          "Analyzing query pattern",
          "Fetching query insights",
        ]}
      />
    );
  }

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  const { query } = querydetails;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: showSideContent ? "2fr 1fr" : "1fr",
        gap: "1rem",
      }}
    >
      <MainContent
        queryId={queryId}
        parentEditorRef={editorRef}
        selectedInsight={selectedInsight}
        query={query}
        setSelectedNode={setSelectedNode}
        sqlLineage={sqlLineage}
        setSelectedTab={setSelectedTab}
        setShowSideContent={setShowSideContent}
        showSideContent={showSideContent}
        from={searchParams.get("from")}
        startDate={startDate}
        endDate={endDate}
      />
      {showSideContent && sqlLineage && (
        <SideContent
          parentEditorRef={editorRef}
          selectedNode={selectedNode}
          sqlLineage={sqlLineage}
          query={query.query_text}
          selectedTab={selectedTab}
          setShowSideContent={setShowSideContent}
          opportunities={sqlLineage?.tags}
        />
      )}
    </div>
  );
};

export { QueryDetails };
