import { useQuery } from "react-query";
import { getQueryJobFilters, getQueryJobs } from "../../helpers/backend_helper";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { ListingTable } from "../../Components/Tables";
import { useState } from "react";
import { RelativeComponentLoader } from "../../Components/Loader";
import {
  JobCostAndExecutionTimeGraph,
  JobExecutions,
  JobSelectedHeader,
} from "./JobExecutions";
import { Paginate } from "../../Components/Paginate";
import { GET_JOBS, GET_JOB_FILTERS } from "./constants";
import { FilterTagWrapper } from "../../Components/Tags";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { addSearchParams, removeSearchParams } from "../../helpers/utils";

const JobList = ({ setSelectedJob, jobFilters, autoTune = false }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { jobID } = useParams();
  const [page, setPage] = useState(0);
  const [sortAttribute, setSortAttribute] = useState("annualized_cost");
  const annualizedCostFilter = jobFilters?.costFilter || 0;
  const warehouseFilter = jobFilters?.warehouseFilter || [];
  const environmentFilter = jobFilters?.environmentFilter || "";
  const [sortOrder, setSortOrder] = useState({
    job_name: "",
    number_of_runs: "",
    annualized_cost: "desc",
    max_total_execution_time: "",
    median_total_execution_time: "",
    avg_total_execution_time: "",
    p90_total_execution_time: "",
    avg_local_spill: "",
  });

  const handleJobNameSortChange = (so) => {
    if (so) {
      setSortAttribute("job_name");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        job_name: so,
      }));
    }
  };

  const handleMaxRunTimeChange = (so) => {
    if (so) {
      setSortAttribute("max_total_execution_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        max_total_execution_time: so,
      }));
    }
  };

  const handleAvgRunTimeChange = (so) => {
    if (so) {
      setSortAttribute("avg_total_execution_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        avg_total_execution_time: so,
      }));
    }
  };

  const handleP90RunTimeChange = (so) => {
    if (so) {
      setSortAttribute("p90_total_execution_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        p90_total_execution_time: so,
      }));
    }
  };

  const handleNumberOfRunsSortChange = (so) => {
    if (so) {
      setSortAttribute("job_frequency");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        job_frequency: so,
      }));
    }
  };

  const handleAnnualizedCostSortChange = (so) => {
    if (so) {
      setSortAttribute("annualized_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        annualized_cost: so,
      }));
    }
  };

  const handleAvgLocalSpillChange = (so) => {
    if (so) {
      setSortAttribute("avg_local_spill");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        avg_local_spill: so,
      }));
    }
  };

  const { data: filters, isLoading: isfiltersLoading } = useQuery({
    queryKey: [GET_JOB_FILTERS],
    queryFn: () => getQueryJobFilters({ auto_tune: autoTune }),
  });

  const getFilterParams = () => {
    const params = { page: page + 1, size: 8 };
    if (sortAttribute) {
      params.sort_key = sortAttribute;
      params.sort_order = sortOrder[sortAttribute];
    }
    if (warehouseFilter.length > 0) params.warehouses = warehouseFilter;
    if (autoTune) params.auto_tune = true;
    if (environmentFilter) params.environments = environmentFilter;
    if (annualizedCostFilter) params.annualized_cost = annualizedCostFilter;
    if (jobID) params.job_id = jobID;
    return params;
  };

  const { data, isLoading } = useQuery({
    queryKey: [
      GET_JOBS,
      page,
      ...warehouseFilter,
      ...environmentFilter,
      sortOrder[sortAttribute],
      sortAttribute,
      annualizedCostFilter,
      jobID,
      autoTune,
    ],
    queryFn: () => getQueryJobs(getFilterParams()),
    onSuccess: (res) => {
      if (jobID) {
        const job = res["items"].find((item) => item.job_id === jobID);
        if (job) {
          setSelectedJob(job);
        }
      }
    },
  });

  const handleItemClick = (item) => {
    setPage(0);
    if (autoTune) {
      navigate(`/query/autotune_jobs/${item.job_id}`);
    } else {
      navigate(`/query/jobs/${item.job_id}`);
    }
  };

  const handleCostFilterChange = (value) => {
    setSearchParams(addSearchParams("cost", value));
  };

  const handleWarehouseFilterChange = (value) => {
    setSearchParams(addSearchParams("warehouses", value));
  };

  const handleEnvironmentFilterChange = (value) => {
    setSearchParams(addSearchParams("environments", value));
  };

  if (isLoading || isfiltersLoading) return <RelativeComponentLoader />;

  const listingTableHeaders = [
    {
      id: 1,
      label: "Job name",
      sort: {
        onChange: handleJobNameSortChange,
        value: sortOrder.job_name,
      },
    },
    {
      id: 2,
      label: "Average run time",
      sort: {
        onChange: handleAvgRunTimeChange,
        value: sortOrder.avg_total_execution_time,
      },
    },
    {
      id: 3,
      label: "90th percentile",
      sort: {
        onChange: handleP90RunTimeChange,
        value: sortOrder.p90_total_execution_time,
      },
    },
    {
      id: 4,
      label: "Max run time",
      sort: {
        onChange: handleMaxRunTimeChange,
        value: sortOrder.max_total_execution_time,
      },
    },
    {
      id: 5,
      label: "Job Frequency",
      sort: {
        onChange: handleNumberOfRunsSortChange,
        value: sortOrder.job_frequency,
      },
    },
    {
      id: 6,
      label: "Annualized cost",
      sort: {
        onChange: handleAnnualizedCostSortChange,
        value: sortOrder.annualized_cost,
      },
      filter: {
        filterType: "text",
        value: annualizedCostFilter,
        placeHolder: "Specify cost in $",
        onChange: handleCostFilterChange,
        label: "Annualized Cost",
      },
    },
    {
      id: 7,
      label: "Warehouses",
      filter: {
        filterType: "dropdown",
        value: warehouseFilter,
        options: filters.warehouses
          ? filters.warehouses.map((warehouse) => ({
              label: warehouse,
              value: warehouse,
            }))
          : [],
        onChange: handleWarehouseFilterChange,
      },
    },
  ];

  if (autoTune) {
    listingTableHeaders.splice(1, 0, {
      id: 8,
      label: "Environment",
      filter: {
        filterType: "dropdown",
        value: environmentFilter,
        options: (filters?.environments || []).map((env) => ({
          label: env,
          value: env,
        })),
        onChange: handleEnvironmentFilterChange,
      },
    });

    listingTableHeaders.splice(8, 0, {
      id: 9,
      label: "Avg. Local Spill",
      sort: {
        onChange: handleAvgLocalSpillChange,
        value: sortOrder.avg_local_spill,
      },
    });
  }

  return (
    <div className={styles.jobs}>
      <div className="d-flex gap-md align-items-center mt-2">
        <div className={styles.title}>Query Jobs</div>
        <FilterTagWrapper
          filters={{
            annualizedCostFilter: {
              label: "Annualized Cost",
              filterStr: annualizedCostFilter,
              onclose: () => {
                setSearchParams(removeSearchParams("cost"));
              },
            },
            warehouseFilter: {
              label: "Warehouse",
              filterStr: warehouseFilter,
              onclose: (removedFilter) => {
                setSearchParams(
                  removeSearchParams("warehouses", removedFilter)
                );
              },
            },
            environmentFilter: {
              label: "Environment",
              filterStr: environmentFilter,
              onclose: (removedFilter) => {
                setSearchParams(
                  removeSearchParams("environments", removedFilter)
                );
              },
            },
          }}
        />
      </div>
      <ListingTable
        header={listingTableHeaders}
        onItemClick={handleItemClick}
        idKey={(item) => item.id}
        items={data.items}
        resetPage={() => {}}
        templateColumns={`${
          autoTune
            ? "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
            : "2fr 1fr 1fr 1fr 1fr 1fr 1fr"
        }`}
        clickable={true}
        rowRender={(item) => {
          const jobFrequency = item?.job_frequency;
          return (
            <>
              <div className="text-primary condense-text">{item.job_name}</div>
              {autoTune && (
                <div className={styles.break_word}>{item?.environment}</div>
              )}
              <div className={styles.break_word}>
                {item?.avg_total_execution_time}
              </div>
              <div className={styles.break_word}>
                {item?.p90_total_execution_time}
              </div>
              <div className={styles.break_word}>
                {item?.max_total_execution_time}
              </div>
              <div className={styles.break_word}>{jobFrequency}</div>
              <div className={styles.break_word}>
                {item?.annualized_cost} USD
              </div>
              <div className={styles.break_word}>
                {item?.warehouses.join(", ")}
              </div>
              {autoTune && (
                <div className={styles.break_word}>{item?.avg_local_spill}</div>
              )}
            </>
          );
        }}
      />
      <Paginate
        itemCount={data.total}
        page={page}
        pageSize={data.size}
        numPages={data.pages}
        onPageClick={setPage}
      />
    </div>
  );
};

const Jobs = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const jobFilters = {
    costFilter: searchParams.getAll("cost") || 0,
    warehouseFilter: searchParams.getAll("warehouses") || [],
    userFilter: searchParams.getAll("users") || [],
    warehouseSizeFilter: searchParams.getAll("warehouseSize") || [],
  };

  return (
    <>
      {!selectedJob ? (
        <JobList setSelectedJob={setSelectedJob} jobFilters={jobFilters} />
      ) : (
        <div className="d-flex flex-column">
          <JobSelectedHeader
            initialJobName={selectedJob?.job_name}
            jobData={selectedJob}
            close={() => setSelectedJob(null)}
          />
          <JobCostAndExecutionTimeGraph jobId={selectedJob?.job_id} />
          <JobExecutions selectedJob={selectedJob} jobFilters={jobFilters} />
        </div>
      )}
    </>
  );
};

const AutoTuneJobs = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const jobFilters = {
    costFilter: searchParams.getAll("cost") || 0,
    warehouseFilter: searchParams.getAll("warehouses") || [],
    warehouseSizeFilter: searchParams.getAll("warehouseSize") || [],
    userFilter: searchParams.getAll("users") || [],
    environmentFilter: searchParams.getAll("environments") || "",
  };

  return (
    <>
      {!selectedJob ? (
        <JobList
          autoTune={true}
          setSelectedJob={setSelectedJob}
          jobFilters={jobFilters}
        />
      ) : (
        <div className="d-flex flex-column">
          <JobSelectedHeader
            environment={selectedJob?.environment}
            initialJobName={selectedJob?.job_name}
            jobData={selectedJob}
            close={() => setSelectedJob(null)}
          />
          <JobCostAndExecutionTimeGraph jobId={selectedJob?.job_id} />
          <JobExecutions
            selectedJob={selectedJob}
            jobFilters={jobFilters}
            autoTune={true}
          />
        </div>
      )}
    </>
  );
};

export { Jobs, AutoTuneJobs };
