import { jsx as _jsx } from "react/jsx-runtime";
import { STATUS_OPTIONS } from "../../constants";
import styles from "./styles.module.scss";
import { Select } from "@/uiCore";
import { useOpportunities } from "../../useOpportunity";
import { getUser } from "@/services/auth";
const StatusAssignerDropdown = ({ opportunity_id, assignedStatus, setAssignedStatus = () => { }, disableUpdateOnChange = false, }) => {
    const { mutation } = useOpportunities("");
    const { mutate } = mutation;
    const user = getUser();
    const handleStatusChange = ({ e, opportunity_id, }) => {
        mutate({
            changes: [
                {
                    opportunity_id,
                    status: e,
                    owner: undefined,
                },
            ],
            user_id: user.id,
        });
    };
    return (_jsx(Select, { id: `state-assigner`, onChange: (e) => {
            setAssignedStatus(e);
            if (!disableUpdateOnChange) {
                handleStatusChange({ e, opportunity_id });
            }
        }, value: assignedStatus, options: STATUS_OPTIONS, showDivider: true, placeholder: "Status", className: styles.bulk_select_dropdown }));
};
export default StatusAssignerDropdown;
