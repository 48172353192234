import { Button } from "reactstrap";
import styles from "./styles.module.scss";
import premiumRobot from "@/assets/images/roboimagechat.svg";
import { useNavigate } from "react-router-dom";
import { createCheckoutSession } from "../../helpers/backend_helper";
import { useMutation } from "react-query";

const CreditLimit = () => {
  const navigate = useNavigate();

  const { mutate: checkout, error } = useMutation(createCheckoutSession, {
    onSuccess: (res) => {
      if (res?.checkout_url) {
        window.location.href = res.checkout_url;
      }
    },
  });

  const handleDetailsClick = () => {
    navigate("/settings/executions");
  };

  const handlePurchase = () => {
    checkout();
  };

  return (
    <div className={styles.credit_limit}>
      <div className={styles.content}>
        <div className={styles.title}>Credit limit reached!</div>
        <div className={styles.text}>
          Allotted credit quota has been used. LLM are expensive! (TBD text).
          Please purchase more credits.
        </div>
        <div className={styles.buttons}>
          <Button
            outline
            className={styles.details}
            onClick={handleDetailsClick}
          >
            <div className={styles.details_text}>See Details</div>
          </Button>
          <Button className={styles.purchase} onClick={handlePurchase}>
            <div className={styles.purchase_text}>Purchase Credits</div>
          </Button>
        </div>
      </div>
      <img src={premiumRobot} alt="premium" />
    </div>
  );
};

export { CreditLimit };
