import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DatapilotAnalysis from "./Components/DatapilotAnalysis";
import FutureAnalysis from "./Components/FutureAnalysis";
import PastAnalysis from "./Components/PastAnalysis";
import { createContext, useContext, useMemo, useReducer, useState, } from "react";
import summarySlice from "./Context/summarySlice";
import classes from "./summary.module.scss";
import { Stack, Tabs } from "@uicore";
import Opportunities from "./Components/Opportunities";
import classNames from "classnames";
import { ReactComponent as Analyze } from "./analyze.svg";
import { ReactComponent as ArrowUp } from "./arrow_up.svg";
export const SummaryContext = createContext({
    state: summarySlice.getInitialState(),
    dispatch: () => null,
});
const Summary = () => {
    const [selectedTab, setSelectedTab] = useState("");
    const [state, dispatch] = useReducer(summarySlice.reducer, summarySlice.getInitialState());
    const values = useMemo(() => ({
        state,
        dispatch,
    }), [state, dispatch]);
    const onTabSelect = (value) => {
        var _a;
        setSelectedTab(value);
        (_a = document
            .getElementById(value)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: "smooth", block: "start" });
    };
    const [isDatapilotAnalysisOpen, setIsDatapilotAnalysisOpen] = useState(false);
    return (_jsx(SummaryContext.Provider, { value: values, children: _jsxs("div", { className: classes.summary, children: [_jsxs(Stack, { className: classes.stickyHeader, children: [_jsx("h3", { className: "text-black", children: "Summary" }), _jsx(Tabs, { className: classes.summaryTabs, pills: false, items: [
                                { label: "Current State", value: "current" },
                                { label: "Future State", value: "future" },
                                { label: "Opportunities", value: "opportunities" },
                            ], selectedTab: selectedTab, onTabSelect: onTabSelect })] }), _jsxs(Stack, { className: classNames(classes.datapilotAnalysisCoverUp, {
                        [classes.analysisOpen]: isDatapilotAnalysisOpen,
                    }), children: [_jsx("div", { className: classes.text, children: "DataPilot can analyze your usage for last 30 days and uncover key highlights" }), _jsx("div", { className: classes.button, onClick: () => setIsDatapilotAnalysisOpen(!isDatapilotAnalysisOpen), children: isDatapilotAnalysisOpen ? (_jsx(ArrowUp, {})) : (_jsxs(_Fragment, { children: [_jsx(Analyze, {}), " Analyze"] })) })] }), isDatapilotAnalysisOpen && _jsx(DatapilotAnalysis, {}), _jsx(PastAnalysis, {}), _jsx(FutureAnalysis, {}), _jsx(Opportunities, {})] }) }));
};
export const useSummaryDispatch = () => {
    const { dispatch } = useContext(SummaryContext);
    return dispatch;
};
export default Summary;
