import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { useRef, useState } from "react";
import { Popover, PopoverBody } from "@uicore";
import { ComponentLoader } from "@/Components/Loader";
import classes from "./styles.module.scss";
const AsyncPopover = ({ text, apiFn, children, queryKey, apiParams, textClassName, loadingLabel, }) => {
    const [showPopover, setShowPopover] = useState(false);
    const timer = useRef(null);
    const ref = useRef(null);
    const { data, isFetching, refetch } = useQuery({
        queryKey: [queryKey, text],
        queryFn: () => apiFn(text, apiParams),
        enabled: false,
        staleTime: 10000,
    });
    const onMouseOut = () => {
        if (timer.current) {
            window.clearTimeout(timer.current);
        }
        timer.current = window.setTimeout(() => {
            setShowPopover(false);
        }, 200);
    };
    const onHover = () => {
        if (timer.current) {
            window.clearTimeout(timer.current);
        }
        timer.current = window.setTimeout(() => {
            setShowPopover(true);
            refetch();
        }, 200);
    };
    const onMouseOverPopover = () => {
        if (timer.current) {
            window.clearTimeout(timer.current);
        }
    };
    if (!text) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [ref.current ? (_jsx(Popover, { target: ref.current, isOpen: showPopover, className: classes.asyncPopover, children: _jsxs(PopoverBody, { onMouseOut: onMouseOut, onMouseOver: onMouseOverPopover, children: [isFetching ? (_jsx("div", { className: classes.loader, children: _jsx(ComponentLoader, { label: loadingLabel }) })) : null, data ? children({ data: data }) : null] }) })) : null, _jsx("span", { className: textClassName, ref: ref, onMouseOver: onHover, onMouseOut: onMouseOut, children: text })] }));
};
export default AsyncPopover;
