import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
    currency: "USD",
};
const appSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setCurrency: (state, action) => {
            state.currency = action.payload;
        },
    },
});
export const { setCurrency } = appSlice.actions;
export default appSlice;
