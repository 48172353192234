// create hook for get opportunities
import { updateOpportunities } from "@/helpers/apis";
import { useMutation, useQueryClient } from "react-query";
export const useOpportunities = (queryID) => {
    const queryClient = useQueryClient();
    // @ts-ignore
    const mutation = useMutation(updateOpportunities, {
        onSuccess: () => {
            if (queryID)
                queryClient.invalidateQueries(queryID);
        },
    });
    return { mutation };
};
