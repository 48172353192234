import { APIClient } from "./api_helper";
export const api = new APIClient();
// Summary v2
export const getCostBreakdown = (path, params) => api.get(`/summary/v2/cost/${path}`, params);
export const getSavingsBreakdown = (path, params) => api.get(`/summary/v2/savings/${path}`, params);
export const getSummaryPageOpportunities = (params) => api.get("/summary/v2/opportunities", params);
export const getOpportunities = (data) => api.get("/opportunities/", data);
export const updateOpportunities = (data) => api.post("/opportunities/", data);
export const getOpportunitiesSummary = (data) => api.get("/opportunities/summary", data);
export const getOpportunitiesResourceNameFilters = (data) => api.get("/opportunities/resource_name_filter", data);
export const getOpportunitiesResource = (data) => api.get("/opportunities/resource", data);
export const getOpportunitiesRedirection = (data) => api.get("/opportunities/redirection", data);
export const getQuerySqlLineageById = (id, params) => api.get(`/query/v2/sql_lineage/${id}`, params, 60000);
export const getQueryGroupSqlLineageById = (id, params) => api.get(`/query/v2/sql_lineage_group/${id}`, params, 60000);
export const getQueryGroupDetailsByHash = (hash, params) => api.get(`/query/v2/query_group/stats/${hash}`, params);
export const getQueryTextByHash = (hash) => api.get(`/query/v2/query_group/query_text/${hash}`, {});
