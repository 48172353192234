import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { TabWithOutlet } from "../../Components/Tab";
import { AlertBox } from "../Alerts/AlertBox";
import { useEffect, useState } from "react";

const QueryContainer = () => {
  const [showAlertBox, setShowAlertBox] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/query/groups/")) {
      setShowAlertBox(true);
    } else {
      setShowAlertBox(false);
    }
  }, [location]);

  return (
    <div>
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h3 className="text-black">Code</h3>
        {showAlertBox && (
          <AlertBox suggestionWorkflowType="alert_creation_query_sidebar" />
        )}
      </div>
      <TabWithOutlet
        tabs={[
          {
            label: "All Queries",
            path: "/query",
          },
          {
            label: "Groups",
            path: "/query/groups",
          },
          {
            label: "Jobs",
            path: "/query/jobs",
            betaMode: true,
          },
          {
            label: "Autotune Jobs",
            path: "/query/autotune_jobs",
            betaMode: true,
          },
        ]}
      />
      <Outlet />
    </div>
  );
};

export { QueryContainer };
