import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowDownIcon, ArrowUpIcon } from "@/assets/icons";
import { Button, Stack } from "@/uiCore";
import { useState } from "react";
const AccordionTitle = ({ title, howToText, howToContent }) => {
    const [expanded, setExpanded] = useState(false);
    const handleClick = () => {
        setExpanded((prev) => !prev);
    };
    return (_jsxs("div", { children: [_jsxs(Stack, { className: "justify-content-between", children: [_jsx("h4", { className: "text-black", children: title }), " ", _jsxs(Button, { color: "link", onClick: handleClick, children: [howToText, " ", expanded ? _jsx(ArrowUpIcon, {}) : _jsx(ArrowDownIcon, {})] })] }), expanded ? (_jsxs("div", { children: [_jsx("h5", { children: howToText }), howToContent] })) : null] }));
};
export default AccordionTitle;
