import { Button } from "reactstrap";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { useCallback, useState } from "react";
import { COPY_INTERACTION_TIME } from "../../helpers/constants";
import { debounce } from "lodash";
import { copyToClipboard } from "../../services/browser";
import { ReactComponent as CopyOutline } from "../../assets/icons/copy_outline.svg";
import { ReactComponent as CopyFill } from "../../assets/icons/copy_fill.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as SelectIconSelected } from "../../assets/icons/quickstart_button_selected.svg";
import { ReactComponent as SelectIcon } from "../../assets/icons/quickstart_button.svg";

const CopyButton = ({
  size = "md",
  value,
  label = "Copy",
  copiedLabel = "Copied",
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const debouncedSetIsCopied = useCallback(
    debounce(setIsCopied, COPY_INTERACTION_TIME, { trailing: true }),
    [setIsCopied]
  );
  return (
    <Button
      size={size}
      className={classnames("d-flex gap-sm", styles.copy_button)}
      color={isCopied ? "success" : "primary"}
      onClick={(e) => {
        e.stopPropagation();
        copyToClipboard(value);
        setIsCopied(true);
        debouncedSetIsCopied(false);
      }}
    >
      <div>{isCopied ? <CheckIcon /> : <CopyOutline />}</div>
      {isCopied ? copiedLabel : label}
    </Button>
  );
};

const CopyIconButton = ({
  value,
  copiedLabel = "Copied",
  color = "white",
  isLight = false,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const debouncedSetIsCopied = useCallback(
    debounce(setIsCopied, COPY_INTERACTION_TIME, { trailing: true }),
    [setIsCopied]
  );
  return (
    <button
      className={styles.copy_icon_button}
      style={{ color }}
      onClick={(e) => {
        e.stopPropagation();
        copyToClipboard(value);
        setIsCopied(true);
        debouncedSetIsCopied(false);
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isCopied ? (
        <div className="d-flex align-items-center gap-xs">
          <CheckIcon />
          {copiedLabel}
        </div>
      ) : isLight ? (
        isHover ? (
          <CopyFill color="#004FBF" />
        ) : (
          <CopyFill />
        )
      ) : isHover ? (
        <CopyFill />
      ) : (
        <CopyOutline />
      )}
    </button>
  );
};

const CopyIconButtonFab = ({ value, copiedLabel = "Copied" }) => (
  <div className={classnames(styles.copy_button_fab)}>
    <CopyIconButton value={value} copiedLabel={copiedLabel} color="white" />
  </div>
);

const CodesBlock = ({ children }) => (
  <div className={styles.code}>
    {children}
    <div className={styles.code_copy_button}>
      <CopyIconButton value={children} copiedLabel="Copied" color="white" />
    </div>
  </div>
);

const InfoTextBlock = ({ children, fontColor, bgColor }) => (
  <div
    className={styles.text_info}
    style={{ "--fontColor": fontColor, "--bgColor": bgColor }}
  >
    {children}
  </div>
);

const SelectButton = ({ text, color, onClick, selected = false }) => (
  <div
    className={classnames(
      styles.select_button,
      { [styles.selected]: selected },
      "d-flex align-items-left gap-sm"
    )}
    style={{ "--color": color }}
    onClick={onClick}
  >
    {selected ? <SelectIconSelected /> : <SelectIcon />}
    {text}
  </div>
);

export {
  CopyButton,
  CopyIconButton,
  CopyIconButtonFab,
  CodesBlock,
  InfoTextBlock,
  SelectButton,
};
