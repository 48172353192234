import { useEffect, useState } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom"
import { ENV, INTERCOM_APP_ID } from "../../config"
import { IntercomChatBoot } from "../Intercom/IntercomChat"


const ContactUs = () => (
    <IntercomProvider appId={INTERCOM_APP_ID}>
        <ContactUsIntercom />
    </IntercomProvider>
)

const ContactUsIntercom = () => {
    const { boot, show } = useIntercom();
    useEffect(() => {
        show();
    }, [show]);
    boot({});

    return null;
};

export { ContactUs };