import styles from "./styles.module.scss";
import shines from "../../assets/icons/shines.svg";
import robot from "../../assets/icons/robot2.svg";
import { Button } from "reactstrap";
import SidebarModal from "../../Components/SidebarModal";
import { CopilotChat, useCopilot } from "../Copilot";
import { useCopilotActions } from "../Copilot/useCopilotActions";
import { Suggestions } from "../Copilot/Suggestions.jsx";
import { postTasks } from "../../helpers/backend_helper";
import { ACTIONS } from "../Copilot/state.js";
import { useEffect } from "react";
import { useState } from "react";
import { ScrollContainer } from "../../Components/ScrollContainer/index.js";

export const AlertBox = ({ suggestionWorkflowType = "alert_creation_sidebar" }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const {
    workflow,
    setWorkflow,
    showSuggestions,
    setShowSuggestions,
    dispatch,
    premium,
    config: copilotConfig,
  } = useCopilot();

  const { addNewChat, handleAgentError, resetWorkflow, _createSession } =
    useCopilotActions();

  useEffect(() => {
    return () => {
      resetWorkflow();
    };
  }, []);

  const toggleDatapilot = () => {
    if (!workflow) {
      addNewChat("alert-code-input-text", true, null, {
        isSubmitted: false,
        onSubmit: async ({ datastoreId, purpose, piiEnabled, config = {} }) => {
          setShowSuggestions(false);
          const workflow_inputs = {
            data_store_id: datastoreId,
            pii_enabled: piiEnabled,
            ...config,
            ...copilotConfig,
          };
          dispatch({ type: ACTIONS.SUBMIT });
          const requestData = {
            workflow_type: workflowType,
            purpose,
            workflow_inputs: workflow_inputs,
          };
          addNewChat("chat-loader", false, null, {
            label: "Creating a plan",
          });
          try {
            const { tasks: _tasks, pii: _pii } = await postTasks(requestData);
            dispatch({ type: ACTIONS.DELETE_ITEM });
            if (_pii) {
              addNewChat("chat-block", true, null, {
                data: _pii?.message,
              });
            } else {
              addNewChat("task-list", true, null, {
                tasks: _tasks,
                onSubmit: () => {
                  dispatch({ type: ACTIONS.SUBMIT });
                  _createSession(_tasks, requestData);
                },
              });
            }
          } catch (error) {
            handleAgentError(error);
          }
        },
      });
    }

    const workflowType = "alert_creation";
    setWorkflow(workflowType);

    setShowSidebar((b) => !b);
  };

  const handleSuggestionClick = (value) => {
    setShowSuggestions(false);
    dispatch({ type: ACTIONS.SET_PURPOSE, payload: { value } });
  };

  return (
    <>
      <div className={styles.alert_box}>
        <img src={shines} alt="shines" />
        <div className={styles.text}>Datapilot can help you to set a check</div>
        <Button className={styles.button} onClick={toggleDatapilot}>
          <img src={robot} alt="robot" />
          DataPilot Assist
        </Button>
      </div>
      <SidebarModal
        isOpen={showSidebar}
        toggleModal={toggleDatapilot}
        width="800"
        bgColor="gray"
      >
        <ScrollContainer>
          <div className={styles.scrollBox}>
            {showSuggestions && (
              <div className={styles.suggestions}>
                <Suggestions
                  workflow={suggestionWorkflowType}
                  handleSuggestionClick={handleSuggestionClick}
                  premium={premium}
                />
              </div>
            )}
            <CopilotChat />
          </div>
        </ScrollContainer>
      </SidebarModal>
    </>
  );
};
