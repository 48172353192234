import { Button, Form, Input, Label } from "reactstrap";
import styles from "./styles.module.scss";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { postIntegration } from "../../helpers/backend_helper";
import { FormInput } from "../Authentication/components/form";
import { GET_INTEGRATIONS } from "./constants";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export const AddIntegration = () => {
  const [integrationName, setIntegrationName] = useState("");
  const [envName, setEnvName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: postIntegration,
    onSuccess: (data) => {
      setError("");
      queryClient.invalidateQueries({ queryKey: [GET_INTEGRATIONS] });
      // open integrations models if successfully saved
      if (data.dbt_core_integration_id && envName) {
        navigate(`/settings/integrations/${data.dbt_core_integration_id}/${envName}`);
      } else {
        navigate("/settings/integrations");
      }
    },
    onError: (e) => {
      if (e.includes("already exists")) {
        setError("The name is already taken. Try another name");
      }
    },
  });

  return (
    <div className={styles.add_integration}>
      <div className={styles.title}>Add Integration</div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          mutate({ name: integrationName, environment: envName });
        }}
        className={styles.form}
      >
        <div className="mb-3">
          <Label className="form-label" htmlFor="name">
            Integration Name
          </Label>
          <Input
            className={classNames(styles.form_input, {
              [styles.form_error]: error,
            })}
            id="name"
            name="name"
            type="name"
            placeholder="Unique name of the Integration"
            onChange={(e) => {
              setError("");
              setIntegrationName(e.target.value);
            }}
            value={integrationName}
          />
          {error && <div className={styles.error_box}>{error}</div>}
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="type">
            Type of Integration
          </Label>
          <FormInput
            id="type"
            name="type"
            type="name"
            placeholder="dbt Core"
            disabled
            value={"dbt Core"}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="environmentName">
            Environment Name
          </Label>
          <FormInput
            id="environmentName"
            name="environmentName"
            placeholder="Environment Name like PROD"
            onChange={(e) => {
              setError("");
              setEnvName(e.target.value);
            }}
            value={envName}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            className={styles.cancel_button}
            outline
            onClick={() => navigate("/settings/integrations")}
          >
            <span className={styles.text}>Cancel</span>
          </Button>
          <Button
            type="submit"
            className={styles.save_button}
            disabled={!integrationName || !envName}
          >
            <span className={styles.text}>Save</span>
          </Button>
        </div>
      </Form>
    </div>
  );
};
