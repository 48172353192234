import { Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { LevelTag, TableTags } from "../../Components/Tags";
import list_icon from "../../assets/icons/list.svg";
import dayjs from "dayjs";
import { ReactComponent as ViolationIcon } from "../../assets/icons/violation_icon.svg";

const Policy = ({ policies }) => {
  return (
    <Card className="m-0 overflow-y">
      <CardBody className="d-flex flex-column overflow-y">
        <div className="d-flex align-items-center">
          <div className="me-2 fs-5 fw-semibold">Policy List</div>
          <div className={styles.count_label}>{policies.length}</div>
        </div>

        <div className={styles.policies_container}>
          {policies.map((item) => (
            <div key={item.policy_id} className={styles.card}>
              <div className="mb-2 d-flex align-items-center justify-content-between">
                <div className="bg-primary p-2 br-2">
                  <img src={list_icon} alt="" />
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-end me-3">
                    {item.last_violation_timestamp && (
                      <>
                        <div className="me-2 level-orange icon-sm">
                          <ViolationIcon />
                        </div>
                        <div>
                          {dayjs(item.last_violation_timestamp).format(
                            "DD MMM, YYYY"
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <LevelTag level={item.level} />
                </div>
              </div>
              <div className="mb-2 fs-5 fw-semibold">{item.name}</div>
              {(item.tables.length > 0 || item.description) && (
                <div className={classnames("mt-2 mb-3", styles.divider)} />
              )}
              <TableTags tables={item.tables} />
              <div className="mt-2 text-muted">{item.description}</div>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export { Policy };
