import React from "react";
import classNames from "classnames";
import { ReactComponent as DangerCircleCheck } from "../../assets/icons/circle_check_danger.svg";
import { ReactComponent as SuccessCircleCheck } from "../../assets/icons/circle_check_enabled.svg";
import { ReactComponent as DisabledCircleCheck } from "../../assets/icons/circle_check_disabled.svg";
import styles from "./components/styles.module.scss";

const passwordValidations = [
  "Use at least 8 characters",
  "Use a mix of upper and lower case characters",
  "Use 1 or more numbers",
  "Use 1 or more special characters",
];

export const PasswordValidation = ({ validatePassword }) => (
  <div
    className={classNames(styles.form_feedback, {
      [styles.is_error]: validatePassword.includes(-1),
      [styles.is_success]:
        !validatePassword.includes(-1) && !validatePassword.includes(0),
    })}
  >
    <span className="text-black">Password Should</span>
    {validatePassword.map((val, idx) => (
      <span
        key={idx}
        className={
          val === 0 ? "text-muted" : val === 1 ? "text-success" : "text-danger"
        }
      >
        {val === 0 ? (
          <DisabledCircleCheck />
        ) : val === 1 ? (
          <SuccessCircleCheck />
        ) : (
          <DangerCircleCheck />
        )}
        {passwordValidations[idx]}
      </span>
    ))}
  </div>
);
