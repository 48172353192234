import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { addSearchParams } from "../../helpers/utils";

const Tab = ({
  tabs,
  onSelectedTabChange,
  defaultTab = 0,
  fullscreen = false,
  pagedTab = true,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const navigate = useNavigate();

  const activeTabs = tabs.filter((t) => {
    return !t.disabled || t.disabled == false;
  });

  const handleTabClick = (index, path) => {
    if (path) {
      navigate(path);
    }
    setSelectedTab(index);
    if (pagedTab) {
      setSearchParams(addSearchParams("tab", index));
    }
    if (typeof onSelectedTabChange === "function") {
      onSelectedTabChange(activeTabs[index].label);
    }
  };

  useEffect(() => {
    if (!pagedTab) return;
    const tab = searchParams.get("tab");
    if (tab && !isNaN(tab) && tab >= 0 && tab < activeTabs.length) {
      setSelectedTab(parseInt(tab));
    }
  }, [activeTabs.length, pagedTab, searchParams]);

  return (
    <div className={classNames({ [styles.fullscreen_tab]: fullscreen })}>
      <div className={styles.tab_labels}>
        {activeTabs.map((tab, index) => (
          <div
            key={index}
            className={classNames(styles.tab_label, {
              [styles.active]: index === selectedTab,
            })}
            onClick={() => handleTabClick(index, tab?.path)}
          >
            <div className="d-flex justify-content-between align-items-center gap-xs">
              {tab.label}
              {tab.betaMode && (
                <div className={styles.beta}>
                  <div className={styles.text}>Beta</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={classNames({ [styles.content]: fullscreen })}>
        {activeTabs[selectedTab].component}
      </div>
    </div>
  );
};

const GhostTab = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <div className={styles.ghost_tab_container}>
      <div className="d-flex">
        {tabs.map(({ label, value }) => (
          <div
            className={classNames(styles.tab_item, {
              [styles.active]: selectedTab === value,
            })}
            key={value}
            onClick={() => setSelectedTab(value)}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

const TabWithOutlet = ({ tabs, defaultTab = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const navigate = useNavigate();
  const location = useLocation();

  const activeTabs = tabs.filter((t) => {
    return !t.disabled || t.disabled == false;
  });

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (location.pathname.includes(tab.path)) {
        setSelectedTab(index);
      }
    });
  }, [location, tabs]);

  const handleTabClick = (index, path) => {
    if (path) {
      navigate(path);
    }
    setSelectedTab(index);
  };

  return (
    <div className={styles.tab_labels}>
      {activeTabs.map((tab, index) => (
        <div
          key={index}
          className={classNames(styles.tab_label, {
            [styles.active]: index === selectedTab,
          })}
          onClick={() => handleTabClick(index, tab?.path)}
        >
          <div className="d-flex justify-content-between align-items-center gap-xs">
            {tab.label}
            {tab.betaMode && (
              <div className={styles.beta}>
                <div className={styles.text}>Beta</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export { Tab, GhostTab, TabWithOutlet };
