import styles from "./styles.module.scss";
import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/arrow_left.svg";

export const BackButton = ({ onClick }) => {
  return (
    <div className={styles.back_button} onClick={onClick}>
      <ArrowLeftIcon />
    </div>
  );
};
