import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import styles from "./styles.module.scss";
import text_logo from "../../assets/images/text_logo.svg";
import incidents from "../../assets/icons/incidents.svg";
import rocket from "../../assets/icons/rocket.svg";
import datasets from "../../assets/icons/datasets.svg";
import warehouse from "../../assets/icons/warehouse.svg";
import contract from "../../assets/icons/contract.svg";
import settings from "../../assets/icons/settings.svg";
import experimentation from "../../assets/icons/experimentation.svg";
import code from "../../assets/icons/code2.svg";
import copilot from "../../assets/icons/copilot.svg";
import colab from "../../assets/icons/collab_icon.svg";
import document from "../../assets/icons/document.svg";
import summary from "../../assets/icons/summary.svg";
import security from "../../assets/icons/security.svg";
import governance from "../../assets/icons/governance.svg";
import logo from "../../assets/images/logo.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow_right.svg";
import { ReactComponent as InviteUser } from "../../assets/icons/invite_user.svg";
import { Collapse } from "reactstrap";
import { useState } from "react";
import {
  getTenant,
  isCodeQuery,
  isFreeStack,
  isWarehouse,
} from "../../helpers/tenant";
import { BetaTag } from "../../Components/Tags";
import { useBoolean, useIsEnterpriseUser } from "../../helpers/hooks";
import { getUser } from "../../services/auth";
import { PermissionsEnum } from "../../helpers/constants";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getCreditUsage } from "../../helpers/backend_helper";
import { GET_CREDIT_USAGE } from "../Credits/queryKey";
import { useQuery } from "react-query";

const linkClass = classnames(
  "p-2 ps-3 mt-1 ms-3 me-3 d-flex align-items-center",
  styles.nav_link
);

const NormalItem = ({
  label,
  icon,
  hideIcon = false,
  isBeta = false,
  expanded = true,
}) => {
  return (
    <div className="w-100 d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <img
          src={icon}
          alt={label}
          className={classnames("me-2", { invisible: hideIcon })}
        />
        {expanded && <div>{label}</div>}
      </div>
      {isBeta && expanded && <BetaTag />}
    </div>
  );
};

const LinkItem = ({
  to,
  label,
  icon,
  hideIcon = false,
  isBeta = false,
  expanded = true,
}) => {
  const { pathname } = useLocation();
  const isSelected = () => {
    if (to?.startsWith("/summary")) {
      return pathname.startsWith("/summary");
    }

    return pathname === to;
  };
  return (
    <Link
      to={to}
      className={classnames(linkClass, {
        [styles.selected]: isSelected(),
      })}
    >
      <NormalItem
        label={label}
        icon={icon}
        hideIcon={hideIcon}
        isBeta={isBeta}
        expanded={expanded}
      />
    </Link>
  );
};

const CustomNavBox = ({
  to,
  label,
  icon,
  expanded,
  show,
  setShow,
  collapseItems,
  linkBox = true,
}) => (
  <div>
    <div className={styles.datasets_nav_link}>
      {linkBox ? (
        <LinkItem to={to} label={label} icon={icon} expanded={expanded} />
      ) : (
        <div className={linkClass}>
          <NormalItem label={label} icon={icon} expanded={expanded} />
        </div>
      )}
      {expanded && (
        <div
          onClick={(e) => {
            e.preventDefault();
            setShow((b) => !b);
          }}
        >
          <ArrowDown className={styles.settings_expand} />
        </div>
      )}
    </div>
    <Collapse isOpen={show && expanded}>
      {collapseItems.map((item, index) => (
        <LinkItem
          key={index}
          to={item.to}
          label={item.label}
          icon={item.icon}
          hideIcon={item.hideIcon}
        />
      ))}
    </Collapse>
  </div>
);

const InviteBox = ({ expanded }) => {
  const navigate = useNavigate();

  const handleInvite = () => {
    navigate("/settings/users", { state: { invite: true } });
  };

  return (
    <div className={styles.invite_box}>
      {expanded ? (
        <>
          <InviteUser />
          <div className={styles.title}>Invite User</div>
          <div className={styles.text}>
            Invite your team members to collaborate and accelerate the work
            together
          </div>
          <Button className={styles.invite_button} onClick={handleInvite}>
            Invite
          </Button>
        </>
      ) : (
        <InviteUser className={styles.pointer} onClick={handleInvite} />
      )}
    </div>
  );
};

const Sidebar = () => {
  const [showSettings, setShowSettings] = useState(false);
  const [showDatasets, setShowDatasets] = useState(false);
  const [showInfra, setShowInfra] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [showGovernance, setShowGovernance] = useState(false);
  const tenant = getTenant();
  const { value: expanded, setTrue, setFalse } = useBoolean(true);
  const user = getUser();
  const isFreeUser = isFreeStack();
  const navigate = useNavigate();
  const { isEnterpriseUser } = useIsEnterpriseUser();
  return (
    <div
      className={classnames("bg-white", styles.sidebar_container, {
        [styles.expanded]: expanded,
      })}
    >
      <div
        className={classnames(
          styles.logo,
          expanded ? "justify-content-between" : "justify-content-center"
        )}
      >
        <img src={expanded ? text_logo : logo} alt="Altimate AI" />
        {expanded && (
          <div
            className={styles.collapse_button_open}
            id="collapse-sidebar"
            onClick={() => {
              setFalse();
              setShowSettings(false);
            }}
          >
            <ArrowLeft />
          </div>
        )}
      </div>
      <div className="d-flex flex-column spacer">
        {!expanded && (
          <div className={styles.collapse_button_close} onClick={setTrue}>
            <ArrowRight />
          </div>
        )}
        {isFreeUser ? (
          <>
            <LinkItem
              to="/quickstart"
              label="Quickstart"
              icon={rocket}
              expanded={expanded}
            />
            <LinkItem
              to="/copilot"
              label="DataPilot"
              icon={copilot}
              expanded={expanded}
            />
            <LinkItem
              to="/collaboration/dbt-docs"
              label="Collab"
              icon={colab}
              expanded={expanded}
            />
            <CustomNavBox
              to={null}
              label="Code"
              icon={code}
              expanded={expanded}
              show={showCode}
              setShow={setShowCode}
              linkBox={false}
              collapseItems={[
                {
                  to: "/dbt_models",
                  label: "dbt",
                  hideIcon: true,
                },
              ]}
            />
            <CustomNavBox
              to="/governance/dbtconfig"
              label="Governance"
              icon={governance}
              expanded={expanded}
              show={showGovernance}
              setShow={setShowGovernance}
              collapseItems={[
                {
                  to: "/governance/dbtconfig",
                  label: "dbt Governance",
                  icon: settings,
                  hideIcon: true,
                },
              ]}
            />
            <div
              className={classnames("justify-content-between", linkClass)}
              onClick={(e) => {
                e.preventDefault();
                setTrue();
                setShowSettings((b) => !b);
              }}
            >
              <div className="d-flex align-items-center">
                <img src={settings} alt="Settings" className="me-2" />
                {expanded && <div>Settings</div>}
              </div>
              {expanded && <ArrowDown className={styles.settings_expand} />}
            </div>
            <Collapse isOpen={showSettings}>
              <LinkItem
                to="/settings/api-key"
                label="API Key"
                icon={settings}
                hideIcon
              />
              <LinkItem
                to="/settings/credits"
                label="Credits"
                icon={settings}
                hideIcon
              />
              <LinkItem
                to="/settings/users"
                label="Users"
                icon={settings}
                hideIcon
              />
              <LinkItem
                to="/settings/integrations"
                label="Integrations"
                icon={settings}
                hideIcon
              />
            </Collapse>
          </>
        ) : (
          <>
            {tenant !== "silq" && (
              <LinkItem
                to="/summary"
                label="Summary"
                icon={summary}
                expanded={expanded}
              />
            )}
            <CustomNavBox
              to="/datasets"
              label="Datasets"
              icon={datasets}
              expanded={expanded}
              show={showDatasets}
              setShow={setShowDatasets}
              collapseItems={[
                {
                  to: "/tables",
                  label: "Usage",
                  icon: settings,
                  hideIcon: true,
                },
              ]}
            />
            <LinkItem
              to="/experimentation"
              label="Experimentation"
              icon={experimentation}
              expanded={expanded}
              isBeta
            />
            {isCodeQuery() && (
              <LinkItem
                to="/query/all"
                label="Code"
                icon={code}
                expanded={expanded}
              />
            )}
            {isWarehouse() && (
              <CustomNavBox
                to="/warehouse"
                label="Infra"
                icon={warehouse}
                expanded={expanded}
                show={showInfra}
                setShow={setShowInfra}
                collapseItems={[
                  {
                    to: "/users",
                    label: "Users & Roles",
                    icon: settings,
                    hideIcon: true,
                  },
                ]}
              />
            )}
            {/* <LinkItem
              to="/contracts"
              label="Contract"
              icon={contract}
              expanded={expanded}
            /> */}
            <LinkItem
              to="/incidents"
              label="Incidents"
              icon={incidents}
              expanded={expanded}
            />
            <LinkItem
              to="/copilot"
              label="DataPilot"
              icon={copilot}
              expanded={expanded}
            />
            <div
              className={classnames("justify-content-between", linkClass)}
              onClick={(e) => {
                e.preventDefault();
                setTrue();
                setShowSettings((b) => !b);
              }}
            >
              <div className="d-flex align-items-center">
                <img src={settings} alt="Settings" className="me-2" />
                {expanded && <div>Settings</div>}
              </div>
              {expanded && <ArrowDown className={styles.settings_expand} />}
            </div>
            <Collapse isOpen={showSettings}>
              {!tenant ? (
                <LinkItem
                  to="/settings/companies"
                  label="Companies"
                  icon={settings}
                  hideIcon
                />
              ) : (
                <>
                  <LinkItem
                    to="/settings/datastores"
                    label="DataStores"
                    icon={settings}
                    hideIcon
                  />
                  <LinkItem
                    to="/settings/users"
                    label="Users"
                    icon={settings}
                    hideIcon
                  />
                  <LinkItem
                    to="/settings/api-key"
                    label="API Key"
                    icon={settings}
                    hideIcon
                  />
                  {isFreeUser && (
                    <LinkItem
                      to="/settings/integrations"
                      label="Integrations"
                      icon={settings}
                      hideIcon
                    />
                  )}
                  <LinkItem
                    to="/settings/notification"
                    label="Notifications"
                    icon={settings}
                    hideIcon
                  />
                  {user?.permission.includes(PermissionsEnum.SECURITY_VIEW) &&
                    !isFreeUser && (
                      <LinkItem
                        to="/settings/security"
                        label="Security"
                        icon={settings}
                        hideIcon
                      />
                    )}
                </>
              )}
            </Collapse>
          </>
        )}
        <div className="spacer" />
        <div className={styles.sidebar_bottom}>
          <InviteBox expanded={expanded} />
          {!isFreeUser && ( // Temporary change until the documentation is ready
            <>
              <div className="spacer" />
              <div className={styles.divider} />
              <div
                className={classnames("mb-2", linkClass)}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open("https://docs.altimate.ai/docs", "_blank");
                }}
              >
                <div className="d-flex align-items-center">
                  <img src={document} alt="Documentation" className="me-2" />
                  {expanded && <div>Documentation</div>}
                </div>
              </div>
            </>
          )}

          {/* Documentation Link */}
          {isFreeUser && ( // Show both links only for free users
            <>
              {expanded && !isEnterpriseUser && (
                <div className={styles.buy_subscription}>
                  <Button
                    className={styles.invite_button}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/settings/credits?manage_credits=true");
                    }}
                  >
                    Upgrade Subscription
                  </Button>
                </div>
              )}
              <div className="spacer" />
              <div className={styles.divider} />

              {/* Documentation Link */}
              <div
                className={classnames("mb-2", linkClass)}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open("https://docs.myaltimate.com", "_blank");
                }}
              >
                <div className="d-flex align-items-center">
                  <img src={document} alt="Documentation" className="me-2" />
                  {expanded && <div>Documentation</div>}
                </div>
              </div>

              {/* Security FAQ Link */}
              <div
                className={classnames("mb-2", linkClass)}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open("https://docs.myaltimate.com/arch/faq", "_blank");
                }}
              >
                <div className="d-flex align-items-center">
                  <img src={security} alt="Security FAQ" className="me-2" />
                  {expanded && <div>Security FAQ</div>}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export { Sidebar };
